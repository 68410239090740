import cn from 'classnames';
import React from 'react';
import { ThemeContext } from 'styled-components';
import { v4 } from 'uuid';

import css from './TextArea.module.scss';

interface IProps {
  fullWidth?: boolean;
  id?: string;
  error?: string;
  label?: string;
  value: string;
  onChange(value: string): void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
}

type InputProps = Omit<JSX.IntrinsicElements['textarea'], 'onChange'>;

const TextArea: React.FC<IProps & InputProps> = ({
  fullWidth = false,
  id = v4(),
  error,
  value,
  onChange,
  label,
  onBlur,
  className,
  ...textAreaProps
}) => {
  const theme = React.useContext(ThemeContext);
  const [hasBlurred, setHasBlurred] = React.useState(false);

  const onChangeWrapper = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  const onBlurWrapper = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setHasBlurred(true);
    onBlur?.(e);
  };

  return (
    <div
      className={cn(css.TextAreaWrapper, className, {
        [css.DarkTheme]: theme.dark,
      })}>
      {label && (
        <label className={css.Label} htmlFor={id}>
          {label}
        </label>
      )}
      <textarea
        id={id}
        {...textAreaProps}
        className={cn(css.TextArea, {
          [css.isInvalid]: hasBlurred && error,
          [css.fullWidth]: fullWidth,
        })}
        value={value}
        onBlur={onBlurWrapper}
        onChange={onChangeWrapper}
      />
      {hasBlurred && error && <div className={css.Error}>{error}</div>}
    </div>
  );
};

export default TextArea;
