import { BaseResponse, fetchJson, IPage, Methods } from 'api/index';

import { IUserData } from './ProfileAPI';

export interface IWatchedMovie {
  media: IMovie;
  status: TWatchedStatus;
  updated_at: string;
}

export interface IWatchedTVShow {
  media: ITVShow;
  status: TWatchedStatus;
  updated_at: string;
}

export interface IWatchListItem {
  media: ITVShow | IMovie;
  status: TWatchedStatus;
  updated_at: string;
}

export interface IPerson {
  id: number;
  birthday: string;
  birth_place: string;
  department: string;
  images: {
    profile: {
      w45: string;
      w185: string;
      h632: string;
      original: string;
    };
  };
  name: string;
  model: 'person';
}

export interface ICastMember {
  name: string;
  character: string;
  person: IPerson;
}

export interface ICrewMember {
  job: string;
  name: string;
  department: string;
  person: IPerson;
}

export interface IMovieCredit {
  cast: ICastMember[];
  crew: ICrewMember[];
}

export interface IPosterImages {
  w92: string;
  w154: string;
  w185: string;
  w342: string;
  w500: string;
  w780: string;
  original: string;
}

export interface IBackdropImages {
  w300: string;
  w780: string;
  w1280: string;
  original: string;
}

export type TWatchedStatus = 'watched' | 'want_to_watch' | 'watching' | 'not_for_me';

export interface IMovie {
  id: number;
  imdb_id: string;
  images: {
    backdrop: IBackdropImages;
    poster: IPosterImages;
  };
  overview: string;
  popularity: number;
  rating?: {
    updated_at: string;
    value: number;
  };
  release_date: string;
  genres?: string[];
  runtime: number;
  tagline: string;
  recommendation?: { updated_at: string };
  title?: string;
  name?: string;
  status: string;
  stream_url: string;
  videos: IMovieVideo[];
  vote_average: number;
  vote_count: number;
  watched?: {
    status: TWatchedStatus;
    updated_at: string;
  };
  model: 'movie';
  friend_recommendations?: IUserData[];
}

export interface IMovieVideo {
  name: string;
  type: string;
  source: string;
  country: string;
  language: string;
  source_id: string;
  resolution: number;
  embed_url: string;
  url: string;
}

export interface ITVShowCredits {
  cast: ICastMember[];
  crew: ICrewMember[];
}

export interface ITVShowEpiside {
  air_date: string;
  credits: {
    crew: ICrewMember[];
    guest_stars: ICastMember[];
  };
  images: {
    still: {
      w92: string;
      w185: string;
      w300: string;
      original: string;
    };
  };
  key: string;
  name: string;
  number: number;
  overview: string;
  vote_average: number;
  vote_count: number;
}

export interface ITVShowSeason {
  air_date: string;
  credits: ITVShowCredits;
  episodes: ITVShowEpiside[];
  images: {
    poster: IPosterImages;
  };
  key: string;
  name: string;
  number: number;
  overview: string;
}

export interface INetwork {
  logo: {
    w45?: string;
    w92?: string;
    w154?: string;
    w185?: string;
    w300?: string;
    w500?: string;
    original?: string;
  };
  name: string;
}

export interface ITVShow {
  id: number;
  genres: string[];
  images: {
    backdrop: IBackdropImages;
    poster: IPosterImages;
  };
  imdb_id: string;
  name: string;
  networks: INetwork[];
  overview: string;
  rating?: {
    value: number;
    updated_at: number;
  };
  recommendation?: { updated_at: string };
  status: string;
  stream_url: string;
  videos: ITVShowVideo[];
  vote_average: number;
  vote_count: number;
  watched?: { status?: TWatchedStatus; updated_at?: string } & {
    [key: string]: {
      status: TWatchedStatus;
      updated_at: number;
    };
  };
  model: 'tv_show';
  friend_recommendations?: IUserData[];
}

export interface ITVShowVideo {
  name: string;
  type: string;
  source: string;
  country: string;
  language: string;
  source_id: string;
  resolution: number;
  embed_url: string;
  url: string;
}

export type Column = 'name' | 'network' | 'rating' | 'date' | 'genre';
export type Direction = 'asc' | 'desc';

export default {
  fetchMovieCredits: (movieId: number): Promise<BaseResponse<IPage<IMovieCredit>>> =>
    fetchJson(`catalog/movie/${movieId}/credits`, Methods.GET),

  fetchMovie: (movieId: number): Promise<BaseResponse<IMovie>> =>
    fetchJson(`catalog/movie/${movieId}`, Methods.GET),

  fetchTVShowCredits: (showId: number): Promise<BaseResponse<ITVShowCredits>> =>
    fetchJson(`catalog/tv/${showId}/credits`, Methods.GET),

  fetchTVShowSeason: (showId: number, season: number): Promise<BaseResponse<ITVShowSeason>> =>
    fetchJson(`catalog/tv/${showId}/seasons/${season}`, Methods.GET),

  fetchTVShowSeasonList: (showId: number): Promise<BaseResponse<ITVShowSeason[]>> =>
    fetchJson(`catalog/tv/${showId}/seasons`, Methods.GET),

  fetchTVShow: (showId: number): Promise<BaseResponse<ITVShow>> =>
    fetchJson(`catalog/tv/${showId}`, Methods.GET),

  fetchMovieList: (
    sort: 'newest' | 'alpha' = 'alpha',
    page = 1,
  ): Promise<BaseResponse<IPage<IMovie>>> =>
    fetchJson(`catalog/movie?sort=${sort}&page=${page}`, Methods.GET),

  fetchMovieListByLetter: (letter: string, page = 1): Promise<BaseResponse<IPage<IMovie>>> =>
    fetchJson(`catalog/movie?letter=${letter.toUpperCase()}&page=${page}`, Methods.GET),

  fetchRecommendedMovieList: (): Promise<BaseResponse<IPage<IMovie>>> =>
    fetchJson('catalog/recommendations/movie?size=32', Methods.GET),

  fetchTVShowList: (
    sort: 'alpha' | 'newest' = 'alpha',
    page = 1,
  ): Promise<BaseResponse<IPage<ITVShow>>> =>
    fetchJson(`catalog/tv?sort=${sort}&page=${page}`, Methods.GET),

  fetchTVShowListByLetter: (letter: string, page = 1): Promise<BaseResponse<IPage<ITVShow>>> =>
    fetchJson(`catalog/tv?letter=${letter.toUpperCase()}&page=${page}`, Methods.GET),

  fetchRecommendedTVShowList: (): Promise<BaseResponse<IPage<ITVShow>>> =>
    fetchJson('catalog/recommendations/tv?size=32', Methods.GET),

  fetchRatingList: (): Promise<BaseResponse<IPage<IWatchListItem>>> =>
    fetchJson('catalog/ratings', Methods.GET),

  fetchWatchedMovies: (
    column: Column,
    direction: Direction,
    page = 1,
  ): Promise<BaseResponse<IPage<IWatchedMovie>>> =>
    fetchJson(
      `catalog/watched?type=movie&size=1000&page=${page}&status=watched,watching&sort_field=${column}&sort_dir=${direction}`,
      Methods.GET,
    ),

  fetchWatchedTVShows: (
    column: Column,
    direction: Direction,
    page = 1,
  ): Promise<BaseResponse<IPage<IWatchedTVShow>>> =>
    fetchJson(
      `catalog/watched?size=1000&type=tv&page=${page}&status=watched,watching&sort_field=${column}&sort_dir=${direction}`,
      Methods.GET,
    ),

  fetchWatchList: (page = 1): Promise<BaseResponse<IPage<IWatchListItem>>> =>
    fetchJson(`catalog/watched?status=want_to_watch&size=25&page=${page}`, Methods.GET),

  removeMovieRating: (movieId: number): Promise<BaseResponse<undefined>> =>
    fetchJson(`catalog/movie/${movieId}/rating`, Methods.DELETE),

  removeMovieWatchedStatus: (movieId: number): Promise<BaseResponse<undefined>> =>
    fetchJson(`catalog/movie/${movieId}/watched`, Methods.DELETE),

  removeTVShowRating: (showId: number): Promise<BaseResponse<undefined>> =>
    fetchJson(`catalog/tv/${showId}/rating`, Methods.DELETE),

  removeTVShowWatchedStatus: (
    showId: number,
    episodes: { key: string }[],
  ): Promise<BaseResponse<undefined>> =>
    fetchJson(`catalog/tv/${showId}/watched`, Methods.DELETE, { episodes }),

  submitMovieRating: (movieId: number, rating: number): Promise<BaseResponse<undefined>> =>
    fetchJson(`catalog/movie/${movieId}/rating`, Methods.POST, { rating }),

  submitMovieWatchedStatus: (
    movieId: number,
    status: TWatchedStatus,
  ): Promise<BaseResponse<undefined>> =>
    fetchJson(`catalog/movie/${movieId}/watched`, Methods.POST, { status }),

  submitMovieRecommendation: (
    movieId: number,
    recommended: boolean,
  ): Promise<BaseResponse<undefined>> =>
    fetchJson(`catalog/movie/${movieId}/recommend`, recommended ? Methods.POST : Methods.DELETE),

  submitTVShowRating: (showId: number, rating: number): Promise<BaseResponse<undefined>> =>
    fetchJson(`catalog/tv/${showId}/rating`, Methods.POST, { rating }),

  submitTVShowEpisodeWatchedStatus: (
    showId: number,
    episodes: { key: string; status: TWatchedStatus }[],
  ): Promise<BaseResponse<undefined>> =>
    fetchJson(`catalog/tv/${showId}/watched`, Methods.POST, { episodes }),

  submitTVShowWatchedStatus: (
    showId: number,
    status: TWatchedStatus | null,
  ): Promise<BaseResponse<undefined>> =>
    fetchJson(`catalog/tv/${showId}/watched`, Methods.POST, { status }),

  submitTVShowRecommendation: (
    showId: number,
    recommended: boolean,
  ): Promise<BaseResponse<undefined>> =>
    fetchJson(`catalog/tv/${showId}/recommend`, recommended ? Methods.POST : Methods.DELETE),

  submitWantToWatch: (showId: number): Promise<BaseResponse<undefined>> =>
    fetchJson(`catalog/tv/${showId}/want_to_watch`, Methods.POST),

  removeWantToWatch: (showId: number): Promise<BaseResponse<undefined>> =>
    fetchJson(`catalog/tv/${showId}/want_to_watch`, Methods.DELETE),

  removeWatchedStatus: (showId: number): Promise<BaseResponse<undefined>> =>
    fetchJson(`catalog/tv/${showId}/watched/all`, Methods.DELETE),
};
