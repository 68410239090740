import React from 'react';
import { Link, useParams } from 'react-router-dom';

import ContentBox from 'components/ContentBox';
import LoginPageWrapper from 'components/LoginPageWrapper';

import css from './ForgotPasswordRequestSuccess.module.scss';

const ForgotPasswordRequestSuccess: React.FC = () => {
  const { email } = useParams();

  return (
    <LoginPageWrapper>
      <ContentBox title="We've sent you an email!">
        <p>
          We have sent an email with the instructions to
          <br />
          {email}.
          <br />
          <br />
          Please check your inbox. If you don&apos;t see the email
          <br />
          shortly, check your Spam folder.
        </p>
        <div className={css.LoginButtonWrapper}>
          <Link to='/login'>
            <div className={css.LoginButton}>Log In</div>
          </Link>
        </div>
      </ContentBox>
    </LoginPageWrapper>
  );
};

export default ForgotPasswordRequestSuccess;
