import React from 'react';

import Input from 'components/Input';
import Spinner from 'components/Spinner';
import SCButton from 'components/styled/SCButton';
import LoggedInPageWrapper from 'containers/LoggedInPageWrapper';
import UserList from 'containers/pages/Dashboard/Friends/UserList';
import { inviteFriendAction } from 'stores/FriendsStore';
import { SearchUsersStore } from 'stores/SearchStore';

const SearchField: React.FC = () => {
  const [search, setSearch] = React.useState('');
  const [term, setTerm] = React.useState<string>('');
  const users = SearchUsersStore.usePaginatedData({ term });

  const onSubmit: React.FormEventHandler = (e) => {
    e.preventDefault();
  };

  React.useEffect(() => {
    if (search === '') return;

    const timeout = setTimeout(() => {
      setTerm(search);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [search]);

  return (
    <form onSubmit={onSubmit}>
      <Input type='search' value={search} onChange={setSearch} placeholder='Search' />
      {users.isLoaded ? <UserList users={users.items} /> : <Spinner />}
    </form>
  );
};

const AddAFriend: React.FC = () => {
  const [inviteEmails, setInviteEmails] = React.useState('');
  const sendInvites = () => {
    inviteEmails.split(/, */).forEach((email) => {
      inviteFriendAction.run({ email });
    });
    setInviteEmails('');
  };

  return (
    <LoggedInPageWrapper bg='white' title='Add a Friend'>
      <SearchField />
      <br />
      <br />
      <Input
        label='Invite Friends (to add multiple emails add a comma between each one)'
        type='text'
        placeholder='Friends Email'
        value={inviteEmails}
        onChange={(emails) => setInviteEmails(emails)}
      />
      <SCButton onClick={sendInvites} disabled={inviteEmails.length === 0}>
        Send Invites
      </SCButton>
    </LoggedInPageWrapper>
  );
};

export default AddAFriend;
