import { rgba } from 'polished';

export default {
  headerHeight: '100px',
  fonts: {
    heading: 'Gala, sans-serif',
    body: `'Helvetica Neue', 'Segoe UI', sans-serif`,
    icon: '"Font Awesome 5 Free"',
  },
  colors: {
    primary: '#9c171a',
    border: rgba('black', 0.5),
    text: {
      link: '#9c171a',
      error: '#f00',
      disabled: '#999',
      placeholder: {
        dark: rgba('#ccc', 0.4),
        light: rgba('black', 0.4),
      },
    },
    background: {
      site: '#0d0d0d',
      gray: '#333',
      red: '#9c171a',
      error: '#fae4e4',
      disabled: '#d0d0d0',
      dropdown: '#999',
      input: {
        dark: rgba('#d2cebc', 0.2),
        light: rgba('#d2cebc', 0.2),
      },
    },
  },
};
