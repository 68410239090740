import useIsMobile from 'hooks/useIsMobile';
import useOnClickOutside from 'hooks/useOnClickOutside';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Input from 'components/Input';
import SCNavLink from 'components/styled/SCNavLink';

const SCSpacer = styled.div`
  flex-grow: 1;
`;

const SCForm = styled.form`
  display: inline-block;
  flex-grow: 1;
  justify-content: flex-end;
  position: relative;

  @media (max-width: 1000px) {
    margin-left: 40px;
  }
`;

const SCInput = styled(Input)`
  margin-bottom: 0;

  input {
    padding-right: 50px;
  }
`;

const SCSubmitButton = styled(SCNavLink)`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;

const HeaderSearch: React.FC = () => {
  const history = useHistory();
  const [showInput, setShowInput] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const isMobile = useIsMobile();
  const formRef = React.useRef<HTMLFormElement>(null);

  const onSubmit: React.FormEventHandler = (e) => {
    e.preventDefault();
    if (!search) return;

    setSearch('');
    setShowInput(false);
    history.push(`/dashboard/search/all/${encodeURIComponent(search)}`);
  };

  useOnClickOutside(() => {
    if (showInput) setShowInput(false);
  });

  React.useLayoutEffect(() => {
    if (formRef.current == null) return;

    formRef.current.querySelector('input')?.focus();
  }, [showInput]);

  return showInput ? (
    <SCForm onSubmit={onSubmit} ref={formRef} onClick={(e) => e.stopPropagation()}>
      <SCInput
        type='search'
        value={search}
        onChange={setSearch}
        placeholder={
          isMobile ? 'Search for a movie...' : 'Search for a movie, tv show, user or group…'
        }
      />
      <SCSubmitButton type='submit'>
        <i className='fas fa-search' />
      </SCSubmitButton>
    </SCForm>
  ) : (
    <>
      <SCSpacer />
      <SCNavLink onClick={() => setShowInput(true)}>
        <i className='fas fa-search' />
      </SCNavLink>
    </>
  );
};

export default HeaderSearch;
