import React from 'react';
import SCNavLink from '../../components/styled/SCNavLink';
import { useLocation } from 'react-router-dom';

const NavLink: React.FC<React.ComponentProps<typeof SCNavLink>> = ({ href, ...rest }) => {
  const { pathname } = useLocation();
  return (
    <SCNavLink isActive={href != null && pathname.indexOf(href) === 0} href={href} {...rest} />
  );
};

export default NavLink;
