import moment from 'moment';
import { rgba } from 'polished';
import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import GroupsAPI, { IGroup, IGroupPost } from 'api/GroupsAPI';
import Input from 'components/Input';
import SCAvatar from 'components/styled/SCAvatar';
import SCButton from 'components/styled/SCButton';
import SCHideForMobile from 'components/styled/SCHideForMobile';
import SCShowForMobile from 'components/styled/SCShowForMobile';
import variables from 'components/styled/variables';
import UploadMedia from 'components/UploadMedia';
import LoggedInPageWrapper from 'containers/LoggedInPageWrapper';
import GroupCoverBar from 'containers/pages/Dashboard/Groups/GroupCoverBar';
import MembersModal from 'containers/pages/Dashboard/Groups/MembersModal';
import uploadIcon from 'img/paperclip.png';
import { GroupFeedStore } from 'stores/groups/GroupFeedStore';
import { GroupListStore } from 'stores/groups/GroupListStore';
import { GroupMembersStore } from 'stores/groups/GroupMembersStore';

import LeaveGroupModal from './LeaveGroupModal';

const SCNoCommentsWrapper = styled.div`
  margin-top: 20px;
`;

const SCSelect = styled.select`
  border-radius: 10px;
  color: #666;
  height: 36px;
  padding: 0 40px 0 20px;

  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 20px) 50%, calc(100% - 15px) 50%;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const SCGroupTitle = styled.div`
  font-weight: 500;
`;

const SCGroupDescription = styled.div`
  color: white;
  font-size: 16px;
`;

const SCGroupActionWrapper = styled.div`
  display: flex;
  margin: 20px 0;
`;

const SCGroupCommentsWrapper = styled.div`
  padding: 0 0 20px;
`;

const SCCommentAndHeadingWrapper = styled.div`
  & + & {
    margin-top: 20px;
  }
`;

const SCComment = styled.div`
  background: black;
  border: 1px solid white;
  border-radius: 10px;
  box-sizing: border-box;
  max-width: 840px;
  padding: 20px;
`;

const SCCommentHeader = styled.div`
  align-items: center;
  display: flex;
`;

const SCUserName = styled.div`
  flex-grow: 1;
  margin-left: 20px;
`;

const SCCommentBody = styled.div`
  margin-top: 20px;
`;

const SCGroupsBar = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 500;
  justify-content: space-between;
  line-height: 40px;
  margin-bottom: 5px;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const SCGroupWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: wrap;
`;

const SCNewCommentWrapper = styled.div`
  margin-top: 40px;
`;

const SCNewCommentForm = styled.form`
  display: flex;
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
    height: auto;
  }
`;

const SCInput = styled(Input)`
  line-height: 60px;
  width: 100%;

  input {
    padding-right: 50px;
    border-radius: ${(props) => (props.hasAttachment ? '10px 10px 0 0' : '10px')}
`;

const SCPostButton = styled.button`
  align-items: center;
  background: ${variables.colors.background.red};
  border: 0;
  border-radius: 30px;
  color: white;
  display: flex;
  font-family: Gala, sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  height: 60px;
  justify-content: center;
  line-height: 60px;
  padding: 0 30px;

  &[disabled] {
    background-color: ${rgba('#d0d0d0', 0.5)};
    color: ${rgba('white', 0.5)};
  }

  .fas {
    display: block;
    font-size: 16px;
    margin-right: 10px;
  }

  @media (max-width: 1000px) {
    border-radius: 10px;
  }
`;

const SCBottomButton = styled(SCButton)`
  text-decoration: underline;
`;

const SCDateHeading = styled.div`
  border-top: 1px solid ${rgba('white', 0.125)};
  margin-top: 40px;
  padding: 20px 0;
  font-size: 20px;
  font-weight: 500;
`;

const SCMobileActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SCCommentAttachment = styled.img`
  max-width: 100%;
  max-height: 300px;
  margin-top: 10px;
`;

const SCUploadAttachmentButton = styled(SCButton)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 18px;
  height: 60px;

  img {
    max-height: 40px;
  }
`;

const SCUploadAttachmentError = styled.p`
  color: ${variables.colors.text.error};
  text-align: center;
  margin-top: 10px;
`;

const SCAttachmentWrapper = styled.div`
  padding: 34px 20px 19px;
  background-color: ${variables.colors.background.input.dark};
  border-top: solid 1px rgba(196, 196, 196, 0.3);
  border-radius: 0 0 10px 10px;
`;
const SCInputAttachmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  margin-right: 10px;
  position: relative;

  @media (max-width: 1000px) {
    margin-right: 0;
    margin-bottom: 5px;
  }
`;
const SCAttachment = styled.div`
  display: inline-block;
  position: relative;

  img {
    max-width: 100%;
    max-height: 300px;
  }
`;

const SCRemoveAttachmentButton = styled.button`
  background: white;
  border: none;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  font-family: sans-serif;
  font-size: 20px;
  height: 40px;
  position: absolute;
  right: -20px;
  top: -20px;
  width: 40px;
`;

const getTimeString = (dateString: string): string => moment(dateString).format('hh:mm a');

let commentAvatars: Record<number, string> = {};
const Comments: React.FC<{ groupId: number }> = ({ groupId }) => {
  const comments = GroupFeedStore.usePaginatedData({ groupId });

  /*
   * The URLS of the avatars change on every GET- as they are presigned- which causes all of the avatars to flash.
   * This prevents that.
   */
  commentAvatars = comments.items.reduce((accum, comment) => {
    if (!accum[comment.id]) {
      accum[comment.id] = comment?.user?.avatar ?? '';
    }
    return accum;
  }, commentAvatars);

  React.useEffect(() => {
    const interval = setInterval(() => {
      GroupFeedStore.fetchAction.run({ groupId }, { treatAsUpdate: true });
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [groupId]);

  const shouldDisplayDateHeading = (index: number): boolean => {
    const previousComment = comments.items[index - 1];
    const comment = comments.items[index];
    if (previousComment == null) return true;

    const currentDate = moment(comment.created_at);
    const previousDate = moment(previousComment.created_at);
    return !currentDate.isSame(previousDate, 'day');
  };

  const getDateHeading = (comment: IGroupPost): string => {
    const date = moment(comment.created_at);
    const today = moment();
    const yesterday = moment().subtract(1, 'day');

    if (date.isSame(today, 'day')) return 'Today';
    if (date.isSame(yesterday, 'day')) return 'Yesterday';

    const format = date.isSame(today, 'year') ? 'MMMM D' : 'MMMM D, YYYY';
    return date.format(format);
  };

  return (
    <>
      <NewComment groupId={groupId} onSuccess={comments.refresh} />
      <SCGroupCommentsWrapper>
        {comments.items.map((comment, index) => (
          <SCCommentAndHeadingWrapper key={comment.id}>
            {shouldDisplayDateHeading(index) && (
              <SCDateHeading>{getDateHeading(comment)}</SCDateHeading>
            )}

            <SCComment>
              <SCCommentHeader>
                <SCAvatar variant='comment' imageUrl={commentAvatars[comment.id]} />
                <SCUserName>@{comment.user.username}</SCUserName>
                <div>{getTimeString(comment.created_at)}</div>
              </SCCommentHeader>
              <SCCommentBody>{comment.post.text}</SCCommentBody>
              {comment.post?.attachments?.[0]?.url && (
                <SCCommentAttachment src={comment.post?.attachments?.[0]?.url} />
              )}
            </SCComment>
          </SCCommentAndHeadingWrapper>
        ))}
        {comments.items.length === 0 && (
          <SCNoCommentsWrapper>There are no comments yet.</SCNoCommentsWrapper>
        )}
      </SCGroupCommentsWrapper>
    </>
  );
};

const GroupsBar: React.FC<{
  canEditGroup: boolean;
  groupId: number;
  groups: IGroup[];
}> = ({ canEditGroup, groupId, groups }) => {
  const history = useHistory();

  const showSelect = groups.length > 1;

  const onGroupChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    history.push(`/dashboard/groups/details/${e.target.value}`);
  };

  return groups[0] == null ? (
    <Redirect to='/dashboard/groups' />
  ) : (
    <SCGroupsBar>
      <SCGroupWrapper>
        {showSelect ? (
          <SCSelect value={groupId} onChange={onGroupChange}>
            {groups.map((group) => (
              <option key={group.id} value={group.id}>
                {group.title}
              </option>
            ))}
          </SCSelect>
        ) : (
          <SCGroupTitle>{groups[0].title}</SCGroupTitle>
        )}
      </SCGroupWrapper>
      <SCHideForMobile>
        {canEditGroup && (
          <SCButton as='a' link href={`/dashboard/groups/edit/${groupId}`}>
            Edit Group
          </SCButton>
        )}
        <SCButton as='a' link href='/dashboard/groups/create'>
          + Create a Group
        </SCButton>
      </SCHideForMobile>
      <SCShowForMobile>
        <SCMobileActions>
          {canEditGroup ? (
            <SCButton as='a' link href={`/dashboard/groups/edit/${groupId}`}>
              Edit
            </SCButton>
          ) : (
            <div />
          )}
          <SCButton as='a' link href='/dashboard/groups/create'>
            + Create a Group
          </SCButton>
        </SCMobileActions>
      </SCShowForMobile>
    </SCGroupsBar>
  );
};

const NewComment: React.FC<{ groupId: number; onSuccess: () => void }> = ({
  groupId,
  onSuccess,
}) => {
  const [comment, setComment] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [attachment, setAttachment] = React.useState<{
    mediaId?: number;
    url?: string;
  }>({});
  const onSubmit: React.FormEventHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const json = await GroupsAPI.postToGroupFeed(groupId, comment, attachment?.mediaId);
      if (!json.success) throw new Error(json.error.message);

      onSuccess();
    } catch (err) {
      console.error(err);
    }
    setComment('');
    setAttachment({});
    setIsLoading(false);
  };

  return (
    <SCNewCommentWrapper>
      <SCNewCommentForm onSubmit={onSubmit}>
        <SCInputAttachmentWrapper>
          <SCInput
            disabled={isLoading}
            noMargin
            onChange={setComment}
            value={comment}
            fullWidth
            placeholder='Write a comment...'
            type='text'
            hasAttachment={!!attachment?.url}
          />
          <UploadMedia
            type='attachment'
            onError={setError}
            onSuccess={(attachment) => {
              setAttachment(attachment);
              setError('');
            }}>
            {(triggerUpload, resetFileInput) => (
              <>
                <SCUploadAttachmentButton link={true} onClick={triggerUpload} type='button'>
                  <img alt='Upload Attachment' src={uploadIcon} />
                </SCUploadAttachmentButton>
                {attachment?.url && (
                  <SCAttachmentWrapper>
                    <SCAttachment>
                      <img alt='' src={attachment?.url} />
                      <SCRemoveAttachmentButton
                        onClick={() => {
                          setAttachment({});
                          resetFileInput();
                        }}>
                        &times;
                      </SCRemoveAttachmentButton>
                    </SCAttachment>
                  </SCAttachmentWrapper>
                )}
              </>
            )}
          </UploadMedia>
        </SCInputAttachmentWrapper>
        <SCPostButton disabled={isLoading || (comment.length < 3 && !attachment?.mediaId)}>
          <i className='fas fa-pen' />
          <span>Post</span>
        </SCPostButton>
      </SCNewCommentForm>
      {!!error && <SCUploadAttachmentError>{error}</SCUploadAttachmentError>}
    </SCNewCommentWrapper>
  );
};

interface IProps {
  group: IGroup;
}

const GroupDetailsMemberView: React.FC<IProps> = ({ group }) => {
  const groups = GroupListStore.usePaginatedData();
  const members = GroupMembersStore.usePaginatedData({ groupId: group.id });

  const [shouldShowMembersModal, setShouldShowMembersModal] = React.useState(false);
  const [shouldShowLeaveModal, setShouldShowLeaveModal] = React.useState(false);

  const canEditGroup = group?.membership?.role === 'creator';
  const isGroupMember = group?.membership?.status === 'accepted';

  const isLoading = groups.isLoading || members.isLoading;

  return (
    <LoggedInPageWrapper bg='gray' title='My Groups' isLoading={isLoading}>
      <GroupsBar canEditGroup={canEditGroup} groupId={Number(group.id)} groups={groups.items} />
      <GroupCoverBar coverUrl={group.cover} avatarUrl={group.avatar} />
      <SCGroupDescription>{group.description}</SCGroupDescription>
      <SCGroupActionWrapper>
        <SCBottomButton link onClick={() => setShouldShowMembersModal(true)}>
          {members.items.length} members
        </SCBottomButton>
        {isGroupMember && group.membership?.role !== 'creator' && (
          <SCBottomButton link onClick={() => setShouldShowLeaveModal(true)}>
            Leave Group
          </SCBottomButton>
        )}
      </SCGroupActionWrapper>
      {isGroupMember && <Comments groupId={Number(group.id)} />}
      {shouldShowMembersModal && (
        <MembersModal groupId={group.id} onRequestClose={() => setShouldShowMembersModal(false)} />
      )}
      {shouldShowLeaveModal && (
        <LeaveGroupModal groupId={group.id} onRequestClose={() => setShouldShowLeaveModal(false)} />
      )}
    </LoggedInPageWrapper>
  );
};

export default GroupDetailsMemberView;
