import { rgba } from 'polished';
import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import Spinner from 'components/Spinner';
import SCHideForMobile from 'components/styled/SCHideForMobile';
import SCShowForMobile from 'components/styled/SCShowForMobile';
import Theme from 'components/Theme';

const SCBlackout = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  background-color: ${rgba('black', 0.4)};
`;

const SCModal = styled.div<{ customStyle: string | undefined }>`
  background: white;
  border-radius: 10px;
  width: calc(100% - 20px);
  max-width: 950px;
  position: relative;
  min-height: 140px;

  ${(props) => (props.customStyle ? props.customStyle : '')};
`;

const SCCloseButton = styled.button<{ customStyle: string | undefined }>`
  outline: none;
  background: white;
  border: 1px solid ${rgba('black', 0.2)};
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  font-family: sans-serif;
  font-size: 30px;
  height: 60px;
  position: absolute;
  right: 60px;
  top: 60px;
  width: 60px;
  position: absolute;
  top: 40px;
  right: 40px;
  padding: 0;

  @media (max-width: 1000px) {
    top: 20px;
    right: 20px;
  }

  ${(props) => (props.customStyle ? props.customStyle : '')};
`;

const SCSpinner = styled(Spinner)`
  margin: 80px;
  font-size: 30px;
  text-align: center;
  opacity: 0.5;
`;

interface IProps {
  children: React.ReactNode;
  isLoading?: boolean;
  onRequestClose: () => void;
  modalStyles?: string;
  closeButtonStyles?: string;
  mobileCloseButtonCharacter?: string;
}

const GenericModal: React.FC<IProps> = ({
  children,
  isLoading = false,
  onRequestClose,
  modalStyles,
  closeButtonStyles,
  mobileCloseButtonCharacter
}) => {
  return ReactDOM.createPortal(
    <SCBlackout onClick={onRequestClose}>
      <Theme theme='light'>
        <SCModal onClick={(e) => e.stopPropagation()} customStyle={modalStyles}>
          {isLoading ? (
            <SCSpinner />
          ) : (
            <>
              {children}
              {mobileCloseButtonCharacter
                ? <SCCloseButton customStyle={closeButtonStyles} onClick={onRequestClose}>
                  <SCShowForMobile>
                    {mobileCloseButtonCharacter}
                  </SCShowForMobile>
                  <SCHideForMobile>
                    &times;
                  </SCHideForMobile>
                </SCCloseButton>
                : <SCCloseButton customStyle={closeButtonStyles} onClick={onRequestClose}>
                   &times;
                </SCCloseButton>  
              }
            </>
          )}
        </SCModal>
      </Theme>
    </SCBlackout>,
    document.body,
  );
};

export default GenericModal;
