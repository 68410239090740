import Link from '../../../components/Link';
import MaxWidthWrapper from '../../../components/MaxWidthWrapper';
import variables from '../../../components/styled/variables';
import React from 'react';
import styled from 'styled-components';
import SCSection from './SCSection';

const SCCTAButton = styled(Link)`
  line-height: 60px;
  background: ${variables.colors.background.red};
  color: white;
  padding: 0 80px;
  display: inline-block;
  border-radius: 5px;
  margin-top: 20px;
`;

const SCCreateAccountSection = styled.div`
  align-items: center;
  display: flex;
  margin: 60px;

  h1 {
    font-size: 75px;
  }

  h2 {
    max-width: 410px;
  }

  > * {
    flex-grow: 1;
  }

  & + & {
    margin-left: 60px;
  }

  @media (max-width: 1050px) {
    flex-direction: column;
    padding-bottom: 60px;

    h1 {
      text-align: center;
    }
  }

  @media (max-width: 1000px) {
    margin: 0;
    padding: 60px 20px;
    text-align: center;
    h1 {
      font-size: 30px;
      margin-top: 0;
    }
    h2 {
      font-size: 16px;
      font-weight: normal;
    }
  }
`;

const CreateAccountSection: React.FC = () => {
  return (
    <SCSection>
      <MaxWidthWrapper>
        <SCCreateAccountSection>
          <div>
            <h1>Sign up for free.</h1>
            <h2>
              Create your account for free and get these features and more, just for signing up!
            </h2>
          </div>
          <div>
            <SCCTAButton href='/register'>Sign Up</SCCTAButton>
          </div>
        </SCCreateAccountSection>
      </MaxWidthWrapper>
    </SCSection>
  );
};

export default CreateAccountSection;
