import SCAvatar from '../../components/styled/SCAvatar';
import SCNavLink from '../../components/styled/SCNavLink';
import { useCurrentUser } from '../../stores/auth/CurrentUserStore';
import { rgba } from 'polished';
import { Store } from 'pullstate';
import React from 'react';
import styled from 'styled-components';

import variables from 'components/styled/variables';
import logo from 'img/logo.svg';

import NavLink from './NavLink';

const SCWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: ${rgba('black', 0.25)};
`;

const SCMenu = styled.div`
  position: absolute;
  top: 0;
  right: 80px;
  bottom: 0;
  left: 0;
  background: black;

  ${SCNavLink} {
    display: block;
    margin: 0 0 5px;
    line-height: 200%;
  }
`;

const SCHeader = styled.div`
  background: ${variables.colors.background.red};
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;

  img {
    height: 40px;
  }
`;

const SCCloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: black;
  color: ${rgba('white', 0.5)};
`;

const SCSpacer = styled.div`
  flex-grow: 1;
`;

const SCLinkWrapper = styled.div`
  padding: 20px;
`;

const SCBrowseWrapper = styled.div`
  padding: 0 20px;

  h1 {
    font-size: inherit;
    font-weight: normal;
    color: ${rgba('white', 0.5)};
  }

  div {
    padding-left: 20px;
  }
`;

const SlideOutMenuStore = new Store(false);

export const showSlideOutModal = (): void => SlideOutMenuStore.update(() => true);
export const hideSlideOutModal = (): void => SlideOutMenuStore.update(() => false);

const SlideOutMenu: React.FC = () => {
  const [isShowingBrowse, setIsShowingBrowse] = React.useState(false);
  const currentUser = useCurrentUser();
  const isVisible = SlideOutMenuStore.useState();

  const toggleBrowseMenu: React.MouseEventHandler<HTMLAnchorElement> = () => {
    setIsShowingBrowse((v) => !v);
  };

  return isVisible ? (
    <SCWrapper onClick={hideSlideOutModal}>
      <SCMenu onClick={(e) => e.stopPropagation()}>
        <SCHeader>
          <img src={logo} alt='BingeRoom' />
          <SCSpacer />
          <SCNavLink href='/dashboard/profile'>
            <SCAvatar variant='header' imageUrl={currentUser?.avatar} />
          </SCNavLink>
        </SCHeader>
        <SCLinkWrapper>
          <NavLink href='/dashboard/movies'>My Movies</NavLink>
          <NavLink href='/dashboard/shows'>My Shows</NavLink>
          <NavLink href='/dashboard/groups'>My Groups</NavLink>
          <NavLink href='/dashboard/browse' onClick={toggleBrowseMenu} noClick>
            Browse <i className='fas fa-caret-down' />
          </NavLink>
          {isShowingBrowse && (
            <SCBrowseWrapper>
              <h1>TV Shows</h1>
              <div>
                <NavLink href='/dashboard/browse/tv/new'>New Releases</NavLink>
                <NavLink href='/dashboard/browse/tv/all'>All</NavLink>
              </div>
              <h1>Movies</h1>
              <div>
                <NavLink href='/dashboard/browse/movies/new'>New Releases</NavLink>
                <NavLink href='/dashboard/browse/movies/all'>All</NavLink>
              </div>
            </SCBrowseWrapper>
          )}
          <NavLink href='/dashboard/alerts'>Alerts</NavLink>
        </SCLinkWrapper>
      </SCMenu>
      <SCCloseButton onClick={hideSlideOutModal}>
        <i className='fas fa-times' />
      </SCCloseButton>
    </SCWrapper>
  ) : null;
};

export default SlideOutMenu;
