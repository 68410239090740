import useEffectOnce from '../../../hooks/useEffectOnce';
import cn from 'classnames';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { IFollower, IFollowing } from 'api/FollowAPI';
import { IUserData } from 'api/ProfileAPI';
import Input from 'components/Input';
import { ProfileReview as Review } from 'components/Review';
import SCButton from 'components/styled/SCButton';
import SCHideForMobile from 'components/styled/SCHideForMobile';
import SCNavLink from 'components/styled/SCNavLink';
import SCPageTitle from 'components/styled/SCPageTitle';
import SCShowForMobile from 'components/styled/SCShowForMobile';
import SCShowMoreButtonWrapper from 'components/styled/SCShowMoreButtonWrapper';
import variables from 'components/styled/variables';
import UploadMedia from 'components/UploadMedia';
import GenericMediaCarousel from 'containers/GenericMediaCarousel';
import LoggedInPageWrapper from 'containers/LoggedInPageWrapper';
import { RemoveFollowerPrompt, UnfollowPrompt } from 'containers/modals/FollowerPrompts';
import GenericModal from 'containers/modals/GenericModal';
import WatchVideoModal from 'containers/modals/WatchVideoModal';
import UserList from 'containers/pages/Dashboard/Friends/UserList';
import UserRow from 'containers/pages/Dashboard/Friends/UserRow';
import getImageUrlString from 'helpers/getImageUrlString';
import {
  IProfileUserState,
  logoutAction,
  setProfile,
  useCurrentUser,
} from 'stores/auth/CurrentUserStore';
import { FollowerStore, FollowingStore, MayKnowStore } from 'stores/FollowStore';
import { inviteFriendAction } from 'stores/FriendsStore';
import { GroupListStore } from 'stores/groups/GroupListStore';
import { UserRecommendedMovieStore } from 'stores/movies/MovieStore';
import { UsersReviewsStore } from 'stores/ReviewStore';
import { UserRecommendedShowStore } from 'stores/shows/ShowStore';
import { UserIsWatchingStore, WatchListStore } from 'stores/WatchListStore';

import css from './Profile.module.scss';

const SCHero = React.memo(styled.div<{ imageUrl: string | null }>`
  background-color: ${variables.colors.background.red};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${(props) => getImageUrlString(props.imageUrl)};
  height: 485px;
  margin: 0 -60px 40px;
  max-width: 1440px;
  overflow: hidden;
  position: relative;

  > * {
    z-index: 100;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    background-color: ${rgba('black', 0.5)};
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  @media (max-width: 1000px) {
    margin: -20px -20px 40px;
  }
`);

const SCErrorMessage = styled.p`
  color: ${variables.colors.text.error};
  margin: 0 0 40px;
  text-align: center;
`;

const SCUser = styled.div`
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: 1px 1px 5px ${rgba('black', 0.5)};
  z-index: 100;
`;

const SCUserName = styled.div`
  font-family: Gala, sans-serif;
  text-transform: uppercase;
  font-size: 50px;
  left: 50%;
  margin: 10px 0 0;
  text-align: center;
`;

const SCFullName = styled.div`
  color: rgba(white, 0.5);
  font-size: 16px;
`;

const SCLabel = styled.h1`
  font-size: 20px;
  font-weight: 500;
  margin: 60px 0 20px;

  &:first-child {
    margin-top: 0;
  }
`;

const SCProfileLink = styled(SCNavLink)`
  width: 215px;
  text-align: center;
  margin: 0;
  span {
    color: grey;
  }
`;

const customModalStyles = `
  max-width: 720px;
`;

const SCModalForm = styled.form`
  padding: 49px 49px 94px;

  @media (max-width: 1000px) {
    padding: 35px 35px 62px;
  }
`;

const SCButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 40px 0 0;
`;

const SCClearButton = styled(SCButton)`
  width: calc(50% - 10px);

  @media (max-width: 1000px) {
    padding: 0;
  }
`;

const SCSubmitButton = styled(SCButton)`
  width: calc(50% - 10px);

  @media (max-width: 1000px) {
    padding: 0;
  }
`;

const SCModalHeaderContainer = styled.div`
  height: 110px;
  display: flex;
  padding-left: 57px;
  align-items: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.15);
`;

const SCModalHeader = styled.h1`
  font-family: Gala;
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 74px;
  margin: 0;
`;

const SCModalBodyContainer = styled.div`
  padding: 30px 65px 0;

  @media (max-width: 1000px) {
    padding: 10px 40px 0;
  }
`;

const SCMobileModalHeader = styled.h2`
  text-align: center;
`;

const SCMobileModalNav = styled.button<{ highlight: boolean }>`
  background: white;
  cursor: pointer;
  height: 50px;
  width: 50%;
  border: 0;
  border-bottom: ${(props) => (props.highlight ? 'solid 1px red' : '')};
`;

const SCSearch = styled(Input)`
  @media (max-width: 1000px) {
    margin-bottom: 10px;

    input {
      height: 36px;
    }
  }
`;

const modalStyles = `
  max-height: 550px;
  overflow: hidden;
  overflow-y: scroll;

  @media (max-width: 1000px) {
    max-height: none;
    height: 100%;
    width: 100%;
  }
`;

const modalCloseButtonStyles = `
  @media (max-width: 1000px) {
    border: 0;
    left: 20px;
    right: auto;
    top: 0;
  }
`;

const Avatar: React.FC<{ user: IProfileUserState; onError?: (error: string) => void }> = ({
  user,
  onError,
}) => {
  return (
    <UploadMedia type='avatar' onError={onError}>
      {(onClick: (event: React.MouseEvent) => void) => (
        <button className={css.Avatar} onClick={onClick}>
          {user.avatar ? (
            <div
              className={css.UserAvatar}
              style={{ backgroundImage: getImageUrlString(user.avatar) }}
            />
          ) : (
            <i className={cn('fas fa-user fa-8x', css.DefaultIcon)} />
          )}
          <div className={css.Overlay}>
            <i className={cn('fas fa-camera', css.CameraIcon)} />
            <div className={css.Text}>Update Thumbnail</div>
          </div>
        </button>
      )}
    </UploadMedia>
  );
};

interface IProps {
  onError?: (error: string) => void;
}
const UpdateBackgroundButton: React.FC<IProps> = ({ onError }) => {
  return (
    <UploadMedia type='cover' onError={onError}>
      {(onClick: (event: React.MouseEvent) => void) => (
        <button className={cn(css.Button, css.UpdateBackgroundButton)} onClick={onClick}>
          <i className={cn('fas fa-camera', css.Icon)} />
          <span className={css.ButtonText}>Update Background Photo</span>
        </button>
      )}
    </UploadMedia>
  );
};

const EditProfileModal: React.FC<{ hideModal: () => void; currentUser: IProfileUserState }> = ({
  currentUser,
  hideModal,
}) => {
  const [firstName, setFirstName] = React.useState(currentUser.firstName);
  const [lastName, setLastName] = React.useState(currentUser.lastName);

  const onSubmitForm: React.FormEventHandler = (e) => {
    e.preventDefault();
    setProfile.run({ lastName, firstName });
    hideModal();
  };

  const onClearForm = () => {
    setFirstName(currentUser.firstName);
    setLastName(currentUser.lastName);
  };

  return (
    <GenericModal onRequestClose={hideModal} modalStyles={customModalStyles}>
      <SCModalForm onSubmit={onSubmitForm}>
        <SCPageTitle>Edit Info</SCPageTitle>
        <Input
          type='text'
          label='First Name'
          value={firstName || ''}
          onChange={setFirstName}
          fullWidth={true}
        />
        <Input
          type='text'
          label='Last Name'
          value={lastName || ''}
          onChange={setLastName}
          fullWidth={true}
        />
        <SCButtonWrapper>
          <SCClearButton horizontal cancel onClick={onClearForm}>
            Clear
          </SCClearButton>
          <SCSubmitButton horizontal>Submit</SCSubmitButton>
        </SCButtonWrapper>
      </SCModalForm>
    </GenericModal>
  );
};

const FollowModal: React.FC<{
  hideModal: () => void;
  initialState: 'following' | 'followers';
  followers: IFollower[];
  following: IFollowing[];
  currentUser: IProfileUserState;
}> = ({ hideModal, initialState = 'following', followers, following, currentUser }) => {
  const [search, setSearch] = React.useState('');
  const [state, setState] = React.useState(initialState);
  const [removeFollowerUser, setRemoveFollowerUser] = React.useState<IFollower>();
  const [unfollowUser, setUnfollowUser] = React.useState<IFollowing>();
  const hidePrompt = () => {
    setUnfollowUser(undefined);
    setRemoveFollowerUser(undefined);
  };
  React.useEffect(() => {
    setSearch('');
  }, [state]);

  const filteredFollowers = React.useMemo(
    () =>
      followers.filter((user) => {
        const lowerCaseSearch = search.toLowerCase();
        return (
          user.follower.username.toLowerCase().match(lowerCaseSearch) ||
          user.follower.first_name?.toLowerCase().match(lowerCaseSearch) ||
          user.follower.last_name?.toLowerCase().match(lowerCaseSearch)
        );
      }),
    [followers, search],
  );
  const filteredFollowing = React.useMemo(
    () =>
      following.filter((user) => {
        const lowerCaseSearch = search.toLowerCase();
        return (
          user.subject.username.toLowerCase().match(lowerCaseSearch) ||
          user.subject.first_name?.toLowerCase().match(lowerCaseSearch) ||
          user.subject.last_name?.toLowerCase().match(lowerCaseSearch)
        );
      }),
    [following, search],
  );

  return (
    <>
      <GenericModal
        onRequestClose={hideModal}
        modalStyles={modalStyles}
        closeButtonStyles={modalCloseButtonStyles}
        mobileCloseButtonCharacter='<'>
        <SCHideForMobile>
          <SCModalHeaderContainer>
            <SCModalHeader>{state === 'followers' ? 'Followers' : 'Following'}</SCModalHeader>
          </SCModalHeaderContainer>
        </SCHideForMobile>
        <SCShowForMobile>
          <SCMobileModalHeader>@{currentUser.username}</SCMobileModalHeader>
          <SCMobileModalNav highlight={state === 'followers'} onClick={() => setState('followers')}>
            {followers.length} Followers
          </SCMobileModalNav>
          <SCMobileModalNav highlight={state === 'following'} onClick={() => setState('following')}>
            {following.length} Following
          </SCMobileModalNav>
        </SCShowForMobile>
        <SCModalBodyContainer>
          <SCSearch
            type='search'
            value={search}
            onChange={setSearch}
            placeholder={state === 'followers' ? 'Search my followers' : 'Search who I\'m following'}
            fullWidth={true}
          />
          {state === 'followers'
            ? filteredFollowers.map((user) => (
              <UserRow
                key={user.follower.id}
                buttonAction={() => setRemoveFollowerUser(user)}
                buttonText='Remove'
                user={user.follower}
              />
            ))
            : filteredFollowing.map((user) => (
              <UserRow
                key={user.subject.id}
                buttonAction={() => setUnfollowUser(user)}
                buttonText='Unfollow'
                user={user.subject}
              />
            ))}
        </SCModalBodyContainer>
      </GenericModal>
      {removeFollowerUser?.follower && (
        <RemoveFollowerPrompt
          removeFollowerUser={removeFollowerUser.follower}
          onRequestClose={hidePrompt}
        />
      )}
      {unfollowUser?.subject && (
        <UnfollowPrompt unfollowUser={unfollowUser.subject} onRequestClose={hidePrompt} />
      )}
    </>
  );
};

export interface ProfilePrompt {
  prompt: SetPromptState | null;
}

export interface SetPromptState {
  state: 'follower' | 'following';
  user: IUserData;
}

const Profile: React.FC<{ currentUser: IProfileUserState }> = ({ currentUser }) => {
  const watchlist = WatchListStore.usePaginatedData();
  const groups = GroupListStore.usePaginatedData();

  const userWatchlist = UserIsWatchingStore.usePaginatedData({ userId: currentUser.id });
  const recommendedShows = UserRecommendedShowStore.usePaginatedData({ userId: currentUser.id });
  const recommendedMovies = UserRecommendedMovieStore.usePaginatedData({ userId: currentUser.id });

  const followers = FollowerStore.usePaginatedData();
  const following = FollowingStore.usePaginatedData();
  const peopleYouMayKnow = MayKnowStore.usePaginatedData();

  const reviews = UsersReviewsStore.usePaginatedData({ userId: currentUser.id });

  const [inviteEmails, setInviteEmails] = React.useState('');
  const [modalName, setModalName] = React.useState('');
  const [error, setError] = React.useState<string | null>(null);

  const showModal = (modalName: string) => setModalName(modalName);
  const hideModal = () => setModalName('');
  const sendInvites = () => {
    inviteEmails.split(/, */).forEach((email) => {
      inviteFriendAction.run({ email });
    });
    setInviteEmails('');
  };

  useEffectOnce(() => {
    if (watchlist.isLoaded) WatchListStore.fetchAction.clearAllCache();
    if (groups.isLoaded) GroupListStore.fetchAction.clearAllCache();
    if (userWatchlist.isLoaded) UserIsWatchingStore.fetchAction.clearAllCache();
    if (recommendedShows.isLoaded) UserRecommendedShowStore.fetchAction.clearAllCache();
    if (recommendedMovies.isLoaded) UserRecommendedMovieStore.fetchAction.clearAllCache();
    if (followers.isLoaded) FollowerStore.fetchAction.clearAllCache();
    if (following.isLoaded) FollowingStore.fetchAction.clearAllCache();
    if (peopleYouMayKnow.isLoaded) MayKnowStore.fetchAction.clearAllCache();
    if (reviews.isLoaded) UsersReviewsStore.fetchAction.clearAllCache();
  });

  if (currentUser == null) {
    return null;
  }

  const { username, cover, firstName, lastName } = currentUser;

  const isLoading = groups.isLoading || watchlist.isLoading;

  return (
    <LoggedInPageWrapper bg='gray' noTopPadding isLoading={isLoading}>
      <SCHero imageUrl={cover}>
        <UpdateBackgroundButton onError={(error) => setError(error)} />
        <SCUser>
          <Avatar user={currentUser} onError={(error) => setError(error)} />
          <SCUserName>@{username}</SCUserName>
          <SCFullName>
            {firstName} {lastName}
          </SCFullName>
        </SCUser>
        <div className={css.ButtonWrapper}>
          <button className={css.Button} onClick={() => showModal('edit')}>
            Edit Info
          </button>
        </div>
      </SCHero>
      {error != null && <SCErrorMessage>Error: {error}</SCErrorMessage>}
      <div className={css.Content}>
        <div className={css.LinkWrapper}>
          <SCProfileLink onClick={() => showModal('followers')}>
            {followers.count} <span>Followers</span>
          </SCProfileLink>
          <SCProfileLink onClick={() => showModal('following')}>
            {following.count} <span>Following</span>
          </SCProfileLink>
          <SCProfileLink onClick={logoutAction}>Sign Out</SCProfileLink>
        </div>
        {userWatchlist.items.length > 0 && (
          <>
            <SCLabel>Currently Watching</SCLabel>
            <GenericMediaCarousel media={userWatchlist.items} />
          </>
        )}
        {recommendedShows.items.length > 0 && (
          <>
            <SCLabel>Shows Recommended</SCLabel>
            <GenericMediaCarousel media={recommendedShows.items} />
          </>
        )}
        {recommendedMovies.items.length > 0 && (
          <>
            <SCLabel>Movies Recommended</SCLabel>
            <GenericMediaCarousel media={recommendedMovies.items} />
          </>
        )}
        <br />
        <br />
        <Input
          label='Invite Friends (to add multiple emails add a comma between each one)'
          type='text'
          placeholder='Friends Email'
          value={inviteEmails}
          onChange={(emails) => setInviteEmails(emails)}
        />
        <SCButton onClick={sendInvites} disabled={inviteEmails.length === 0}>
          Send Invites
        </SCButton>
        {following.items.length > 0 && peopleYouMayKnow.items.length > 0 && (
          <>
            <SCLabel>People You May Want To Follow</SCLabel>
            <UserList users={peopleYouMayKnow.items} />
            {peopleYouMayKnow.hasMorePages && (
              <SCShowMoreButtonWrapper>
                <SCButton
                  transparent={true}
                  disabled={peopleYouMayKnow.isUpdating}
                  onClick={peopleYouMayKnow.loadNextPage}>
                  SEE MORE
                </SCButton>
              </SCShowMoreButtonWrapper>
            )}
          </>
        )}
        {reviews.items.length > 0 && (
          <>
            <SCLabel>Reviews</SCLabel>
            {reviews.items.map((review) => (
              <Review
                key={review.id}
                review={review}
                user={currentUser}
                show={review.tv_show}
                movie={review.movie}
              />
            ))}
            {reviews.hasMorePages && (
              <SCShowMoreButtonWrapper>
                <SCButton
                  transparent={true}
                  disabled={reviews.isUpdating}
                  onClick={reviews.loadNextPage}>
                  SEE MORE
                </SCButton>
              </SCShowMoreButtonWrapper>
            )}
          </>
        )}
      </div>
      {modalName === 'edit' && <EditProfileModal currentUser={currentUser} hideModal={hideModal} />}
      {(modalName === 'followers' || modalName === 'following') && (
        <FollowModal
          hideModal={hideModal}
          initialState={modalName}
          followers={followers.items}
          following={following.items}
          currentUser={currentUser}
        />
      )}
      <WatchVideoModal />
    </LoggedInPageWrapper>
  );
};

export default function ProfileLoader() {
  const currentUser = useCurrentUser();
  return currentUser == null ? null : <Profile currentUser={currentUser} />;
}
