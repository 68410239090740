import cn from 'classnames';
import React from 'react';

import css from './FormButton.module.scss';

interface IProps {
  type: 'submit' | 'cancel';
  isDisabled?: boolean;
  children: React.ReactNode;
}

type ButtonProps = Omit<JSX.IntrinsicElements['button'], 'type'>;

const FormButton: React.FC<IProps & ButtonProps> = ({
  type,
  isDisabled = false,
  children,
  ...buttonProps
}) => {
  const buttonType = type === 'submit' ? 'submit' : 'button';
  return (
    <button
      type={buttonType}
      className={cn(css.FormButton, {
        [css.isDisabled]: isDisabled,
        [css.isTransparent]: type === 'cancel',
      })}
      disabled={isDisabled}
      {...buttonProps}>
      {children}
    </button>
  );
};

export default FormButton;
