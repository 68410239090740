import FriendsAPI, { IFriend, TFriendWatchItem } from 'api/FriendsAPI';
import { FriendRequestStore } from 'stores/AlertsStore';
import { createGenericAsyncAction, createPaginationStore } from 'stores/index';

export const FriendStore = createPaginationStore<undefined, IFriend>((action, page) =>
  FriendsAPI.fetchFriendsList(page),
);

export const FriendWatchListStore = createPaginationStore<undefined, TFriendWatchItem>(
  (action, page) => FriendsAPI.fetchWhatFriendsAreWatching(page),
);

export const addFriendAction = createGenericAsyncAction<{ userId: number }, undefined>(
  ({ userId }) => FriendsAPI.requestFriend(userId),
  () => FriendStore.refresh(),
);

export const acceptFriendRequestAction = createGenericAsyncAction<{ userId: number }, undefined>(
  ({ userId }) => FriendsAPI.accept(userId),
  () => FriendRequestStore.refresh(),
);

export const removeFriendAction = createGenericAsyncAction<{ userId: number }, undefined>(
  ({ userId }) => FriendsAPI.removeFriend(userId),
  () => FriendRequestStore.refresh(),
);

export const inviteFriendAction = createGenericAsyncAction<{ email: string }, undefined>(
  ({ email }) => FriendsAPI.sendInvite(email),
);
