import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Input from 'components/Input';
import Link from 'components/Link';
import SCButton from 'components/styled/SCButton';
import SCShowMoreButtonWrapper from 'components/styled/SCShowMoreButtonWrapper';
import LoggedInPageWrapper from 'containers/LoggedInPageWrapper';
import { FriendStore } from 'stores/FriendsStore';

import FriendsList from './FriendsList';

const SCAddLink = styled(Link)`
  color: black;
  float: right;
  font-size: 16px;
  font-weight: 500;

  @media (max-width: 1000px) {
    position: absolute;
    top: 37px;
    right: 20px;
  }
`;

const SCLabel = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 10px;
  display: block;
`;

const SearchField: React.FC = () => {
  const history = useHistory();
  const onClick = () => {
    history.push('/dashboard/friends/add');
  };

  return (
    <>
      <SCLabel>You have no friends at the moment.</SCLabel>
      <SCButton onClick={onClick}>Add a friend</SCButton>
    </>
  );
};

const Friends: React.FC = () => {
  const [search, setSearch] = React.useState('');

  const friends = FriendStore.usePaginatedData();

  const filteredFriends = React.useMemo(
    () =>
      friends.items.filter((friend) => {
        const lowerCaseSearch = search.toLowerCase();
        return (
          friend.user.username.toLowerCase().match(lowerCaseSearch) ||
          friend.user.first_name?.toLowerCase().match(lowerCaseSearch) ||
          friend.user.last_name?.toLowerCase().match(lowerCaseSearch)
        );
      }),
    [friends, search],
  );

  return (
    <LoggedInPageWrapper bg='white' title='Friends' isLoading={friends.isLoading}>
      <SCAddLink href='/dashboard/friends/add'>+ Add a Friend</SCAddLink>
      {friends.items.length ? (
        <>
          <Input type='search' value={search} onChange={setSearch} placeholder='Search Friends' />
          <FriendsList friends={filteredFriends} />
          {friends.hasMorePages && (
            <SCShowMoreButtonWrapper>
              <SCButton disabled={friends.isUpdating} onClick={friends.loadNextPage}>
                Show More
              </SCButton>
            </SCShowMoreButtonWrapper>
          )}
        </>
      ) : (
        <SearchField />
      )}
    </LoggedInPageWrapper>
  );
};

export default Friends;
