import throttle from 'lodash/throttle';
import { useEffect, useState } from 'react';

const getIsMobile = (w: number): boolean => {
  return w < 1000;
};

export default function (): boolean {
  const defaultIsMobile = getIsMobile(window.innerWidth);
  const [isMobile, setIsMobile] = useState(defaultIsMobile);

  useEffect(() => {
    const calcInnerWidth = function () {
      const updatedIsMobile = getIsMobile(window.innerWidth);

      // Avoids rerenders on every resize called
      if (updatedIsMobile !== isMobile) {
        setIsMobile(updatedIsMobile);
      }
    };
    const trottledInnerWidth = throttle(calcInnerWidth, 200);

    window.addEventListener('resize', trottledInnerWidth);
    return () => window.removeEventListener('resize', trottledInnerWidth);
  });

  return isMobile;
}
