import useEffectOnce from '../../../hooks/useEffectOnce';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { IGroup } from 'api/GroupsAPI';
import { IUserData } from 'api/ProfileAPI';
import Link from 'components/Link';
import FriendsWatchingCarousel from 'containers/FriendsWatchingCarousel';
import GenericMediaCarousel from 'containers/GenericMediaCarousel';
import GroupsCarousel from 'containers/GroupsCarousel';
import LoggedInPageWrapper from 'containers/LoggedInPageWrapper';
import FriendSearchInput from 'containers/SearchInput/FriendSearchInput';
import GroupSearchInput from 'containers/SearchInput/GroupSearchInput';
import { FriendWatchListStore } from 'stores/FriendsStore';
import { GroupListStore } from 'stores/groups/GroupListStore';
import { RecommendedMovieStore } from 'stores/movies/MovieStore';
import { RecommendedShowStore } from 'stores/shows/ShowStore';
import { WatchListStore } from 'stores/WatchListStore';

const SCLabel = styled.h1`
  font-size: 20px;
  font-weight: 500;
  margin: 60px 0 20px;

  &:first-child {
    margin-top: 0;
  }
`;

const SCLink = styled(Link)`
  font-weight: 500;
  color: white;
`;

const Dashboard: React.FC = () => {
  const history = useHistory();

  const friendsWatchlist = FriendWatchListStore.usePaginatedData();
  const groups = GroupListStore.usePaginatedData();
  const watchList = WatchListStore.usePaginatedData();
  const recommendedShows = RecommendedShowStore.usePaginatedData();
  const recommendedMovies = RecommendedMovieStore.usePaginatedData();

  useEffectOnce(() => {
    if (friendsWatchlist.isLoaded) FriendWatchListStore.fetchAction.clearAllCache();
    if (groups.isLoaded) GroupListStore.fetchAction.clearAllCache();
    if (watchList.isLoaded) WatchListStore.fetchAction.clearAllCache();
    if (recommendedShows.isLoaded) RecommendedShowStore.fetchAction.clearAllCache();
    if (recommendedMovies.isLoaded) RecommendedMovieStore.fetchAction.clearAllCache();
  });

  const isLoading =
    friendsWatchlist.isLoading ||
    groups.isLoading ||
    watchList.isLoading ||
    recommendedShows.isLoading ||
    recommendedMovies.isLoading;

  const onSelectFriend = (friend: { item: IUserData }) => {
    history.push(`/dashboard/user/${friend.item.id}`);
  };

  const onSelectGroup = (group: { item: IGroup }) => {
    history.push(`/dashboard/groups/details/${group.item.id}`);
  };

  return (
    <LoggedInPageWrapper bg='gray' isLoading={isLoading}>
      <>
        {groups.items.length > 0 && (
          <>
            <SCLabel>Groups</SCLabel>
            <GroupsCarousel groups={groups.items} />
          </>
        )}
        {friendsWatchlist.items.length > 0 && (
          <>
            <SCLabel>Friends are Watching</SCLabel>
            <FriendsWatchingCarousel items={friendsWatchlist.items} />
          </>
        )}
        {watchList.items.length > 0 && (
          <>
            <SCLabel>Watch List</SCLabel>
            <GenericMediaCarousel media={watchList.items} />
          </>
        )}
        {recommendedShows.items.length > 0 && (
          <>
            <SCLabel>Recommended Shows</SCLabel>
            <GenericMediaCarousel media={recommendedShows.items} />
          </>
        )}
        {recommendedMovies.items.length > 0 && (
          <>
            <SCLabel>Recommended Movies</SCLabel>
            <GenericMediaCarousel media={recommendedMovies.items} />
          </>
        )}
        {!friendsWatchlist.items.length && (
          <>
            <SCLabel>Friends</SCLabel>
            <FriendSearchInput
              value={null}
              cacheOptions
              onChange={onSelectFriend}
              placeholder='Search'
              menuPlacement='top'
            />
          </>
        )}
        {!groups.items.length && (
          <>
            <SCLabel>Groups</SCLabel>
            <GroupSearchInput value={null} menuPlacement='top' onChange={onSelectGroup} />
            <SCLink href='/dashboard/groups/create'>+ Create Group</SCLink>
          </>
        )}
      </>
    </LoggedInPageWrapper>
  );
};

export default Dashboard;
