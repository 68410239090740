import { createAsyncAction, errorResult, Store, successResult } from 'pullstate';

import GroupsAPI, { IGroup } from 'api/GroupsAPI';
import ProfileAPI from 'api/ProfileAPI';
import { GroupInviteStore, GroupRequestStore } from 'stores/AlertsStore';
import { GroupListStore } from 'stores/groups/GroupListStore';
import { createGenericAsyncAction } from 'stores/index';

import { GroupMembersStore } from './GroupMembersStore';

export interface IGroupDetailsStore {
  [key: number]: IGroup;
}

export const GroupDetailsStore = new Store<IGroupDetailsStore>({});

GroupDetailsStore.createReaction(
  (s) => s,
  (groupStore) => {
    GroupListStore.store.update((s) => {
      s.items = s.items.map((item) => groupStore[item.id] ?? item);
    });
  },
);

export const fetchGroupAction = createGenericAsyncAction<{ groupId: number }, IGroup>(
  ({ groupId }) => GroupsAPI.fetchGroup(groupId),
  ({ groupId }, group) => {
    GroupDetailsStore.update((s) => {
      s[groupId] = group;
    });
  },
);

export const uploadGroupImage = createAsyncAction<
  { groupId: number; type: 'avatar' | 'cover'; image: string },
  undefined
>(async ({ groupId, type, image }) => {
  try {
    const imageJson = await ProfileAPI.uploadImage(
      type,
      image.replace('data:image/jpeg;base64,', ''),
    );
    if (!imageJson.success) throw new Error(imageJson.error.message);

    const avatarJson = await GroupsAPI.setGroupImage(groupId, imageJson.response.media_id, type);
    if (!avatarJson.success) throw new Error(avatarJson.error.message);

    return successResult();
  } catch (err) {
    return errorResult([], err.message);
  }
});

export const editGroupAction = createGenericAsyncAction<
  {
    groupId: number;
    description: string;
    isPublic: boolean;
    title: string;
    avatar?: string | null;
    cover?: string | null;
  },
  IGroup
>(
  async ({ groupId, description, isPublic, title, avatar, cover }) => {
    if (avatar != null) {
      const json = await uploadGroupImage.run({ groupId, type: 'avatar', image: avatar });
      if (json.error) return { success: false, error: { message: json.message } };
    }
    if (cover != null) {
      const json = await uploadGroupImage.run({ groupId, type: 'cover', image: cover });
      if (json.error) return { success: false, error: { message: json.message } };
    }
    return GroupsAPI.editGroup(groupId, description, isPublic, title);
  },
  ({ groupId }) => {
    GroupListStore.refresh();
    fetchGroupAction.run({ groupId }, { treatAsUpdate: true });
  },
  ({ groupId, description, isPublic, title }) => {
    GroupListStore.store.update((store) => {
      store.items.forEach((item) => {
        if (item.id !== groupId) return;
        item.description = description;
        item.is_public = isPublic;
        item.title = title;
      });
    });
  },
);

export const createGroupAction = createGenericAsyncAction<
  {
    description: string;
    isPublic: boolean;
    title: string;
    avatar?: string | null;
    cover?: string | null;
  },
  IGroup
>(async ({ description, isPublic, title, avatar, cover }) => {
  const groupJson = await GroupsAPI.createGroup(description, isPublic, title);
  if (!groupJson.success) throw new Error(groupJson.error.message);

  const groupId = groupJson.response.id;

  if (avatar != null) {
    await uploadGroupImage.run({ groupId, type: 'avatar', image: avatar });
  }

  if (cover != null) {
    await uploadGroupImage.run({ groupId, type: 'cover', image: cover });
  }

  const editJson = await editGroupAction.run({
    groupId,
    description,
    isPublic,
    title,
    avatar,
    cover,
  });
  if (editJson.error) throw new Error(editJson.message);

  // Manually recreate expected response, since we're relying on edit here
  return { success: true, response: editJson.payload };
});

export const joinGroupAction = createGenericAsyncAction<{ groupId: number }, undefined>(
  ({ groupId }) => GroupsAPI.joinPublicGroup(groupId),
  ({ groupId }) => {
    GroupListStore.refresh();
    fetchGroupAction.run({ groupId }, { treatAsUpdate: true });
    GroupMembersStore.fetchAction.run({ groupId }, { treatAsUpdate: true });
  },
);

export const requestToJoinGroupAction = createGenericAsyncAction<{ groupId: number }, undefined>(
  ({ groupId }) => GroupsAPI.joinPublicGroup(groupId),
  ({ groupId }) => {
    GroupListStore.refresh();
    fetchGroupAction.run({ groupId }, { treatAsUpdate: true });
  },
);

export const leaveGroupAction = createGenericAsyncAction<{ groupId: number }, undefined>(
  ({ groupId }) => GroupsAPI.leaveGroup(groupId),
  ({ groupId }) => {
    GroupListStore.refresh();
    GroupInviteStore.refresh();
    GroupMembersStore.fetchAction.run({ groupId }, { treatAsUpdate: true });
    fetchGroupAction.run({ groupId }, { treatAsUpdate: true });
  },
);

export const acceptGroupInviteAction = createGenericAsyncAction<{ groupId: number }, undefined>(
  ({ groupId }) => GroupsAPI.acceptGroupInvite(groupId),
  () => GroupInviteStore.refresh(),
);

export const acceptGroupRequestAction = createGenericAsyncAction<
  { groupId: number; userId: number },
  undefined
>(
  ({ groupId, userId }) => GroupsAPI.acceptGroupRequest(groupId, userId),
  () => GroupRequestStore.refresh(),
);

export const rejectGroupRequestAction = createGenericAsyncAction<
  { groupId: number; userId: number },
  undefined
>(
  ({ groupId, userId }) => GroupsAPI.rejectGroupRequest(groupId, userId),
  () => GroupRequestStore.refresh(),
);
