import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Link from 'components/Link';
import LoggedInPageWrapper from 'containers/LoggedInPageWrapper';
import MovieTable from 'containers/pages/Dashboard/Movies/MovieTable';
import { BrowseAllMoviesStore } from 'stores/movies/MovieStore';

const SCAlphabet = styled.div`
  padding: 0 0 40px;
  display: flex;
  margin-left: -10px;
  width: 100%;
  overflow: auto;
`;

const SCLink = styled(Link)``;

const SCLetter = styled.div<{ selected: boolean }>`
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
  pointer-events: ${(props) => (props.selected ? 'none' : undefined)};
  text-transform: uppercase;

  ${SCLink} {
    padding: 10px;
  }
`;

const Alphabet: React.FC<{ letter: string }> = ({ letter }) => {
  const letters = 'abcdefghijklmnopqrstuvwxyz'.split('');
  return (
    <SCAlphabet>
      {letters.map((l) => (
        <SCLetter selected={l === letter} key={l}>
          <SCLink href={`/dashboard/browse/movies/all/${l}`}>{l}</SCLink>
        </SCLetter>
      ))}
    </SCAlphabet>
  );
};

const BrowseAllMovies: React.FC = () => {
  const { letter } = useParams();
  const movies = BrowseAllMoviesStore.usePaginatedData({ letter });

  return (
    <LoggedInPageWrapper bg='white' isLoading={movies.isLoading} title='Browse - All Movies'>
      {movies.items.length ? (
        <>
          <Alphabet letter={letter} />
          <MovieTable
            type='browse'
            movies={movies.items}
            hasMorePages={movies.hasMorePages}
            loadMore={movies.loadNextPage}
            isUpdating={movies.isUpdating}
          />
        </>
      ) : (
        <p>No movies yet</p>
      )}
    </LoggedInPageWrapper>
  );
};

export default BrowseAllMovies;
