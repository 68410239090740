import { Store } from 'pullstate';

import CatalogAPI, {
  Column,
  Direction,
  IMovie,
  IWatchedMovie,
  TWatchedStatus,
} from 'api/CatalogAPI';
import ProfileAPI from 'api/ProfileAPI';
import {
  createGenericAsyncAction,
  createPaginatedLookupStore,
  createPaginationStore,
} from 'stores/index';
import { SearchAllStore } from 'stores/SearchStore';

export interface IMovieStore {
  [movieId: string]: IMovie;
}

export const MovieStore = new Store<IMovieStore>({});

MovieStore.createReaction(
  (s) => s,
  (movieStore) => {
    WatchedMovieStore.store.update((s) => {
      for (const key in s) {
        s[key].items = s[key].items.map((item) => {
          const match = movieStore[item.media.id];
          return match == null ? item : { ...item, media: match };
        });
      }
    });
    RecommendedMovieStore.store.update((sourceStore) => {
      sourceStore.items = sourceStore.items.map((item) => movieStore[item.id] ?? item);
    });
    BrowseAllMoviesStore.store.update((sourceStore) => {
      for (const letter in sourceStore) {
        sourceStore[letter].items = sourceStore[letter].items.map(
          (item) => movieStore[item.id] ?? item,
        );
      }
    });
    SearchAllStore.store.update((s) => {
      for (const key in s) {
        s[key].items = s[key].items.map((item) =>
          item.model === 'movie' ? movieStore[item.id] ?? item : item,
        );
      }
    });
    UserRecommendedMovieStore.store.update((sourceStore) => {
      for (const userId in sourceStore) {
        sourceStore[userId].items = sourceStore[userId].items.map((item) => {
          const match = movieStore[item.media.id];
          return match == null ? item : { ...item, media: match };
        });
      }
    });
  },
);

const updateMovieStoreFromMovieArray = (movies: IMovie[]) => {
  MovieStore.update((store) => {
    movies.forEach((movie) => (store[movie.id] = movie));
  });
};

export const WatchedMovieStore = createPaginatedLookupStore<
  {
    column: Column;
    direction: Direction;
  },
  IWatchedMovie
>(
  ({ column, direction }, page) => CatalogAPI.fetchWatchedMovies(column, direction, page),
  ({ column, direction }) => `${column}/${direction}`,
  (action, response) => updateMovieStoreFromMovieArray(response.items.map(({ media }) => media)),
);

export const RecommendedMovieStore = createPaginationStore<undefined, IMovie>(
  () => CatalogAPI.fetchRecommendedMovieList(),
  (action, response) => updateMovieStoreFromMovieArray(response.items),
);

export const BrowseAllMoviesStore = createPaginatedLookupStore<{ letter: string }, IMovie>(
  ({ letter }, page) => CatalogAPI.fetchMovieListByLetter(letter, page),
  ({ letter }) => letter,
  (_, response) => updateMovieStoreFromMovieArray(response.items),
);

export const BrowseNewMoviesStore = createPaginationStore<undefined, IMovie>(
  (_, page) => CatalogAPI.fetchMovieList('newest', page),
  (_, response) => updateMovieStoreFromMovieArray(response.items),
);

export const setMovieWatchedStatusAction = createGenericAsyncAction<
  { movieId: number; status: TWatchedStatus },
  undefined
>(
  ({ movieId, status }) => CatalogAPI.submitMovieWatchedStatus(movieId, status),
  undefined,
  ({ movieId, status }) => {
    MovieStore.update((s) => {
      const movie = s[movieId];
      if (movie == null) return;

      movie.watched = {
        status,
        updated_at: new Date().toISOString(),
      };
    });
  },
);

export const clearMovieWatchedStatusAction = createGenericAsyncAction<
  { movieId: number },
  undefined
>(
  ({ movieId }) => CatalogAPI.removeMovieWatchedStatus(movieId),
  undefined,
  ({ movieId }) => {
    WatchedMovieStore.store.update((store) => {
      for (const key in store) {
        store[key].items = store[key].items.filter((item) => item.media.id !== movieId);
      }
    });
  },
);

export const setMovieRatingAction = createGenericAsyncAction<
  { movieId: number; rating: number },
  undefined
>(
  ({ movieId, rating }) => CatalogAPI.submitMovieRating(movieId, rating),
  undefined,
  ({ movieId, rating }) => {
    const newRating = {
      updated_at: new Date().toISOString(),
      value: rating,
    };
    MovieStore.update((s) => {
      const movie = s[movieId];
      if (movie == null) return;
      movie.rating = newRating;
    });
  },
);

export const fetchMovieAction = createGenericAsyncAction<{ movieId: number }, IMovie>(
  ({ movieId }) => CatalogAPI.fetchMovie(movieId),
  ({ movieId }, movie) =>
    MovieStore.update((s) => {
      s[movieId] = movie;
    }),
);

export const UserRecommendedMovieStore = createPaginatedLookupStore<
  { userId: number },
  IWatchedMovie
>(
  ({ userId }, page) => ProfileAPI.fetchUserRecommendedMovies(userId, page),
  ({ userId }) => userId.toString(),
  (_, response) => {
    response.items.forEach((movie) => {
      MovieStore.update((s) => {
        s[movie.media.id] = movie.media;
      });
    });
  },
);
