import DefaultPoster from '../../../../components/DefaultPoster';
import SCShowForMobile from '../../../../components/styled/SCShowForMobile';
import { group } from 'console';
import { rgba } from 'polished';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { IMovie, ITVShow } from 'api/CatalogAPI';
import { IFollowing } from 'api/FollowAPI';
import { IGroup } from 'api/GroupsAPI';
import { IUserData } from 'api/ProfileAPI';
import Link from 'components/Link';
import Rating from 'components/Rating';
import SCAvatar from 'components/styled/SCAvatar';
import SCButton from 'components/styled/SCButton';
import SCHideForMobile from 'components/styled/SCHideForMobile';
import SCPageTitle from 'components/styled/SCPageTitle';
import SCResultsTable from 'components/styled/SCResultsTable';
import SCShowMoreButtonWrapper from 'components/styled/SCShowMoreButtonWrapper';
import variables from 'components/styled/variables';
import LoggedInPageWrapper from 'containers/LoggedInPageWrapper';
import { displayEditShowModal } from 'containers/modals/EditShowModal';
import { UnfollowPrompt } from 'containers/modals/FollowerPrompts';
import { FollowingStore, startFollowingAction } from 'stores/FollowStore';
import { GroupDetailsStore, IGroupDetailsStore, joinGroupAction, requestToJoinGroupAction } from 'stores/groups/GroupDetailsStore';
import { SearchGroupsStore, SearchMoviesStore, SearchShowsStore, SearchUsersStore, TSearchResult } from 'stores/SearchStore';

type TSearchModel = 'movie' | 'tv_show' | 'user' | 'group';
type TFollowingMap = {
  [key: number]: IFollowing
};
const capitalizeGenre = (genre: string): string => {
  const words = genre.split(/ /g);
  return words.map((w) => w.charAt(0).toUpperCase() + w.substr(1)).join(' ');
};

const SCGenre = styled.div`
  white-space: nowrap;
`;

const SCNetworkWrapper = styled.div`
  & + & {
    margin-top: 5px;
  }

  @media (max-width: 1000px) {
    img {
      max-height: 40px;
    }
  }
`;

const SCTitle = styled(SCPageTitle)`
  margin: 0 0 10px;
`;

const SCButtonFixed = styled(SCButton)`
  padding: 0;
  width: 190px;
`;

const SCSubtitle = styled.h2`
  font-size: 16px;
  font-weight: normal;
  margin: 0 0 40px;
`;

const SCShowMoreButtonWrapperCustom = styled(SCShowMoreButtonWrapper)`
  border-top: solid 1px rgba(0, 0, 0, .15);
  padding: 28px;
`;

const SCResultsTableCustom = styled(SCResultsTable)`
tr {
  th,
  td {
    &:first-child {
      padding: 19px 10px 20px;
      width: 80px;
    }

    &:nth-child(2) {
      width: 330px;
      padding: 19px 10px 20px;
    }
  }

  th {
    border-bottom: solid 1px rgba(0, 0, 0, .15);
    padding: 19px 10px 20px;
  }

  td {
    padding: 19px 10px 20px;

    &:last-of-type {
      padding-right: 10px;
      text-align: left;
      width: 190px;
    }
  }
}
`;

const SCSearchResultSection = styled.div`
  border: 1px solid rgba(0, 0, 0, .5);
  border-radius: 5px;
  margin: 0 0 28px;
`;

const SCSectionTitle = styled(SCPageTitle)`
  color: ${variables.colors.text.link};
  margin: 0 0 17px;
`;

const SCMobileResult = styled.div`
  display: flex;
  padding: 10px 11px 5px;

  & + & {
    border-top: 1px solid #c4c4c4;
  }
`;

const SCMobilePosterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-right: 10px;
`;

const SCMobileResultDetails = styled.div`
  h1 {
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;
    margin: 0 0 2px;
  }

  h2 {
    font-size: 16px;
    line-height: 150%;
    font-weight: normal;
    margin: 0 0 9px;
  }
`;

const SCMobileNetworkWrapper = styled.div`
  display: flex;
  margin: 0;
  align-items: center;
`;

const SCMobileNetwork = styled.div`
  margin: 0 10px 10px 0;
`;

const SCMobileFriendWrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, .15);
  border-top: 0;
`;

const SCMobileFriendTopRow = styled.div`
  display: flex;
  cursor: pointer;
`;

const SCMobileAvatarWrapper = styled.div`
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, .15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 80px;
  padding-top: 20px;
  width: 100px;

  img {
    margin-bottom: 20px;
    max-width: 80px;
    max-height: 80px;
  }
`;

const SCMobileUserWrapper = styled.div`
  padding: 20px;
  flex: 1 0;

  h1 {
    font-family: ${variables.fonts.heading};
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 500;
    margin: 0 0 5px;
    color: black;
  }

  h2 {
    color: ${rgba('black', 0.6)};
    font-size: 14px;
    font-weight: normal;
    margin: 0;
    color: black;
  }
`;

const SCMobileActionWrapper = styled.div`
  padding: 20px;
  border-top: 1px solid rgba(0, 0, 0, .15);
  text-align: center;
`;

const MobileResult: React.FC<{ data: TSearchResult, isFollowing: boolean, group: IGroup }> = ({ data, isFollowing, group }) => {
  switch (data.model) {
    case 'movie':
    case 'tv_show':
      return <MovieOrShowMobileResult data={data} />;
    case 'group':
      return <GroupMobileResult group={group} />;
    case 'user':
      return <UserMobileResult user={data} isFollowing={isFollowing} />;
    default:
      return null;
  }
};

const MobileResults: React.FC<{ results: TSearchResult[], followingMap: TFollowingMap, groupMap: IGroupDetailsStore }> = ({ results, followingMap, groupMap }) =>
  <>
    {results.map((result: TSearchResult) => (
      <MobileResult key={result.id} data={result} isFollowing={!!followingMap[result.id]} group={groupMap[result.id]} />
    ))}
  </>;

const TableHeader: React.FC<{ model: TSearchModel }> = ({ model }) => {
  switch (model) {
    case 'movie':
      return (
        <thead>
          <tr>
            <th>{/* Poster */}</th>
            <th>Title</th>
            <th>Genre</th>
            <th></th>
            <th>Avg Rating</th>
            <th>{/* Actions */}</th>
          </tr>
        </thead>
      );
    case 'tv_show':
      return (
        <thead>
          <tr>
            <th>{/* Poster */}</th>
            <th>Title</th>
            <th>Genre</th>
            <th>Network</th>
            <th>Avg Rating</th>
            <th>{/* Actions */}</th>
          </tr>
        </thead>
      );
    case 'group':
      return (
        <thead>
          <tr>
            <th>{/* Poster */}</th>
            <th>Title</th>
            <th>Description</th>
            <th>{/* Actions */}</th>
          </tr>
        </thead>
      );
    case 'user':
      return (
        <thead>
          <tr>
            <th>{/* Poster */}</th>
            <th>Username</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>{/* Actions */}</th>
          </tr>
        </thead>
      );
    default:
      return null;
  }
};

const TableBody: React.FC<{ results: TSearchResult[], followingMap: TFollowingMap, groupMap: IGroupDetailsStore }> = ({ results, followingMap, groupMap }) => {
  return (
    <tbody>
      {results.map((result: TSearchResult) => (
        <Row key={result.id} data={result} isFollowing={!!followingMap[result.id]} group={groupMap[result.id]} />
      ))}
    </tbody>
  );
};

const Row: React.FC<{ data: TSearchResult, isFollowing: boolean, group: IGroup }> = ({ data, isFollowing, group }) => {
  switch (data.model) {
    case 'movie':
      return <MovieRow movie={data} />;
    case 'tv_show':
      return <TVShowRow show={data} />;
    case 'group':
      return <GroupRow group={group}></GroupRow>;
    case 'user':
      return <UserRow user={data} isFollowing={isFollowing}></UserRow>;
    default:
      return null;
  }
};

const MovieRow: React.FC<{ movie: IMovie }> = ({ movie }) => {
  return (
    <tr>
      <td>
        <Link href={`/dashboard/movies/details/${movie.id}`}>
          {movie.images.poster.w92 && <img src={movie.images.poster.w92} alt={movie.title} />}
        </Link>
      </td>
      <td>
        <Link href={`/dashboard/movies/details/${movie.id}`}>{movie.title}</Link>
      </td>
      <td>
        {movie.genres?.map((genre) => (
          <SCGenre key={genre}>{capitalizeGenre(genre)}</SCGenre>
        ))}
      </td>
      <td></td>
      <td>
        <Rating value={movie.vote_average} />
      </td>
      <td>
        <Link href={`/dashboard/movies/details/${movie.id}`}>Edit</Link>
      </td>
    </tr>
  );
};

const MovieOrShowMobileResult: React.FC<{ data: IMovie | ITVShow }> = ({ data }) => {
  const { id, images, genres, vote_average } = data;
  const title = data.model === 'movie' ? data.title : data.name;
  const networks = data.model === 'movie' ? [] : data.networks;
  const href =
    data.model === 'movie' ? `/dashboard/movies/details/${id}` : `/dashboard/shows/details/${id}`;

  return (
    <SCMobileResult>
      <SCMobilePosterWrapper>
        <Link href={href}>
          {images.poster.w92 ? (
            <img src={images.poster.w92} alt={title} />
          ) : (
            <DefaultPoster imageKey='w92' />
          )}
        </Link>
        <Link href={href}>Edit</Link>
      </SCMobilePosterWrapper>
      <SCMobileResultDetails>
        <h1>{title}</h1>
        <h2>{genres?.map(capitalizeGenre).join(', ')}</h2>
        {networks.length > 0 && (
          <SCMobileNetworkWrapper>
            {networks.map((network) => (
              <SCNetworkWrapper key={network.name}>
                <SCMobileNetwork>
                  {network.logo.w92 != null ? (
                    <img src={network.logo.w92} alt={network.name} />
                  ) : (
                    network.name
                  )}
                </SCMobileNetwork>
              </SCNetworkWrapper>
            ))}
          </SCMobileNetworkWrapper>
        )}
        <div>
          <Rating value={vote_average} />
        </div>
      </SCMobileResultDetails>
    </SCMobileResult>
  );
};

const GroupMobileResult: React.FC<{ group: IGroup }> = ({ group }) => {
  return (
    <SCMobileFriendWrapper key={group?.id}>
      <Link href={`/dashboard/groups/details/${group?.id}`}>
        <SCMobileFriendTopRow>
          <SCMobileAvatarWrapper>
            {group?.avatar ? <img src={group?.avatar} alt={`${group?.title}`} /> : <SCAvatar variant="comment" icon='\f0c0' />}
          </SCMobileAvatarWrapper>
          <SCMobileUserWrapper>
            <h1>{group?.title}</h1>
            <h2>{group?.description}</h2>
          </SCMobileUserWrapper>
        </SCMobileFriendTopRow>
      </Link>
      <SCMobileActionWrapper>
        <JoinButton group={group} />
      </SCMobileActionWrapper>
    </SCMobileFriendWrapper>
  );
};

const UserMobileResult: React.FC<{ user: IUserData, isFollowing: boolean }> = ({ user, isFollowing }) => {
  return (
    <SCMobileFriendWrapper key={user.id}>
      <Link href={`/dashboard/user/${user.id}`}>
        <SCMobileFriendTopRow>
          <SCMobileAvatarWrapper>
            <SCAvatar variant='comment' imageUrl={user?.avatar} />
          </SCMobileAvatarWrapper>
          <SCMobileUserWrapper>
            <h1>@{user.username}</h1>
            <h2>
              {user.first_name} {user.last_name}
            </h2>
          </SCMobileUserWrapper>
        </SCMobileFriendTopRow>
      </Link>
      <SCMobileActionWrapper>
        <FollowButton user={user} isFollowing={isFollowing} />
      </SCMobileActionWrapper>
    </SCMobileFriendWrapper>
  );
};

const TVShowRow: React.FC<{ show: ITVShow }> = ({ show }) => {
  return (
    <tr>
      <td>
        <Link href={`/dashboard/shows/details/${show.id}`}>
          {show.images.poster.w92 && <img src={show.images.poster.w92} alt={show.name} />}
        </Link>
      </td>
      <td>
        <Link href={`/dashboard/shows/details/${show.id}`}>{show.name}</Link>
      </td>
      <td>
        {show.genres?.map((genre) => (
          <SCGenre key={genre}>{capitalizeGenre(genre)}</SCGenre>
        ))}
      </td>
      <td>
        {show.networks.map((network) => (
          <div key={network.name}>
            {network.logo.w45 ? <img src={network.logo.w45} alt={network.name} /> : network.name}
          </div>
        ))}
      </td>
      <td>
        <Rating value={show.vote_average} />
      </td>
      <td>
        <a
          href={`/dashboard/shows/details/${show.id}`}
          onClick={(e) => {
            e.preventDefault();
            return displayEditShowModal(show.id);
          }}>
          Edit
        </a>
      </td>
    </tr>
  );
};

const GroupRow: React.FC<{ group: IGroup }> = ({ group }) => {
  return (
    <tr>
      <td>
        <Link href={`/dashboard/groups/details/${group?.id}`}>
          {group?.avatar && <img src={group?.avatar} alt={group?.title} style={{
            'maxWidth': '80px',
            'maxHeight': '80px'
          }} />}
        </Link>
      </td>
      <td>
        <Link href={`/dashboard/groups/details/${group?.id}`}>{group?.title}</Link>
      </td>
      <td>
        {group?.description}
      </td>
      <td>
        <JoinButton group={group} />
      </td>
    </tr>
  );
};

const UserRow: React.FC<{ user: IUserData, isFollowing: boolean }> = ({ user, isFollowing }) => {
  return (
    <tr>
      <td>
        <Link href={`/dashboard/user/${user.id}`}>
          <SCAvatar variant='comment' imageUrl={user?.avatar} />
        </Link>
      </td>
      <td>
        <Link href={`/dashboard/user/${user.id}`}>{user.username}</Link>
      </td>
      <td>
        {user.first_name}
      </td>
      <td>
        {user.last_name}
      </td>
      <td>
        <FollowButton user={user} isFollowing={isFollowing} />
      </td>
    </tr>
  );
};

const JoinButton: React.FC<{ group: IGroup }> = ({ group }) => {
  const [isRequested, setIsRequested] = React.useState(group?.membership?.status === 'requested');
  const [isAccepted, setIsAccepted] = React.useState(group?.membership?.status === 'accepted');
  const join = (group: IGroup) => {
    if (group?.is_public) {
      setIsAccepted(true);
      joinGroupAction.run({ groupId: group?.id }, { treatAsUpdate: true });
    } else {
      setIsRequested(true);
      requestToJoinGroupAction.run({ groupId: group?.id }, { treatAsUpdate: true });
    }
  };

  if (isAccepted) {
    return (
      <Link href={`/dashboard/groups/details/${group.id}`}>
        <SCButtonFixed>Enter</SCButtonFixed>
      </Link>
    );
  } else {
    return (
      isRequested
        ? <p>Request Sent</p>
        : <SCButtonFixed onClick={() => join(group)}>
          {group?.is_public ? 'Join' : 'Request to Join'}
        </SCButtonFixed>
    );
  }
};

const FollowButton: React.FC<{ user: IUserData, isFollowing: boolean }> = ({ user, isFollowing }) => {
  const [showUnfollowPrompt, setShowUnfollowPrompt] = React.useState(false);
  const follow = () => {
    startFollowingAction.run({ userId: user.id });
  };
  const unfollow = () => {
    setShowUnfollowPrompt(true);
  };

  if (user.is_me) {
    return null;
  }

  return (
    <>
      <SCButtonFixed onClick={() => isFollowing ? unfollow() : follow()}>
        {isFollowing ? 'Unfollow' : 'Follow'}
      </SCButtonFixed>
      {showUnfollowPrompt &&
        <UnfollowPrompt
          unfollowUser={user}
          onRequestClose={() => setShowUnfollowPrompt(false)} />
      }
    </>
  );
};

const Search: React.FC = () => {
  const { search: encodedSearch } = useParams();
  const search = decodeURIComponent(encodedSearch);
  const movieResults = SearchMoviesStore.usePaginatedData({ term: search, size: 2 });
  const showResults = SearchShowsStore.usePaginatedData({ term: search, size: 2 });
  const groupResults = SearchGroupsStore.usePaginatedData({ term: search, size: 2 });
  const userResults = SearchUsersStore.usePaginatedData({ term: search, size: 2 });
  const groupDetailsResults = GroupDetailsStore.useState();
  const followingMap = FollowingStore.usePaginatedData().items.reduce((accum: TFollowingMap, curr: IFollowing) => {
    accum[curr.subject.id] = curr;
    return accum;
  }, {});
  if (!groupResults.isLoaded) {
    groupResults.items.forEach((item) => {
      GroupDetailsStore.update((s) => ({
        ...s,
        [item.id]: item
      }));
    });
  }
  const models: Array<TSearchModel> = ['movie', 'tv_show', 'group', 'user'];

  const getConfig = (model: TSearchModel) => {
    const modelToConfig = {
      movie: { results: movieResults, title: 'Movies', model },
      tv_show: { results: showResults, title: 'TV Shows', model },
      user: { results: userResults, title: 'Users', model },
      group: { results: groupResults, title: 'Groups', model },
    };

    return modelToConfig[model];
  };

  return (
    <LoggedInPageWrapper bg='white'>
      <SCTitle>Search Results</SCTitle>
      <SCSubtitle>Showing results for &quot;{search}&quot;</SCSubtitle>
      {(
        movieResults.isLoaded &&
        showResults.isLoaded &&
        groupResults.isLoaded &&
        userResults.isLoaded
      ) ? (
        <>
          {models.map(getConfig).map(({ results, title, model }) => !!results.items.length &&
            <div key={model}>
              <SCSectionTitle>{title}</SCSectionTitle>
              <SCSearchResultSection>
                <SCHideForMobile>
                  <SCResultsTableCustom cellPadding={0} cellSpacing={0}>
                    <TableHeader model={model} />
                    <TableBody results={results.items} followingMap={followingMap} groupMap={groupDetailsResults} />
                  </SCResultsTableCustom>
                </SCHideForMobile>
                <SCShowForMobile>
                  <MobileResults results={results.items} followingMap={followingMap} groupMap={groupDetailsResults} />
                </SCShowForMobile>
                {results.hasMorePages && (
                  <SCShowMoreButtonWrapperCustom>
                    <SCButton disabled={results.isUpdating} onClick={results.loadNextPage} transparent>
                      Show More
                    </SCButton>
                  </SCShowMoreButtonWrapperCustom>
                )}
              </SCSearchResultSection>
            </div>
          )}
        </>
      ) : (
        <p>Loading results...</p>
      )}
    </LoggedInPageWrapper>
  );
};

export default Search;
