import useEffectOnce from 'hooks/useEffectOnce';
import { rgba } from 'polished';
import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import styled from 'styled-components';

import CatalogAPI, { TWatchedStatus } from 'api/CatalogAPI';
import Link from 'components/Link';
import Rating from 'components/Rating';
import Review from 'components/Review';
import Spinner from 'components/Spinner';
import SCHideForMobile from 'components/styled/SCHideForMobile';
import SCPageTitle from 'components/styled/SCPageTitle';
import SCShowForMobile from 'components/styled/SCShowForMobile';
import variables from 'components/styled/variables';
import LoggedInPageWrapper from 'containers/LoggedInPageWrapper';
import WatchVideoModal from 'containers/modals/WatchVideoModal';
import WatchNowButtons from 'containers/pages/Dashboard/WatchNowButtons';
import { CurrentUserStore } from 'stores/auth/CurrentUserStore';
import {
  clearMovieWatchedStatusAction,
  fetchMovieAction,
  MovieStore,
  setMovieRatingAction,
  setMovieWatchedStatusAction,
} from 'stores/movies/MovieStore';
import { MovieReviewsStore, UserReviewOptimizedStore, UsersReviewsStore } from 'stores/ReviewStore';

const SCMovieTitle = styled(SCPageTitle)`
  font-size: 60px;
  margin: 0 0 20px;

  @media (max-width: 1000px) {
    font-size: 40px;
  }
`;

const SCRatingWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;

  > * {
    flex-grow: 0;
    margin: 0 20px 0 0 !important;
  }
`;

const SCParagraph = styled.p`
  font-size: 16px;
  line-height: 175%;
  margin: 0 0 40px;

  @media (max-width: 1000px) {
    font-size: 14px;
  }
`;

const SCDetailRow = styled.div`
  line-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 700px;

  & + & {
    border-top: 1px solid #c4c4c4;
  }

  @media (max-width: 1000px) {
    font-size: 14px;
  }
`;

const SCCircle = styled.div.attrs({ size: 40 }) <{ size: number; checked: boolean }>`
  cursor: pointer;
  border: 1px solid white;
  border-radius: 50%;
  display: block;
  flex-shrink: 0;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  margin-right: 20px;
  background-color: ${(props) => props.checked && `${variables.colors.text.link} !important`};
  position: relative;
  border: 1px solid ${variables.colors.text.link};

  &::after {
    color: white;
    content: '\f00c';
    display: ${(props) => (props.checked ? 'block' : 'none')};
    font-family: ${variables.fonts.icon};
    left: 50%;
    position: absolute;
    font-weight: 900;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const SCWrapper = styled.div`
  background-color: ${rgba('white', 0.9)};
  padding: 60px;
  min-height: calc(100% - 120px);

  @media (max-width: 1000px) {
    padding: 20px;
    margin: 0 -20px;
  }
`;


const SCReviewLink = styled(Link)`
  text-decoration: underline;
`;

const getYear = (dateString?: string): string => {
  if (dateString == null) return '';
  return new Date(dateString).getFullYear().toString();
};

const capitalizeGenre = (genre: string): string => {
  const words = genre.split(/ /g);
  return words.map((w) => w.charAt(0).toUpperCase() + w.substr(1)).join(' ');
};

const MoviePDP: React.FC = () => {
  const { movieId } = useParams();
  const [isMovieLoaded] = fetchMovieAction.useBeckon({ movieId });
  const movie = MovieStore.useState((s) => s[movieId], movieId);
  const reviews = MovieReviewsStore.usePaginatedData({ movieId });

  const userState = CurrentUserStore.getRawState();
  const userReviews = UsersReviewsStore.usePaginatedData({ userId: userState?.user?.id ?? 0, size: 1000 });
  const userOptimizedReviews = UserReviewOptimizedStore.useState();
  const hasReviewed = userOptimizedReviews?.movie?.[movieId];

  useEffectOnce(() => {
    if (reviews.isLoaded) MovieReviewsStore.fetchAction.clearAllCache();
    if (userReviews.isLoaded) UsersReviewsStore.fetchAction.clearAllCache();
  });

  if (isMovieLoaded && movie == null) {
    return <Redirect to='/' />;
  }

  const setWatched = async (status: TWatchedStatus) => {
    if (status === movie?.watched?.status) {
      await clearMovieWatchedStatusAction.run({ movieId });
    } else {
      await setMovieWatchedStatusAction.run({ movieId, status });
    }
    fetchMovieAction.run({ movieId }, { treatAsUpdate: true });
  };

  const toggleRecommended = async () => {
    await CatalogAPI.submitMovieRecommendation(movieId, movie?.recommendation == null);
    fetchMovieAction.run({ movieId }, { treatAsUpdate: true });
  };

  const setMovieRating = (movieId: number, rating: number): void => {
    setMovieRatingAction.run({ movieId, rating });
    setMovieWatchedStatusAction.run({ movieId, status: 'watched' });
  };

  return (
    <LoggedInPageWrapper
      bg='white'
      isLoading={!isMovieLoaded}
      backgroundImage={movie?.images?.backdrop?.original}>
      {movie ? (
        <SCWrapper>
          <SCMovieTitle>{movie.title}</SCMovieTitle>
          <SCRatingWrapper>
            <Rating value={movie.vote_average} />
            <div>{(movie.vote_average / 2).toFixed(1)} avg rating</div>
            <SCHideForMobile>
              <WatchNowButtons media={movie} />
            </SCHideForMobile>
          </SCRatingWrapper>
          <SCRatingWrapper>
            <Rating
              value={movie.rating?.value}
              onChange={(rating) => setMovieRating(movieId, rating)}
            />
            <div>your rating</div>
          </SCRatingWrapper>
          <SCShowForMobile>
            <WatchNowButtons media={movie} />
          </SCShowForMobile>
          <SCParagraph>{movie.overview}</SCParagraph>
          <SCDetailRow>
            {getYear(movie.release_date)} &middot; {movie.genres?.map(capitalizeGenre).join(', ')}
          </SCDetailRow>
          <SCDetailRow>
            <div>Watched</div>
            <SCCircle
              checked={movie.watched?.status === 'watched'}
              onClick={() => setWatched('watched')}
            />
          </SCDetailRow>
          <SCDetailRow>
            <div>Want to Watch</div>
            <SCCircle
              checked={movie.watched?.status === 'want_to_watch'}
              onClick={() => setWatched('want_to_watch')}
            />
          </SCDetailRow>
          <SCDetailRow>
            <div>Recommend It</div>
            <SCCircle checked={movie.recommendation != null} onClick={toggleRecommended} />
          </SCDetailRow>
          <SCDetailRow>
            <div>User Reviews</div>
            {!hasReviewed &&
              <SCReviewLink href={`/dashboard/review/movie/${movie.id}`}>
                Write a review
              </SCReviewLink>
            }
          </SCDetailRow>
          <div>
            {reviews?.items?.map((review) => (
              <Review key={review.id} review={review} movie={movie} user={review.user}></Review>
            ))}
          </div>
        </SCWrapper>
      ) : (
        <Spinner />
      )}
      <WatchVideoModal />
    </LoggedInPageWrapper>
  );
};

export default MoviePDP;
