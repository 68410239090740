import React from 'react';

import { IUserData } from 'api/ProfileAPI';
import SCAvatar from 'components/styled/SCAvatar';
import SCHideForMobile from 'components/styled/SCHideForMobile';
import SCShowForMobile from 'components/styled/SCShowForMobile';
import GenericPrompt from 'containers/modals/GenericPrompt';
import { removeFollowerAction, stopFollowingAction } from 'stores/FollowStore';

export const RemoveFollowerPrompt: React.FC<{
  removeFollowerUser: IUserData,
  onRequestClose: () => void,
  cb?: () => void
}> = ({
  removeFollowerUser,
  onRequestClose,
  cb
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const onRemoveFollower = (user: IUserData) => {
    setIsLoading(true);
    removeFollowerAction.run({ userId: user.id }).then(() => {
      cb?.();
      setIsLoading(false);
      onRequestClose();
    });
  };

  return (
    <GenericPrompt
      buttonText='Remove'
      buttonAction={() => onRemoveFollower(removeFollowerUser)}
      onRequestClose={onRequestClose}
      isLoading={isLoading}
    >
      <SCShowForMobile>
        <SCAvatar variant="promptSmall" imageUrl={removeFollowerUser.avatar} />
      </SCShowForMobile>
      <SCHideForMobile>
        <SCAvatar variant="promptBig" imageUrl={removeFollowerUser.avatar} />
      </SCHideForMobile>
      <h3>Remove follower?</h3>
      <p>We wont tell @{removeFollowerUser.username} they<br/> were removed from your followers.</p>
    </GenericPrompt>
  );
};

export const UnfollowPrompt: React.FC<{
  unfollowUser: IUserData,
  onRequestClose: () => void,
  cb?: () => void
}> = ({
  unfollowUser,
  onRequestClose,
  cb
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const onStopFollowing = (user: IUserData) => {
    setIsLoading(true);
    stopFollowingAction.run({ userId: user.id }).then(() => {
      cb?.();
      setIsLoading(false);
      onRequestClose();
    });
  };

  return (
    <GenericPrompt
      buttonText='Unfollow'
      buttonAction={() => onStopFollowing(unfollowUser)}
      onRequestClose={onRequestClose}
      isLoading={isLoading}
    >
      <SCShowForMobile>
        <SCAvatar variant="promptSmall" imageUrl={unfollowUser.avatar} />
      </SCShowForMobile>
      <SCHideForMobile>
        <SCAvatar variant="promptBig" imageUrl={unfollowUser.avatar} />
      </SCHideForMobile>
      <h3>Unfollow?</h3>
      <p>Are you sure you want<br/> to unfollow @{unfollowUser.username}?</p>
    </GenericPrompt>
  );
};