import variables from '../../../../components/styled/variables';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import GroupsAPI from 'api/GroupsAPI';
import { IUserData } from 'api/ProfileAPI';
import Input from 'components/Input';
import RadioGroup from 'components/RadioGroup';
import SCButton from 'components/styled/SCButton';
import TextArea from 'components/TextArea';
import LoggedInPageWrapper from 'containers/LoggedInPageWrapper';
import FriendSearchInput from 'containers/SearchInput/FriendSearchInput';
import {
  editGroupAction,
  fetchGroupAction,
  GroupDetailsStore,
} from 'stores/groups/GroupDetailsStore';

import GroupCoverBar from './GroupCoverBar';

type Type = {
  id: string;
  display: string;
};

const typeOptions: Type[] = [
  {
    id: 'public',
    display: 'PUBLIC: Anyone can join this group.  Anyone can view the group information and feed.',
  },
  {
    id: 'private',
    display:
      'PRIVATE: New members must be approved by the group moderators.  Anyone can see the group information, but only members can see the polls and discussion boards.  Only moderators can invite new members.',
  },
];

const SCSubmitButton = styled(SCButton)`
  margin-top: 40px;
  min-width: 330px;
`;

const SCErrorMessage = styled.p`
  color: ${variables.colors.text.error};
`;

const EditGroup: React.FC = () => {
  const history = useHistory();
  const { groupId } = useParams();
  const [isGroupLoaded] = fetchGroupAction.useBeckon({ groupId });
  const group = GroupDetailsStore.useState((s) => s[groupId], [groupId]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [users, setUsers] = React.useState<{ value: number; display: string; item: IUserData }[]>(
    [],
  );
  const [type, setType] = React.useState<Type | null>(null);
  const [avatar, setAvatar] = React.useState<string | null>(null);
  const [cover, setCover] = React.useState<string | null>(null);
  const [inviteEmails, setInviteEmails] = React.useState('');
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (group == null) return;

    setTitle(group.title);
    setDescription(group.description);

    const typeToFind = group.is_public ? 'public' : 'private';
    const typeMatch = typeOptions.find((option) => option.id === typeToFind);
    if (typeMatch != null) setType(typeMatch);
  }, [group]);

  const onSubmit: React.FormEventHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    // Invite users, but we don't care about following the success here yet
    users.forEach((user) => {
      GroupsAPI.sendGroupInvite(group.id, user.item.id);
    });

    const results = await editGroupAction.run({
      groupId: groupId,
      title,
      description,
      isPublic: type?.id === 'public',
      avatar,
      cover,
    });
    if (results.error) {
      setError(results.message);
      return;
    }

    if (inviteEmails.length > 0) {
      Promise.all(
        inviteEmails.split(',').map((rawEmail) => {
          const email = rawEmail.trim();
          return GroupsAPI.sendGroupInviteByEmail(groupId, email);
        }),
      );
    }

    history.push(`/dashboard/groups/details/${groupId}`);
  };

  const isDisabled = title === '' || description === '' || type === null;

  return (
    <LoggedInPageWrapper bg='gray' isLoading={!isGroupLoaded} title='Edit Group'>
      <form onSubmit={onSubmit}>
        <GroupCoverBar
          edit
          avatarUrl={group?.avatar}
          coverUrl={group?.cover}
          onAvatarChange={setAvatar}
          onCoverChange={setCover}
        />
        <Input
          disabled={!isGroupLoaded}
          fullWidth
          onChange={setTitle}
          value={title}
          placeholder='Title'
          type='text'
        />
        <TextArea
          disabled={!isGroupLoaded}
          fullWidth
          onChange={setDescription}
          value={description}
          placeholder='Description'
        />
        <FriendSearchInput
          isMulti
          cacheOptions
          disabled={isLoading}
          fullWidth
          value={users}
          onChange={setUsers}
          label='Add Friends'
          placeholder='Search'
        />
        <Input
          label='Invite Friends (to add multiple emails add a comma between each one)'
          type='text'
          placeholder='Friends Email'
          value={inviteEmails}
          onChange={(emails) => setInviteEmails(emails)}
        />
        <RadioGroup
          disabled={!isGroupLoaded}
          label='Type'
          options={typeOptions}
          value={type}
          onChange={(item) => setType(item)}
        />
        <SCSubmitButton disabled={isDisabled}>Save</SCSubmitButton>
        {error != null && <SCErrorMessage>Error saving group: {error}</SCErrorMessage>}
      </form>
    </LoggedInPageWrapper>
  );
};

export default EditGroup;
