import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import ReviewsAPI, { ITVShowReview } from 'api/ReviewsAPI';
import Input from 'components/Input';
import Link from 'components/Link';
import SCButton from 'components/styled/SCButton';
import SCPageTitle from 'components/styled/SCPageTitle';
import variables from 'components/styled/variables';
import TextArea from 'components/TextArea';
import UploadMedia from 'components/UploadMedia';
import LoggedInPageWrapper from 'containers/LoggedInPageWrapper';
import WatchVideoModal, { displayWatchVideoModal } from 'containers/modals/WatchVideoModal';
import { SCSpinner } from 'containers/pages/register/shared';
import playIcon from 'img/play_icon.png';
import { useCurrentUser } from 'stores/auth/CurrentUserStore';
import { GroupListAllStore } from 'stores/groups/GroupListStore';
import { UserReviewOptimizedStore, UsersReviewsStore } from 'stores/ReviewStore';
import { fetchShowAction, ShowStore } from 'stores/shows/ShowStore';

const SCBack = styled(Link)`
  color: black;
  display: block;
  margin-bottom: 30px;
`;

const SCDetailRow = styled.div`
  display: flex;
  align-items: center;
  max-width: 700px;
  margin-bottom: 30px;
`;
const SCErrorMessage = styled.p`
  color: ${variables.colors.text.error};
`;
const SCShowName = styled.p`
  margin: 0;
`;
const SCSubmitButton = styled(SCButton)`
  margin-top: 40px;
  min-width: 330px;
  width: 100%;

  @media (min-width: 1000px) {
    width: 350px;
  }
`;

const SCSubmitContainer = styled.div`
  display:flex;
  justify-content: center;
`;
// TODO: abstract this to a component perhaps
const SCSelect = styled.select`
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  color: #666;
  height: 36px;
  padding: 0 40px 0 20px;
  width: 100%;

  background: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-color: ${variables.colors.background.input.dark};
  background-position: calc(100% - 20px) 50%, calc(100% - 15px) 50%;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
`;

const SCSelectWrapper = styled.div`
  margin: 0 0 30px;

  p {
    margin: 0 0 14px;
  }

  @media (min-width: 1000px) {
    display: flex;
    flex-direction: row;

    p {
      margin: 0 40px 0 0;
      min-width: 150px;
    }
  }
`;

const SCSelectContainer = styled.div`
  @media (min-width: 1000px) {
    display: inline-block;
  }
`;
// TODO: abstract this to a component perhaps
const SCCircle = styled.div.attrs({ size: 30 }) <{ size: number; checked: boolean }>`
  cursor: pointer;
  border: 1px solid white;
  border-radius: 50%;
  display: block;
  flex-shrink: 0;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  background-color: ${(props) => props.checked && `${variables.colors.text.link} !important`};
  position: relative;
  border: 1px solid ${variables.colors.text.link};
  margin-left: 10px;

  &::after {
    color: white;
    content: '\f00c';
    display: ${(props) => (props.checked ? 'block' : 'none')};
    font-family: ${variables.fonts.icon};
    left: 50%;
    position: absolute;
    font-weight: 900;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const SCUploadAttachmentError = styled.p`
  color: ${variables.colors.text.error};
  text-align: center;
  margin-top: 10px;
`;

const SCAttachmentWrapper = styled.div`
  padding: 19px 21px 28px;
  background-color: ${variables.colors.background.input.dark};
  border-radius: 10px;
`;
const SCInputAttachmentWrapper = styled.div`
  position: relative;
  margin-bottom: 30px;

  @media (max-width: 1000px) {
  }
`;
const SCAttachment = styled.div`
  display: inline-block;
  position: relative;

  img, video {
    max-width: 100%;
    max-height: 300px;
  }
`;
const SCVideoOverlay = styled.div`
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  img {
    max-height: 80px;
    max-width: 80px;
  }
`;
const SCRemoveAttachmentButton = styled.button`
  background: white;
  border: none;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  font-family: sans-serif;
  font-size: 20px;
  height: 40px;
  position: absolute;
  right: -20px;
  top: -20px;
  width: 40px;
`;

const SCReviewPageTitle = styled(SCPageTitle)`
  font-size: 60px;
  line-height: 75px;
  margin: 0 0 50px;
`;

const SCReviewTitleInput = styled(Input)`
  margin: 0 0 20px;

  input {
    font-size: 20px;
    font-weight: bold;

    &::placeholder {
      font-size: 16px;
      font-weight: normal;
    }
  }
`;

const SCReviewTextArea = styled(TextArea)`
  margin: 0 0 20px;
`;

const SCMarginDiv = styled.div`
  margin-top: 35px;
`;

const Reviews: React.FC = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const { id: showId, season: initialSeason } = useParams();

  const [isShowLoaded] = fetchShowAction.useBeckon({ showId });
  const showMatch = ShowStore.useState((s) => s[showId], showId);
  const { show, seasons } = showMatch ?? {};

  const groups = GroupListAllStore.usePaginatedData();
  const currentUser = useCurrentUser();
  const userReviews = UsersReviewsStore.usePaginatedData({ userId: currentUser?.id ?? 0, size: 1000 });
  const indexedReviews = UserReviewOptimizedStore.useState();

  const getReviewForSeason = (s: number) => indexedReviews?.show?.[show?.id]?.[s];

  const [season, setSeason] = React.useState(initialSeason);
  const [existingReview, setExistingReview] = React.useState<ITVShowReview>();
  const [title, setTitle] = React.useState('');
  const [body, setBody] = React.useState('');
  const [attachment, setAttachment] = React.useState<{
    format?: 'image' | 'video';
    mediaId?: number;
    url?: string;
  }>({ url: undefined });
  const [attachmentError, setAttachmentError] = React.useState('');
  const [hasSpoilers, setHasSpoilers] = React.useState(false);
  const [group, setGroup] = React.useState<number | string>('');
  const isSubmitDisabled = !title || !body;

  useEffect(() => {
    if (userReviews.isLoaded) UsersReviewsStore.fetchAction.clearAllCache();
  }, [currentUser]);

  useEffect(() => {
    const review = getReviewForSeason(season);

    setExistingReview(review);
    setTitle(review?.title);
    setBody(review?.body);
    setAttachment({ url: review?.media?.path });
    setHasSpoilers(review?.has_spoilers ?? false);
    setGroup(review?.group?.id);
  }, [indexedReviews]);

  const setSanitizedTitle = (val: string) => {
    val?.length > 50 ? setTitle(val?.slice(0, 50)) : setTitle(val);
  };
  const setSanitizedBody = (val: string) => {
    val?.length > 300 ? setBody(val?.slice(0, 300)) : setBody(val);
  };

  const onSubmit: React.FormEventHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    try {
      const sanitizedGroup = !group ? undefined : Number(group);
      const json = existingReview ?
        await ReviewsAPI.updateTVShowReview(
          showId,
          existingReview.id,
          title,
          body,
          hasSpoilers,
          sanitizedGroup,
          attachment?.mediaId,
        )
        : await ReviewsAPI.submitTVReview(
          showId,
          title,
          body,
          hasSpoilers,
          season,
          sanitizedGroup,
          attachment?.mediaId,
        );
      if (!json.success) throw new Error(json?.error?.message);

      setIsLoading(false);
      history.goBack();
      return;
    } catch (err) {
      setError(err.message);
      console.error(err.message);
    }
    setIsLoading(false);
  };

  return (
    <LoggedInPageWrapper bg='white' isLoading={!isShowLoaded}>
      <SCBack onClick={history.goBack}>{'< BACK'}</SCBack>
      <SCShowName>{show?.name}</SCShowName>
      <SCReviewPageTitle>Your Review</SCReviewPageTitle>
      <form onSubmit={onSubmit}>
        <SCReviewTitleInput
          disabled={isLoading}
          fullWidth
          onChange={setSanitizedTitle}
          value={title ?? ''}
          placeholder='Title'
          type='text'
        />
        <SCReviewTextArea
          disabled={isLoading}
          fullWidth
          onChange={setSanitizedBody}
          value={body ?? ''}
          placeholder='Write your review here.'
        />
        <SCInputAttachmentWrapper>
          <UploadMedia
            type='attachment'
            supportVideo={true}
            onError={err => setAttachmentError(err)}
            onSuccess={(attachment) => {
              setAttachment(attachment);
              setAttachmentError('');
            }}>
            {(triggerUpload, resetFileInput, uploading) => (
              <>
                {!attachment.url &&
                  <>
                    <SCMarginDiv />
                    <p>Add a video or image</p>
                    <SCButton disabled={uploading} onClick={uploading ? () => undefined : triggerUpload} block={false} type='button'>
                      {uploading
                        ? <SCSpinner />
                        : <>Search</>
                      }
                    </SCButton>
                  </>
                }
                {attachment?.url && (
                  <SCAttachmentWrapper>
                    <SCAttachment>
                      {attachment.format === 'image'
                        ? <img alt='' src={attachment?.url} />
                        : <>
                          <SCVideoOverlay onClick={() => displayWatchVideoModal(attachment?.url || '')}>
                            <img src={playIcon} alt='Play Icon' />
                          </SCVideoOverlay>
                          <video src={attachment?.url} onClick={() => displayWatchVideoModal(attachment.url || '')} />
                        </>}
                      <SCRemoveAttachmentButton
                        onClick={() => {
                          setAttachment({});
                          resetFileInput();
                        }}>
                        &times;
                      </SCRemoveAttachmentButton>
                    </SCAttachment>
                  </SCAttachmentWrapper>
                )}
              </>
            )}
          </UploadMedia>
        </SCInputAttachmentWrapper>
        {!!attachmentError && <SCUploadAttachmentError>{attachmentError}</SCUploadAttachmentError>}
        <div>
          <SCDetailRow>
            <div>Spoilers?</div>
            <SCCircle checked={!!hasSpoilers} onClick={(e) => setHasSpoilers(!hasSpoilers)} />
          </SCDetailRow>
        </div>
        <SCSelectContainer>
          {!!groups.items.length && (
            <SCSelectWrapper>
              <p>Promote a Group</p>
              <SCSelect value={group ?? '0'} onChange={(e) => setGroup(e.target.value)}>
                <option key='0' value='0' disabled>
                  -
                </option>
                {groups.items.map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.title}
                  </option>
                ))}
              </SCSelect>
            </SCSelectWrapper>
          )}
          {!!seasons?.length && (
            <SCSelectWrapper>
              <p>Season</p>
              <SCSelect value={season ?? 1} onChange={(e) => setSeason(e.target.value)}>
                {seasons?.map((season) => (
                  <option disabled={!!getReviewForSeason(season.number)} key={season.key} value={season.number}>
                    {season.name}
                  </option>
                ))}
              </SCSelect>
            </SCSelectWrapper>
          )}
        </SCSelectContainer>
        <SCSubmitContainer>
          <SCSubmitButton disabled={isSubmitDisabled}>
            {isLoading ? <SCSpinner /> : 'SUBMIT'}
          </SCSubmitButton>
        </SCSubmitContainer>
        {error != null && <SCErrorMessage>Error saving review: {error}</SCErrorMessage>}
      </form>
      <WatchVideoModal />
    </LoggedInPageWrapper>
  );
};

export default Reviews;
