import React from 'react';
import styled from 'styled-components';

import Footer from 'components/Footer';
import Link from 'components/Link';
import MaxWidthWrapper from 'components/MaxWidthWrapper';
import Theme from 'components/Theme';
import GenericHeader from 'containers/GenericHeader';

const SCContent = styled.div`
  background: white;
  color: black;
  border-radius: 10px;
  padding: 20px 60px;
  margin: 0 60px;

  @media (max-width: 1000px) {
    padding: 20px;
    margin: 0 20px;
  }
`;

const PrivacyPolicy: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Theme theme='dark'>
      <GenericHeader />
      <MaxWidthWrapper>
        <SCContent>
          <h1>Privacy Policy</h1>
          <p>
            BingeRoom, LLC (&quot;BingeRoom,&quot; &quot;we,&quot; or &quot;us&quot;) knows that you
            care how information about you is used and shared. This Privacy Policy explains what
            information of yours will be collected by BingeRoom when you use BingeRoom.com , the
            mobile application, and any of our online content, features, or materials -(the
            &quot;site&quot;) and related services (together the “Service”), how the information
            will be used, and how you can control the collection, correction and/or deletion of
            information. We will not use or share your information with anyone except as described
            in this Privacy Policy.
          </p>
          <h2>Information We Collect</h2>
          <p>
            <b>User-Provided Information:</b> You provide us information about yourself, such as a
            user name and e-mail address, when you register with the Service. If you correspond with
            us by email, we may retain the content of your email messages, your email address and
            our responses. In addition, you may choose to add information about yourself to your
            Profile that you can then share with friends. You may be able to modify, delete, and set
            certain privacy options for this information in the Profile section of the site after
            you have signed in using your personal password.
          </p>
          <p>
            <b>Cookies Information:</b> When you visit BingeRoom.com, we may send one or more
            cookies - a small text file containing a string of alphanumeric characters - to your
            computer that uniquely identifies your browser and lets BingeRoom help you log in
            faster, enhance your navigation through the site, and/or enable additional service
            features. A cookie does not collect personal information about you. BingeRoom uses both
            session cookies and persistent cookies. A persistent cookie remains on your hard drive
            after you close your browser. Persistent cookies may be used by your browser on
            subsequent visits to the site. Persistent cookies can be removed by following your web
            browser’s directions. A session cookie is temporary and disappears after you close your
            browser. You can reset your web browser to refuse all cookies or to indicate when a
            cookie is being sent. However, some features of the Service may not function properly if
            the ability to accept cookies is disabled.
          </p>
          <p>
            <b>Log File Information:</b> Log file information is automatically reported by your
            browser each time you access a web page. When you register with or view our site, our
            servers automatically record certain information that your web browser sends whenever
            you visit any website. These server logs may include information such as your web
            request, Internet Protocol (&quot;IP&quot;) address, browser type, referring / exit
            pages and URLs, number of clicks, domain names, landing pages, pages viewed, and other
            such information.
          </p>
          <p>
            <b>Clear Gifs Information:</b> When you use the Service, we may employ clear gifs
            (a.k.a. Web Beacons or action tags) which are used to track the online usage patterns of
            our users anonymously. No personally identifiable information from your BingeRoom
            account is collected using these clear gifs. In addition, we may also use clear gifs in
            HTML-based emails sent to our users to track which emails are opened by recipients. The
            information is used to enable service analysis, research, more accurate reporting,
            improving the effectiveness of our marketing, and making BingeRoom better for our users.
            We may also allow third-parties to place Web Beacons and other related technologies on
            our site to conduct research and analysis.
          </p>
          <p>
            <b>Mobile:</b> When you download or use Apps created by BingeRoom, we may receive
            information about your location and your mobile device, including, but not limited to, a
            unique identifier for your device. We may use this information to provide you with
            location-based services, such as advertising, local show times, movie times, search
            results, and other personalized content. Be aware that mobile devices may allow you to
            turn off your location services. We may also access certain information on your mobile
            device in order to enable the Service to function, and to enhance your user experience.
            By downloading and using the App, you agree to permit us to access such information
            solely as it relates to the Service, and to use it in a manner consistent with the App,
            and which serves to protect the privacy of the information, subject to its use to enable
            certain App function.
          </p>
          <h2>How We Use Your Information</h2>
          <p>
            We use the personal information you submit to operate, maintain, and provide to you the
            features and functionality of the Service. Any personal information or content that you
            voluntarily disclose for posting to the Service (for instance, in User Content you post)
            becomes available to the public via the Service and our business partners. User Content
            includes, but is not limited to, reviews, ratings, recommendations, profiles, comments,
            images and/or videos. If you remove User Content, copies may remain viewable in cached
            and archived pages or if other users have copied or stored your User Content.
          </p>
          <p>
            BingeRoom takes reasonable measures to keep messages that you send to other Members
            through the Service private between you, the recipient, and/or the Group. However, even
            if you have deleted the message from your BingeRoom chat, inbox, or outbox, copies of
            the message may remain viewable in cached and archived pages or if other users have
            copied or stored the message.
          </p>
          <p>
            By providing BingeRoom your email address, you consent to our using the email address to
            send you Service-related notices, including any notices required by law, in lieu of
            communication by postal mail. We may also use your email address to send you other
            messages, such as newsletters, changes to features of the Service, or special offers. If
            you do not want to receive such email messages, we may add in a feature that allows you
            to opt out by changing your settings on the Profile page. Opting out may prevent you
            from receiving email messages regarding updates, improvements, or offers. You may not
            opt out of Service-related or legal e-mails.
          </p>
          <p>
            Your user name and email address are used when you invite another person to join
            BingeRoom, or when a you request to add another member to your friends list or group.
          </p>
          <p>
            BingeRoom may use certain information about you and/or your User Content without
            identifying you as an individual to third parties. We do this for purposes such as
            analyzing how the Service is used, diagnosing service or technical problems, maintaining
            security, research, and personalizing advertisements and promotions.
          </p>
          <p>
            BingeRoom may develop special sites in cooperation with other companies. If you register
            at these &quot;co-branded&quot; sites, we may share your registration information (such
            as user name and email address) with that company.
          </p>
          <p>
            BingeRoom reserves the right, but has no obligation, to monitor the User Content you
            post on the site. BingeRoom has the right to remove any such information or material for
            any reason or no reason, including without limitation, if in its sole opinion, such
            information or material violates, or may violate, any applicable law or our Terms of Use
            Agreement or to protect or defend its rights or property or those of any third party.
            BingeRoom also reserves the right to remove information upon the request of any third
            party.
          </p>
          <p>
            We use cookies, clear gifs, and log file information to: (a) remember information so
            that you will not have to re-enter it during your visit or the next time you visit the
            site; (b) provide custom, personalized content and information; (c) monitor the
            effectiveness of our service; (d) monitor aggregate metrics such as total number of
            visitors and traffic; (e) diagnose or fix technology problems reported by our users or
            engineers that are associated with the IP addresses controlled by a specific web company
            or ISP; and (f) help you efficiently access your information after you sign in.
          </p>
          <h2>How We Share Your Information</h2>
          <p>
            <b>Personally Identifiable Information:</b> BingeRoom may share your personally
            identifiable information with third parties solely for the purpose of providing services
            to you. If we do this, such third parties’ use of your information will be bound by this
            Privacy Policy. We may store personal information in locations outside the direct
            control of BingeRoom (for instance, on servers or databases co-located with hosting
            providers).
          </p>
          <p>
            As we develop our business, we may buy or sell assets or business offerings or create
            strategic partnerships. Customer, email, and visitor information is generally one of the
            transferred business assets in these types of transactions. We may also transfer such
            information in the course of corporate divestitures, mergers, or dissolution.
          </p>
          <p>
            Except as otherwise described in this privacy statement, BingeRoom will not disclose
            personal information to any third party unless required to do so by law or subpoena or
            if we believe that such action is necessary to (a) conform to the law, comply with legal
            process served on us or our affiliates, or investigate, prevent, or take action
            regarding suspected or actual illegal activities; (b) to enforce our Terms of Use, take
            precautions against liability, to investigate and defend ourselves against any
            third-party claims or allegations, to assist government enforcement agencies, or to
            protect the security or integrity of our site; and (c) to exercise or protect the
            rights, property, or personal safety of BingeRoom, our users or others.
          </p>
          <p>
            <b>Non-Personally Identifiable Information:</b> We may share non-personally identifiable
            information (such as anonymous usage data, referring/exit pages and URLs, platform
            types, number of clicks, etc.) with interested third parties to help them understand the
            usage patterns for certain BingeRoom services and for potentially developing marketing
            promotions/offers.
          </p>
          <p>
            BingeRoom may allow other companies, called third-party ad servers or ad networks, to
            serve advertisements on the BingeRoom site. These third-party ad servers or ad networks
            use technology to send, directly to your browser, the advertisements and links that
            appear on BingeRoom. They automatically receive your IP address when this happens. They
            may also use other technologies (such as cookies, JavaScript, or Web Beacons) to measure
            the effectiveness of their advertisements and to personalize the advertising content.
          </p>
          <p>
            BingeRoom does not provide any personally identifiable information to these third-party
            ad servers or ad networks without your consent. However, please note that if an
            advertiser asks BingeRoom to show an advertisement to a certain audience and you respond
            to that advertisement, the advertiser or ad-server may conclude that you fit the
            description of the audience they are trying to reach.
          </p>
          <p>
            You should consult the respective privacy policies of these third-party ad servers or ad
            networks. The BingeRoom privacy policy does not apply to, and we cannot control the
            activities of, such advertisers.
          </p>
          <h2>How We Protect Your Information</h2>
          <p>
            BingeRoom uses commercially reasonable physical, managerial, and technical safeguards to
            preserve the integrity and security of your personal information. We cannot, however,
            ensure or warrant the security of any information you transmit to BingeRoom and you do
            so at your own risk. Once we receive your transmission of information, BingeRoom makes
            commercially reasonable efforts to ensure the security of our systems. However, please
            note that this is not a guarantee that such information may not be accessed, disclosed,
            altered, or destroyed by breach of any of our physical, technical, or managerial
            safeguards.
          </p>
          <p>
            To protect your privacy and security, we take reasonable steps (such as requesting a
            unique password) to verify your identity before granting you access to your account. You
            are responsible for maintaining the secrecy of your unique password and account
            information, and for controlling access to your email communications from BingeRoom, at
            all times.
          </p>
          <h2>Compromise of Personal Information</h2>
          <p>
            In the event that personal information is compromised as a result of a breach of
            security, BingeRoom will promptly notify those persons whose personal information has
            been compromised, in accordance with the notification procedures set forth in our
            Privacy Policy, or as otherwise required by applicable law.
          </p>
          <h2>Your Choices About Your Information</h2>
          <p>
            You may access or change your information and preferences at any time in your Profile
            area once you have signed in with your personal password. For example, you may be able
            to control which actions, if any, will appear on your alert feed, whether to display
            your name, and what sort of email notifications you will receive from the Service. You
            may, of course, decline to submit personally identifiable information through BingeRoom,
            in which case BingeRoom may not be able to provide certain services to you.
          </p>
          <h2>Children&#39;s Privacy</h2>
          <p>
            Protecting the privacy of young children is especially important. For that reason,
            BingeRoom does not knowingly collect or solicit personal information from anyone under
            the age of 13 or knowingly allow such persons to register. If you are under 13, please
            do not send any information about yourself to us, including your name, address,
            telephone number, or email address. No one under age 13 is allowed to provide any
            personal information to or on BingeRoom. In the event that we learn that we have
            collected personal information from a child under age 13 without verification of
            parental consent, we will delete that information as quickly as possible. If you believe
            that we might have any information from or about a child under 13, please{' '}
            <Link href='/contact'>Contact Us.</Link>
          </p>
          <h2>Links to Other Web Sites</h2>
          <p>
            We are not responsible for the practices employed by Web sites linked to or from our Web
            site nor the information or content contained therein. Please remember that when you use
            a link to go from our Web site to another Web site, our Privacy Policy is no longer in
            effect. Your browsing and interaction on any other Web site, including those that have a
            link on our Web site, is subject to that Web site&#39;s own rules and policies. Please
            read over those rules and policies before proceeding.
          </p>
          <h2>Notification Procedures</h2>
          <p>
            It is our policy to provide notifications, whether such notifications are required by
            law or are for marketing or other business related purposes, to you via email notice,
            written or hard copy notice, or through conspicuous posting of such notice on our
            Website page, as determined by BingeRoom in its sole discretion. We reserve the right to
            determine the form and means of providing notifications to you, provided that you may
            opt out of certain means of notification as described in this Privacy Policy.
          </p>
          <h2>Changes to Our Privacy Policy</h2>
          <p>
            If we change our privacy policies and procedures, we will post those changes on our
            website to keep you aware of what information we collect, how we use it and under what
            circumstances we may disclose it. Changes to this Privacy Policy are effective when they
            are posted on this page.
          </p>
          <p>
            If you have any questions about this Privacy Policy, the practices of this site, or your
            dealings with this Web site, please <Link href='/contact'>Contact Us</Link> or send mail
            to:
          </p>
          <p>
            BingeRoom, LLC
            <br />
            130 Washington Street
            <br />
            P.O. Box 211
            <br />
            Rocky Hill, NJ 08553
          </p>
          <h1>Group Moderator Guidelines</h1>
          <p>
            If you create and moderate a group on BingeRoom, here are some guidelines and
            information that will help you.
          </p>
          <ul>
            <li>
              People use BingeRoom to primarily discuss Movies, TV/Streaming Shows,
              Actors/Characters, and Entertainment Media. We understand that imagery and explicit
              language is a regular part of some of this discussion. We respect people’s right to
              share content that is meaningful to their discussions, such as photos of movie/show
              settings, actors, or photos that represent feelings about a movie/show and the
              characters, including erotica (so long as it does not violate our content
              requirements). If your group regularly features explicit content, then your group MUST
              be designated for adults only (18+).
            </li>
            <li>
              If a group is designated for adults only (18+) – whether it includes discussion of
              erotica or not - its discussion board and content can only be viewed by BingeRoom
              members who are 18 and older.
            </li>
            <li>
              BingeRoom members age 17 and younger are not allowed to join groups that are
              designated for adults only (18+).
            </li>
            <li>
              Sexual role-play is not tolerated in any group or any other part of the site. Romance
              is permitted in role-play games, and in tasteful chat conversations, but pornographic
              content and explicitly sexual content or scenarios are not permitted.
            </li>
            <li>
              If your group is designated for adults only (18+) and it comes to your attention that
              a member is actually 17 or younger, you MUST remove this member from the group
              immediately. If you have any questions regarding this, please email the BingeRoom team
              at Support@BingeRoom.com.
            </li>
            <li>
              Moderators have the power to delete any discussion post in their group. We suggest
              leaving all member comments except for the following: spam, porn, extremely offensive
              material, or direct personal attacks on other members. Arguing is often part of
              discussing, but personal attacks are not necessary. When you do delete a comment that
              members may miss, note you have done so and why, so members know what happened. Note
              that members may also edit or delete their own comments.
            </li>
            <li>
              Moderators have the power to remove any member from the group. Do not abuse this
              power, use this to be vindictive, overly controlling, to unduly stifle discussion, or
              to bully members of your group. Removing a member involuntarily should only be done
              for the most serious violations, and warnings should be given prior to the actual
              Removal. The Moderator’s ability to remove specific members from a group is subject to
              review and reversal by BingeRoom (though we are under no obligation to do so) if we
              receive a specific complaint that is deemed to be justified, in our sole discretion.
            </li>
            <li>
              Moderators can add other moderators. We suggest you do this if you aren&#39;t
              particularly active, or want to leave the group. All moderators of a group have full
              moderator powers.
            </li>
            <li>
              Only the group&#39;s creator can delete a group. Deleting a group is a drastic action,
              as members may want to continue their community and discussion, and reference prior
              information. Do not abuse this power, use this to be vindictive, overly controlling,
              to unduly stifle discussion, or to bully members of your group. Before deleting a
              group, you should ask if the other members are generally in agreement, consider
              letting another moderator take over and run the group, or just consider letting the
              group remain as a dormant/inactive group – as you may decide in the future to resume
              active discussions if something interesting comes along! Groups that have been
              inactive for more than a year, may be subject to deletion by us, in our sole
              discretion.
            </li>
            <li>
              If a group creator/moderator becomes permanently unable to fulfill his or her duties
              (for example, death), a member of the group may{' '}
              <Link href='/contact'>Contact Us</Link> to request that a new moderator be designated
              to continue with the group. Any such request must include verifiable evidence
              regarding the prior creator/moderator being unable to fulfill their role, and at least
              a majority decision of the remaining group members as to who should be designated as
              the new moderator(s). We are under no obligation to do so, but we will consider the
              request, in our sole discretion.
            </li>
            <li>
              Need some help from fellow moderators? Join the moderator&#39;s BingeRoom group here:
              Moderator’s Forum.
            </li>
          </ul>
        </SCContent>
      </MaxWidthWrapper>
      <Footer />
    </Theme>
  );
};

export default PrivacyPolicy;
