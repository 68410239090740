import React from 'react';
import styled from 'styled-components';

import MaxWidthWrapper from 'components/MaxWidthWrapper';
import SCButton from 'components/styled/SCButton';
import variables from 'components/styled/variables';
import logo from 'img/logo.svg';

const SCHeader = styled.div`
  background: ${variables.colors.background.site};
`;

const SCInner = styled.div`
  align-items: center;
  display: flex;
  padding: 60px;

  > * {
    flex-grow: 0;
  }

  @media (max-width: 1000px) {
    padding: 20px;

    > img {
      width: 50px;
    }

    ${SCButton} {
      padding: 0 30px;
      height: 36px;
      line-height: 36px;
    }
  }
`;

const SCSpacer = styled.div`
  flex-grow: 1;
`;

const LoggedOutHeader: React.FC = () => {
  return (
    <SCHeader>
      <MaxWidthWrapper theme='dark'>
        <SCInner>
          <img src={logo} alt='BingeRoom' />
          <SCSpacer />
          <SCButton as='a' transparent href='/login'>
            Log In
          </SCButton>
          <SCButton as='a' href='/register'>
            Sign Up
          </SCButton>
        </SCInner>
      </MaxWidthWrapper>
    </SCHeader>
  );
};

export default LoggedOutHeader;
