import cn from 'classnames';
import React from 'react';

interface IProps {
  className?: string;
}

const Spinner: React.FC<IProps> = ({ className }) => {
  return (
    <div className={cn(className)}>
      <span className='fas fa-spinner fa-spin' />
    </div>
  );
};

export default Spinner;
