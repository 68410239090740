import React from 'react';
import { Link } from 'react-router-dom';

import ContentBox from 'components/ContentBox';
import LoginPageWrapper from 'components/LoginPageWrapper';

import css from './ForgotPasswordConfirmSuccess.module.scss';

const ForgotPasswordConfirmSuccess: React.FC = () => {
  return (
    <LoginPageWrapper>
      <ContentBox title='Reset Password'>
        <p>
          You have successfully reset your password
          <br />
          Log in now to access your account.
        </p>
        <div className={css.LoginButtonWrapper}>
          <Link to='/login'>
            <div className={css.LoginButton}>Log In</div>
          </Link>
        </div>
      </ContentBox>
    </LoginPageWrapper>
  );
};

export default ForgotPasswordConfirmSuccess;
