import React from 'react';
import styled from 'styled-components';

import { IMovie, IMovieVideo, ITVShow, ITVShowVideo } from 'api/CatalogAPI';
import SCButton from 'components/styled/SCButton';
import { displayWatchVideoModal } from 'containers/modals/WatchVideoModal';
import eyeIcon from 'img/eyeball_icon.svg';
import playIcon from 'img/play.svg';

const SCButtonWrapper = styled.div`
  display: flex;
  margin: 0;

  @media (max-width: 1000px) {
    margin: 30px 0;
  }
`;

const SCPlayButton = styled(SCButton)`
  align-items: center;
  display: flex;
  height: 35px;
  line-height: 35px;
  margin-right: 10px;
  padding: 0 20px 0 15px;

  img {
    margin-right: 10px;
  }
`;

const SCWatchNowButton = styled(SCPlayButton)`
  padding: 0 10px;

  img {
    margin-right: 10px;
  }
`;

const getTrailerUrl = (videos: ITVShowVideo[] | IMovieVideo[]): string | undefined => {
  const trailer = videos.find((video: ITVShowVideo | IMovieVideo) => video.type === 'trailer');
  return trailer?.embed_url;
};

const getFullVideoUrl = (show: ITVShow | IMovie | undefined): string | undefined => {
  return show?.stream_url;
};

const openTrailer = (url: string) => {
  displayWatchVideoModal(url);
  (window)?.gtag('event', 'play_trailer', {
    'page': 'PDP'
  });
};

interface IProps {
  media?: ITVShow | IMovie;
}

const WatchNowButtons: React.FC<IProps> = (props): JSX.Element | null => {
  const media = props.media;
  const trailerUrl = media?.videos ? getTrailerUrl(media?.videos) : undefined;
  const fullVideoUrl = getFullVideoUrl(media);

  return (trailerUrl || fullVideoUrl) ?
    <SCButtonWrapper>
      {trailerUrl &&
        <SCPlayButton onClick={() => openTrailer(trailerUrl)}>
          <img src={playIcon} alt='Play Icon' />
          Play Trailer
        </SCPlayButton>
      }
      {fullVideoUrl &&
        <a href={fullVideoUrl} onClick={() => (window)?.gtag('event', 'watch_now', { page: 'PDP' })} target="_blank" rel="noopener noreferrer">
          <SCWatchNowButton>
            <img src={eyeIcon} alt='Watch Icon' />
            Watch Now
          </SCWatchNowButton>
        </a>
      }
    </SCButtonWrapper> : null;
};

export default WatchNowButtons;