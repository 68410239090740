import React from 'react';
import MaxWidthWrapper from '../../../components/MaxWidthWrapper';
import BuildYourBingingBrandImage from './img/BuildYourBingingBrand.png';
import styled from 'styled-components';
import SCSection from './SCSection';
import SCHideForMobile from '../../../components/styled/SCHideForMobile';
import SCCTAButton from './SCCTAButton';
import IPhoneImage from './img/iPhone3.png';
import SCShowForMobile from '../../../components/styled/SCShowForMobile';

const SCTrackSection = styled.div`
  height: 800px;
  overflow: hidden;
  position: relative;

  @media (max-width: 1000px) {
    height: auto;
  }
`;

const SCBuildYourBrandImage = styled.img.attrs({
  src: BuildYourBingingBrandImage,
  alt: 'Build your binging brand',
})`
  position: absolute;
  right: 60px;
  top: 60px;

  @media (max-width: 1300px) {
    right: auto;
    left: 520px;
  }
`;

const SCTrackContent = styled.div`
  left: 60px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 410px;

  > h1 {
    font-size: 75px;
  }

  @media (max-width: 1000px) {
    left: 0;
    position: relative;
    top: 0;
    transform: none;
    max-width: none;
    padding: 60px 20px;
    text-align: center;

    > h1 {
      font-size: 30px;
      margin-top: 0;
    }

    > h2 {
      font-size: 16px;
      font-weight: normal;
    }
  }
`;

const SCIPhoneImage = styled.img.attrs({ src: IPhoneImage, alt: 'iPhone' })`
  margin-top: 40px;
`;

const TrackSection: React.FC = () => {
  return (
    <SCSection>
      <MaxWidthWrapper>
        <SCTrackSection>
          <SCHideForMobile>
            <SCBuildYourBrandImage />
          </SCHideForMobile>
          <SCTrackContent>
            <h1>Build your binging brand.</h1>
            <h2>
              Stand out as a sage of Stallone movies. Flex your Walking Dead fandom. Create a
              self-portrait of your authentic binging persona. First impressions here are based on
              the movies and shows you unapologetically love to binge.
            </h2>
            <SCCTAButton href='/register'>Create Portrait</SCCTAButton>
            <SCShowForMobile>
              <SCIPhoneImage />
            </SCShowForMobile>
          </SCTrackContent>
        </SCTrackSection>
      </MaxWidthWrapper>
    </SCSection>
  );
};

export default TrackSection;
