import React from 'react';
import styled from 'styled-components';

import Footer from 'components/Footer';
import Link from 'components/Link';
import MaxWidthWrapper from 'components/MaxWidthWrapper';
import Theme from 'components/Theme';
import GenericHeader from 'containers/GenericHeader';

const SCContent = styled.div`
  background: white;
  color: black;
  border-radius: 10px;
  padding: 20px 60px;
  margin: 0 60px;

  @media (max-width: 1000px) {
    padding: 20px;
    margin: 0 20px;
  }
`;

const Terms: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Theme theme='dark'>
      <GenericHeader />
      <MaxWidthWrapper>
        <SCContent>
          <h1>Terms of Use</h1>
          <p>
            Welcome to BingeRoom.com, the website, the mobile application, and online service
            (&quot;Service&quot;) of BingeRoom, LLC (together with its affiliates,
            &quot;BingeRoom&quot; &quot;we,&quot; or &quot;us&quot;). These Terms of Use, Privacy
            Policy, and Community Guidelines govern all use of BingeRoom’s Service, including our
            websites, mobile applications, online presence, and any other content, features,
            materials, widgets, and/or online services offered by BingeRoom. By accessing,
            downloading, or using the BingeRoom services, website and/or software provided through
            or in connection with the Service, you signify that you have read, understood, given us
            permissions necessary for you to use the Servce, and agree to be bound by BingeRoom’s
            Terms of Use, Privacy Policy, Community Guidelines, and other applicable rules, policies
            and terms posted on the BingeRoom website or provided with the Service (collectively,
            this &quot;Agreement&quot;), whether or not you are a registered user of our Service.
          </p>
          <p>
            We can amend this Agreement at any time in our sole discretion by posting the revised
            Agreement on BingeRoom.com. We intend to post a notice here of any amendments, and after
            a period of 30 days thereafter, your continued use of the Service constitutes your
            acceptance of the revised Agreement. This Agreement applies to all visitors, users, and
            others who access the Service (&quot;Users&quot;).
          </p>
          <h2>1. Use of Our Service</h2>
          <p>
            BingeRoom provides a place for you to discover, track, review, recommend, and chat
            primarily about Movies, TV/Streaming Shows, Actors/Characters and Entertainment Media
            with friends, groups, and our community.
          </p>
          <p>
            To access features of the Service, you will need to register with BingeRoom and create a
            &quot;Member&quot; account. Your account gives you access to the services and
            functionality that we may establish and maintain from time to time and in our sole
            discretion.
          </p>
          <h3>Mobile Application:</h3>
          <p>
            Our Terms, Privacy Policy, and Community Guidelines apply for iOS Application or using
            any part of the Service through our mobile app (BRApp) from the Apple App Store, Google
            Play Store, or any other provider. To further that end, you hereby give us all
            permissions required for you to download and use our mobile application. Our Terms are
            solely between you and us, not Apple, not Google, etc., and that such providers like
            Apple and Google have no responsibility for the BRApp or content therein. Your use of
            the iOSApp must comply with the App Store Terms of Use. Your use of the Google Play
            Store (or any other provider) must comply with its Terms of Use. As between their terms
            and ours, the most restrictive Terms of Use will apply. Neither Apple, nor any other
            provider, has any obligation whatsoever to furnish maintenance or support services with
            respect to the iOS App and our BRApp. In the event of any failure of the iOS App to
            conform to any applicable warranty, you may notify Apple, and Apple can refund the
            purchase price, if any, for the iOS App to you; to the maximum extent permitted by
            applicable law, Apple (or other provider) will have no warranty obligation whatsoever
            with respect to the iOS App and our BRApp, and any other claims, losses, liabilities,
            damages, costs or expenses attributable to any failure to conform to any warranty will
            be solely governed by our Terms. You and BingeRoom acknowledge that Apple (or any other
            provider) is not responsible for addressing any claims by you or any third-party
            relating to the iOS App and the BRApp, including, but not limited to, (i) product
            liability claims; (ii) any claim that the iOS App fails to conform to any applicable
            legal or regulatory requirement, and (iii) claims arising under consumer protection or
            similar laws. You and BingeRoom agree that in the event of any third party claim that
            the BRApp or your possession and use of the iOS App infringes that third party’s
            intellectual property rights, BingeRoom, and not Apple, will be solely responsible for
            the investigation, defense, settlement and discharge of any such intellectual property
            claim to the extent required by these Terms. You must comply with applicable third party
            terms of agreement when using the iOS App or BRApp. You and BingeRoom acknowledge and
            agree that Apple, and Apple’s subsidiaries (as well as other providers), are third party
            beneficiaries of these Terms as they relate to your license of the iOS App or BRApp, and
            that upon your acceptance of the Terms, Apple (and other providers) will have the right
            (and will be deemed to have accepted the right) to enforce these Terms against you as a
            third party beneficiary thereof.
          </p>
          <p>
            Apps made available through the App Store are licensed, not sold, to you. Your license
            to each App is subject to your prior acceptance of both our Terms and that of Apple’s
            Licensed End User License Agreement (&quot;EULA&quot;). Your license to any Apple App
            under the EULA is granted by Apple, and your license to our BRApp is granted by
            BingeRoom. Any App that is subject to the EULA is a Licensed Application. BingeRoom
            and/or Apple as applicable &quot;Licensor&quot; reserves all rights in and to the
            Licensed Application not expressly granted to you under the EULA or our Terms.
          </p>
          <p>
            You may never use another Member’s account without permission. You are responsible for
            maintaining the confidentiality of your account and password and for restricting access
            to your account, and you agree to accept responsibility for all activities that occur
            under your account or password. You agree to notify BingeRoom immediately of any breach
            of security or unauthorized use of your account.
          </p>
          <p>
            You may be able to change the settings on your Profile page to control how other members
            communicate with you. By providing BingeRoom your email address, you consent to our
            using the email address in accordance with our{' '}
            <Link href='/privacy'>Privacy Policy</Link>.
          </p>
          <p>
            Subject to your compliance with this Agreement and your payment of any applicable fees,
            we grant you a limited, non-exclusive, non-transferable, non-sublicensable license to
            access and make use of the Service. This license does not include any resale or
            forbidden commercial use (spamming, obscenity, etc.) of any part of the Service, or its
            contents; any collection and use of any movie/show listings, descriptions, reviews or
            other material included in the Service; any reverse engineering, decompiling, tampering,
            and/or derivative use of any part of the Service or its contents; any downloading,
            copying, or other use of account information for the benefit of any third party; or any
            use of data mining, robots, or similar data gathering and extraction tools. All rights
            not expressly granted to you in this Agreement are reserved and retained by us or our
            licensors, suppliers, rightsholders, or other content providers. No part of the Service
            may be reproduced, duplicated, copied, sold, resold without our express written consent.
            You may not frame or utilize framing techniques to enclose any trademark, logo, or other
            proprietary information (including images, text, page layout, or form) of BingeRoom
            without our express written consent. You may not use any meta tags or any other
            &quot;hidden text&quot; utilizing our name or trademarks without our express written
            consent. You may not misuse the Service. You may use the Service only as permitted by
            law. The licenses granted by us terminate if you do not comply with this Agreement.
          </p>
          <p>
            In order to protect our Members from unsolicited advertising or solicitation, BingeRoom
            reserves the right to restrict the number of communications that a Member may send to
            other Members in any 24- hour period to a number that BingeRoom deems appropriate in its
            sole discretion.
          </p>
          <p>
            BingeRoom may permanently or temporarily terminate, suspend, or otherwise refuse to
            permit your access to the Service without notice and liability for any reason, including
            if in BingeRoom&#39;s sole determination you violate any provision of this Agreement, or
            for no reason. Upon termination for any reason or no reason, you continue to be bound by
            this Agreement. BingeRoom reserves the right to refuse, remove, and/or limit ratings,
            reviews, recommendations, comments, images, and other content, in our sole discretion.
            The Service is subject to scheduled and unscheduled service interruptions. All aspects
            of the Service are subject to change or elimination at BingeRoom’s sole discretion. You
            agree that BingeRoom will not be liable to you for any interruption of the Service,
            delay, discontinuation, or failure to perform.
          </p>
          <p>
            You are solely responsible for your interactions with other BingeRoom Users. We reserve
            the right, but have no obligation, to monitor disputes between you and other Users.
            BingeRoom shall have no liability for your interactions with other Users, or for any
            User’s action or inaction. We encourage you to read our{' '}
            <Link href='/privacy'>Community Guidelines</Link>.
          </p>
          <h2>2. User Content</h2>
          <p>
            Our Service may allow Users to upload, publish, display, link to or otherwise make
            available (hereinafter, &quot;post&quot;) reviews, recommendations, comments, questions,
            highlights, videos, and other information including Users’ names, voices and likenesses
            (&quot;User Content&quot;). You are solely responsible for your User Content. You agree
            not to post User Content that is illegal, obscene, pornographic, threatening,
            defamatory, libelous, invasive of privacy, infringing of intellectual property rights
            (including publicity rights), or otherwise injurious to third parties or objectionable,
            and does not consist of or contain software viruses, political campaigning, spamming,
            chain letters, mass mailings, or any form of &quot;spam&quot; or unsolicited electronic
            messages. You may not use a false e-mail address, impersonate any person or entity, or
            otherwise mislead as to the origin of content. You hereby represent that you are the
            owner of all the copyright rights with respect to, or that you have the legal right to
            post, your User Content, and that you have the power to grant the license granted below.
            BingeRoom reserves the right to monitor, reject and/or remove any User Content at any
            time. For example, BingeRoom may, but is not obligated to, reject and/or remove any User
            Content that BingeRoom believes, in its sole discretion, violates these provisions.
          </p>
          <p>
            BingeRoom takes no responsibility and assumes no liability for any User Content that you
            or any other Users or third parties post or send over the Service. You understand and
            agree that any loss or damage of any kind that occurs as a result of the use of any User
            Content that you post is solely your responsibility. BingeRoom is not responsible for
            any public display or misuse of your User Content. You understand and acknowledge that
            you may be exposed to User Content that is inaccurate, offensive, indecent, or
            objectionable, and you agree that BingeRoom shall not be liable for any damages you
            allege to incur as a result of such User Content. BingeRoom may provide tools for you to
            remove some User Content, but does not guarantee that all or any User Content will be
            removable.
          </p>
          <h2>3. License Grant</h2>
          <p>
            By posting any User Content on the Service, you expressly grant to BingeRoom a
            nonexclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to
            use, reproduce, modify, adapt, publish, perform, translate, create derivative works
            from, distribute, and display such content throughout the world in any media. You
            represent and warrant that you own or otherwise control all of the rights to the content
            that you post; that the content is accurate; that use of the content you supply does not
            violate this policy and will not cause injury to any person or entity; and that you will
            indemnify BingeRoom for all claims resulting from content you supply.
          </p>
          <h2>4. Our Proprietary Rights</h2>
          <p>
            Except for your User Content, the Service and all materials therein or transferred
            thereby, including, without limitation, software, images, text, graphics, illustrations,
            logos, patents, trademarks, service marks, copyrights, photographs, audio, videos,
            music, and User Content (the &quot;BingeRoom Content&quot;), and all intellectual
            property Rights related thereto, are the exclusive property of BingeRoom and its
            licensors. Except as explicitly provided herein, nothing in this Agreement shall be
            deemed to create a license in or under any such intellectual property Rights, and you
            agree not to sell, license, rent, modify, distribute, copy, reproduce, transmit,
            publicly display, publicly perform, publish, adapt, edit or create derivative works from
            any materials or content accessible on the Service. Use of the BingeRoom Content or
            materials on the Service for any purpose not expressly permitted by this Agreement is
            strictly prohibited.
          </p>
          <p>
            You may choose to or we may invite you to submit comments or ideas about the Service,
            including without limitation about how to improve the Service or our products
            (&quot;Ideas&quot;). By submitting any Idea, you agree that your disclosure is
            gratuitous, unsolicited and without restriction and will not place BingeRoom under any
            fiduciary or other obligation, and that we are free to use the Idea without any
            additional compensation to you, and/or to disclose the Idea on a non-confidential basis
            or otherwise to anyone. You further acknowledge that, by acceptance of your submission,
            BingeRoom does not waive any rights to use similar or related ideas previously known to
            BingeRoom, or developed by its employees, or obtained from sources other than you.
          </p>
          <h2>5. Eligibility</h2>
          <p>
            This Service is intended solely for Users who are thirteen (13) years of age or older,
            and any registration, use or access to the Service by anyone under 13 is unauthorized,
            unlicensed, and in violation of this Agreement. If you are under 18 years of age you may
            use the Service only if you either are an emancipated minor or possess legal parental or
            guardian consent, and are fully able and competent to enter into the terms, conditions,
            obligations, affirmations, representations, and warranties set forth in this Agreement,
            and to abide by and comply with this Agreement.
          </p>
          <h2>6. Paid Services</h2>
          <p>
            <b>Billing Policies.</b> Certain aspects of the Service may be provided for a fee or
            other charge. These fees and charges, if any, will be described on the website. If you
            elect to use paid aspects of the Service, you agree to the pricing, payment, and billing
            policies applicable to such fees and charges, posted at the website. BingeRoom may add
            new services for additional fees and charges, or amend fees and charges for existing
            services, at any time in its sole discretion.
          </p>
          <p>
            <b>No Refunds.</b> You may cancel your BingeRoom Account at any time; however, there are
            no refunds for cancellation. As permitted under the terms of this Agreement, BingeRoom
            has the right at any time for any reason or no reason to suspend or terminate your
            Account, terminate this Agreement, and/or refuse any and all current or future use of
            the Service without notice, refund, obligation, or liability to you. In the event that
            BingeRoom suspends or terminates your BingeRoom Account or this Agreement, you
            understand and agree that you shall receive no refund or exchange for any unused time on
            a subscription, any license or subscription fees for any portion of the Service, any
            content or data associated with your BingeRoom Account, or for anything else. You
            acknowledge that BingeRoom has the perpetual and irrevocable right to delete any or all
            of your content and data from BingeRoom&#39;s servers and from the Service, whether
            intentionally or unintentionally, and for any reason or no reason, without any liability
            of any kind to you or any other party.
          </p>
          <h2>7. Copyright Complaints</h2>
          <p>
            If you believe that your copyrighted work has been copied in a way that constitutes
            copyright infringement and is accessible via the Service, please send us the following
            information:
          </p>
          <ol>
            <li>
              An electronic or physical signature of a person authorized to act on behalf of the
              copyright owner;
            </li>
            <li>A description of the copyrighted work that you claim has been infringed upon;</li>
            <li>
              A description of the material that is claimed to be infringing and where it is located
              on the Service;
            </li>
            <li>Your address, telephone number, and e-mail address;</li>
            <li>
              A statement that you have a good faith belief that use of the material in the manner
              complained of is not authorized by the copyright owner, its agent, or law; and
            </li>
            <li>
              A statement, made under penalty of perjury, that the above information is accurate,
              and that you are the copyright owner or are authorized to act on behalf of the owner.
            </li>
          </ol>
          <p>
            BingeRoom’s Copyright Agent for notice of claims of copyright infringement on its site
            can be reached as follows:
          </p>
          <p>
            BingeRoom Copyright Agent
            <br />
            P.O. Box 211, Rocky Hill, NJ 08553
            <br />
            Email: <a href='mailto:Copyright@BingeRoom.com'>Copyright@BingeRoom.com</a>
          </p>
          <p>
            Please note that this procedure is exclusively for notifying BingeRoom and its
            affiliates that your copyrighted material has been infringed.
          </p>
          <h2>8. Third-Party Websites, Advertisers or Services</h2>
          <p>
            BingeRoom may contain links to third-party websites, advertisers, or services that are
            not owned or controlled by BingeRoom. BingeRoom has no control over, and assumes no
            responsibility for, the content, privacy policies, or practices of any third-party
            websites or services. If you access a third- party website from BingeRoom, you do so at
            your own risk, and you understand that this Agreement and BingeRoom’s Privacy Policy do
            not apply to your use of such sites. You expressly relieve BingeRoom from any and all
            liability arising from your use of any third-party website or services or third party
            owned content.
          </p>
          <p>
            Additionally, your dealings with or participation in promotions of advertisers found on
            BingeRoom, including payment and delivery of goods, and any other terms (such as
            warranties) are solely between you and such advertisers. You agree that BingeRoom shall
            not be responsible for any loss or damage of any sort relating to your dealings with
            such advertisers.
          </p>
          <p>
            We encourage you to be aware of when you leave the Service, and to read the terms and
            conditions and privacy policy of any third-party website or service that you visit.
          </p>
          <p>BingeRoom uses the TMDb API but is not endorsed or certified by TMDb.</p>
          <h2>9. Indemnity</h2>
          <p>
            You agree to defend, indemnify and hold harmless BingeRoom and its subsidiaries, agents,
            managers, and other affiliated companies, and their employees, contractors, agents,
            officers and directors, from and against any and all claims, damages, obligations,
            losses, liabilities, costs or debt, and expenses (including but not limited to
            attorney&#39;s fees) arising from: (i) your use of and access to the Service, including
            any data or content transmitted or received by you; (ii) your violation of any term of
            this Agreement, including without limitation your breach of any of the representations
            and warranties above; (iii) your violation of any third-party right, including without
            limitation any right of privacy, publicity rights or intellectual property rights; (iv)
            your violation of any law, rule or regulation of the United States or any other country;
            (v) any claim or damages that arise as a result of any of your User Content or any that
            are submitted via your account; or (vi) any other party’s access and use of the Service
            with your unique username, password or other appropriate security code.
          </p>
          <h2>10. Disclaimers of Warranties and Limitation of Liability</h2>
          <p>
            THE SERVICE AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) AND
            OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SERVICE ARE
            PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS, UNLESS OTHERWISE
            SPECIFIED IN WRITING. BINGEROOM MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
            EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE SERVICE, OR THE INFORMATION, CONTENT,
            MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE
            AVAILABLE TO YOU THROUGH THE SERVICE, UNLESS OTHERWISE SPECIFIED IN WRITING. YOU
            EXPRESSLY AGREE THAT YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK.
          </p>
          <p>
            TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, BINGEROOM DISCLAIMS ALL WARRANTIES
            EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY
            AND FITNESS FOR A PARTICULAR PURPOSE. BINGEROOM DOES NOT WARRANT THAT THE SERVICE,
            INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES
            INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SERVICE, BINGEROOM’S SERVERS
            OR ELECTRONIC COMMUNICATIONS SENT FROM BINGEROOM ARE FREE OF VIRUSES OR OTHER HARMFUL
            COMPONENTS. BINGEROOM WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE
            USE OF THE SERVICE, OR FROM ANY INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING
            SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE
            SERVICE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND
            CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING. CERTAIN STATE LAWS DO NOT
            ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN
            DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS,
            OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
          </p>
          <p>
            <b>Assignment.</b> This Agreement, and any rights and licenses granted hereunder, may
            not be transferred or assigned by you, but may be assigned by BingeRoom without
            restriction.
          </p>
          <p>
            <b>Disputes/Binding Arbitration.</b> Any dispute or claim arising from or relating to
            this Agreement, the Software, the Digital Content or the Service is subject to binding
            arbitration, Delaware governing law, disclaimer of warranties, limitation of liability
            and all other terms herein. You agree to those terms by entering into this Agreement or
            using/visiting the Service. You and BingeRoom further agree that any dispute resolution
            arbitration will be conducted only on an individual basis and not in a class action,
            consolidated, or representative action. If for any reason a claim proceeds in a court
            rather than in an arbitration, you and BingeRoom each waive any right to a jury trial.
            You and BingeRoom also agree that you or we may bring suit in a court to enjoin
            infringement or other misuse of intellectual property rights.
          </p>
          <p>
            <b>No Waiver.</b> No waiver of any term of this Agreement shall be deemed a further or
            continuing waiver of such term or any other term, and BingeRoom&#39;s failure to assert
            any right or provision under this Agreement shall not constitute a waiver of such right
            or provision.
          </p>
          <p>
            Please <Link href='/contact'>CONTACT US</Link> with any questions regarding this
            Agreement.
          </p>
          <h1>UPDATES</h1>
          <h2>Community Guidelines</h2>
          <p>
            Our Community Guidelines are intended to provide clarity on interactions with all of
            BingeRoom&#39;s features and services. Our goal is to keep BingeRoom a welcoming place
            where people can come together to share reviews, recommendations, participate in
            discussions, and enjoy interacting with groups relating to Movies, TV/Streaming Shows,
            Actors, and Entertainment Media in general. We appreciate all that you contribute to the
            BingeRoom community!
          </p>
          <p>
            BingeRoom guidelines apply to all areas of community interaction, and by using BingeRoom
            you agree to abide by them.
          </p>
          <ul>
            <li>
              We want you to have the best possible experience on BingeRoom. The BingeRoom community
              is diverse and passionate. Not everyone shares the same point of view, and that
              diversity of opinion is what makes BingeRoom a great place to share your ideas.
              Respectful, lively debates and cutting-edge discussions are encouraged, but consider
              the impact of your contributions on individuals and the community as a whole.
            </li>
            <li>
              Hate speech, bigotry, threats, slander, libel, and ad hominem attacks are not
              tolerated. Respectful criticism of the opinions of others is permitted, but attacking
              individuals for their opinions is not.
            </li>
            <li>
              You should communicate openly with other members, but spam and excessive
              self-promotional content are prohibited. We reserve the right to remove and/or limit
              any such material in our sole discretion.
            </li>
            <li>
              We support original, creative contributions to BingeRoom. Where you can add content,
              go for it. Just make sure it belongs to you—plagiarism and copyright infringement are
              not allowed.
            </li>
            <li>
              You are welcome to post images, but images containing nudity, sexually-explicit
              content, or graphic violence are not allowed. We reserve the right to remove and/or
              limit any such material in our sole discretion.
            </li>
            <li>
              Bring your authentic self to BingeRoom. Please don’t misrepresent your identity or
              create accounts to harass other members, abuse features, or evade our rules. Spammers
              and scammers are not welcome.
            </li>
            <li>
              Artificially inflating or deflating ratings or reputation violates our rules. This
              includes activity like creating fake accounts to manipulate ratings, purchasing
              reviews, and incentivizing votes, likes, or other similar actions on BingeRoom.
            </li>
            <li>
              If you see content that breaks our rules, please{' '}
              <Link href='/contact'>Contact Us</Link>. Don’t take it upon yourself to correct other
              people’s bad behavior, and don’t encourage bad behavior by acknowledging it. Staff
              members evaluate each flagged item and take action to educate members about our rules.
              If a conversation is deteriorating, you can opt to disengage.
            </li>
          </ul>
          <p>
            Simply put, add value to the community—don’t diminish it. If you’re here because you
            adore movies, shows and entertainment media and you make it your business to treat
            others with respect, then welcome!
          </p>
          <p>Here are a few disclaimers:</p>
          <ul>
            <li>
              BingeRoom reserves the right to remove content (including discontinuing/deleting
              groups) at any time for any reason. It is at our sole discretion to decide when
              content violates our guidelines.
            </li>
            <li>
              The content posted on BingeRoom contains individual and subjective opinions. Unless
              otherwise indicated by us in writing, the opinions expressed are those of BingeRoom
              members and not of BingeRoom. We do not endorse any of the opinions expressed by
              members.
            </li>
          </ul>
          <p>
            For additional information about community interaction in groups, please refer to our{' '}
            <Link href='/privacy'>Group Moderator Guidelines</Link>.
          </p>
        </SCContent>
      </MaxWidthWrapper>
      <Footer />
    </Theme>
  );
};

export default Terms;
