import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { TFollowRecommendation } from 'api/FollowAPI';
import Link from 'components/Link';
import Networks from 'components/Networks';
import Rating from 'components/Rating';
import SCAvatar from 'components/styled/SCAvatar';
import SCButton from 'components/styled/SCButton';
import SCHideForMobile from 'components/styled/SCHideForMobile';
import SCPageTitle from 'components/styled/SCPageTitle';
import SCShowForMobile from 'components/styled/SCShowForMobile';
import SCShowMoreButtonWrapper from 'components/styled/SCShowMoreButtonWrapper';
import variables from 'components/styled/variables';
import Theme from 'components/Theme';
import LoggedInPageWrapper from 'containers/LoggedInPageWrapper';
import getImageUrlString from 'helpers/getImageUrlString';
import DefaultGroupAvatar from 'img/DefaultGroupAvatar.png';
import {
  FollowRecommendationStore,
  FriendRequestStore,
  getFilteredFollowRecommendations,
  GroupInviteStore,
  GroupRequestStore,
  hideFollowRecommendation,
} from 'stores/AlertsStore';
import { acceptFriendRequestAction, removeFriendAction } from 'stores/FriendsStore';
import {
  acceptGroupInviteAction,
  acceptGroupRequestAction,
  leaveGroupAction,
  rejectGroupRequestAction,
} from 'stores/groups/GroupDetailsStore';

const SCSectionTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 20px;
`;

const SCAlertsContainer = styled.div`
  margin: 0 0 60px;
`;

const SCRequest = styled.div`
  display: flex;
  background-color: white;
  color: black;

  & + & {
    margin-top: 10px;
  }

  > div {
    display: flex;

    &:first-of-type {
      flex-grow: 1;
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;

    > div {
      &:not(:first-of-type) {
        border-top: 1px solid #c4c4c4;
      }
    }
  }
`;

const SCFriendAvatarWrapper = styled.div`
  padding: 40px;
  border-right: 1px solid #c4c4c4;

  @media (max-width: 1000px) {
    padding: 20px;
  }
`;

const SCFriendNameWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 40px;

  h1 {
    margin: 0;
    font-family: ${variables.fonts.heading};
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 500;
    margin: 0 0 10px;
  }

  h2 {
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    color: ${rgba('black', 0.6)};
  }

  @media (max-width: 1000px) {
    padding: 0 20px;

    h1 {
      font-size: 24px;
      margin-bottom: 5px;
    }
  }
`;

const SCAlertDetailsWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 40px;

  p {
    font-size: 16px;
    color: ${rgba('black', 0.5)};
    margin: 0 0 10px;
  }

  @media (max-width: 1000px) {
    padding: 0 20px;
  }
`;

const SCAlertDetailsHeader = styled.h1`
  margin: 0 0 10px;
  font-size: 20px;
  font-weight: 500;

  @media (max-width: 1000px) {
    padding: 0 20px;

    h1 {
      font-size: 14px;
    }
  }
`;

const SCActionsWrapper = styled.div`
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 40px;

  @media (max-width: 1000px) {
    justify-content: space-around;
    flex-grow: 1;
    padding: 20px;
  }
`;

const SCActionButton = styled(SCButton).attrs({ link: true })<{ secondary?: boolean }>`
  font-weight: normal;
  color: ${(props) =>
    props.secondary ? variables.colors.text.disabled : variables.colors.text.link};
`;

const SCGroupAvatar = styled.div<{ imageUrl?: string | null }>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${rgba('white', 0.125)};
  background-image: ${(props) => getImageUrlString(props.imageUrl ?? DefaultGroupAvatar)};
  border: 0;
  cursor: pointer;
  display: block;
  height: 230px;
  outline: none;
  position: relative;
  width: 190px;
  flex-grow: 0;

  @media (max-width: 1000px) {
    width: 100px;
    height: 100px;
    border-right: 1px solid #c4c4c4;
  }
`;

const SCUserParagraph = styled.div`
  font-size: 12px;
  color: ${rgba('black', 0.6)};
`;

const SCMobileDescriptionWrapper = styled.div`
  padding: 20px;
  color: ${rgba('black', 0.6)};
  font-size: 14px;
`;

const SCLink = styled(Link)`
  margin-left: 23px;
`;

const SCNetworksWrapper = styled.div`
  margin-bottom: 22px;
`;

const SCRatingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 21px;
`;

const SCRatingText = styled.span`
  margin-left: 25px;
`;

const SCMediaTitle = styled(SCPageTitle)`
  font-size: 60px;
  margin-bottom: 22px;

  @media (max-width: 1000px) {
    font-size: 30px;
  }
`;

const Alerts: React.FC = () => {
  const friendRequests = FriendRequestStore.usePaginatedData();
  const groupInvites = GroupInviteStore.usePaginatedData();
  const groupRequests = GroupRequestStore.usePaginatedData();
  const followRecommendations = getFilteredFollowRecommendations(
    FollowRecommendationStore.usePaginatedData().items,
  );

  // Do the frontend things that the backend should do.
  const [counter, setCounter] = React.useState(0);
  const hideFollowRecommendationAndRefresh = (
    followRecommendation: TFollowRecommendation,
  ): void => {
    hideFollowRecommendation(followRecommendation);
    setCounter(counter + 1);
  };

  const noAlerts =
    friendRequests.count +
      groupInvites.count +
      groupRequests.count +
      followRecommendations.length ===
    0;

  const onAcceptFriendInvite = (userId: number) => {
    acceptFriendRequestAction.run({ userId });
  };

  const onIgnoreFriendRequest = (userId: number) => {
    removeFriendAction.run({ userId });
  };

  const onAcceptGroupInvite = (groupId: number) => {
    acceptGroupInviteAction.run({ groupId });
  };

  const onIgnoreGroupInvite = (groupId: number) => {
    leaveGroupAction.run({ groupId });
  };

  const onAcceptGroupRequest = (groupId: number, userId: number) => {
    acceptGroupRequestAction.run({ groupId, userId });
  };

  const onIgnoreGroupRequest = (groupId: number, userId: number) => {
    rejectGroupRequestAction.run({ groupId, userId });
  };

  return (
    <LoggedInPageWrapper
      bg='red'
      isLoading={friendRequests.isLoading || groupInvites.isLoading}
      title='Alerts'>
      {noAlerts ? (
        <p>There are no alerts at the moment.</p>
      ) : (
        <>
          {friendRequests.items.length > 0 && (
            <>
              <SCSectionTitle>Friend Requests</SCSectionTitle>
              <SCAlertsContainer>
                {friendRequests.items.map((friend) => (
                  <SCRequest key={friend.id} theme={{ light: true }}>
                    <div>
                      <SCFriendAvatarWrapper>
                        <SCAvatar variant='comment' imageUrl={friend.user.avatar} />
                      </SCFriendAvatarWrapper>
                      <SCFriendNameWrapper>
                        <h1>@{friend.user.username}</h1>
                        <h2>
                          {friend.user.first_name} {friend.user.last_name}
                        </h2>
                      </SCFriendNameWrapper>
                    </div>
                    <div>
                      <SCActionsWrapper>
                        <SCActionButton
                          secondary
                          onClick={() => onIgnoreFriendRequest(friend.user.id)}>
                          Ignore
                        </SCActionButton>
                        <SCActionButton onClick={() => onAcceptFriendInvite(friend.user.id)}>
                          Accept
                        </SCActionButton>
                      </SCActionsWrapper>
                    </div>
                  </SCRequest>
                ))}
                {friendRequests.hasMorePages && (
                  <SCShowMoreButtonWrapper>
                    <SCButton
                      disabled={friendRequests.isUpdating}
                      onClick={friendRequests.loadNextPage}>
                      Show More
                    </SCButton>
                  </SCShowMoreButtonWrapper>
                )}
              </SCAlertsContainer>
            </>
          )}
          {groupInvites.items.length > 0 && (
            <>
              <SCSectionTitle>Group Invites</SCSectionTitle>
              <SCAlertsContainer>
                {groupInvites.items.map((group) => (
                  <Theme key={group.id} theme='light'>
                    <SCRequest>
                      <div>
                        <SCGroupAvatar imageUrl={group.avatar} />
                        <SCAlertDetailsWrapper>
                          <SCAlertDetailsHeader>{group.title}</SCAlertDetailsHeader>
                          <SCHideForMobile>
                            <p>{group.description}</p>
                          </SCHideForMobile>
                          <SCUserParagraph>
                            Invited by <b>@{group.creator.user.username}</b>
                          </SCUserParagraph>
                        </SCAlertDetailsWrapper>
                      </div>
                      <SCShowForMobile>
                        <SCMobileDescriptionWrapper>{group.description}</SCMobileDescriptionWrapper>
                      </SCShowForMobile>
                      <div>
                        <SCActionsWrapper>
                          <SCActionButton secondary onClick={() => onIgnoreGroupInvite(group.id)}>
                            Ignore
                          </SCActionButton>
                          <SCActionButton onClick={() => onAcceptGroupInvite(group.id)}>
                            Accept
                          </SCActionButton>
                        </SCActionsWrapper>
                      </div>
                    </SCRequest>
                  </Theme>
                ))}
                {groupInvites.hasMorePages && (
                  <SCShowMoreButtonWrapper>
                    <SCButton
                      disabled={groupInvites.isUpdating}
                      onClick={groupInvites.loadNextPage}>
                      Show More
                    </SCButton>
                  </SCShowMoreButtonWrapper>
                )}
              </SCAlertsContainer>
            </>
          )}
          {groupRequests.items.length > 0 && (
            <>
              <SCSectionTitle>Group Requests</SCSectionTitle>
              <SCAlertsContainer>
                {groupRequests.items.map((request, index) => (
                  <Theme key={index} theme='light'>
                    <SCRequest>
                      <div>
                        <SCGroupAvatar imageUrl={request.group.avatar} />
                        <SCAlertDetailsWrapper>
                          <SCAlertDetailsHeader>{request.group.title}</SCAlertDetailsHeader>
                          <SCHideForMobile>
                            <p>{request.group.description}</p>
                          </SCHideForMobile>
                          <SCUserParagraph>
                            Requested by <b>@{request.user.username}</b>
                          </SCUserParagraph>
                        </SCAlertDetailsWrapper>
                      </div>
                      <SCShowForMobile>
                        <SCMobileDescriptionWrapper>
                          {request.group.description}
                        </SCMobileDescriptionWrapper>
                      </SCShowForMobile>
                      <div>
                        <SCActionsWrapper>
                          <SCActionButton
                            secondary
                            onClick={() => onIgnoreGroupRequest(request.group.id, request.user.id)}>
                            Ignore
                          </SCActionButton>
                          <SCActionButton
                            onClick={() => onAcceptGroupRequest(request.group.id, request.user.id)}>
                            Accept
                          </SCActionButton>
                        </SCActionsWrapper>
                      </div>
                    </SCRequest>
                  </Theme>
                ))}
                {groupRequests.hasMorePages && (
                  <SCShowMoreButtonWrapper>
                    <SCButton
                      disabled={groupRequests.isUpdating}
                      onClick={groupRequests.loadNextPage}>
                      Show More
                    </SCButton>
                  </SCShowMoreButtonWrapper>
                )}
              </SCAlertsContainer>
            </>
          )}
          {!!followRecommendations.length && (
            <>
              <SCSectionTitle>Friend Recommendations</SCSectionTitle>
              <SCAlertsContainer>
                <Theme theme='light'>
                  {followRecommendations.map((followRecommendation) => (
                    <SCRequest key={followRecommendation.id}>
                      <div>
                        <SCGroupAvatar imageUrl={followRecommendation.media.images?.poster?.w185} />
                        <SCAlertDetailsWrapper>
                          <SCMediaTitle>
                            {followRecommendation.media?.model === 'tv_show'
                              ? followRecommendation.media.name
                              : followRecommendation.media.title}
                          </SCMediaTitle>
                          {followRecommendation.media?.model === 'tv_show' && (
                            <SCNetworksWrapper>
                              <Networks networks={followRecommendation.media.networks} />
                            </SCNetworksWrapper>
                          )}
                          <SCRatingWrapper>
                            <Rating
                              value={followRecommendation.media.vote_average}
                              onChange={() => '#9C171A'}
                            />
                            <SCRatingText>
                              {(followRecommendation.media.vote_average / 2).toFixed(1)} avg rating
                            </SCRatingText>
                          </SCRatingWrapper>
                          <SCUserParagraph>
                            Recommended by <b>@{followRecommendation.user.username}</b>
                          </SCUserParagraph>
                        </SCAlertDetailsWrapper>
                      </div>
                      <div>
                        <SCActionsWrapper>
                          <SCActionButton
                            secondary
                            onClick={() =>
                              hideFollowRecommendationAndRefresh(followRecommendation)
                            }>
                            Close
                          </SCActionButton>
                          <SCLink
                            href={
                              followRecommendation.media.model === 'tv_show'
                                ? `/dashboard/shows/details/${followRecommendation.media.id}`
                                : `/dashboard/movies/details/${followRecommendation.media.id}`
                            }>
                            Show Me
                          </SCLink>
                        </SCActionsWrapper>
                      </div>
                    </SCRequest>
                  ))}
                </Theme>
              </SCAlertsContainer>
            </>
          )}
        </>
      )}
    </LoggedInPageWrapper>
  );
};

export default Alerts;
