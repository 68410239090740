import React from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { IGroup } from 'api/GroupsAPI';
import SCAvatar from 'components/styled/SCAvatar';
import SCButton from 'components/styled/SCButton';
import LoggedInPageWrapper from 'containers/LoggedInPageWrapper';
import GroupCoverBar from 'containers/pages/Dashboard/Groups/GroupCoverBar';
import MembersModal from 'containers/pages/Dashboard/Groups/MembersModal';
import getFullNameDisplay from 'helpers/getFullNameDisplay';
import { joinGroupAction } from 'stores/groups/GroupDetailsStore';
import { GroupListStore } from 'stores/groups/GroupListStore';
import { GroupMembersStore } from 'stores/groups/GroupMembersStore';

const SCGroupTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
`;

const SCGroupDescription = styled.div`
  color: white;
  font-size: 16px;
`;

const SCGroupActionWrapper = styled.div`
  display: flex;
  margin: 20px 0 40px;
`;

const SCBottomButton = styled(SCButton)`
  text-decoration: underline;
`;

const SCCreatorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0 0;
`;

const SCCreatedByLabel = styled.div`
  margin-right: 20px;
`;

const SCFullName = styled.div`
  margin-left: 20px;
  font-weight: bold;
`;

const SCUserName = styled.div`
  margin-left: 20px;
`;

interface IProps {
  group: IGroup;
}

const GroupPDP: React.FC<IProps> = ({ group }) => {
  const groups = GroupListStore.usePaginatedData();
  const members = GroupMembersStore.usePaginatedData({ groupId: group.id });

  const shouldShowJoinButton = group?.membership == null;
  const isPublic = group?.is_public;

  const [shouldShowMembersModal, setShouldShowMembersModal] = React.useState(false);

  const isLoading = groups.isLoading || members.isLoading;

  const joinGroup = () => {
    joinGroupAction.run({ groupId: group.id }, { treatAsUpdate: true });
  };

  const creatorFullName = getFullNameDisplay(group.creator.user);

  return (
    <LoggedInPageWrapper bg='gray' title='Groups' isLoading={isLoading}>
      {group == null ? (
        <Redirect to='/' />
      ) : (
        <>
          <GroupCoverBar coverUrl={group.cover} avatarUrl={group.avatar} />
          <SCGroupTitle>{group.title}</SCGroupTitle>
          <SCGroupDescription>{group.description}</SCGroupDescription>
          <SCCreatorWrapper>
            <SCCreatedByLabel>Created By:</SCCreatedByLabel>
            <SCAvatar imageUrl={group.creator.user.avatar} variant='comment' />
            {creatorFullName != null && <SCFullName>{creatorFullName}</SCFullName>}
            <SCUserName>@{group.creator.user.username}</SCUserName>
          </SCCreatorWrapper>
          <SCGroupActionWrapper>
            <SCBottomButton link onClick={() => setShouldShowMembersModal(true)}>
              {members.items.length} members
            </SCBottomButton>
          </SCGroupActionWrapper>
          {shouldShowJoinButton && (
            <SCButton block onClick={joinGroup}>
              {isPublic ? 'Join' : 'Request to Join'}
            </SCButton>
          )}
          {shouldShowMembersModal && (
            <MembersModal
              groupId={group.id}
              onRequestClose={() => setShouldShowMembersModal(false)}
            />
          )}
        </>
      )}
    </LoggedInPageWrapper>
  );
};

export default GroupPDP;
