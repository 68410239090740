import { createBrowserHistory } from 'history';
import React from 'react';
import { BrowserRouter, Redirect, Route, Router, Switch, useRouteMatch } from 'react-router-dom';

import Dashboard from 'containers/pages/Dashboard';
import Alerts from 'containers/pages/Dashboard/Alerts';
import Friends from 'containers/pages/Dashboard/Friends';
import AddAFriend from 'containers/pages/Dashboard/Friends/AddAFriend';
import Groups from 'containers/pages/Dashboard/Groups';
import CreateGroup from 'containers/pages/Dashboard/Groups/CreateGroup';
import EditGroup from 'containers/pages/Dashboard/Groups/EditGroup';
import Movies from 'containers/pages/Dashboard/Movies';
import BrowseAllMovies from 'containers/pages/Dashboard/Movies/BrowseAllMovies';
import BrowseNewReleaseMovies from 'containers/pages/Dashboard/Movies/BrowseNewReleaseMovies';
import MoviePDP from 'containers/pages/Dashboard/Movies/MoviePDP';
import MovieReview from 'containers/pages/Dashboard/Movies/Review';
import Profile from 'containers/pages/Dashboard/Profile';
import Search from 'containers/pages/Dashboard/Search';
import Shows from 'containers/pages/Dashboard/Shows';
import BrowseAllShows from 'containers/pages/Dashboard/Shows/BrowseAllShows';
import BrowseNewReleaseShows from 'containers/pages/Dashboard/Shows/BrowseNewReleaseShows';
import ShowReview from 'containers/pages/Dashboard/Shows/Review';
import ShowPDP from 'containers/pages/Dashboard/Shows/ShowPDP';
import UserProfile from 'containers/pages/Dashboard/UserProfile';
import PostRegisterStep1 from 'containers/pages/register/PostRegisterStep1';
import PostRegisterStep2 from 'containers/pages/register/PostRegisterStep2';
import PostRegisterStep3 from 'containers/pages/register/PostRegisterStep3';
import { CurrentUserStore } from 'stores/auth/CurrentUserStore';

import { hideSlideOutModal } from './LoggedInHeader/SlideOutMenu';
import ContactUs from './pages/ContactUs';
import GroupDetails from './pages/Dashboard/Groups/GroupDetails';
import FAQs from './pages/FAQs';
import ForgotPasswordConfirm from './pages/ForgotPasswordConfirm';
import ForgotPasswordConfirmSuccess from './pages/ForgotPasswordConfirmSuccess';
import ForgotPasswordRequest from './pages/ForgotPasswordRequest';
import ForgotPasswordRequestSuccess from './pages/ForgotPasswordRequestSuccess';
import Home from './pages/Home';
import Login from './pages/Login';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Register from './pages/Register';
import Terms from './pages/Terms';

export const history = createBrowserHistory();

const Routes: React.FC = () => {
  const token = CurrentUserStore.useState((s) => s.token);

  history.listen((location) => {
    window.ga?.('set', 'page', location.pathname + location.search);
    window.ga?.('send', 'pageview');
    hideSlideOutModal();
  });

  return (
    <Router history={history}>
      <Switch>
        {/* login */}
        <Route path='/login'>{token ? <Redirect to='/' /> : <Login />}</Route>

        {/* forgot password */}
        <Route path='/forgot/confirm/:code'>
          {token ? <Redirect to='/' /> : <ForgotPasswordConfirm />}
        </Route>
        <Route path='/forgot/success'>
          {token ? <Redirect to='/' /> : <ForgotPasswordConfirmSuccess />}
        </Route>
        <Route path='/forgot/request/:email'>
          {token ? <Redirect to='/' /> : <ForgotPasswordRequestSuccess />}
        </Route>
        <Route path='/forgot/request'>
          {token ? <Redirect to='/' /> : <ForgotPasswordRequest />}
        </Route>
        <Route path='/forgot'>
          <Redirect to='/forgot/request' />
        </Route>

        {/* registration */}
        <Route path='/register/1'>{token ? <PostRegisterStep1 /> : <Redirect to='/' />}</Route>
        <Route path='/register/2'>{token ? <PostRegisterStep2 /> : <Redirect to='/' />}</Route>
        <Route path='/register/3'>{token ? <PostRegisterStep3 /> : <Redirect to='/' />}</Route>
        <Route path='/register'>{token ? <Redirect to='/' /> : <Register />}</Route>

        {/* dashboard */}
        <Route path='/dashboard'>
          <DashboardRoutes />
        </Route>

        {/* footer pages */}
        <Route path='/faqs'>
          <FAQs />
        </Route>
        <Route path='/privacy'>
          <PrivacyPolicy />
        </Route>
        <Route path='/terms'>
          <Terms />
        </Route>
        <Route path='/contact'>
          <ContactUs />
        </Route>

        {/* root */}
        <Route path='/'>{token ? <Redirect to='/dashboard' /> : <Home />}</Route>
      </Switch>
    </Router>
  );
};

const DashboardRoutes: React.FC = () => {
  const token = CurrentUserStore.useState((s) => s.token);
  const { path } = useRouteMatch();

  return token ? (
    <Switch>
      <Route path={path + '/browse/movies/all/:letter'}>
        <BrowseAllMovies />
      </Route>
      <Route path={path + '/browse/movies/all'}>
        <Redirect to={path + '/browse/movies/all/a'} />
      </Route>
      <Route path={path + '/browse/movies/new'}>
        <BrowseNewReleaseMovies />
      </Route>
      <Route path={path + '/browse/tv/all/:letter'}>
        <BrowseAllShows />
      </Route>
      <Route path={path + '/browse/tv/all'}>
        <Redirect to={path + '/browse/tv/all/a'} />
      </Route>
      <Route path={path + '/browse/tv/new'}>
        <BrowseNewReleaseShows />
      </Route>
      <Route path={path + '/alerts'}>
        <Alerts />
      </Route>
      <Route path={path + '/movies/details/:movieId'}>
        <MoviePDP />
      </Route>
      <Route path={path + '/movies/watched/:column/:direction'}>
        <Movies />
      </Route>
      <Route path={path + '/movies'}>
        <Redirect to={path + '/movies/watched/date/desc'} />
      </Route>
      <Route path={path + '/shows/details/:showId'}>
        <ShowPDP />
      </Route>
      <Route path={path + '/shows/watched/:column/:direction'}>
        <Shows />
      </Route>
      <Route path={path + '/shows'}>
        <Redirect to={path + '/shows/watched/date/desc'} />
      </Route>
      <Route path={path + '/groups/create'}>
        <CreateGroup />
      </Route>
      <Route path={path + '/groups/edit/:groupId'}>
        <EditGroup />
      </Route>
      <Route path={path + '/groups/details/:groupId'}>
        <GroupDetails />
      </Route>
      <Route path={path + '/groups'}>
        <Groups />
      </Route>
      <Route path={path + '/review/tv/:id/:season'}>
        <ShowReview />
      </Route>
      <Route path={path + '/review/movie/:id'}>
        <MovieReview />
      </Route>
      <Route path={path + '/profile'}>
        <Profile />
      </Route>
      <Route path={path + '/user/:userId'}>
        <UserProfile />
      </Route>
      <Route path={path + '/friends/add'}>
        <AddAFriend />
      </Route>
      <Route path={path + '/friends'}>
        <Friends />
      </Route>
      <Route path={path + '/search/:type/:search'}>
        <Search />
      </Route>
      <Route path={path}>
        <Dashboard />
      </Route>
    </Switch>
  ) : (
    <Redirect to='/' />
  );
};

export default class AppRouter extends BrowserRouter {
  render(): React.ReactElement {
    return <Routes />;
  }
}
