import styled from 'styled-components';
import Link from '../../../components/Link';
import variables from '../../../components/styled/variables';

const SCCTAButton = styled(Link)`
  line-height: 60px;
  background: ${variables.colors.background.red};
  color: white;
  padding: 0 80px;
  display: inline-block;
  border-radius: 5px;
  margin-top: 20px;
`;

export default SCCTAButton;
