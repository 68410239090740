import { rgba } from 'polished';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import ProfileAPI, { IUserData } from 'api/ProfileAPI';
import { ProfileReview as Review } from 'components/Review';
import SCAvatar from 'components/styled/SCAvatar';
import SCButton from 'components/styled/SCButton';
import SCShowMoreButtonWrapper from 'components/styled/SCShowMoreButtonWrapper';
import variables from 'components/styled/variables';
import GenericMediaCarousel from 'containers/GenericMediaCarousel';
import LoggedInPageWrapper from 'containers/LoggedInPageWrapper';
import getImageUrlString from 'helpers/getImageUrlString';
import DefaultProfileCover from 'img/DefaultProfileCover.png';
import { createGenericAsyncAction } from 'stores';
import { UserRecommendedMovieStore } from 'stores/movies/MovieStore';
import { UsersReviewsStore } from 'stores/ReviewStore';
import { UserRecommendedShowStore } from 'stores/shows/ShowStore';
import { UserIsWatchingStore } from 'stores/WatchListStore';

const SCHero = styled.div<{ imageUrl: string | null }>`
  background-color: ${variables.colors.background.red};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${(props) => getImageUrlString(props.imageUrl)};
  height: 485px;
  margin: 0 -60px 40px;
  max-width: 1440px;
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    background-color: ${rgba('black', 0.5)};
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  @media (max-width: 1000px) {
    margin: 0 -20px 40px;
  }
`;

const SCUser = styled.div`
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: 1px 1px 5px ${rgba('black', 0.5)};
  z-index: 100;
`;

const SCUserName = styled.div`
  font-family: Gala, sans-serif;
  text-transform: uppercase;
  font-size: 50px;
  left: 50%;
  margin: 10px 0 0;
  text-align: center;
`;

const SCFullName = styled.div`
  color: rgba(white, 0.5);
  font-size: 16px;
`;

const fetchUserAction = createGenericAsyncAction<{ userId: number }, IUserData>(({ userId }) =>
  ProfileAPI.fetchUser(userId),
);

const SCLabel = styled.h1`
  font-size: 20px;
  font-weight: 500;
  margin: 60px 0 20px;

  &:first-child {
    margin-top: 0;
  }
`;

const UserProfile: React.FC = () => {
  const { userId } = useParams();
  const [isUserLoaded, userResponse] = fetchUserAction.useBeckon({ userId });
  const user = userResponse?.payload;
  const userWatchlist = UserIsWatchingStore.usePaginatedData({ userId });
  const recommendedShows = UserRecommendedShowStore.usePaginatedData({ userId });
  const recommendedMovies = UserRecommendedMovieStore.usePaginatedData({ userId });
  const reviews = UsersReviewsStore.usePaginatedData({ userId });

  return (
    <LoggedInPageWrapper bg='gray' noTopPadding isLoading={!isUserLoaded}>
      {user != null && (
        <>
          <SCHero imageUrl={user.cover ?? DefaultProfileCover}>
            <SCUser>
              <SCAvatar variant='profile' imageUrl={user.avatar} />
              <SCUserName>@{user.username}</SCUserName>
              <SCFullName>
                {user.first_name} {user.last_name}
              </SCFullName>
            </SCUser>
          </SCHero>
          {userWatchlist.items.length > 0 && (
            <>
              <SCLabel>Currently Watching</SCLabel>
              <GenericMediaCarousel media={userWatchlist.items} />
            </>
          )}
          {recommendedShows.items.length > 0 && (
            <>
              <SCLabel>Shows Recommended</SCLabel>
              <GenericMediaCarousel media={recommendedShows.items} />
            </>
          )}
          {recommendedMovies.items.length > 0 && (
            <>
              <SCLabel>Movies Recommended</SCLabel>
              <GenericMediaCarousel media={recommendedMovies.items} />
            </>
          )}
          {reviews.items.length > 0 && (
            <>
              <SCLabel>Reviews</SCLabel>
              {reviews.items.map((review) => (
                <Review
                  key={review.id}
                  review={review}
                  user={user}
                  show={review.tv_show}
                  movie={review.movie}
                />
              ))}
              {reviews.hasMorePages && (
                <SCShowMoreButtonWrapper>
                  <SCButton
                    transparent={true}
                    disabled={reviews.isUpdating}
                    onClick={reviews.loadNextPage}>
                    SEE MORE
                  </SCButton>
                </SCShowMoreButtonWrapper>
              )}
            </>
          )}
        </>
      )}
    </LoggedInPageWrapper>
  );
};

export default UserProfile;
