import cn from 'classnames';
import React from 'react';

import css from './Carousel.module.scss';

interface ICarouselItemProps {
  className?: string;
  children?: React.ReactNode;
}

export const CarouselItem: React.FC<ICarouselItemProps> = ({ className, children }) => {
  return <div className={cn(className, css.CarouselItem)}>{children}</div>;
};

interface IProps {
  className?: string;
  children: React.ReactElement<typeof CarouselItem>[];
  fitContentToPage?: boolean;
}

const Carousel: React.FC<IProps> = ({ className, children, fitContentToPage = false }) => {
  const carouselRef = React.useRef<HTMLDivElement>(null);
  const innerRef = React.useRef<HTMLDivElement>(null);

  const [page, setPage] = React.useState(0);
  const [lastPage, setLastPage] = React.useState(0);
  const [pageWidth, setPageWidth] = React.useState(0);
  const [buttonTop, setButtonTop] = React.useState(0);

  const prevPage = () => setPage((page) => Math.max(0, page - 1));
  const nextPage = () => setPage((page) => Math.min(lastPage, page + 1));

  const calculateDomSizes = React.useCallback(() => {
    if (carouselRef.current == null || innerRef.current == null) return;

    const items = Array.from(
      carouselRef.current.getElementsByClassName(css.CarouselItem),
    ) as HTMLDivElement[];

    items.forEach((item) => {
      item.style.removeProperty('margin-left');
      item.style.removeProperty('margin-right');
    });

    const numItems = items.length;
    if (numItems < 2) return;

    const itemWidth = items[0].offsetWidth;
    const itemMargin = items[1].offsetLeft - items[0].offsetLeft - items[0].offsetWidth;
    const pageWidth = innerRef.current.clientWidth;
    const itemsPerPage = Math.max(
      1,
      Math.floor((pageWidth + itemMargin) / (itemWidth + itemMargin)),
    );
    const lastPage = Math.max(numItems / itemsPerPage - 1, 0);
    setLastPage(lastPage);
    setPage((page) => Math.min(page, lastPage));

    const element = (items[0].querySelector('.carousel-item') as HTMLDivElement) ?? items[0];
    const itemHeight = element.offsetHeight;
    setButtonTop(itemHeight / 2);

    if (fitContentToPage && itemsPerPage > 1) {
      const stretchedMargin = (pageWidth - itemsPerPage * itemWidth) / (itemsPerPage - 1);
      setPageWidth(itemsPerPage * (itemWidth + itemMargin));
      items.forEach((item) => {
        item.style.setProperty('margin-left', '0');
        item.style.setProperty('margin-right', `${stretchedMargin}px`);
      });
      setPageWidth(itemsPerPage * (itemWidth + stretchedMargin));
    } else {
      setPageWidth(itemsPerPage * (itemWidth + itemMargin));
    }
  }, [fitContentToPage]);

  React.useLayoutEffect(calculateDomSizes, [calculateDomSizes]);

  React.useLayoutEffect(() => {
    const handleResize = () => calculateDomSizes();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [calculateDomSizes]);

  return (
    <div className={cn(className, css.Carousel)} ref={carouselRef}>
      <div
        className={css.CarouselInner}
        ref={innerRef}
        style={{ transform: `translateX(${-page * pageWidth}px)` }}>
        {children}
      </div>
      {page > 0 && (
        <button
          className={css.PreviousButton}
          type='button'
          onClick={prevPage}
          style={{ top: buttonTop }}>
          <i className='fas fa-chevron-left' />
        </button>
      )}
      {page < lastPage && (
        <button
          className={css.NextButton}
          type='button'
          onClick={nextPage}
          style={{ top: buttonTop }}>
          <i className='fas fa-chevron-right' />
        </button>
      )}
    </div>
  );
};

export default Carousel;
