import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { IMovie, ITVShow, IWatchListItem, TWatchedStatus } from 'api/CatalogAPI';
import { IUserData } from 'api/ProfileAPI';
import Carousel, { CarouselItem } from 'components/Carousel';
import Link from 'components/Link';
import variables from 'components/styled/variables';
import getFullNameDisplay from 'helpers/getFullNameDisplay';
import getImageUrlString from 'helpers/getImageUrlString';
import { setMovieWatchedStatusAction } from 'stores/movies/MovieStore';
import { setShowWatchedStatusAction } from 'stores/shows/ShowStore';

import RecommendedByModal from './modals/RecommendedByModal';

const MOBILE_SCALE = 0.725;

const SCCarousel = styled(Carousel)`
  margin: 0 -60px;
  padding: 0 60px;

  @media (max-width: 1000px) {
    margin: 0 -20px;
    padding: 0 20px;
  }
`;

const SCCarouselItem = styled(CarouselItem)`
  width: 190px;
  margin-right: 20px;

  @media (max-width: 1000px) {
    margin-right: 0;
    padding-right: 20px;
    width: ${MOBILE_SCALE * 190}px;
  }
`;

const SCWrapper = styled.div`
  color: ${(props) => (props.theme.dark ? 'white' : 'black')};

  &::before,
  &::after {
    clear: both;
    content: '';
    display: table;
  }
`;

const SCPoster = styled.a<{ imageUrl?: string | null }>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${rgba('white', 0.125)};
  background-image: ${(props) => props.imageUrl != null && getImageUrlString(props.imageUrl)};
  border: 0;
  cursor: pointer;
  display: block;
  height: 280px;
  outline: none;
  position: relative;
  width: 190px;

  &::after {
    display: ${(props) => props.imageUrl != null && 'none'};
    color: ${rgba('white', 0.5)};
    content: '\f03d';
    font-family: ${variables.fonts.icon};
    font-size: 30px;
    font-weight: 900;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 1000px) {
    width: ${MOBILE_SCALE * 190}px;
    height: ${MOBILE_SCALE * 280}px;
  }
`;

const SCAddButton = styled.button<{ size: number; hasStatus: boolean; isShowingOptions: boolean }>`
  background-color: ${(props) =>
    props.hasStatus && !props.isShowingOptions ? 'green' : rgba('black', 0.35)};
  border-radius: 50%;
  color: white;
  cursor: pointer;
  font-size: 16px;
  height: ${(props) => props.size}px;
  outline: none;
  position: absolute;
  right: 10px;
  top: 10px;
  width: ${(props) => props.size}px;
  z-index: 100;
  border: 0;
  box-shadow: none;
  padding: 0;

  > .fas {
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.7);
    transform-origin: 50% 50%;
  }
`;

const SCOptionsWrapper = styled.div`
  background: ${rgba('white', 0.85)};
  border-radius: 10px;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  padding: 40px 20px;
  width: 100%;

  > h1 {
    color: ${variables.colors.text.link};
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 20px;
  }

  @media (max-width: 1000px) {
    padding: 40px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

const SCOption = styled.button<{ selected: boolean }>`
  background: transparent;
  border: 0;
  color: ${variables.colors.text.link};
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
  margin: 0 0 20px;
  outline: none;
  padding: 0;

  @media (max-width: 1000px) {
    text-align: left;
    margin: 0;
  }
`;

const SCMediaTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin: 10px 0 0;
`;

const SCRecommendedBy = styled.div`
  opacity: 0.5;
`;

const SCLink = styled(Link)`
  color: white;
  text-decoration: underline;
`;

const RecommendedBy: React.FC<{ by?: IUserData[]; data: ITVShow | IMovie }> = ({ by, data }) => {
  const [isModalShowing, setIsModalShowing] = React.useState(false);

  const onClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    setIsModalShowing(true);
  };

  const onRequestClose = () => {
    setIsModalShowing(false);
  };

  if (by == null || by.length < 1) return null;

  if (by.length === 1)
    return (
      <SCRecommendedBy>
        by <SCLink href={`/dashboard/user/${by[0].id}`}>{getFullNameDisplay(by[0])}</SCLink>
      </SCRecommendedBy>
    );

  return (
    <>
      <SCRecommendedBy>
        by{' '}
        <SCLink href='#' onClick={onClick}>
          {by.length} friends
        </SCLink>
      </SCRecommendedBy>
      {isModalShowing && <RecommendedByModal by={by} data={data} onRequestClose={onRequestClose} />}
    </>
  );
};

const MediaItem: React.FC<{ data: IMovie | ITVShow }> = ({ data }) => {
  const [showingOptions, setShowingOptions] = React.useState(false);
  const [hasStatusChanged, setHasStatusChanged] = React.useState(false);

  const onOptionClick = (media: ITVShow | IMovie, status: TWatchedStatus): void => {
    if (media.model === 'movie') setMovieWatchedStatusAction.run({ movieId: media.id, status });
    else setShowWatchedStatusAction.run({ showId: media.id, status });
    setShowingOptions(false);
    setHasStatusChanged(true);
  };

  const options: { value: TWatchedStatus; display: string }[] = [
    { value: 'want_to_watch', display: 'Want to Watch' },
    { value: 'watched', display: 'Watched' },
    { value: 'watching', display: 'Watching' },
    { value: 'not_for_me', display: 'Not for Me' },
  ];

  const getTitle = () => (data.model === 'movie' ? data.title : data.name);

  const onClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowingOptions((v) => !v);
  };

  const getHref = (): string =>
    `/dashboard/${data.model === 'movie' ? 'movies' : 'shows'}/details/${data.id}`;

  return (
    <SCCarouselItem>
      <SCPoster imageUrl={data.images.poster.w342} href={getHref()}>
        {showingOptions && (
          <SCOptionsWrapper
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}>
            {options.map((option) => (
              <SCOption
                selected={option.value === data.watched?.status}
                key={option.value}
                onClick={() => onOptionClick(data, option.value)}>
                {option.display}
              </SCOption>
            ))}
          </SCOptionsWrapper>
        )}
        <SCAddButton
          hasStatus={hasStatusChanged}
          isShowingOptions={showingOptions}
          size={20}
          onClick={onClick}>
          {showingOptions ? (
            <span className='fas fa-times' />
          ) : hasStatusChanged ? (
            <span className='fas fa-check' />
          ) : (
            <span className='fas fa-plus' />
          )}
        </SCAddButton>
      </SCPoster>
      <SCMediaTitle>{getTitle()}</SCMediaTitle>
      <RecommendedBy by={data.friend_recommendations} data={data} />
    </SCCarouselItem>
  );
};

type TMediaItem = IWatchListItem | IMovie | ITVShow;

interface IProps {
  media: TMediaItem[];
}

const isWatchListItem = (item: IWatchListItem | ITVShow | IMovie): item is IWatchListItem =>
  (item as IWatchListItem).media != null;

const getFormattedMediaList = (media: TMediaItem[]): (ITVShow | IMovie)[] =>
  media.map((item) => {
    // If it's ITVShow or IMovie, just return it
    if (!isWatchListItem(item)) return item;

    // If it's ITVShow, return item.media since it includes watched status already
    if (item.media.model === 'tv_show') return item.media;

    // Otherwise, it must be IMovie, in which case we will attach the watched status
    return {
      ...item.media,
      watched: {
        status: item.status,
        updated_at: item.updated_at,
      },
    };
  });

const GenericMediaCarousel: React.FC<IProps> = ({ media }) => {
  const formattedMedia = getFormattedMediaList(media);
  return (
    <SCWrapper>
      <SCCarousel>
        {formattedMedia.map((mediaItem) => (
          <MediaItem key={mediaItem.id} data={mediaItem} />
        ))}
      </SCCarousel>
    </SCWrapper>
  );
};

export default GenericMediaCarousel;
