import { rgba } from 'polished';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import SCAvatar from 'components/styled/SCAvatar';
import SCButton from 'components/styled/SCButton';
import SCShowMoreButtonWrapper from 'components/styled/SCShowMoreButtonWrapper';
import variables from 'components/styled/variables';
import GenericModal from 'containers/modals/GenericModal';
import { fetchGroupAction, GroupDetailsStore } from 'stores/groups/GroupDetailsStore';
import { GroupMembersStore } from 'stores/groups/GroupMembersStore';
import SCHideForMobile from 'components/styled/SCHideForMobile';
import SCShowForMobile from 'components/styled/SCShowForMobile';

const SCWrapper = styled.div`
  padding: 60px;

  @media (max-width: 1000px) {
    padding: 60px 20px;
  }
`;

const SCGroupTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 175%;
`;

const SCModalHeading = styled.div`
  font-family: ${variables.fonts.heading};
  text-transform: uppercase;
  font-size: 60px;
  font-weight: 500;
  margin: 0 0 60px;
  line-height: 100%;
`;

const SCMemberRow = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid black;
  cursor: pointer;

  & + & {
    margin-top: 10px;
  }
`;

const SCBaseCell = styled.div`
  padding: 20px;

  @media (max-width: 1000px) {
    padding: 10px;
  }
`;

const SCAvatarCell = styled(SCBaseCell)`
  border-right: 1px solid ${rgba('black', 0.15)};
`;

const SCUsernameCell = styled(SCBaseCell)`
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-family: ${variables.fonts.heading};
    text-transform: uppercase;
    font-size: 30px;
    margin: 0;
    line-height: 100%;
    font-weight: 500;
  }

  h2 {
    font-size: 16px;
    color: ${rgba('black', 0.5)};
    margin: 10px 0 0;
    line-height: 100%;
    font-weight: normal;
  }

  @media (max-width: 1000px) {
    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 14px;
    }
  }
`;

const SCScrollableArea = styled.div`
  max-height: 438px;
  overflow: auto;
  margin: 0 -60px;
  padding: 0 60px;
`;

const MembersModal: React.FC<{ groupId: number; onRequestClose: () => void }> = ({
  groupId,
  onRequestClose,
}) => {
  const history = useHistory();
  const [isGroupLoaded] = fetchGroupAction.useBeckon({ groupId });
  const group = GroupDetailsStore.useState((s) => s[groupId], [groupId]);
  const members = GroupMembersStore.usePaginatedData({ groupId });

  const isLoading = !isGroupLoaded || members.isLoading;

  const onClickUser = (userId: number) => history.push(`/dashboard/user/${userId}`);

  return (
    <GenericModal isLoading={isLoading} onRequestClose={onRequestClose}>
      {group != null && (
        <SCWrapper>
          <SCGroupTitle>{group.title}</SCGroupTitle>
          <SCModalHeading>Members</SCModalHeading>
          <SCScrollableArea>
            {members.items.map((member) => (
              <SCMemberRow key={member.user.id} onClick={() => onClickUser(member.user.id)}>
                <SCAvatarCell>
                  <SCHideForMobile>
                    <SCAvatar variant='comment' imageUrl={member.user.avatar} />
                  </SCHideForMobile>
                  <SCShowForMobile>
                    <SCAvatar variant='header' imageUrl={member.user.avatar} />
                  </SCShowForMobile>
                </SCAvatarCell>
                <SCUsernameCell>
                  <h1>@{member.user.username}</h1>
                  <h2>
                    {member.user.first_name} {member.user.last_name}
                  </h2>
                </SCUsernameCell>
              </SCMemberRow>
            ))}
            {members.hasMorePages && (
              <SCShowMoreButtonWrapper>
                <SCButton disabled={members.isUpdating} onClick={members.loadNextPage}>
                  Show More
                </SCButton>
              </SCShowMoreButtonWrapper>
            )}
          </SCScrollableArea>
        </SCWrapper>
      )}
    </GenericModal>
  );
};

export default MembersModal;
