import React from 'react';
import { Link } from 'react-router-dom';

import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validatePassword,
  validateUsername,
} from 'helpers/validators';

import ContentBox from './ContentBox';
import FormButton from './FormButton';
import Input from './Input';
import css from './RegisterForm.module.scss';

interface IProps {
  error?: string;
  isLoading: boolean;
  onSubmit(
    email: string,
    username: string,
    password: string,
    firstName: string,
    lastName: string,
  ): void;
}

const RegisterForm: React.FC<IProps> = ({ onSubmit, isLoading, error }) => {
  const [email, setEmail] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');

  const onSubmitWrapper = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(email, username, password, firstName, lastName);
  };

  const emailError = validateEmail(email);
  const usernameError = validateUsername(username);
  const passwordError = validatePassword(password);
  const firstNameError = validateFirstName(firstName);
  const lastNameError = validateLastName(lastName);

  const isDisabled = Boolean(
    emailError || usernameError || passwordError || firstNameError || lastNameError || isLoading,
  );

  return (
    <form onSubmit={onSubmitWrapper}>
      <ContentBox title='Create an Account'>
        {error && <div className={css.ErrorMessage}>{error}</div>}
        <Input
          type='email'
          placeholder='Email'
          value={email}
          onChange={setEmail}
          error={emailError}
        />
        <Input
          type='text'
          placeholder='First Name'
          value={firstName}
          onChange={setFirstName}
          error={firstNameError}
        />
        <Input
          type='text'
          placeholder='Last Name'
          value={lastName}
          onChange={setLastName}
          error={lastNameError}
        />
        <Input
          type='text'
          placeholder='Username'
          value={username}
          onChange={setUsername}
          error={usernameError}
        />
        <Input
          type='password'
          placeholder='Password'
          value={password}
          onChange={setPassword}
          error={passwordError}
        />
        <FormButton type='submit' isDisabled={isDisabled}>
          Submit
        </FormButton>
      </ContentBox>
      <ContentBox>
        Have an account? <Link to='/login'>Log In</Link>
      </ContentBox>
    </form>
  );
};

export default RegisterForm;
