import React from 'react';

import MediaAPI from 'api/MediaAPI';
import { uploadImage } from 'stores/auth/CurrentUserStore';

import css from './UploadMedia.module.scss';

interface ISuccessResponse {
  format: 'image' | 'video'
  url?: string;
  mediaId?: number;
}
interface IProps {
  type: 'avatar' | 'cover' | 'attachment';
  supportVideo?: boolean;
  children: (triggerUpload: () => void, resetFileInput: () => void, uploading: boolean) => React.ReactNode;
  onError?: (error: string) => void;
  onSuccess?: (obj: ISuccessResponse) => void;
}

const UploadMedia: React.FC<IProps> = ({ supportVideo = false, type, children, onError, onSuccess }) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [count, setCount] = React.useState(0);
  const [uploading, setUploading] = React.useState(false);

  const triggerUpload = () => inputRef.current?.click();
  // Reset the file input so we can upload the same image multiple times, and still fire a change event.
  const resetFileInput = () => setCount(count + 1);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target.files?.[0];
    if (file == null) return;

    setUploading(true);

    if (supportVideo && file?.type?.startsWith('video')) {
      MediaAPI.createUploadToken('video').then((uploadTokenResponse) => {
        if (uploadTokenResponse.success) {
          const response = uploadTokenResponse.response;
          try {
            const formData = new FormData();
            formData.append('Content-Type', file.type);
            Object.entries(response.fields).forEach(function (field) {
              formData.append(field[0], field[1]);
            });
            formData.append('file', file);
            fetch(response.url, {
              'method': 'POST',
              'body': formData,
            })
              .then((res) => {

                if (res.ok) {
                  return res.text();
                }

                // TODO throw error so control flow is simpler.
                throw new Error();
              })
              .then(() => MediaAPI.pollForUploadStatus(response.id))
              .then((res) => {
                if (res.success) {
                  onSuccess?.({
                    mediaId: res.response.media_id,
                    url: res.response.preview,
                    format: 'video'
                  });
                }
              })
              .catch(err => onError?.(err?.message || 'Something went wrong.'))
              .finally(() => setUploading(false));
          } catch (e) {
            setUploading(false);
            console.error(e);
            throw e;
          }
        } else {
          onError?.(uploadTokenResponse?.error?.message ?? 'Something went wrong.');
        }
      });
    } else {
      const reader = new FileReader();
      reader.onload = async () => {
        const data = btoa(reader.result as string);
        const response = await uploadImage.run({
          type,
          image: 'data:image/jpeg;base64,' + data,
        });
        if (response.error) {
          onError?.(response.message);
        } else {
          onSuccess?.(response.payload);
        }
        setUploading(false);
      };
      reader.readAsBinaryString(file);
    }
  };

  return (
    <>
      {children(triggerUpload, resetFileInput, uploading)}
      <input
        key={count}
        type='file'
        accept={`image/*${supportVideo ? ',video/*' : ''}`}
        className={css.HiddenFileInput}
        ref={inputRef}
        onChange={onChange}
      />
    </>
  );
};

export default UploadMedia;
