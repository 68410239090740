import useEffectOnce from 'hooks/useEffectOnce';
import { rgba } from 'polished';
import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ITVShowSeason } from 'api/CatalogAPI';
import Link from 'components/Link';
import Networks from 'components/Networks';
import Rating from 'components/Rating';
import Review from 'components/Review';
import Spinner from 'components/Spinner';
import SCButton from 'components/styled/SCButton';
import SCHideForMobile from 'components/styled/SCHideForMobile';
import SCPageTitle from 'components/styled/SCPageTitle';
import SCShowForMobile from 'components/styled/SCShowForMobile';
import variables from 'components/styled/variables';
import LoggedInPageWrapper from 'containers/LoggedInPageWrapper';
import { displayEditShowModal } from 'containers/modals/EditShowModal';
import WatchVideoModal from 'containers/modals/WatchVideoModal';
import WatchNowButtons from 'containers/pages/Dashboard/WatchNowButtons';
import { CurrentUserStore } from 'stores/auth/CurrentUserStore';
import { ShowReviewsStore, UserReviewOptimizedStore, UsersReviewsStore } from 'stores/ReviewStore';
import {
  clearShowWantToWatchStatusAction,
  clearShowWatchedStatusAction,
  fetchShowAction,
  setShowRatingAction,
  setShowRecommendationAction,
  setShowWatchedStatusAction,
  ShowStore,
} from 'stores/shows/ShowStore';

const SCMovieTitle = styled(SCPageTitle)`
  font-size: 60px;
  margin: 0;

  @media (max-width: 1000px) {
    font-size: 40px;
  }
`;

const SCRatingWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 20px 0;

  > * {
    flex-grow: 0;
    margin: 0 20px 0 0 !important;
  }
`;

const SCParagraph = styled.p`
  font-size: 16px;
  line-height: 175%;
  margin: 0 0 40px;

  @media (max-width: 1000px) {
    font-size: 14px;
  }
`;

const SCDetailRow = styled.div`
  line-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 700px;

  & + & {
    border-top: 1px solid #c4c4c4;
  }

  @media (max-width: 1000px) {
    font-size: 14px;
  }
`;

const SCCircle = styled.div.attrs({ size: 30 }) <{ size: number; checked: boolean }>`
  cursor: pointer;
  border: 1px solid white;
  border-radius: 50%;
  display: block;
  flex-shrink: 0;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  background-color: ${(props) => props.checked && `${variables.colors.text.link} !important`};
  position: relative;
  border: 1px solid ${variables.colors.text.link};

  &::after {
    color: white;
    content: '\f00c';
    display: ${(props) => (props.checked ? 'block' : 'none')};
    font-family: ${variables.fonts.icon};
    left: 50%;
    position: absolute;
    font-weight: 900;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const SCWrapper = styled.div`
  background-color: ${rgba('white', 0.9)};
  padding: 60px;
  min-height: calc(100% - 120px);

  @media (max-width: 1000px) {
    padding: 20px;
    margin: 0 -20px;
  }
`;

const SCEpisodeButton = styled(SCButton)`
  font-size: 16px;
  text-decoration: underline;

  @media (max-width: 1000px) {
    font-size: 14px;
  }
`;

const SCReviewLink = styled(Link)`
  text-decoration: underline;
`;

const SCSelect = styled.select`
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  color: #666;
  height: 36px;
  margin-bottom: 31px;
  padding: 0 40px 0 20px;
  width: 320px;

  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-color: ${variables.colors.background.input.dark};
  background-position: calc(100% - 20px) 50%, calc(100% - 15px) 50%;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const getYear = (dateString?: string): string => {
  if (dateString == null) return '';
  return new Date(dateString).getFullYear().toString();
};

const getYearString = (seasons: ITVShowSeason[]) => {
  const startDate = getYear(seasons[0].air_date);
  if (seasons.length === 1) return getYear(startDate);

  const endDate = getYear(seasons[seasons.length - 1].air_date);
  return `${startDate} - ${endDate}`;
};

const capitalizeGenre = (genre: string): string => {
  const words = genre.split(/ /g);
  return words.map((w) => w.charAt(0).toUpperCase() + w.substr(1)).join(' ');
};

const ShowPDP: React.FC = () => {
  const { showId } = useParams();
  const [isShowLoaded] = fetchShowAction.useBeckon({ showId });
  const showMatch = ShowStore.useState((s) => s[showId], showId);
  const { show, seasons } = showMatch ?? {};
  const [season, setSeason] = React.useState(1); // TODO
  const reviews = ShowReviewsStore.usePaginatedData({ showId, season });

  const userState = CurrentUserStore.getRawState();
  const userReviews = UsersReviewsStore.usePaginatedData({ userId: userState?.user?.id ?? 0, size: 1000 });
  const userOptimizedReviews = UserReviewOptimizedStore.useState();
  const hasReviewed = userOptimizedReviews?.show?.[showId]?.[season];

  useEffectOnce(() => {
    if (reviews.isLoaded) ShowReviewsStore.fetchAction.clearAllCache();
    if (userReviews.isLoaded) UsersReviewsStore.fetchAction.clearAllCache();
  });

  if (isShowLoaded && show == null) return <Redirect to='/' />;

  const toggleWantToWatch = () => {
    const value = show?.watched?.status !== 'want_to_watch';

    if (value) setShowWatchedStatusAction.run({ showId, status: 'want_to_watch' });
    else clearShowWantToWatchStatusAction.run({ showId });
  };

  const toggleWatched = () => {
    const value = show?.watched?.status !== 'watched';

    if (value) setShowWatchedStatusAction.run({ showId, status: 'watched' });
    else clearShowWatchedStatusAction.run({ showId });
  };

  const toggleRecommended = async () => {
    setShowRecommendationAction.run({ showId, recommended: show?.recommendation == null });
  };

  const setShowRating = (showId: number, rating: number): void => {
    setShowRatingAction.run({ showId, rating });
  };

  return (
    <LoggedInPageWrapper
      bg='white'
      isLoading={!isShowLoaded}
      backgroundImage={show?.images?.backdrop?.original}>
      {show && seasons ? (
        <SCWrapper>
          <SCMovieTitle>{show.name}</SCMovieTitle>
          <Networks networks={show.networks} />
          <SCRatingWrapper>
            <Rating value={show.vote_average} />
            <div>{(show.vote_average / 2).toFixed(1)} avg rating</div>
            <SCHideForMobile>
              <WatchNowButtons media={show} />
            </SCHideForMobile>
          </SCRatingWrapper>
          <SCRatingWrapper>
            <Rating
              value={show.rating?.value}
              onChange={(rating) => setShowRating(showId, rating)}
            />
            <div>your rating</div>
          </SCRatingWrapper>
          <SCShowForMobile>
            <WatchNowButtons media={show} />
          </SCShowForMobile>
          <SCParagraph>{show.overview}</SCParagraph>
          <SCDetailRow>
            {getYearString(seasons)} &middot; {show.genres?.map(capitalizeGenre).join(', ')}{' '}
            &middot; {seasons.length} {seasons.length > 1 ? 'seasons' : 'season'}
          </SCDetailRow>
          <SCDetailRow>
            <div>Currently Watching</div>
            <SCEpisodeButton link onClick={() => displayEditShowModal(showId)}>
              What episode are you on?
            </SCEpisodeButton>
          </SCDetailRow>
          <SCDetailRow>
            <div>Watched</div>
            <SCCircle
              checked={show.watched?.status === 'watched'}
              onClick={() => toggleWatched()}
            />
          </SCDetailRow>
          <SCDetailRow>
            <div>Want to Watch</div>
            <SCCircle
              checked={show.watched?.status === 'want_to_watch'}
              onClick={() => toggleWantToWatch()}
            />
          </SCDetailRow>
          <SCDetailRow>
            <div>Recommend It</div>
            <SCCircle checked={show.recommendation != null} onClick={toggleRecommended} />
          </SCDetailRow>
          <SCDetailRow>
            <div>User Reviews</div>
            {!hasReviewed &&
              <SCReviewLink href={`/dashboard/review/tv/${show.id}/${season}`}>
                Write a review
              </SCReviewLink>
            }
          </SCDetailRow>
          <div>
            <SCSelect value={season} onChange={(e) => setSeason(Number(e.target.value))}>
              {seasons.map((season) => (
                <option key={season.key} value={season.number}>
                  {season.name}
                </option>
              ))}
            </SCSelect>
          </div>
          <div>
            {reviews?.items?.map((review) => (
              <Review key={review.id} review={review} show={show} user={review.user}></Review>
            ))}
          </div>
        </SCWrapper>
      ) : (
        <Spinner />
      )}
      <WatchVideoModal />
    </LoggedInPageWrapper>
  );
};

export default ShowPDP;
