import moment from 'moment';

import AlertsAPI from 'api/AlertsAPI';
import { TFollowRecommendation } from 'api/FollowAPI';
import { IFriend } from 'api/FriendsAPI';
import { IGroup, IGroupMember, TGroupRequest } from 'api/GroupsAPI';
import { createPaginationStore } from 'stores/index';

export const FriendRequestStore = createPaginationStore<undefined, IFriend>((action, page) =>
  AlertsAPI.listFriendRequests(page),
);

export type TGroupInvite = IGroup & { creator: IGroupMember };
export const GroupInviteStore = createPaginationStore<undefined, TGroupInvite>((action, page) =>
  AlertsAPI.listGroupInvites(page),
);

export const GroupRequestStore = createPaginationStore<undefined, TGroupRequest>((action, page) =>
  AlertsAPI.listGroupRequests(page),
);

export const FollowRecommendationStore = createPaginationStore<undefined, TFollowRecommendation>(
  (action, page) => AlertsAPI.listFollowRecommendations(page),
);

// API said it can't do this, so here we are.
const getHiddenFollowRecommendations = (): Record<string, true> => {
  const hidden = localStorage.getItem('binge-hide-rec');
  let hiddenParsed;

  if (!hidden) {
    localStorage.setItem('binge-hide-rec', JSON.stringify({}));
    return {};
  }

  try {
    hiddenParsed = JSON.parse(hidden);
  } catch (e) {
    hiddenParsed = {};
  }
  return hiddenParsed;
};
export const hideFollowRecommendation = (followRecommendation: TFollowRecommendation): void => {
  const hiddenFollowRecommendations = getHiddenFollowRecommendations();
  const id = `${followRecommendation.media.model}-${followRecommendation.media.id}`;
  hiddenFollowRecommendations[id] = true;
  try {
    console.log('setting');
    console.log(hiddenFollowRecommendations);
    localStorage.setItem('binge-hide-rec', JSON.stringify(hiddenFollowRecommendations));
  } catch (e) {
    console.error(e);
  }
};
export const getFilteredFollowRecommendations = function (
  followRecommendations: TFollowRecommendation[],
): TFollowRecommendation[] {
  const removedFollowRecommendations = getHiddenFollowRecommendations();
  const mediaIds = new Set();
  const cleanedFollowRecommendations = followRecommendations
    .filter((item) => moment().subtract(30, 'days').isSameOrBefore(moment(item.updated_at)))
    .reduce<TFollowRecommendation[]>((accum, item) => {
      const id = `${item.media.model}-${item.media.id}`;
      if (!mediaIds.has(id) && !removedFollowRecommendations[id]) {
        mediaIds.add(id);
        accum.push(item);
      }
      return accum;
    }, []);

  return cleanedFollowRecommendations;
};
