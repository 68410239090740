import FollowAPI, { IFollower, IFollowing } from 'api/FollowAPI';
import { IUserData } from 'api/ProfileAPI';
import { createGenericAsyncAction, createPaginationStore } from 'stores/index';

export const FollowerStore = createPaginationStore<undefined, IFollower>((action, page) =>
  FollowAPI.fetchFollowersList(page),
);

export const FollowingStore = createPaginationStore<undefined, IFollowing>((action, page) =>
  FollowAPI.fetchFollowingList(page),
);

export const MayKnowStore = createPaginationStore<undefined, IUserData>((action, page) =>
  FollowAPI.fetchMayKnowList(page),
);

export const startFollowingAction = createGenericAsyncAction<{ userId: number }, undefined>(
  ({ userId }) => FollowAPI.startFollowing(userId),
  () => {
    FollowingStore.refresh();
    MayKnowStore.refresh();
  }
);

export const stopFollowingAction = createGenericAsyncAction<{ userId: number }, undefined>(
  ({ userId }) => FollowAPI.stopFollowing(userId),
  () => {
    FollowingStore.refresh();
    MayKnowStore.refresh();
  }
);

export const removeFollowerAction = createGenericAsyncAction<{ userId: number }, undefined>(
  ({ userId }) => FollowAPI.removeFollower(userId),
  () => FollowerStore.refresh(),
);
