import CatalogAPI, { IWatchListItem } from 'api/CatalogAPI';
import ProfileAPI from 'api/ProfileAPI';
import { createPaginatedLookupStore, createPaginationStore } from 'stores/index';

export const WatchListStore = createPaginationStore<undefined, IWatchListItem>((action, page) =>
  CatalogAPI.fetchWatchList(page),
);

export const UserIsWatchingStore = createPaginatedLookupStore<{ userId: number }, IWatchListItem>(
  ({ userId }, page) => ProfileAPI.fetchWhatUserIsWatching(userId, page),
  ({ userId }) => userId.toString(),
);
