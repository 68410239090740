import { Store } from 'pullstate';

import { IMovie, ITVShow } from 'api/CatalogAPI';
import ReviewsAPI, { IMovieReview, IReview, ITVShowReview } from 'api/ReviewsAPI';
import { createGenericAsyncAction, createPaginatedLookupStore } from 'stores/index';

interface IUserStore {
  [id: string]: IReview;
}
interface IUserIndexedStore {
  movie: {
    [id: number]: IMovieReview;
  };
  show: {
    [id: number]: {
      [season: number]: ITVShowReview;
    };
  };
}
interface IMovieStore {
  [id: string]: IMovieReview;
}
interface ITVStore {
  [id: string]: ITVShowReview;
}
const UserStore = new Store<IUserStore>({});
const MovieStore = new Store<IMovieStore>({});
const TVStore = new Store<ITVStore>({});

// TODO: do i need to do this stuff?
export const UserReviewOptimizedStore = new Store<IUserIndexedStore>({ movie: {}, show: {} });
export const UsersReviewsStore = createPaginatedLookupStore<
  { userId: number; size?: number },
  IReview
>(
  ({ userId, size }, page) => ReviewsAPI.fetchUserReviews(userId, size, page),
  ({ userId }) => userId.toString(),
  (_, response) => {
    response.items.forEach((review) => {
      UserStore.update((s) => {
        s[review.id] = review;
      });

      if (review.movie) {
        UserReviewOptimizedStore.update((s) => {
          s.movie[review?.movie?.id ?? 0] = review;
        });
      } else if (review.tv_show) {
        const showId = review.tv_show.id;
        const seasonId = Number(review?.season?.replace('s', '')) ?? 0;

        UserReviewOptimizedStore.update((s) => {
          const show = s.show[showId] || {};
          show[seasonId] = review;
          s.show[showId] = show;
        });
      }
    });
  },
);

export const MovieReviewsStore = createPaginatedLookupStore<{ movieId: number }, IMovieReview>(
  ({ movieId }, page) => ReviewsAPI.fetchMovieReviews(movieId, page),
  ({ movieId }) => movieId.toString(),
  ({ movieId }, response) => {
    response.items.forEach((review) => {
      MovieStore.update((s) => {
        review.movie = { id: movieId } as IMovie;
        s[review.id] = review;
      });
    });
  },
);

export const ShowReviewsStore = createPaginatedLookupStore<
  { showId: number; season: number },
  ITVShowReview
>(
  ({ showId, season }, page) => ReviewsAPI.fetchTVShowReviews(showId, season, page),
  ({ showId }) => showId.toString(),
  ({ showId }, response) => {
    response.items.forEach((review) => {
      TVStore.update((s) => {
        review.tv_show = { id: showId } as ITVShow;
        s[review.id] = review;
      });
    });
  },
);

export const setReviewVoteAction = createGenericAsyncAction<
  { reviewId: number; status: 'up' | 'down' },
  undefined
>(
  ({ reviewId, status }) => ReviewsAPI.voteOnReview(reviewId, status),
  ({ reviewId }) => reviewId.toString(),
  ({ reviewId, status }) => {
    TVStore.update((s) => {
      const review = s[reviewId];
      const show = review?.tv_show;
      if (!review || !show) return;

      const currentlyUpoted = review?.my_vote?.action === 'up';
      const currentlyDownvoted = review?.my_vote?.action === 'down';
      if (currentlyUpoted && status === 'down') {
        review.my_vote = {
          action: 'down',
        };
        review.rating = review.rating - 2;
      } else if (currentlyDownvoted && status === 'up') {
        review.my_vote = {
          action: 'up',
        };
        review.rating = review.rating + 2;
      }
      return review;
    });
  },
);
