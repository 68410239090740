import { BaseResponse, fetchJson, IPage, Methods } from 'api/index';
import { IUserData } from 'api/ProfileAPI';

import { IMovie, ITVShow } from './CatalogAPI';

export type IFollowing = {
  subject: IUserData;
  meta?: object;
  model: 'user.follow';
  status?: 'accepted' | 'requested';
};

export type IFollower = {
  follower: IUserData;
  meta?: object;
  model: 'user.follow';
  status?: 'accepted' | 'requested';
};

export type TFollowRecommendation = {
  id: number;
  media: ITVShow | IMovie;
  user: IUserData;
  updated_at: string;
};

export default {
  fetchFollowersList: (page = 1): Promise<BaseResponse<IPage<IFollower>>> =>
    fetchJson(`follow/followers?size=1000&page=${page}`, Methods.GET),

  fetchFollowingList: (page = 1): Promise<BaseResponse<IPage<IFollowing>>> =>
    fetchJson(`follow/following?size=1000&page=${page}`, Methods.GET),

  fetchMayKnowList: (page = 1): Promise<BaseResponse<IPage<IUserData>>> =>
    fetchJson(`friend/may_know?size=5&page=${page}`, Methods.GET),

  startFollowing: (userId: number): Promise<BaseResponse<undefined>> =>
    fetchJson(`follow/${userId}`, Methods.POST),

  stopFollowing: (userId: number): Promise<BaseResponse<undefined>> =>
    fetchJson(`follow/following/${userId}`, Methods.DELETE),

  removeFollower: (userId: number): Promise<BaseResponse<undefined>> =>
    fetchJson(`follow/followers/${userId}`, Methods.DELETE),
};
