import React from 'react';
import styled from 'styled-components';

import Link from 'components/Link';
import MaxWidthWrapper from 'components/MaxWidthWrapper';
import variables from 'components/styled/variables';
import logo from 'img/logo.svg';

const SCHeader = styled.div`
  background: ${variables.colors.background.site};
`;

const SCInner = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 60px;

  > * {
    flex-grow: 0;
  }
`;

const GenericHeader: React.FC = () => {
  return (
    <SCHeader>
      <MaxWidthWrapper theme='dark'>
        <SCInner>
          <Link href='/'>
            <img src={logo} alt='BingeRoom' />
          </Link>
        </SCInner>
      </MaxWidthWrapper>
    </SCHeader>
  );
};

export default GenericHeader;
