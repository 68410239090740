import React from 'react';
import styled from 'styled-components';

import Footer from 'components/Footer';
import MaxWidthWrapper from 'components/MaxWidthWrapper';
import Theme from 'components/Theme';
import GenericHeader from 'containers/GenericHeader';

const SCContent = styled.div`
  background: white;
  color: black;
  border-radius: 10px;
  padding: 20px 60px;
  margin: 0 60px;

  @media (max-width: 1000px) {
    padding: 20px;
    margin: 0 20px;
  }
`;

const FAQs: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Theme theme='dark'>
      <GenericHeader />
      <MaxWidthWrapper>
        <SCContent>
          <h1>FAQs</h1>
          <h2>How do I create a group?</h2>
          <p>
            To create a group click on “My Groups” on the navbar then click “Create a Group”. On the
            ensuing page fill out all the pertinent information (title, description, friends,
            imagery and type) then click “Create Group”.
          </p>
          <h2>How do I leave a group?</h2>
          <p>
            To leave a group click on “My Groups” on the navbar then click on the specific group you
            would like to leave. On that groups main page click “Leave Group” and you will no longer
            be a member of that group.
          </p>
          <h2>How do I edit a group?</h2>
          <p>
            If you are the creator of a group and you want to edit it, you must first click on “My
            Groups” on the navbar. Then click on the specific group you would like to edit. Then
            once on that groups main page click “Edit Group”. From there you can make any update you
            like and then click the “Save” button.
          </p>
          <h2>How do I find/add friends?</h2>
          <p>
            To add or find friends click on “Friends” on the navbar. Then click on “Add a Friend”.
            Enter the username, first or last name of the friend you are looking for in the search
            text field. A list of possible matches will be displayed. Click on “Send Request” to ask
            that person to accept your friend invite.
          </p>
          <h2>How do I accept a friend or group invite?</h2>
          <p>
            If someone has sent you a friend request or group invite you will find those on the
            Alerts page. You can get to the Alerts page by clicking “Alerts” on the navbar.
          </p>
          <h2>How do I recommend a show/movie?</h2>
          <p>
            To recommend a movie or show click the magnifying glass on the navbar. Then type in the
            movie or show you are looking for. On the search results page click the title of the
            movie or show you want to recommend. On the landing page of that movie or show click the
            circle to the right of Recommend It.
          </p>
          <h2>How do I add a show to my Watch List?</h2>
          <p>
            To add a movie or show to your Watch List click the magnifying glass on the navbar. Then
            type in the movie or show you are looking for. On the search results page click the
            title of the movie or show you want to add to your watch list. On the landing page of
            that movie or show click the circle to the right of Want to Watch.
          </p>
          <h2>What information will people see on my Profile?</h2>
          <p>When people go to your Profile page they will see the following:</p>
          <ul>
            <li>Your background image</li>
            <li>Your thumbnail image</li>
            <li>Your username</li>
            <li>Your first/last name if you added them</li>
            <li>The list of shows you are currently watching</li>
            <li>The list of shows and movies you have recommended</li>
          </ul>
        </SCContent>
      </MaxWidthWrapper>
      <Footer />
    </Theme>
  );
};

export default FAQs;
