import { IMovie, ITVShow } from 'api/CatalogAPI';
import { IGroup } from 'api/GroupsAPI';
import { BaseResponse, fetchJson, IPage, Methods } from 'api/index';
import { IUserData } from 'api/ProfileAPI';

export type TSearchType = 'all' | 'movie' | 'tv_show';

export default {
  searchAll: (
    term: string,
    type = 'all',
    page = 1,
    split = true,
  ): Promise<BaseResponse<IPage<IMovie | ITVShow | IGroup | IUserData>>> =>
    fetchJson(`search/${type}`, Methods.POST, {
      term,
      page,
      types: type === 'all' ? ['movie', 'tv_show', 'group', 'user'] : undefined,
      split,
      size: 2
    }),

  searchMovies: (term: string, page = 1, size?: number): Promise<BaseResponse<IPage<IMovie>>> =>
    fetchJson('search/movie', Methods.POST, { term, page, size }),

  searchShows: (term: string, page = 1, size?: number): Promise<BaseResponse<IPage<ITVShow>>> =>
    fetchJson('search/tv_show', Methods.POST, { term, page, size }),

  searchUsers: (term: string, page = 1, size?: number): Promise<BaseResponse<IPage<IUserData>>> =>
    fetchJson('search/user', Methods.POST, { term, page, size }),

  searchGroups: (term: string, page = 1, size?: number): Promise<BaseResponse<IPage<IGroup>>> =>
    fetchJson('search/group', Methods.POST, { term, page, size }),
};
