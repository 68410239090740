import { TFollowRecommendation } from 'api/FollowAPI';
import { IFriend } from 'api/FriendsAPI';
import { IGroup, TGroupRequest } from 'api/GroupsAPI';
import { BaseResponse, fetchJson, IPage, Methods } from 'api/index';

export default {
  listFriendRequests: (page = 1): Promise<BaseResponse<IPage<IFriend>>> =>
    fetchJson(`alert/friend?page=${page}`, Methods.GET),

  listGroupInvites: (page = 1): Promise<BaseResponse<IPage<IGroup>>> =>
    fetchJson(`alert/group?page=${page}`, Methods.GET),

  listGroupRequests: (page = 1): Promise<BaseResponse<IPage<TGroupRequest>>> =>
    fetchJson(`alert/group_request?page=${page}`, Methods.GET),

  listFollowRecommendations: (page = 1): Promise<BaseResponse<IPage<TFollowRecommendation>>> =>
    fetchJson(`alert/follow_recommendation?page=${page}&size=1000`, Methods.GET),
};
