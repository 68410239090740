import { IMovie, ITVShow, TWatchedStatus } from 'api/CatalogAPI';
import { BaseResponse, fetchJson, IPage, Methods } from 'api/index';
import { IUserData } from 'api/ProfileAPI';

export interface IFriend {
  accepter_user_id: number;
  created_at: string;
  id: number;
  requester_user_id: number;
  status: string;
  user: IUserData;
}

export type TEpisodeStatus = {
  key: string;
  status: TWatchedStatus;
  updated_at: number;
};

export type TFriendWatchItem = {
  media: ITVShow | IMovie;
  user: IUserData;
  updated_at: string;
  episodes?: TEpisodeStatus[];
};

export default {
  fetchFriendsList: (page = 1): Promise<BaseResponse<IPage<IFriend>>> =>
    fetchJson(`friend?status=accepted&size=5&page=${page}`, Methods.GET),

  removeFriend: (userId: number): Promise<BaseResponse<undefined>> =>
    fetchJson(`friend/users/${userId}`, Methods.DELETE),

  requestFriend: (userId: number): Promise<BaseResponse<undefined>> =>
    fetchJson(`friend/users/${userId}`, Methods.POST),

  accept: (userId: number): Promise<BaseResponse<undefined>> =>
    fetchJson(`friend/users/${userId}/accept`, Methods.POST),

  sendInvite: (email: string): Promise<BaseResponse<undefined>> =>
    fetchJson('friend/invite', Methods.POST, { email }),

  fetchWhatFriendsAreWatching: (page = 1): Promise<BaseResponse<IPage<TFriendWatchItem>>> =>
    fetchJson(`friend/watching?page=${page}&status=watching`, Methods.GET),
};
