import { BaseResponse, fetchJson, IPage, Methods } from 'api/index';

import { IMovie, ITVShow } from './CatalogAPI';
import { IGroup } from './GroupsAPI';
import { IUserData } from './ProfileAPI';

interface IBaseReview {
  id: number;
  body: string;
  created_at: string;
  group: IGroup;
  has_spoilers: boolean;
  is_me: boolean;
  media?: {
    format: 'image' | 'video';
    path: string;
  };
  media_rating?: {
    updated_at: string;
    value: number;
  };
  model: 'review';
  my_vote?: {
    action: 'up' | 'down';
  };
  rating: number;
  title: string;
  updated_at: string;
  user: IUserData;
}

export interface IMovieReview extends IBaseReview {
  movie?: IMovie;
}

export interface ITVShowReview extends IBaseReview {
  season?: string;
  tv_show?: ITVShow;
}

export interface IReview extends IMovieReview, ITVShowReview {}

const ReviewsAPI = {
  fetchMovieReview: (movieId: number, reviewId: number): Promise<BaseResponse<IMovieReview>> =>
    fetchJson(`review/movie/${movieId}/${reviewId}`, Methods.GET),

  fetchTVShowReview: (tvShowId: number, reviewId: number): Promise<BaseResponse<ITVShowReview>> =>
    fetchJson(`review/tv/${tvShowId}/${reviewId}`, Methods.GET),

  fetchMovieReviews: (movieId: number, page = 1): Promise<BaseResponse<IPage<IMovieReview>>> =>
    fetchJson(`review/movie/${movieId}?page=${page}`, Methods.GET),

  fetchTVShowReviews: (
    tvShowId: number,
    season?: number,
    page = 1,
  ): Promise<BaseResponse<IPage<ITVShowReview>>> =>
    fetchJson(
      `review/tv/${tvShowId}?page=${page}${season ? `&season=${season}` : ''}`,
      Methods.GET,
    ),

  fetchUserReviews: (userId: number, size = 25, page = 1): Promise<BaseResponse<IPage<IReview>>> =>
    fetchJson(`review/user/${userId}?page=${page}&size=${size}`, Methods.GET),

  removeMovieReview: (movieId: number, reviewId: number): Promise<BaseResponse<undefined>> =>
    fetchJson(`review/movie/${movieId}/${reviewId}`, Methods.DELETE),

  removeTVShowReview: (tvShowId: number, reviewId: number): Promise<BaseResponse<undefined>> =>
    fetchJson(`review/tv/${tvShowId}/${reviewId}`, Methods.DELETE),

  removeVoteOnReview: (reviewId: number): Promise<BaseResponse<undefined>> =>
    fetchJson(`review/vote/${reviewId}`, Methods.DELETE),

  submitMovieReview: (
    movieId: number,
    title: string,
    body: string,
    has_spoilers: boolean,
    group_id?: number,
    media_id?: number,
  ): Promise<BaseResponse<undefined>> =>
    fetchJson(`review/movie/${movieId}`, Methods.POST, {
      title,
      body,
      has_spoilers,
      group_id,
      media_id,
    }),

  submitTVReview: (
    showId: number,
    title: string,
    body: string,
    has_spoilers: boolean,
    season: string,
    group_id?: number,
    media_id?: number,
  ): Promise<BaseResponse<undefined>> =>
    fetchJson(`review/tv/${showId}`, Methods.POST, {
      title,
      body,
      has_spoilers,
      season,
      group_id,
      media_id,
    }),

  updateMovieReview: (
    movieId: number,
    reviewId: number,
    title: string,
    body: string,
    has_spoilers: boolean,
    group_id?: number,
    media_id?: number,
  ): Promise<BaseResponse<undefined>> =>
    fetchJson(`review/movie/${movieId}/${reviewId}`, Methods.PUT, {
      title,
      body,
      has_spoilers,
      group_id,
      media_id,
    }),

  updateTVShowReview: (
    showId: number,
    reviewId: number,
    title: string,
    body: string,
    has_spoilers: boolean,
    group_id?: number,
    media_id?: number,
  ): Promise<BaseResponse<undefined>> =>
    fetchJson(`review/tv/${showId}/${reviewId}`, Methods.PUT, {
      title,
      body,
      has_spoilers,
      group_id,
      media_id,
    }),

  voteOnReview: (reviewId: number, action: 'up' | 'down'): Promise<BaseResponse<undefined>> =>
    fetchJson(`review/vote/${reviewId}`, Methods.POST, { action }),
};

export default ReviewsAPI;
