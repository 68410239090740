import React from 'react';
import AtlantaImage from './img/Atlanta.png';
import BulbbulImage from './img/Bulbbul.png';
import TheTwilightZoneImage from './img/TheTwilightZone.png';
import PennyDreadfulImage from './img/PennyDreadful.png';
import AmazingStoriesImage from './img/AmazingStories.png';
import EuphoriaImage from './img/Euphoria.png';
import SCSection from './SCSection';
import MaxWidthWrapper from '../../../components/MaxWidthWrapper';
import SCCTAButton from './SCCTAButton';
import Rating from '../../../components/Rating';
import styled from 'styled-components';
import variables from '../../../components/styled/variables';

const SCCenterWrapper = styled.div`
  text-align: center;
`;

const SCDiscoverSection = styled.div`
  padding: 60px;

  h1 {
    font-size: 75px;
    text-align: center;
    max-width: 750px;
    margin: 0 auto;
  }

  h2 {
    text-align: center;
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 1000px) {
    padding: 60px 20px;

    h1 {
      margin-top: 0;
      font-size: 30px;
    }

    h2 {
      font-size: 16px;
      font-weight: normal;
      line-height: normal;
    }
  }
`;

const MOBILE_SCALE = 0.53;
const MOVIE_HEIGHT = 282;
const MOVIE_WIDTH = 190;
const WRAPPER_HEIGHT = 330;
const SCMovieWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: ${WRAPPER_HEIGHT}px;
  justify-content: center;
  margin-top: 40px;
  overflow: hidden;
  width: 100%;

  @media (max-width: 1000px) {
    height: ${16 + 20 + MOVIE_HEIGHT * MOBILE_SCALE}px;
  }
`;

const SCMovie = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  text-align: center;

  img {
    display: block;
    height: ${MOVIE_HEIGHT}px;
    width: ${MOVIE_WIDTH}px;
  }

  h1 {
    font-family: ${variables.fonts.body};
    font-size: 18px;
    font-weight: normal;
    line-height: 30px;
    margin: 0;
    opacity: 0.8;
  }

  @media (max-width: 1000px) {
    margin: 0 5px;
    img {
      height: ${MOBILE_SCALE * MOVIE_HEIGHT}px;
      width: ${MOBILE_SCALE * MOVIE_WIDTH}px;
    }

    h1 {
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

const DiscoverSection: React.FC = () => {
  const recommendations: {
    imageSource: string;
    title: string;
    rating: number;
  }[] = [
    { title: 'Atlanta', imageSource: AtlantaImage, rating: 4.8 * 2 },
    { title: 'Bulbbul', imageSource: BulbbulImage, rating: 4 * 2 },
    { title: 'The Twilight Zone', imageSource: TheTwilightZoneImage, rating: 3.25 * 2 },
    { title: 'Penny Dreadful', imageSource: PennyDreadfulImage, rating: 4 * 2 },
    { title: 'Amazing Stories', imageSource: AmazingStoriesImage, rating: 3.8 * 2 },
    { title: 'Euphoria', imageSource: EuphoriaImage, rating: 4.8 * 2 },
  ];

  return (
    <SCSection>
      <MaxWidthWrapper>
        <SCDiscoverSection>
          <h1>See what celebs and your friends are watching.</h1>
          <h2>
            See the content that celebs and your friends watch every day. Get suggestions based on
            their preferences, not just yours. Your new obsession with Bollywood Horror? Both your
            old prom date AND your aunt can’t get enough.
          </h2>
          <SCCenterWrapper>
            <SCCTAButton href='/register'>Join My Friends</SCCTAButton>
          </SCCenterWrapper>
          <SCMovieWrapper>
            {recommendations.map(({ imageSource, title, rating }, index) => (
              <SCMovie key={index}>
                <img src={imageSource} alt={title} />
                <h1>{title}</h1>
                <Rating value={rating} color='white' />
              </SCMovie>
            ))}
          </SCMovieWrapper>
        </SCDiscoverSection>
      </MaxWidthWrapper>
    </SCSection>
  );
};

export default DiscoverSection;
