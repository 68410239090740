import { rgba } from 'polished';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Carousel, { CarouselItem } from 'components/Carousel';
import Spinner from 'components/Spinner';
import SCButton from 'components/styled/SCButton';
import variables from 'components/styled/variables';
import getImageUrlString from 'helpers/getImageUrlString';

export const SCCarousel = styled(Carousel)`
  margin: 0 0 40px;
  padding: 0 60px;

  @media (max-width: 1000px) {
    padding: 0 20px;
  }
`;

export const SCCarouselItem = styled(CarouselItem)`
  padding: 0 20px 0 0;

  @media (max-width: 1000px) {
    padding-right: 5px;
    &:last-of-type {
      padding-right: 20px;
    }
  }
`;

export const SCSpinner = styled(Spinner)`
  display: inline-block;
  font-size: 25px;
  margin: 0 0 40px;
  opacity: 0.5;
`;

export const SCBox = styled.div`
  background: white;
  border-radius: 10px;
  box-sizing: border-box;
  margin: 0;
  padding: 60px 0 20px;
  position: relative;
  text-align: center;
  width: 500px;

  h1 {
    color: ${variables.colors.text.link};
    font-family: ${variables.fonts.heading};
    text-transform: uppercase;
    font-size: 30px;
    margin: 0 0 20px;
  }

  h2 {
    font-size: 18px;
    font-weight: normal;
    line-height: 20px;
    margin: 0 0 40px;
  }

  h3 {
    color: #666;
    font-size: 18px;
    font-weight: normal;
    line-height: 20px;
    margin: 0 0 40px;
  }

  @media (max-width: 1000px) {
    width: auto;
  }
`;

export const SCStep = styled.div`
  position: absolute;
  right: 40px;
  top: 10px;
`;

export const SCShowSelector = styled.div`
  border-color: #d0d0d0;
  border-style: solid;
  border-width: 1px 0;
  margin: 0 0 40px;
  max-height: 200px;
  overflow: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    background-color: #ccc;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #999;
  }
`;

export const SCNextButton = styled(SCButton).attrs({ as: 'div', block: true })`
  margin: 0 80px 5px;
  width: auto;
`;

export const SCSkipButton = styled(Link)`
  display: block;
  color: black;
  font-weight: 500;
  line-height: 25px;
  margin: 0 0 10px;
`;

export const SCDontWorry = styled.div`
  color: #666;
  margin: 20px 0 0;
`;

export const SCPoster = React.memo(styled.button.attrs({
  type: 'button',
  className: 'carousel-item',
  width: 190,
  height: 280,
  multiplier: 0.59,
})<{ imageUrl?: string }>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${(props) => getImageUrlString(props.imageUrl)};
  border: 0;
  cursor: pointer;
  display: block;
  height: ${(props) => props.height * props.multiplier}px;
  outline: none;
  position: relative;
  width: ${(props) => props.width * props.multiplier}px;

  &::after {
    display: ${(props) => props.imageUrl != null && 'none'};
    color: ${rgba('white', 0.5)};
    content: '\f03d';
    font-family: ${variables.fonts.icon};
    font-size: 30px;
    font-weight: 900;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`);

export const SCAddButton = styled.div.attrs({ size: 30 })<{ size: number; hasStatus: boolean }>`
  background-color: ${(props) => (props.hasStatus ? 'green' : rgba('black', 0.35))};
  border-radius: 50%;
  color: white;
  cursor: pointer;
  font-size: 16px;
  height: ${(props) => props.size}px;
  outline: none;
  position: absolute;
  right: 10px;
  top: 10px;
  width: ${(props) => props.size}px;
  z-index: 100;
  border: 0;
  box-shadow: none;

  > .fas {
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.7);
    transform-origin: 50% 50%;
  }
`;

export const SCMediaTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin: 10px 0 0;

  @media (max-width: 1000px) {
    font-size: 12px;
    font-weight: normal;
    margin: 5px 0 0;
  }
`;
