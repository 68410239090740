import { BaseResponse, fetchJson, IPage, Methods } from 'api/index';
import { IUserData } from 'api/ProfileAPI';

export interface IGroupMember {
  id: number;
  model: 'group_member';
  status: 'invited' | 'accepted' | 'requested';
  role: 'member' | 'creator';
  user: IUserData;
}

export type TGroupRequest = IGroupMember & {
  group: IGroup;
};

export interface IGroup {
  id: number;
  avatar: string | null;
  cover: string | null;
  description: string;
  is_public: boolean;
  title: string;
  model: 'group';
  creator: IGroupMember;
  membership?: IGroupMember;
}
interface IGroupPostAttachment {
  url: string;
}
export interface IGroupPost {
  created_at: string;
  id: number;
  post: {
    text: string;
    attachments?: IGroupPostAttachment[];
  };
  user: IUserData;
}

export default {
  listGroupMembers: (groupId: number, page = 1): Promise<BaseResponse<IPage<IGroupMember>>> =>
    fetchJson(`group/${groupId}/members?status=accepted&page=${page}`, Methods.GET),

  listGroups: (page = 1, size = 25): Promise<BaseResponse<IPage<IGroup>>> =>
    fetchJson(`group?status=accepted&page=${page}&size=${size}`, Methods.GET),

  postToGroupFeed: (
    groupId: number,
    text: string,
    media?: number,
  ): Promise<BaseResponse<Omit<IGroupPost, 'user'>>> => {
    const payload: any = { text };

    if (media) {
      payload.attachments = [{ media_id: media }];
    }

    return fetchJson(`group/${groupId}/feed`, Methods.POST, payload);
  },

  rejectGroupInvite: (groupId: number): Promise<BaseResponse<undefined>> =>
    fetchJson(`group/${groupId}/reject`, Methods.POST),

  sendGroupInvite: (groupId: number, userId: number): Promise<BaseResponse<undefined>> =>
    fetchJson(`group/${groupId}/members/${userId}`, Methods.POST),

  // TODO: Update when Chris responds
  sendGroupInviteByEmail: (groupId: number, email: string): Promise<BaseResponse<undefined>> =>
    fetchJson(`group/${groupId}/members/email`, Methods.POST, { email }),

  setGroupImage: (
    groupId: number,
    mediaId: number,
    type: 'avatar' | 'cover',
  ): Promise<BaseResponse<undefined>> =>
    fetchJson(`group/${groupId}/${type}`, Methods.POST, { media_id: mediaId }),

  acceptGroupInvite: (groupId: number): Promise<BaseResponse<undefined>> =>
    fetchJson(`group/${groupId}/accept`, Methods.POST),

  acceptGroupRequest: (groupId: number, userId: number): Promise<BaseResponse<undefined>> =>
    fetchJson(`group/${groupId}/requests/${userId}/accept`, Methods.POST),

  rejectGroupRequest: (groupId: number, userId: number): Promise<BaseResponse<undefined>> =>
    fetchJson(`group/${groupId}/requests/${userId}/reject`, Methods.POST),

  fetchGroup: (groupId: number): Promise<BaseResponse<IGroup>> =>
    fetchJson(`group/${groupId}`, Methods.GET),

  listGroupFeed: (groupId: number, page = 1): Promise<BaseResponse<IPage<IGroupPost>>> =>
    fetchJson(`group/${groupId}/feed?page=${page}`, Methods.GET),

  createGroup: (
    description: string,
    isPublic: boolean,
    title: string,
  ): Promise<BaseResponse<IGroup>> =>
    fetchJson('group', Methods.POST, { description, is_public: isPublic, title }),

  editGroup: (
    groupId: number,
    description: string,
    isPublic: boolean,
    title: string,
  ): Promise<BaseResponse<IGroup>> =>
    fetchJson(`group/${groupId}`, Methods.PUT, {
      description,
      is_public: isPublic,
      title,
    }),

  leaveGroup: (groupId: number): Promise<BaseResponse<undefined>> =>
    fetchJson(`group/${groupId}/leave`, Methods.POST),

  joinPublicGroup: (groupId: number): Promise<BaseResponse<undefined>> =>
    fetchJson(`group/${groupId}/join`, Methods.POST),
};
