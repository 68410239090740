import React from 'react';

import css from './DefaultPoster.module.scss';

const getSizeFromWidth = (width: number) => ({ width, height: (width * 3) / 2 });

const SIZES: { [key: string]: { width: number; height: number } } = {
  w92: getSizeFromWidth(92),
};

interface IProps {
  imageKey: keyof typeof SIZES;
}

const DefaultPoster: React.FC<IProps> = ({ imageKey }) => {
  const { height, width } = SIZES[imageKey];

  const fontSize = `${width / 3}px`;

  return <div className={css.DefaultPoster} style={{ height, width, fontSize }} />;
};

export default DefaultPoster;
