import { IMovie, ITVShow } from 'api/CatalogAPI';
import { IGroup } from 'api/GroupsAPI';
import { IUserData } from 'api/ProfileAPI';
import SearchAPI, { TSearchType } from 'api/SearchAPI';
import { createPaginatedLookupStore } from 'stores/index';
import { MovieStore } from 'stores/movies/MovieStore';
import { ShowStore } from 'stores/shows/ShowStore';
export type TSearchResult = IMovie | ITVShow | IGroup | IUserData;

const preCheck = ({ term }: { term: string }) => {
  if (term.length < 3) return new Error('Term must be at least 3 characters long');
};

export const SearchAllStore = createPaginatedLookupStore<
  { term: string; type: TSearchType },
  TSearchResult
>(
  ({ term, type }, page) => SearchAPI.searchAll(term, type, page),
  ({ term }) => term,
  (_, results) => {
    /* PATCH ME */
    MovieStore.update((s) => {
      results.items.forEach((item) => {
        if (item.model === 'movie') s[item.id] = item;
      });
    });
    ShowStore.update((s) => {
      results.items.forEach((item) => {
        if (item.model === 'tv_show')
          s[item.id] = {
            show: item,
            seasons: s[item.id]?.seasons,
          };
      });
    });
  },
  preCheck,
);


export const SearchMoviesStore = createPaginatedLookupStore<{ term: string, size?: number }, IMovie>(
  ({ term, size }, page) => SearchAPI.searchMovies(term, page, size),
  ({ term }) => term,
  undefined,
  preCheck,
);


export const SearchShowsStore = createPaginatedLookupStore<{ term: string, size?: number }, ITVShow>(
  ({ term, size }, page) => SearchAPI.searchShows(term, page, size),
  ({ term }) => term,
  undefined,
  preCheck,
);

export const SearchUsersStore = createPaginatedLookupStore<{ term: string, size?: number }, IUserData>(
  ({ term, size }, page) => SearchAPI.searchUsers(term, page, size),
  ({ term }) => term,
  undefined,
  preCheck,
);

export const SearchGroupsStore = createPaginatedLookupStore<{ term: string, size?: number }, IGroup>(
  ({ term, size }, page) => SearchAPI.searchGroups(term, page, size),
  ({ term }) => term,
  undefined,
  preCheck,
);
