import { IWatchedMovie, IWatchedTVShow, IWatchListItem } from 'api/CatalogAPI';
import { BaseResponse, fetchJson, IPage, Methods } from 'api/index';

export interface IUserData {
  am_i_following: boolean;
  avatar: string | null;
  cover: string | null;
  id: number;
  is_following_me: boolean;
  is_me: boolean;
  first_name: string | null;
  last_name: string | null;
  model: 'user';
  username: string;
}

export default {
  setProfileDetails: (
    firstName: string | null,
    lastName: string | null,
  ): Promise<BaseResponse<undefined>> =>
    fetchJson('profile', Methods.POST, { first_name: firstName, last_name: lastName }),

  fetchCurrentUser: (): Promise<BaseResponse<IUserData>> => fetchJson('profile', Methods.GET),

  fetchUser: (userId: number): Promise<BaseResponse<IUserData>> =>
    fetchJson(`profile/users/${userId}`, Methods.GET),

  uploadImage: (
    type: 'avatar' | 'cover',
    image: string,
  ): Promise<BaseResponse<{ media_id: number; url: string }>> =>
    fetchJson(`media/image/${type}`, Methods.POST, { image }),

  setImage: (type: 'avatar' | 'cover', mediaId: number): Promise<BaseResponse<undefined>> =>
    fetchJson(`profile/${type}`, Methods.POST, { media_id: mediaId }),

  fetchWhatUserIsWatching: (
    userId: number,
    page = 1,
  ): Promise<BaseResponse<IPage<IWatchListItem>>> =>
    fetchJson(`profile/users/${userId}/watching?page=${page}&status=watching`, Methods.GET),

  fetchUserRecommendedMovies: (
    userId: number,
    page = 1,
  ): Promise<BaseResponse<IPage<IWatchedMovie>>> =>
    fetchJson(`profile/users/${userId}/recommended?type=movie&size=32&page=${page}`, Methods.GET),

  fetchUserRecommendedShows: (
    userId: number,
    page = 1,
  ): Promise<BaseResponse<IPage<IWatchedTVShow>>> =>
    fetchJson(`profile/users/${userId}/recommended?type=tv&size=32&page=${page}`, Methods.GET),
};
