import { BaseResponse, fetchJson, Methods } from 'api';

const passwordResetRequest = async (email: string): Promise<BaseResponse<undefined>> =>
  fetchJson('auth/password/reset/request', Methods.POST, { email });

const login = async (email: string, password: string): Promise<BaseResponse<{ token: string }>> =>
  fetchJson('auth/login', Methods.POST, { email, password });

const register = async (
  email: string,
  username: string,
  password: string,
  firstName: string,
  lastName: string,
): Promise<BaseResponse<{ token: string }>> =>
  fetchJson('auth/register', Methods.POST, {
    email,
    username,
    password,
    first_name: firstName,
    last_name: lastName,
  });

const fetchUserEmail = async (code: string): Promise<BaseResponse<{ email: string }>> =>
  fetchJson(`auth/password/reset/request?code=${encodeURIComponent(code)}`, Methods.GET);

const resetPassword = async (code: string, password: string): Promise<BaseResponse<undefined>> =>
  fetchJson('auth/password/reset/submit', Methods.POST, { code, password });

export default {
  login,
  resetPassword,
  fetchUserEmail,
  register,
  passwordResetRequest,
};
