import { CurrentUserStore } from 'stores/auth/CurrentUserStore';

export type BaseResponse<TResponse> =
  | {
      success: true;
      response: TResponse;
    }
  | {
      success: false;
      error: {
        message: string;
      };
    };

export enum Methods {
  PUT = 'PUT',
  POST = 'POST',
  GET = 'GET',
  DELETE = 'DELETE',
}

export interface IPage<T> {
  items: T[];
  count: number;
  pagination: {
    page: number;
    size: number;
    total: number;
  };
}

export const getHeaders = (): Headers => {
  const { token } = CurrentUserStore.getRawState();
  const headers = new Headers();
  if (token != null) headers.append('x-auth-token', token);
  headers.append('Content-Type', 'application/json');
  return headers;
};

export const fetchJson = async <T>(
  path: string,
  method: Methods,
  body?: { [key: string]: any },
): Promise<BaseResponse<T>> => {
  const cb = Number(new Date());
  const separator = path.indexOf('?') < 0 ? '?' : '&';
  const response = await fetch(`/api/v1/${path}${separator}cb=${cb}`, {
    method,
    headers: getHeaders(),
    body: body ? JSON.stringify(body) : undefined,
  });
  return await response.json();
};
