import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import LoginPageWrapper from 'components/LoginPageWrapper';
import variables from 'components/styled/variables';
import {
  fetchShowAction,
  RegistrationSelectedShowStore,
  setEpisodeWatchedStatus,
  ShowStore,
} from 'stores/shows/ShowStore';

import {
  SCBox,
  SCDontWorry,
  SCNextButton,
  SCShowSelector,
  SCSkipButton,
  SCSpinner,
  SCStep,
} from './shared';

const SCShow = styled.div`
  display: block;
  margin: 10px 80px;
  text-align: left;

  @media (max-width: 1000px) {
    margin: 10px 20px;
  }
`;

const SCShowTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 5px;
`;

const SCSeasonWrapper = styled.div`
  display: flex;
`;

const SCSeasonTitle = styled.div`
  flex-grow: 0;
  margin: 0 20px 0 0;
`;

const SCNumberWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const SCNumberButton = styled.button<{ active?: boolean }>`
  border: 0;
  background: ${(props) => (props.active ? variables.colors.text.link : 'white')};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: ${(props) => (props.active ? 'white' : 'black')};
  text-align: center;
  margin: 0 0 10px 10px;
  display: block;
  outline: none;
  cursor: pointer;
  border: 1px solid ${variables.colors.text.link};
  padding: 0;
`;

const Show: React.FC<{ showId: number }> = ({ showId }) => {
  const [isShowLoaded] = fetchShowAction.useBeckon({ showId });
  const { show, seasons } = ShowStore.useState((s) => s[showId] ?? {}, [showId]);

  const isEpisodeWatched = (key: string) => show?.watched?.[key]?.status === 'watched';

  const getEpisodeKeys = (key: string) => {
    if (seasons == null) return [];
    const seasonKeys = seasons.map((season) => season.key);
    const index = seasonKeys.indexOf(key);
    return seasonKeys.slice(0, index + 1);
  };

  const toggleEpisodeWatchStatus = (key: string) => {
    const watched = !isEpisodeWatched(key);
    const episodeKeys = watched ? getEpisodeKeys(key) : [key];
    setEpisodeWatchedStatus.run({ episodeKeys, showId, watched });
  };

  return (
    <SCShow key={show.id}>
      <SCShowTitle>{show.name}</SCShowTitle>
      <SCSeasonWrapper>
        <SCSeasonTitle>Season</SCSeasonTitle>
        <SCNumberWrapper>
          {isShowLoaded && seasons?.length ? (
            seasons?.map((season) => (
              <SCNumberButton
                key={season.number}
                active={isEpisodeWatched(season.key)}
                onClick={() => toggleEpisodeWatchStatus(season.key)}>
                {season.number}
              </SCNumberButton>
            ))
          ) : (
            <SCSpinner />
          )}
        </SCNumberWrapper>
      </SCSeasonWrapper>
    </SCShow>
  );
};

const PostRegisterStep1: React.FC = () => {
  const history = useHistory();
  const selectedShows = RegistrationSelectedShowStore.useState();

  return selectedShows.length > 0 ? (
    <LoginPageWrapper>
      <SCBox>
        <SCStep>Step 2 of 3</SCStep>
        <h1>Create Account</h1>
        <h2>How much of the show did you watch?</h2>
        <SCShowSelector>
          {selectedShows.map((show) => (
            <Show key={show.id} showId={show.id} />
          ))}
        </SCShowSelector>
        <SCNextButton onClick={() => history.push('/register/3')}>Next</SCNextButton>
        <SCSkipButton to='/'>Skip</SCSkipButton>
        <SCDontWorry>You can add episodes later.</SCDontWorry>
      </SCBox>
    </LoginPageWrapper>
  ) : (
    <Redirect to='/register/1' />
  );
};

export default PostRegisterStep1;
