import { createAsyncAction, errorResult, successResult } from 'pullstate';
import React from 'react';
import Async from 'react-select/async';
import styled, { ThemeContext } from 'styled-components';
import { v4 } from 'uuid';

import { IPage } from 'api';
import { IUserData } from 'api/ProfileAPI';
import SearchAPI from 'api/SearchAPI';
import getStyles from 'containers/SearchInput/getStyles';

const fetchSearchResults = createAsyncAction<{ term: string }, IPage<IUserData>>(
  async ({ term }) => {
    try {
      const json = await SearchAPI.searchUsers(term);
      if (!json.success) throw new Error(json.error.message);

      return successResult(json.response);
    } catch (err) {
      return errorResult([], err.message);
    }
  },
);

const loadOptions: React.ComponentProps<typeof Async>['loadOptions'] = async (term) => {
  if (term.length < 3) return [];

  const json = await fetchSearchResults.run({ term });
  const items = json?.payload?.items ?? [];
  return items.map((item) => ({
    value: item.id,
    item,
    label:
      item.first_name && item.last_name
        ? `${item.first_name} ${item.last_name}`
        : `@${item.username}`,
  }));
};

type AsyncProps = Omit<React.ComponentProps<typeof Async>, 'styles'>;

const SCWrapper = styled.div<{ noMargin: boolean }>`
  margin: ${(props) => (props.noMargin ? 0 : '0 0 40px')};
  max-width: 610px;
`;

const SCLabel = styled.label`
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 10px;
`;

interface IProps {
  id?: string;
  label?: string;
  noMargin?: boolean;
}

const FriendSearchInput: React.FC<AsyncProps & IProps> = ({
  id = v4(),
  noMargin = false,
  label,
  ...props
}) => {
  const theme = React.useContext(ThemeContext);
  return (
    <SCWrapper noMargin={noMargin}>
      {label && <SCLabel htmlFor={id}>{label}</SCLabel>}
      <Async id={id} styles={getStyles(theme.dark)} loadOptions={loadOptions} {...props} />
    </SCWrapper>
  );
};

export default FriendSearchInput;
