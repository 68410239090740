import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { Column, Direction, IMovie } from 'api/CatalogAPI';
import DefaultPoster from 'components/DefaultPoster';
import Link from 'components/Link';
import Rating from 'components/Rating';
import SCButton from 'components/styled/SCButton';
import SCHideForMobile from 'components/styled/SCHideForMobile';
import SCResultsTable from 'components/styled/SCResultsTable';
import SCShowForMobile from 'components/styled/SCShowForMobile';
import SCShowMoreButtonWrapper from 'components/styled/SCShowMoreButtonWrapper';
import { ConfirmRemoveMovieModal, displayConfirmRemoveMovieModal } from 'containers/modals/ConfirmRemoveMediaModal';
import { setMovieRatingAction } from 'stores/movies/MovieStore';

const SCGenre = styled.div`
  white-space: nowrap;
`;

const SCMobileMovie = styled.div`
  display: flex;
  padding: 20px 0;

  & + & {
    border-top: 1px solid #c4c4c4;
  }
`;

const SCMobilePosterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-right: 10px;
`;

const SCMobileMovieDetails = styled.div`
  h1 {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }

  h2 {
    font-size: 16px;
    font-weight: normal;
    margin: 0 0 10px;
  }
`;

const capitalizeGenre = (genre: string): string => {
  const words = genre.split(/ /g);
  return words.map((w) => w.charAt(0).toUpperCase() + w.substr(1)).join(' ');
};

const Arrow: React.FC<{ sort?: Sort; column: Column }> = ({ sort, column }) => {
  if (sort?.column !== column) return null;

  return (
    <>
      &nbsp;
      <i className={`fas fa-caret-${sort.direction === 'asc' ? 'up' : 'down'}`} />
    </>
  );
};

type Sort = {
  onChange: (column: Column, direction: Direction) => void;
  column: Column;
  direction: Direction;
};

interface IProps {
  type: 'browse' | 'watched';
  movies: IMovie[];
  hasMorePages: boolean;
  loadMore: () => void;
  isUpdating: boolean;
  useMyRatings?: boolean;
  sort?: Sort;
}

const MovieTable: React.FC<IProps> = ({
  type,
  movies,
  hasMorePages,
  loadMore,
  isUpdating,
  useMyRatings,
  sort,
}) => {
  const setRating = (movie: IMovie, rating: number) => {
    setMovieRatingAction.run({ movieId: movie.id, rating });
  };

  const includeDateAddedColumn = type === 'watched';

  const getOnClick = (column: Column) => {
    if (sort == null) return undefined;

    const invertDirection = (direction: Direction): Direction =>
      direction === 'asc' ? 'desc' : 'asc';
    const getDefaultDirection = (): Direction => {
      switch (column) {
        case 'name':
          return 'asc';
        default:
          return 'desc';
      }
    };

    return () =>
      sort.onChange(
        column,
        sort.column === column ? invertDirection(sort.direction) : getDefaultDirection(),
      );
  };

  return (
    <>
      <SCHideForMobile>
        <SCResultsTable cellPadding={0} cellSpacing={0}>
          <thead>
            <tr>
              <th>{/* Poster */}</th>
              <th className={classNames({ clickable: sort != null })} onClick={getOnClick('name')}>
                Title
                <Arrow sort={sort} column='name' />
              </th>
              <th className={classNames({ clickable: sort != null })} onClick={getOnClick('genre')}>
                Genre
                <Arrow sort={sort} column='genre' />
              </th>
              <th
                className={classNames({ clickable: sort != null })}
                onClick={getOnClick('rating')}>
                {useMyRatings ? 'My Rating' : 'Avg Rating'}
                <Arrow sort={sort} column='rating' />
              </th>
              {includeDateAddedColumn && (
                <th
                  className={classNames({ clickable: sort != null })}
                  onClick={getOnClick('date')}>
                  Date Added
                  <Arrow sort={sort} column='date' />
                </th>
              )}
              <th>{/* Actions */}</th>
            </tr>
          </thead>
          <tbody>
            {movies.map((movie) => (
              <tr key={movie.id}>
                <td>
                  <Link href={`/dashboard/movies/details/${movie.id}`}>
                    {movie.images.poster.w92 ? (
                      <img src={movie.images.poster.w92} alt={movie.title} />
                    ) : (
                      <DefaultPoster imageKey='w92' />
                    )}
                  </Link>
                </td>
                <td>
                  <Link href={`/dashboard/movies/details/${movie.id}`}>{movie.title}</Link>
                </td>
                <td>
                  {movie.genres?.map((genre) => (
                    <SCGenre key={genre}>{capitalizeGenre(genre)}</SCGenre>
                  ))}
                </td>
                <td>
                  {useMyRatings ? (
                    <Rating
                      value={movie.rating?.value}
                      onChange={(rating) => setRating(movie, rating)}
                    />
                  ) : (
                    <Rating value={movie.vote_average} />
                  )}
                </td>
                {includeDateAddedColumn && (
                  <td>{moment(movie.watched?.updated_at).format('MM/DD/YYYY')}</td>
                )}
                <td>
                  <p>
                    <Link href={`/dashboard/movies/details/${movie.id}`}>Edit</Link>
                  </p>
                  {type === 'watched' && (
                    <p>
                      <Link onClick={() => displayConfirmRemoveMovieModal(movie.id)}>Remove</Link>
                    </p>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </SCResultsTable>
      </SCHideForMobile>
      <SCShowForMobile>
        {movies.map((movie) => (
          <SCMobileMovie key={movie.id}>
            <SCMobilePosterWrapper>
              <Link href={`/dashboard/movies/details/${movie.id}`}>
                {movie.images.poster.w92 ? (
                  <img src={movie.images.poster.w92} alt={movie.title} />
                ) : (
                  <DefaultPoster imageKey='w92' />
                )}
              </Link>
              <Link href={`/dashboard/movies/details/${movie.id}`}>Edit</Link>
              {type === 'watched' && <Link onClick={() => displayConfirmRemoveMovieModal(movie.id)}>Remove</Link>}
            </SCMobilePosterWrapper>
            <SCMobileMovieDetails>
              <h1>{movie.title}</h1>
              <h2>{movie.genres?.map(capitalizeGenre).join(', ')}</h2>
              {includeDateAddedColumn && (
                <p>{moment(movie.watched?.updated_at).format('MM/DD/YYYY')}</p>
              )}
            </SCMobileMovieDetails>
          </SCMobileMovie>
        ))}
      </SCShowForMobile>
      {hasMorePages && (
        <SCShowMoreButtonWrapper>
          <SCButton disabled={isUpdating} onClick={loadMore}>
            Show More
          </SCButton>
        </SCShowMoreButtonWrapper>
      )}
      <ConfirmRemoveMovieModal />
    </>
  );
};

export default MovieTable;
