import useEffectOnce from '../../../../hooks/useEffectOnce';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Column, Direction, ITVShow } from 'api/CatalogAPI';
import Input from 'components/Input';
import LoggedInPageWrapper from 'containers/LoggedInPageWrapper';
import ShowTable from 'containers/pages/Dashboard/Shows/ShowTable';
import escapeRegexp from 'helpers/escapeRegexp';
import { WatchedShowStore } from 'stores/shows/ShowStore';

const SCSearch = styled(Input)`
  @media(max-width: 1000px) {
    margin-bottom:10px;

    input {
      height: 36px;
    }
  }
`;


const Shows: React.FC = () => {
  const { column, direction } = useParams();
  const history = useHistory();
  const [search, setSearch] = React.useState('');

  const shows = WatchedShowStore.usePaginatedData({ column, direction });
  const filteredShows = React.useMemo(
    () =>
      shows?.items?.filter((show) => {
        const lowerCaseSearch = search.toLowerCase();
        return show.media.name?.toLowerCase().match(escapeRegexp(lowerCaseSearch));
      }),
    [shows, search],
  );

  useEffectOnce(() => {
    if (shows.isLoaded) WatchedShowStore.fetchAction.clearAllCache();
  });

  const onChange = (column: Column, direction: Direction) => {
    history.push(`/dashboard/shows/watched/${column}/${direction}`);
  };

  return (
    <LoggedInPageWrapper bg='white' title='My Shows' isLoading={shows.isLoading}>
      <SCSearch type='search' value={search} onChange={setSearch} placeholder='Search My Shows' fullWidth={true} />
      {filteredShows.length ? (
        <ShowTable
          sort={{ onChange, column, direction }}
          type='watched'
          useMyRatings
          shows={
            filteredShows.map((m) => ({
              ...m.media,
              watched: { ...m.media.watched, updated_at: m.updated_at },
            })) as ITVShow[]
          }
          hasMorePages={shows.hasMorePages}
          loadMore={shows.loadNextPage}
          isUpdating={shows.isUpdating}
        />
      ) : (
        shows.items.length > 0 
          ? <p>No shows match your search</p>
          : <p>No shows yet</p>
      )}
    </LoggedInPageWrapper>
  );
};

export default Shows;
