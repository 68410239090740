import React from 'react';
import { useHistory } from 'react-router-dom';

import AuthAPI from 'api/AuthAPI';
import ForgotPasswordRequestForm from 'components/ForgotPasswordRequestForm';
import LoginPageWrapper from 'components/LoginPageWrapper';

const ForgotPasswordRequest: React.FC = () => {
  const history = useHistory();

  const [error, setError] = React.useState('');

  const [isLoading, setIsLoading] = React.useState(false);

  const onResetPassword = async (email: string) => {
    setIsLoading(true);
    try {
      const json = await AuthAPI.passwordResetRequest(email);
      if (!json.success) throw new Error(json.error.message);

      history.push(`/forgot/request/${email}`);
      return;
    } catch (err) {
      setError(err.message);
    }
    setIsLoading(false);
  };

  const onCancel = () => {
    history.push('/login');
  };

  return (
    <LoginPageWrapper>
      <ForgotPasswordRequestForm
        onCancel={onCancel}
        onSubmit={onResetPassword}
        error={error}
        isLoading={isLoading}
      />
    </LoginPageWrapper>
  );
};

export default ForgotPasswordRequest;
