import React from 'react';

import Footer from 'components/Footer';
import Theme from 'components/Theme';
import LoggedOutHeader from 'containers/LoggedOutHeader';

import CreateAccountSection from './CreateAccountSection';
import DiscoverSection from './DiscoverSection';
import DiscussSection from './DiscussSection';
import HeroSection from './HeroSection';
import TrackSection from './TrackSection';

const Home: React.FC = () => {
  return (
    <Theme theme='dark'>
      <LoggedOutHeader />
      <HeroSection />
      <DiscoverSection />
      <DiscussSection />
      <TrackSection />
      <CreateAccountSection />
      <Footer />
    </Theme>
  );
};

export default Home;
