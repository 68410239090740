import React from 'react';
import styled from 'styled-components';

import { IUserData } from 'api/ProfileAPI';
import Link from 'components/Link';
import SCAvatar from 'components/styled/SCAvatar';
import SCButton from 'components/styled/SCButton';
import SCHideForMobile from 'components/styled/SCHideForMobile';
import SCResultsTable from 'components/styled/SCResultsTable';
import SCShowForMobile from 'components/styled/SCShowForMobile';
import UserRow from 'containers/pages/Dashboard/Friends/UserRow';
import { startFollowingAction } from 'stores/FollowStore';

const SCResultsTableModified = styled(SCResultsTable)`
tr {
  th,
  td {
    &:nth-child(2) {
      width: auto;
      padding: 10px 20px;
    }
    &:nth-child(4) {
      width: 100%;
    }
  }
  td {
    &:last-of-type {
      text-align: center;
    }
  }
}`;

const SCLink = styled(Link)`
    color: white;
`;

const UserList: React.FC<{ users: IUserData[] }> = ({ users }) => {
  const [requestsSent, setRequestsSent] = React.useState<{ [id: number]: boolean }>({});

  if (users.length === 0) return <p>No matching friends.</p>;

  const sendFollowRequest = (userId: number) => {
    startFollowingAction.run({ userId });
    setRequestsSent((requestsSent) => ({
      ...requestsSent,
      [userId]: true,
    }));
  };

  return (
    <>
      <SCHideForMobile>
        <SCResultsTableModified cellSpacing={0} cellPadding={0}>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>
                  <SCAvatar variant='comment' imageUrl={user.avatar} />
                </td>
                <td>
                  <SCLink href={`/dashboard/user/${user.id}`}>@{user.username}</SCLink>
                </td>
                <td>{user.first_name ?? 'N/A'}</td>
                <td>{user.last_name ?? 'N/A'}</td>
                <td>
                  {requestsSent[user.id] == null ? (
                    <SCButton
                      disabled={requestsSent[user.id]}
                      onClick={() => sendFollowRequest(user.id)}>
                      Follow
                    </SCButton>
                  ) : (
                    'Following'
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </SCResultsTableModified>
      </SCHideForMobile>
      <SCShowForMobile>
        {users.map((user) => (
          <UserRow
            key={user.id}
            buttonAction={() => sendFollowRequest(user.id)}
            buttonText={requestsSent[user.id] ? 'Following'  :'Follow'}
            disabled={requestsSent[user.id]}
            textColor='#fff'
            user={user}
          />
        ))}
      </SCShowForMobile>
    </>
  );
};

export default UserList;
