import React from 'react';
import { useHistory } from 'react-router-dom';

import { ITVShow } from 'api/CatalogAPI';
import LoginPageWrapper from 'components/LoginPageWrapper';
import {
  SCAddButton,
  SCBox,
  SCCarousel,
  SCCarouselItem,
  SCDontWorry,
  SCMediaTitle,
  SCNextButton,
  SCPoster,
  SCSkipButton,
  SCSpinner,
  SCStep,
} from 'containers/pages/register/shared';
import {
  RecommendedShowStore,
  RegistrationSelectedShowStore,
  updatedSelectedShowsAction,
} from 'stores/shows/ShowStore';

const Show: React.FC<{ show: ITVShow }> = ({ show }) => {
  const poster = show.images.poster.w342;
  const [hasStatus, setHasStatus] = React.useState(Boolean(Object.keys(show.watched ?? {}).length));

  const handleClick = () => {
    const newStatus = !hasStatus;
    setHasStatus(newStatus);
    updatedSelectedShowsAction(show, newStatus);
  };

  return (
    <SCCarouselItem>
      <SCPoster onClick={handleClick} imageUrl={poster}>
        <SCAddButton hasStatus={hasStatus}>
          {hasStatus ? <span className='fas fa-check' /> : <span className='fas fa-plus' />}
        </SCAddButton>
      </SCPoster>
      <SCMediaTitle>{show.name}</SCMediaTitle>
    </SCCarouselItem>
  );
};

const PostRegisterStep1: React.FC = () => {
  const history = useHistory();
  const recommendedShows = RecommendedShowStore.usePaginatedData();
  const selectedShows = RegistrationSelectedShowStore.useState();

  return (
    <LoginPageWrapper>
      <SCBox>
        <SCStep>Step 1 of 3</SCStep>
        <h1>Create Account</h1>
        <h2>What are some of your favorite shows?</h2>
        <h3>This will help us improve your recommendations.</h3>
        {recommendedShows.isLoaded ? (
          <SCCarousel>
            {recommendedShows.items.map((show) => (
              <Show key={show.id} show={show} />
            ))}
          </SCCarousel>
        ) : (
          <SCSpinner />
        )}
        <SCNextButton
          disabled={selectedShows.length === 0}
          onClick={() => history.push('/register/2')}>
          Next
        </SCNextButton>
        <SCSkipButton to='/register/3'>Skip</SCSkipButton>
        <SCDontWorry>Don&apos;t worry, you can add more later.</SCDontWorry>
      </SCBox>
    </LoginPageWrapper>
  );
};

export default PostRegisterStep1;
