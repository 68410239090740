import React from 'react';
import MaxWidthWrapper from '../../../components/MaxWidthWrapper';
import DiscussPreviewImage from './img/DiscussPreview.png';
import SCCTAButton from './SCCTAButton';
import styled from 'styled-components';
import SCSection from './SCSection';
import SCHideForMobile from '../../../components/styled/SCHideForMobile';
import IPhoneImage from './img/iPhone2.png';
import SCShowForMobile from 'components/styled/SCShowForMobile';

const SCDiscussContent = styled.div`
  position: absolute;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);
  max-width: 410px;

  > h1 {
    font-size: 75px;
  }

  @media (max-width: 1000px) {
    position: relative;
    right: 0;
    top: 0;
    transform: none;
    max-width: none;
    text-align: center;
    padding: 60px 20px;

    > h1 {
      margin-top: 0;
      font-size: 30px;
    }

    > h2 {
      font-size: 16px;
      font-weight: normal;
    }
  }
`;

const SCIPhoneImage = styled.img.attrs({ src: IPhoneImage, alt: 'iPhone' })`
  margin-top: 40px;
`;

const SCDiscussSection = styled.div`
  height: 785px;
  position: relative;

  @media (max-width: 1000px) {
    height: auto;
  }
`;

const SCPreviewImage = styled.img.attrs({ src: DiscussPreviewImage, alt: 'Discuss' })`
  bottom: 0;
  left: 60px;
  position: absolute;

  @media (max-width: 1235px) {
    left: auto;
    right: 565px;
  }
`;

const DiscussSection: React.FC = () => {
  return (
    <SCSection>
      <MaxWidthWrapper>
        <SCDiscussSection>
          <SCHideForMobile>
            <SCPreviewImage />
          </SCHideForMobile>
          <SCDiscussContent>
            <h1>Join and create binger communities.</h1>
            <h2>
              Relive unforgettable on-screen moments in public and private binger communities. Dive
              deep into your 90s family TV comedy obsession with the largest ever gathering of
              TGIF-fanatics. In the BingeRoom, every sub-genre has its loyal army of bingers.
            </h2>
            <SCCTAButton href='/register'>Browse Communities</SCCTAButton>
            <SCShowForMobile>
              <SCIPhoneImage />
            </SCShowForMobile>
          </SCDiscussContent>
        </SCDiscussSection>
      </MaxWidthWrapper>
    </SCSection>
  );
};

export default DiscussSection;
