import { rgba } from 'polished';
import styled from 'styled-components';

export default styled.table`
  font-size: 20px;
  text-align: left;
  width: 100%;

  tr {
    th,
    td {
      padding: 10px 40px;

      &:first-child {
        padding: 10px 20px;
      }

      &:nth-child(2) {
        width: 100%;
        padding: 10px 20px;
      }
    }

    th {
      white-space: nowrap;

      &.clickable {
        cursor: pointer;
      }
    }

    td {
      vertical-align: top;
      font-size: 16px;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
        text-align: right;
      }

      img {
        display: block;
      }

      p {
        margin: 10px 0;
        &:first-child {
          margin-top: 0;
        }
      }
    }

    &:not(:first-of-type) {
      td {
        border-top: 1px solid ${rgba('white', 0.15)};
      }
    }
  }
`;
