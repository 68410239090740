import useOnClickOutside from 'hooks/useOnClickOutside';
import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Link from 'components/Link';
import SCNavLink from 'components/styled/SCNavLink';
import variables from 'components/styled/variables';

const MENU_WIDTH = '200px';

const SCTrigger = styled(SCNavLink)<{ isMenuShowing: boolean; isActive: boolean }>`
  cursor: pointer;
  margin-left: 35px;
  padding-left: 5px;
  position: relative;
  width: ${MENU_WIDTH};
  background-color: ${(props) =>
    props.isMenuShowing ? variables.colors.background.dropdown : null};
  color: ${(props) => (props.isMenuShowing ? 'black' : props.isActive ? 'white' : null)};
`;

const SCMenu = styled.nav`
  background-color: ${variables.colors.background.dropdown};
  box-sizing: border-box;
  cursor: default;
  left: 0;
  padding: 5px 0;
  position: absolute;
  text-indent: 10px;
  top: 100%;
  width: ${MENU_WIDTH};
  z-index: 100;
  color: black;
`;

const SCMenuHeading = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
`;

const SCMenuItem = styled(Link)`
  color: black;
  display: block;
  font-size: 16px;
  font-weight: normal;
  text-indent: 20px;

  &:hover {
    background: $link-color;
    color: white;
  }
`;

const HeaderBrowseMenu: React.FC = () => {
  const { pathname } = useLocation();
  const href = '/dashboard/browse';

  const [isMenuShowing, setIsMenuShowing] = React.useState(false);

  const onClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsMenuShowing((v) => !v);
  };

  useOnClickOutside(() => setIsMenuShowing(false));

  return (
    <SCTrigger
      as='button'
      isMenuShowing={isMenuShowing}
      isActive={pathname.indexOf(href) === 0}
      onClick={onClick}>
      Browse <i className='fas fa-caret-down' />
      {isMenuShowing && (
        <SCMenu onClick={(e) => e.stopPropagation()}>
          <SCMenuHeading>TV Shows</SCMenuHeading>
          <SCMenuItem href='/dashboard/browse/tv/new' onClick={() => setIsMenuShowing(false)}>
            New Releases
          </SCMenuItem>
          <SCMenuItem href='/dashboard/browse/tv/all' onClick={() => setIsMenuShowing(false)}>
            All
          </SCMenuItem>
          <SCMenuHeading>Movies</SCMenuHeading>
          <SCMenuItem href='/dashboard/browse/movies/new' onClick={() => setIsMenuShowing(false)}>
            New Releases
          </SCMenuItem>
          <SCMenuItem href='/dashboard/browse/movies/all' onClick={() => setIsMenuShowing(false)}>
            All
          </SCMenuItem>
        </SCMenu>
      )}
    </SCTrigger>
  );
};

export default HeaderBrowseMenu;
