import React from 'react';

import { fetchCurrentUser, useAuthToken, useCurrentUser } from 'stores/auth/CurrentUserStore';

import AppRouter from './AppRouter';

const App: React.FC = () => {
  const token = useAuthToken();
  const currentUser = useCurrentUser();

  React.useEffect(() => {
    if (token && !currentUser) {
      fetchCurrentUser.run();
    }
  }, [currentUser, token]);

  return (
    <>
      <AppRouter />
    </>
  );
};

export default App;
