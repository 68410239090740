import React from 'react';
import styled from 'styled-components';

import Footer from 'components/Footer';
import MaxWidthWrapper from 'components/MaxWidthWrapper';
import Theme from 'components/Theme';
import GenericHeader from 'containers/GenericHeader';

const SCContent = styled.div`
  background: white;
  color: black;
  border-radius: 10px;
  padding: 20px 60px;
  margin: 0 60px;

  @media (max-width: 1000px) {
    padding: 20px;
    margin: 0 20px;
  }
`;

const ContactUs: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Theme theme='dark'>
      <GenericHeader />
      <MaxWidthWrapper>
        <SCContent>
          <h1>Contact Us</h1>
          <p>
            If you have any questions or issues with the site, please email the BingeRoom team at{' '}
            <a href='mailto:Support@BingeRoom.com'>Support@BingeRoom.com</a>.
          </p>
          <p>
            BingeRoom’s Copyright Agent for notice of claims of copyright infringement on its site
            can be reached as follows:
            <br />
            BingeRoom Copyright Agent
            <br />
            130 Washington Street
            <br />
            P.O. Box 211
            <br />
            Rocky Hill, NJ 08553
            <br />
            Email: <a href='mailto:Copyright@BingeRoom.com'>Copyright@BingeRoom.com</a>
          </p>
          <p>
            Please note that this procedure is exclusively for notifying BingeRoom and its
            affiliates that your copyrighted material has been infringed.
          </p>
          <p>
            Lastly, you can always send mail to:
            <br />
            BingeRoom, LLC
            <br />
            130 Washington Street
            <br />
            P.O. Box 211
            <br />
            Rocky Hill, NJ 08553
          </p>
        </SCContent>
      </MaxWidthWrapper>
      <Footer />
    </Theme>
  );
};

export default ContactUs;
