import { createAsyncAction, errorResult, successResult } from 'pullstate';
import React from 'react';
import { useHistory } from 'react-router-dom';

import AuthAPI from 'api/AuthAPI';
import LoginForm from 'components/LoginForm';
import LoginPageWrapper from 'components/LoginPageWrapper';
import { CurrentUserStore } from 'stores/auth/CurrentUserStore';

const login = createAsyncAction<{ email: string; password: string }, { token: string }>(
  async ({ email, password }) => {
    try {
      const json = await AuthAPI.login(email, password);
      if (!json.success) throw new Error(json.error.message);

      return successResult(json.response);
    } catch (err) {
      return errorResult([], err.message);
    }
  },
  {
    postActionHook({ result }) {
      if (result.error) return;
      CurrentUserStore.update((s) => {
        s.token = result.payload.token;
      });
    },
  },
);

const Login: React.FC = () => {
  const history = useHistory();

  const [error, setError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const onLogin = async (email: string, password: string) => {
    setIsLoading(true);
    const response = await login.run({ email, password });
    if (response.error) {
      setError(true);
      setIsLoading(false);
    }
  };

  const onForgotPassword = () => history.push('/forgot');

  return (
    <LoginPageWrapper>
      <LoginForm
        onSubmit={onLogin}
        onForgotPassword={onForgotPassword}
        error={error}
        isLoading={isLoading}
      />
    </LoginPageWrapper>
  );
};

export default Login;
