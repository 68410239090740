import React from 'react';
import SCSection from './SCSection';
import MaxWidthWrapper from '../../../components/MaxWidthWrapper';
import SCHideForMobile from '../../../components/styled/SCHideForMobile';
import IPadImage from './img/iPad.png';
import SCCTAButton from './SCCTAButton';
import SCShowForMobile from '../../../components/styled/SCShowForMobile';
import IPhoneImage from './img/iPhone.png';
import styled from 'styled-components';

const LEFT_EDGE = 663;
const SCIpadImage = styled.img.attrs({ src: IPadImage, alt: 'iPad' })`
  right: 60px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;

  @media (max-width: 1270px) {
    left: ${LEFT_EDGE + 40}px;
    transform: translateY(-50%);
  }
`;

const SCIphoneImage = styled.img.attrs({ src: IPhoneImage, alt: 'iPhone' })`
  margin-top: 40px;
`;

const SCHeroSection = styled.div`
  min-height: 750px;
  overflow: hidden;
  position: relative;
  padding: 0 0 0 60px;

  > h1 {
    max-width: 500px;
  }

  > h2 {
    max-width: 500px;
  }

  @media (max-width: 1000px) {
    min-height: none;
    text-align: center;
    padding: 60px 20px;

    h1 {
      font-size: 60px;
      margin-top: 0;
      padding: 0 20px;
    }

    h2 {
      font-size: 16px;
      font-weight: normal;
    }
  }
`;

const HeroSection: React.FC = () => {
  return (
    <SCSection>
      <MaxWidthWrapper>
        <SCHeroSection>
          <SCHideForMobile>
            <SCIpadImage />
          </SCHideForMobile>
          <h1>The social side of streaming.</h1>
          <h2>
            Welcome to BingeRoom, where your most binge-worthy shows and movies create unexpected
            connections.
          </h2>
          <SCCTAButton href='/register'>Create My Room</SCCTAButton>
          <SCShowForMobile>
            <SCIphoneImage />
          </SCShowForMobile>
        </SCHeroSection>
      </MaxWidthWrapper>
    </SCSection>
  );
};

export default HeroSection;
