import SCHideForMobile from '../../../../components/styled/SCHideForMobile';
import SCShowForMobile from '../../../../components/styled/SCShowForMobile';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { Column, Direction, ITVShow } from 'api/CatalogAPI';
import DefaultPoster from 'components/DefaultPoster';
import Link from 'components/Link';
import Rating from 'components/Rating';
import SCButton from 'components/styled/SCButton';
import SCResultsTable from 'components/styled/SCResultsTable';
import SCShowMoreButtonWrapper from 'components/styled/SCShowMoreButtonWrapper';
import { ConfirmRemoveShowModal, displayConfirmRemoveShowModal } from 'containers/modals/ConfirmRemoveMediaModal';
import { displayEditShowModal } from 'containers/modals/EditShowModal';
import { setShowRatingAction } from 'stores/shows/ShowStore';

const SCNetworkWrapper = styled.div`
  & + & {
    margin-top: 5px;
  }

  @media (max-width: 1000px) {
    img {
      max-height: 40px;
    }
  }
`;

const SCGenre = styled.div`
  white-space: nowrap;
`;

const SCMobileShow = styled.div`
  display: flex;
  padding: 10px 0;

  & + & {
    border-top: 1px solid #c4c4c4;
  }
`;

const SCMobilePosterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-right: 10px;
`;

const SCMobileShowDetails = styled.div`
  h1 {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }

  h2 {
    font-size: 16px;
    font-weight: normal;
    margin: 0 0 10px;
  }
`;

const SCMobileNetworkWrapper = styled.div`
  display: flex;
  margin: 0 -10px -10px 0;
  align-items: center;
`;

const SCMobileNetwork = styled.div`
  margin: 0 10px 10px 0;
`;

const SCMobileRatingWrapper = styled.div`
  padding: 10px 0 0;
`;

const capitalizeGenre = (genre: string): string => {
  const words = genre.split(/ /g);
  return words.map((w) => w.charAt(0).toUpperCase() + w.substr(1)).join(' ');
};

const Arrow: React.FC<{ sort?: Sort; column: Column }> = ({ sort, column }) => {
  if (sort?.column !== column) return null;

  return (
    <>
      &nbsp;
      <i className={`fas fa-caret-${sort.direction === 'asc' ? 'up' : 'down'}`} />
    </>
  );
};

type Sort = {
  onChange: (column: Column, direction: Direction) => void;
  column: Column;
  direction: Direction;
};

interface IProps {
  type: 'browse' | 'watched';
  shows: ITVShow[];
  hasMorePages: boolean;
  loadMore: () => void;
  isUpdating: boolean;
  useMyRatings?: boolean;
  sort?: Sort;
}

const ShowTable: React.FC<IProps> = ({
  type,
  shows,
  hasMorePages,
  loadMore,
  isUpdating,
  useMyRatings,
  sort,
}) => {
  const setRating = (show: ITVShow, rating: number) => {
    setShowRatingAction.run({ showId: show.id, rating });
  };

  const includeDateAddedColumn = type === 'watched';

  const getOnClick = (column: Column) => {
    if (sort == null) return undefined;

    const invertDirection = (direction: Direction): Direction =>
      direction === 'asc' ? 'desc' : 'asc';
    const getDefaultDirection = (): Direction => {
      switch (column) {
        case 'name':
          return 'asc';
        default:
          return 'desc';
      }
    };

    return () =>
      sort.onChange(
        column,
        sort.column === column ? invertDirection(sort.direction) : getDefaultDirection(),
      );
  };

  return (
    <>
      <SCHideForMobile>
        <SCResultsTable cellSpacing={0} cellPadding={0}>
          <thead>
            <tr>
              <th>{/* Poster */}</th>
              <th className={classNames({ clickable: sort != null })} onClick={getOnClick('name')}>
                Title
                <Arrow sort={sort} column='name' />
              </th>
              <th className={classNames({ clickable: sort != null })} onClick={getOnClick('genre')}>
                Genre
                <Arrow sort={sort} column='genre' />
              </th>
              <th
                className={classNames({ clickable: sort != null })}
                onClick={getOnClick('network')}>
                Network
                <Arrow sort={sort} column='network' />
              </th>
              <th
                className={classNames({ clickable: sort != null })}
                onClick={getOnClick('rating')}>
                {useMyRatings ? 'My Rating' : 'Avg Rating'}
                <Arrow sort={sort} column='rating' />
              </th>
              {includeDateAddedColumn && (
                <th
                  className={classNames({ clickable: sort != null })}
                  onClick={getOnClick('date')}>
                  Date Added
                  <Arrow sort={sort} column='date' />
                </th>
              )}
              <th>{/* Actions */}</th>
            </tr>
          </thead>
          <tbody>
            {shows.map((show) => (
              <tr key={show.id}>
                <td>
                  <Link href={`/dashboard/shows/details/${show.id}`}>
                    {show.images.poster.w92 ? (
                      <img src={show.images.poster.w92} alt={show.name} />
                    ) : (
                      <DefaultPoster imageKey='w92' />
                    )}
                  </Link>
                </td>
                <td>
                  <Link href={`/dashboard/shows/details/${show.id}`}>{show.name}</Link>
                </td>
                <td>
                  {show.genres?.map((genre) => (
                    <SCGenre key={genre}>{capitalizeGenre(genre)}</SCGenre>
                  ))}
                </td>
                <td>
                  {show.networks.map((network) => (
                    <SCNetworkWrapper key={network.name}>
                      {network.logo.w92 != null ? (
                        <img src={network.logo.w92} alt={network.name} />
                      ) : (
                        network.name
                      )}
                    </SCNetworkWrapper>
                  ))}
                </td>
                <td>
                  {useMyRatings ? (
                    <Rating
                      value={show.rating?.value}
                      onChange={(value) => setRating(show, value)}
                    />
                  ) : (
                    <Rating value={show.vote_average} />
                  )}
                </td>
                {includeDateAddedColumn && (
                  <td>{moment(show.watched?.updated_at).format('MM/DD/YYYY')}</td>
                )}
                <td>
                  <p>
                    <Link onClick={() => displayEditShowModal(show.id)}>Edit</Link>
                  </p>
                  {type === 'watched' && (
                    <p>
                      <Link onClick={() => displayConfirmRemoveShowModal(show.id)}>Remove</Link>
                    </p>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </SCResultsTable>
      </SCHideForMobile>
      <SCShowForMobile>
        {shows.map((show) => (
          <SCMobileShow key={show.id}>
            <SCMobilePosterWrapper>
              <Link href={`/dashboard/shows/details/${show.id}`}>
                {show.images.poster.w92 ? (
                  <img src={show.images.poster.w92} alt={show.name} />
                ) : (
                  <DefaultPoster imageKey='w92' />
                )}
              </Link>
              <Link href={`/dashboard/shows/details/${show.id}`}>Edit</Link>
              {type === 'watched' && <Link onClick={() => displayConfirmRemoveShowModal(show.id)}>Remove</Link>}
            </SCMobilePosterWrapper>
            <SCMobileShowDetails>
              <h1>{show.name}</h1>
              <h2>{show.genres?.map(capitalizeGenre).join(', ')}</h2>
              <SCMobileNetworkWrapper>
                {show.networks.map((network) => (
                  <SCNetworkWrapper key={network.name}>
                    <SCMobileNetwork>
                      {network.logo.w92 != null ? (
                        <img src={network.logo.w92} alt={network.name} />
                      ) : (
                        network.name
                      )}
                    </SCMobileNetwork>
                  </SCNetworkWrapper>
                ))}
              </SCMobileNetworkWrapper>
              <SCMobileRatingWrapper>
                {useMyRatings ? (
                  <Rating value={show.rating?.value} onChange={(value) => setRating(show, value)} />
                ) : (
                  <Rating value={show.vote_average} />
                )}
              </SCMobileRatingWrapper>
              {includeDateAddedColumn && (
                <p>{moment(show.watched?.updated_at).format('MM/DD/YYYY')}</p>
              )}
            </SCMobileShowDetails>
          </SCMobileShow>
        ))}
      </SCShowForMobile>
      {hasMorePages && (
        <SCShowMoreButtonWrapper>
          <SCButton disabled={isUpdating} onClick={loadMore}>
            Show More
          </SCButton>
        </SCShowMoreButtonWrapper>
      )}
      <ConfirmRemoveShowModal />
    </>
  );
};

export default ShowTable;
