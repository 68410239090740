import { Styles } from 'react-select';

export default (dark = true) =>
  ({
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'rgba(210, 206, 188, 0.2)',
      color: dark ? 'white' : 'black',
      minHeight: 60,
      border: 0,
      borderRadius: 10,
      fontFamily: "'Helvetica Neue', 'Segoe UI', sans-serif",
      fontSize: '16px',
      boxShadow: state.isFocused ? `0 0 0 1px ${dark ? 'white' : 'black'}` : 'none',
    }),
    input: (provided) => ({
      ...provided,
      color: dark ? 'white' : 'black',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 20px',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: dark ? '#53524E' : '#F6F5F2',
      color: dark ? 'white' : 'black',
      borderRadius: 10,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: '10px 0',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? dark
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(0,0,0,0.1)'
        : 'transparent',
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      backgroundColor: '#c4c4c4',
      color: 'black',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      backgroundColor: '#c4c4c4',
      color: '#9c171a',
    }),
    clearIndicator: (provided) => ({ display: 'none' }),
    dropdownIndicator: (provided) => ({ display: 'none' }),
    indicatorSeparator: (provided) => ({ display: 'none' }),
  } as Styles);
