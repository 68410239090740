import { Store } from 'pullstate';

import CatalogAPI, {
  Column,
  Direction,
  ITVShow,
  ITVShowSeason,
  IWatchedTVShow,
  TWatchedStatus,
} from 'api/CatalogAPI';
import ProfileAPI from 'api/ProfileAPI';
import {
  createGenericAsyncAction,
  createPaginatedLookupStore,
  createPaginationStore,
} from 'stores/index';
import { SearchAllStore } from 'stores/SearchStore';

export interface ITVShowWrapper {
  show: ITVShow;
  seasons?: ITVShowSeason[];
}

export const ShowStore = new Store<{ [key: string]: ITVShowWrapper }>({});

ShowStore.createReaction(
  (s) => s,
  (showStore) => {
    RecommendedShowStore.store.update((sourceStore) => {
      sourceStore.items = sourceStore.items.map((item) => showStore[item.id]?.show ?? item);
    });
    BrowseAllShowsStore.store.update((sourceStore) => {
      for (const letter in sourceStore) {
        sourceStore[letter].items = sourceStore[letter].items.map(
          (item) => showStore[item.id]?.show ?? item,
        );
      }
    });
    WatchedShowStore.store.update((sourceStore) => {
      for (const key in sourceStore) {
        sourceStore[key].items = sourceStore[key].items.map((item) => {
          const match = showStore[item.media.id];
          return match == null ? item : { ...item, media: match.show };
        });
      }
    });
    SearchAllStore.store.update((sourceStore) => {
      for (const key in sourceStore) {
        sourceStore[key].items = sourceStore[key].items.map((item) =>
          item.model === 'tv_show' ? showStore[item.id]?.show ?? item : item,
        );
      }
    });
    UserRecommendedShowStore.store.update((sourceStore) => {
      for (const key in sourceStore) {
        sourceStore[key].items = sourceStore[key].items.map((item) => {
          const match = showStore[item.media.id];
          return match == null ? item : { ...item, media: match.show };
        });
      }
    });
  },
);

const updateShowStoreFromShowArray = (shows: ITVShow[]) => {
  ShowStore.update((store) => {
    shows.forEach((show) => {
      const match = store[show.id];
      if (match == null) store[show.id] = { show: show };
      else match.show = show;
    });
  });
};

export const WatchedShowStore = createPaginatedLookupStore<
  { column: Column; direction: Direction },
  IWatchedTVShow
>(
  ({ column, direction }, page) => CatalogAPI.fetchWatchedTVShows(column, direction, page),
  ({ column, direction }) => `${column}/${direction}`,
  (action, response) => updateShowStoreFromShowArray(response.items.map(({ media }) => media)),
);

export const RecommendedShowStore = createPaginationStore<undefined, ITVShow>(
  () => CatalogAPI.fetchRecommendedTVShowList(),
  (action, response) => updateShowStoreFromShowArray(response.items),
);

export const BrowseAllShowsStore = createPaginatedLookupStore<{ letter: string }, ITVShow>(
  ({ letter }, page) => CatalogAPI.fetchTVShowListByLetter(letter, page),
  ({ letter }) => letter,
  (action, response) => updateShowStoreFromShowArray(response.items),
);

type TRegistrationSelectedShowStore = ITVShow[];

export const RegistrationSelectedShowStore = new Store<TRegistrationSelectedShowStore>([]);

export const setShowRatingAction = createGenericAsyncAction<
  { showId: number; rating: number },
  undefined
>(
  ({ showId, rating }) => CatalogAPI.submitTVShowRating(showId, rating),
  undefined,
  ({ showId, rating }) => {
    ShowStore.update((s) => {
      const show = s[showId]?.show;
      if (!show) return;
      show.rating = {
        value: rating,
        updated_at: new Date().valueOf(),
      };
    });
  },
);

export const BrowseNewShowsStore = createPaginationStore<undefined, ITVShow>(
  (_, page) => CatalogAPI.fetchTVShowList('newest', page),
  (_, response) => updateShowStoreFromShowArray(response.items),
);

export const fetchShowAction = createGenericAsyncAction<{ showId: number }, ITVShowWrapper>(
  async ({ showId }) => {
    const json = await CatalogAPI.fetchTVShow(showId);
    if (!json.success) throw new Error(json.error.message);

    const seasonsJson = await CatalogAPI.fetchTVShowSeasonList(showId);
    if (!seasonsJson.success) throw new Error(seasonsJson.error.message);

    return {
      success: true,
      response: { show: json.response, seasons: seasonsJson.response },
    };
  },
  ({ showId }, show) =>
    ShowStore.update((s) => {
      s[showId] = show;
    }),
);

export const setEpisodeWatchedStatus = createGenericAsyncAction<
  { showId: number; episodeKeys: string[]; watched: boolean },
  undefined
>(
  ({ showId, episodeKeys, watched }) =>
    watched
      ? CatalogAPI.submitTVShowEpisodeWatchedStatus(
        showId,
        episodeKeys.map((key) => ({ key, status: 'watched' })),
      )
      : CatalogAPI.removeTVShowWatchedStatus(
        showId,
        episodeKeys.map((key) => ({ key })),
      ),
  undefined,
  ({ showId, episodeKeys, watched }) => {
    ShowStore.update((s) => {
      const show = s[showId]?.show;
      if (!show) return;

      if (!watched) {
        episodeKeys.forEach((key) => {
          if (show.watched == null) return;
          delete show.watched[key];
        });
        return;
      }

      episodeKeys.forEach((key) => {
        show.watched = show.watched ?? {};
        show.watched[key] = {
          status: 'watched',
          updated_at: new Date().valueOf(),
        };
      });
    });
  },
);

export const setShowRecommendationAction = createGenericAsyncAction<
  { showId: number; recommended: boolean },
  undefined
>(
  ({ showId, recommended }) => CatalogAPI.submitTVShowRecommendation(showId, recommended),
  undefined,
  ({ showId, recommended }) =>
    ShowStore.update((s) => {
      const show = s[showId]?.show;
      if (!show) return;
      if (recommended) show.recommendation = { updated_at: new Date().toISOString() };
      else delete show.recommendation;
    }),
);

export const clearShowWantToWatchStatusAction = createGenericAsyncAction<
  { showId: number },
  undefined
>(
  ({ showId }) => CatalogAPI.removeWantToWatch(showId),
  undefined,
  ({ showId }) => {
    WatchedShowStore.store.update((store) => {
      for (const key in store) {
        store[key].items = store[key].items.filter((item) => item.media.id !== showId);
      }
    });
    ShowStore.update((s) => {
      const show = s[showId]?.show;
      if (!show) return;

      if (show.watched == null) return;
      delete show.watched.status;
    });
    WatchedShowStore.fetchAction.clearAllCache();
    WatchedShowStore.fetchNextAction.clearAllCache();
  },
);

export const clearShowWatchedStatusAction = createGenericAsyncAction<{ showId: number }, undefined>(
  ({ showId }) => CatalogAPI.removeWatchedStatus(showId),
  undefined,
  ({ showId }) => {
    ShowStore.update((s) => {
      const show = s[showId]?.show;
      if (!show) return;

      if (show.watched == null) return;
      delete show.watched.status;
    });
    WatchedShowStore.store.update((store) => {
      for (const key in store) {
        store[key].items = store[key].items.filter((item) => item.media.id !== showId);
      }
    });
  },
);

export const setShowWatchedStatusAction = createGenericAsyncAction<
  { showId: number; status: TWatchedStatus },
  undefined
>(
  ({ showId, status }) => CatalogAPI.submitTVShowWatchedStatus(showId, status),
  undefined,
  ({ showId, status }) => {
    ShowStore.update((s) => {
      const show = s[showId]?.show;
      if (!show) return;

      if (!status) {
        if (show.watched == null) return;
        delete show.watched.status;
        return;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      show.watched = {
        ...(show.watched ?? {}),
        status: status as TWatchedStatus,
      };
    });
  },
);

export const updatedSelectedShowsAction = (show: ITVShow, selected: boolean): void => {
  if (selected) setShowWatchedStatusAction.run({ showId: show.id, status: 'watched' });
  else clearShowWatchedStatusAction.run({ showId: show.id });
  RegistrationSelectedShowStore.update((s) => {
    return selected ? [...s, show] : s.filter(({ id }) => id !== show.id);
  });
};

export const UserRecommendedShowStore = createPaginatedLookupStore<
  { userId: number },
  IWatchedTVShow
>(
  ({ userId }, page) => ProfileAPI.fetchUserRecommendedShows(userId, page),
  ({ userId }) => userId.toString(),
  (_, response) => {
    response.items.forEach((show) => {
      ShowStore.update((s) => {
        const match = s[show.media.id] ?? {};
        s[show.media.id] = { ...match, show: show.media };
      });
    });
  },
);
