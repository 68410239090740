import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { IGroup } from 'api/GroupsAPI';
import Carousel, { CarouselItem } from 'components/Carousel';
import Link from 'components/Link';
import getImageUrlString from 'helpers/getImageUrlString';
import DefaultGroupAvatar from 'img/DefaultGroupAvatar.png';

const MOBILE_SCALE = 0.6;
const MOBILE_SIZE = 375 - 40 - 40;

const SCCarousel = styled(Carousel)`
  margin: 0 -60px;
  padding: 0 60px;

  @media (max-width: 1000px) {
    margin: 0 -20px;
    padding: 0 20px;
  }
`;

const SCCarouselItem = styled(CarouselItem)`
  padding-right: 20px;
`;

const SCCarouselItemLink = styled(Link)`
  cursor: pointer;
  display: flex;
  background-color: #141414;
`;

const SCWrapper = styled.div`
  color: ${(props) => (props.theme.dark ? 'white' : 'black')};

  &::before,
  &::after {
    clear: both;
    content: '';
    display: table;
  }
`;

const SCGroupDetails = styled.div`
  width: 420px;
  box-sizing: border-box;
  padding: 20px;
  color: white;

  h1 {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 10px;
  }

  p {
    color: ${rgba('white', 0.5)};
  }

  @media (max-width: 1000px) {
    width: ${MOBILE_SIZE - MOBILE_SCALE * 190}px;
    height: ${MOBILE_SCALE * 230}px;
    overflow: hidden;
    padding: 10px;

    h1 {
      font-size: 14px;
    }

    p {
      font-size: 12px;
    }
  }
`;

const SCPoster = styled.div<{ imageUrl?: string | null }>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${rgba('white', 0.125)};
  background-image: ${(props) => getImageUrlString(props.imageUrl ?? DefaultGroupAvatar)};
  border: 0;
  display: block;
  height: 230px;
  outline: none;
  position: relative;
  width: 190px;

  @media (max-width: 1000px) {
    height: ${MOBILE_SCALE * 230}px;
    width: ${MOBILE_SCALE * 190}px;
  }
`;

const Group: React.FC<{ group: IGroup }> = ({ group }) => {
  return (
    <SCCarouselItem>
      <SCCarouselItemLink href={`/dashboard/groups/details/${group.id}`}>
        <SCPoster imageUrl={group.avatar} />
        <SCGroupDetails>
          <h1>{group.title}</h1>
          <p>{group.description}</p>
        </SCGroupDetails>
      </SCCarouselItemLink>
    </SCCarouselItem>
  );
};

interface IProps {
  groups: IGroup[];
}

const GroupsCarousel: React.FC<IProps> = ({ groups }) => {
  return (
    <SCWrapper>
      <SCCarousel>
        {groups.map((group) => (
          <Group key={group.id} group={group} />
        ))}
      </SCCarousel>
    </SCWrapper>
  );
};

export default GroupsCarousel;
