import { rgba } from 'polished';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { IMovie, ITVShow } from 'api/CatalogAPI';
import { IUserData } from 'api/ProfileAPI';
import SCAvatar from 'components/styled/SCAvatar';
import variables from 'components/styled/variables';
import GenericModal from 'containers/modals/GenericModal';

const SCWrapper = styled.div`
  padding: 60px;
`;

const SCGroupTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 175%;
`;

const SCModalHeading = styled.div`
  font-family: ${variables.fonts.heading};
  text-transform: uppercase;
  font-size: 60px;
  font-weight: 500;
  margin: 0 0 60px;
  line-height: 100%;
`;

const SCMemberRow = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid black;
  cursor: pointer;

  & + & {
    margin-top: 10px;
  }
`;

const SCBaseCell = styled.div`
  padding: 20px;
`;

const SCAvatarCell = styled(SCBaseCell)`
  border-right: 1px solid ${rgba('black', 0.15)};
`;

const SCUsernameCell = styled(SCBaseCell)`
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-family: ${variables.fonts.heading};
    text-transform: uppercase;
    font-size: 30px;
    margin: 0;
    line-height: 100%;
    font-weight: 500;
  }

  h2 {
    font-size: 16px;
    color: ${rgba('black', 0.5)};
    margin: 10px 0 0;
    line-height: 100%;
    font-weight: normal;
  }
`;

const SCScrollableArea = styled.div`
  max-height: 438px;
  overflow: auto;
  margin: 0 -60px;
  padding: 0 60px;
`;

const RecommendedByModal: React.FC<{
  data: ITVShow | IMovie;
  by: IUserData[];
  onRequestClose: () => void;
}> = ({ by, data, onRequestClose }) => {
  const history = useHistory();

  const onClickUser = (userId: number) => history.push(`/dashboard/user/${userId}`);

  const getTitle = () => (data.model === 'movie' ? data.title : data.name);

  return (
    <GenericModal onRequestClose={onRequestClose}>
      <SCWrapper>
        <SCGroupTitle>{getTitle()}</SCGroupTitle>
        <SCModalHeading>Recommended By</SCModalHeading>
        <SCScrollableArea>
          {by.map((user) => (
            <SCMemberRow key={user.id} onClick={() => onClickUser(user.id)}>
              <SCAvatarCell>
                <SCAvatar variant='comment' imageUrl={user.avatar} />
              </SCAvatarCell>
              <SCUsernameCell>
                <h1>@{user.username}</h1>
                <h2>
                  {user.first_name} {user.last_name}
                </h2>
              </SCUsernameCell>
            </SCMemberRow>
          ))}
        </SCScrollableArea>
      </SCWrapper>
    </GenericModal>
  );
};

export default RecommendedByModal;
