import React from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';

import variables from 'components/styled/variables';

const SCWrapper = styled.div<{ fullWidth: boolean; noMargin: boolean }>`
  margin: ${(props) => (props.noMargin ? 0 : '0 0 40px')};
  max-width: ${(props) => (props.fullWidth ? null : '610px')};

  @media (max-width: 1000px) {
    max-width: 100%;
    margin: ${(props) => (props.noMargin ? 0 : '0 0 20px')};
  }
`;

const SCLabel = styled.label`
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 10px;
`;

const SCInput = styled.input<{ invalid: boolean }>`
  -webkit-appearance: none;
  background-color: ${(props) =>
    props.theme.dark
      ? variables.colors.background.input.dark
      : variables.colors.background.input.light};
  border: 1px solid transparent;
  border-radius: 10px;
  box-sizing: border-box;
  display: block;
  font-size: 16px;
  height: 60px;
  outline: none;
  padding: 0 20px;
  width: 100%;
  color: ${(props) => (props.theme.dark ? 'white' : 'black')};
  border-color: ${(props) => props.invalid && variables.colors.text.error};

  &:focus {
    box-shadow: 0 0 0 1px white;
  }

  &::placeholder {
    color: ${(props) =>
    props.theme.dark
      ? variables.colors.text.placeholder.dark
      : variables.colors.text.placeholder.light};
  }
`;

const SCError = styled.div`
  background-color: ${variables.colors.background.error};
  border: 1px solid ${variables.colors.text.error};
  border-radius: 0 0 10px 10px;
  box-sizing: border-box;
  color: $error-color;
  font-size: 14px;
  margin-top: -10px;
  padding: 5px 10px;
  text-align: center;
`;

interface IProps {
  noMargin?: boolean;
  fullWidth?: boolean;
  id?: string;
  error?: string;
  label?: string;
  type: 'text' | 'email' | 'password' | 'search';
  value: string;
  onChange(value: string): void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

type InputProps = Omit<React.ComponentProps<typeof SCInput>, 'onChange'>;

const Input: React.FC<IProps & InputProps> = ({
  noMargin = false,
  id = v4(),
  error,
  type,
  fullWidth = false,
  value,
  onChange,
  label,
  onBlur,
  className,
  ...inputProps
}) => {
  const [hasBlurred, setHasBlurred] = React.useState(false);

  const onChangeWrapper = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const onBlurWrapper = (e: React.FocusEvent<HTMLInputElement>) => {
    setHasBlurred(true);
    onBlur?.(e);
  };

  return (
    <SCWrapper className={className} fullWidth={fullWidth} noMargin={noMargin}>
      {label && <SCLabel htmlFor={id}>{label}</SCLabel>}
      <SCInput
        id={id}
        invalid={hasBlurred && error != null}
        type={type}
        value={value}
        onBlur={onBlurWrapper}
        onChange={onChangeWrapper}
        {...inputProps}
      />
      {hasBlurred && error && <SCError>{error}</SCError>}
    </SCWrapper>
  );
};

export default Input;
