import React from 'react';
import styled from 'styled-components';

import variables from 'components/styled/variables';

const SCStarButtonWrapper = styled.button.attrs({ type: 'button' }) <{ clickable?: boolean }>`
  display: inline-block;
  padding: 0;
  margin: 0;
  border: 0;
  cursor: ${(props) => (props.clickable ? 'pointer' : null)};
  pointer-events: ${(props) => (props.clickable ? null : 'none')};
  background-color: transparent;
  outline: none;
  font-size: inherit;

  &:not(:last-child) {
    margin-right: 10px;
  }

  @media (max-width: 1000px) {
    font-size: 10px;

    &:not(:last-child) {
      margin-right: 5px;
    }
  }
`;

const SCRatingWrapper = styled.div<{ clickable?: boolean; color: string }>`
  display: inline-block;
  font-size: 12px;
  line-height: 20px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;

  ${SCStarButtonWrapper} {
    color: ${(props) => (props.clickable ? variables.colors.text.link : props.color)};
  }
`;

const SCRatingBase = styled.div`
  display: block;
  white-space: nowrap;
`;

const SCRating = styled(SCRatingBase) <{ rating: number }>`
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: ${({ rating }) => Math.round((rating / 10) * 100)}%;
`;

const SCRatingOutline = styled(SCRatingBase)`
  opacity: 0.2;
`;

interface IProps {
  value?: number;
  onChange?: (value: number) => void;
  color?: 'black' | 'white';
}

const Rating: React.FC<IProps> = ({ value = 0, onChange, color = 'black' }) => {
  const ratings = [2, 4, 6, 8, 10];
  return (
    <SCRatingWrapper clickable={onChange != null} color={color}>
      <SCRating rating={value}>
        {ratings.map((rating) => (
          <SCStarButtonWrapper key={rating}>
            <i className='fas fa-star' />
          </SCStarButtonWrapper>
        ))}
      </SCRating>
      <SCRatingOutline>
        {ratings.map((rating) => (
          <SCStarButtonWrapper
            key={rating}
            clickable={onChange != null}
            onClick={() => onChange?.(rating)}>
            <i className='fas fa-star' />
          </SCStarButtonWrapper>
        ))}
      </SCRatingOutline>
    </SCRatingWrapper>
  );
};

export default Rating;
