import { Store } from 'pullstate';
import React from 'react';
import styled from 'styled-components';

import SCButton from 'components/styled/SCButton';
import SCPageTitle from 'components/styled/SCPageTitle';
import GenericModal from 'containers/modals/GenericModal';
import { clearMovieWatchedStatusAction,MovieStore } from 'stores/movies/MovieStore';
import { clearShowWatchedStatusAction,ShowStore } from 'stores/shows/ShowStore';

const SCModalInner = styled.div`
  padding: 49px 57px 85px;

  p {
    margin: 0;
  }
`;

const SCCustomPageTitle = styled(SCPageTitle)`
  font-size: 60px;
  margin-bottom: 88px;

  @media(max-width: 1000px) {
    font-size: 30px;
  }
`;

const SCButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const SCPrimaryButton = styled(SCButton)`
  margin-bottom: 28px;
`;

const SCSecondaryButton = styled(SCButton)`
  color: black;
`;

const ConfirmRemoveShowStore = new Store<number | null>(null);
const ConfirmRemoveMovieStore = new Store<number | null>(null);

export const displayConfirmRemoveShowModal = (showId: number): void => ConfirmRemoveShowStore.update(() => showId);
export const displayConfirmRemoveMovieModal = (movieId: number): void => ConfirmRemoveMovieStore.update(() => movieId);

const hideModal = (): void => {
  ConfirmRemoveShowStore.update(() => null);
  ConfirmRemoveMovieStore.update(() => null);
};

const removeShow = (showId: number) => {
  clearShowWatchedStatusAction.run({ showId });
  hideModal();
};

const removeMovie = (movieId: number) => {
  clearMovieWatchedStatusAction.run({ movieId });
  hideModal();
};

const ConfirmRemoveShowActualModal: React.FC<{ showId: number }> = ({ showId }) => {
  const { show } = ShowStore.useState((s) => s[showId] ?? {}, [showId]);

  return (
    <GenericModal onRequestClose={hideModal}>
      {show != null && (
        <SCModalInner>
          <p>{show.name}</p>
          <SCCustomPageTitle>
            Remove from list?
          </SCCustomPageTitle>
          <SCButtonContainer>
            <SCPrimaryButton onClick={() => removeShow(showId)}>Yes, Remove</SCPrimaryButton>
            <SCSecondaryButton transparent={true} onClick={hideModal}>Not Right Now</SCSecondaryButton>
          </SCButtonContainer>
        </SCModalInner>
      )}
    </GenericModal>
  );
};

const ConfirmRemoveMovieActualModal: React.FC<{ movieId: number }> = ({ movieId }) => {
  const movie = MovieStore.useState((s) => s[movieId] ?? {}, [movieId]);

  return (
    <GenericModal onRequestClose={hideModal}>
      {movie != null && (
        <SCModalInner>
          <p>{movie.title}</p>
          <SCCustomPageTitle>
            Remove from list?
          </SCCustomPageTitle>
          <SCButtonContainer>
            <SCPrimaryButton onClick={() => removeMovie(movieId)}>Yes, Remove</SCPrimaryButton>
            <SCSecondaryButton transparent={true} onClick={hideModal}>Not Right Now</SCSecondaryButton>
          </SCButtonContainer>
        </SCModalInner>
      )}
    </GenericModal>
  );
};

const ConfirmRemoveShowModal: React.FC = () => {
  const showId = ConfirmRemoveShowStore.useState();
  return showId == null ? null : <ConfirmRemoveShowActualModal showId={showId} />;
};

const ConfirmRemoveMovieModal: React.FC = () => {
  const movieId = ConfirmRemoveMovieStore.useState();
  return movieId == null ? null : <ConfirmRemoveMovieActualModal movieId={movieId} />;
};

export {
  ConfirmRemoveShowModal,
  ConfirmRemoveMovieModal
};
