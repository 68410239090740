import React from 'react';

export default function (callback: () => void) {
  React.useEffect(() => {
    const clickHandler = () => {
      callback();
    };
    window.addEventListener('click', clickHandler);
    return () => {
      window.removeEventListener('click', clickHandler);
    };
  }, [callback]);
}
