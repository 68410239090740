import React from 'react';
import { useHistory } from 'react-router-dom';

import LoginPageWrapper from 'components/LoginPageWrapper';
import {
  SCAddButton,
  SCBox,
  SCCarousel,
  SCCarouselItem,
  SCDontWorry,
  SCMediaTitle,
  SCNextButton,
  SCPoster,
  SCSkipButton,
  SCSpinner,
  SCStep,
} from 'containers/pages/register/shared';
import {
  clearMovieWatchedStatusAction,
  MovieStore,
  RecommendedMovieStore,
  setMovieWatchedStatusAction,
} from 'stores/movies/MovieStore';

const Movie: React.FC<{ movieId: number }> = ({ movieId }) => {
  const movie = MovieStore.useState((s) => s[movieId], [movieId]);

  const hasStatus = movie?.watched?.status === 'watched';

  const toggleWatchedStatus = () => {
    if (hasStatus) clearMovieWatchedStatusAction.run({ movieId });
    else setMovieWatchedStatusAction.run({ movieId, status: 'watched' });
  };

  return (
    <SCCarouselItem>
      <SCPoster imageUrl={movie.images.poster.w342} onClick={toggleWatchedStatus}>
        <SCAddButton hasStatus={hasStatus}>
          {hasStatus ? <span className='fas fa-check' /> : <span className='fas fa-plus' />}
        </SCAddButton>
      </SCPoster>
      <SCMediaTitle>{movie.title}</SCMediaTitle>
    </SCCarouselItem>
  );
};

const PostRegisterStep1: React.FC = () => {
  const history = useHistory();
  const movies = RecommendedMovieStore.usePaginatedData();

  return (
    <LoginPageWrapper>
      <SCBox>
        <SCStep>Step 3 of 3</SCStep>
        <h1>Create Account</h1>
        <h2>What are some of your favorite movies?</h2>
        <h3>This will help us improve your recommendations.</h3>
        {movies.isLoaded ? (
          <SCCarousel>
            {movies.items.map((movie) => (
              <Movie key={movie.id} movieId={movie.id} />
            ))}
          </SCCarousel>
        ) : (
          <SCSpinner />
        )}
        <SCNextButton onClick={() => history.push('/')}>Next</SCNextButton>
        <SCSkipButton to='/'>Skip</SCSkipButton>
        <SCDontWorry>Don’t worry, you can add more later.</SCDontWorry>
      </SCBox>
    </LoginPageWrapper>
  );
};

export default PostRegisterStep1;
