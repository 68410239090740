import React from 'react';
import { useHistory } from 'react-router-dom';

interface IProps {
  href?: string;
  onClick?: React.MouseEventHandler;
  children?: React.ReactNode;
  noClick?: boolean;
}

const Link: React.FC<IProps & JSX.IntrinsicElements['a']> = ({
  children,
  href,
  onClick,
  noClick = false,
  ...rest
}: IProps & JSX.IntrinsicElements['a']) => {
  const history = useHistory();

  const onClickWrapper: React.MouseEventHandler<HTMLAnchorElement> = (e): void => {
    e.preventDefault();
    if (href && !noClick) history.push(href);
    onClick?.(e);
  };

  return (
    // eslint-disable-next-line no-script-url
    <a href={href ?? '#'} onClick={onClickWrapper} {...rest}>
      {children}
    </a>
  );
};

export default Link;
