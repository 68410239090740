import React from 'react';
import styled from 'styled-components';

import SCButton from 'components/styled/SCButton';
import variables from 'components/styled/variables';
import GenericModal from 'containers/modals/GenericModal';
import {
  fetchGroupAction,
  GroupDetailsStore,
  leaveGroupAction,
} from 'stores/groups/GroupDetailsStore';

const SCWrapper = styled.div`
  padding: 60px;
`;

const SCGroupTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 175%;
`;

const SCModalHeading = styled.div`
  font-family: ${variables.fonts.heading};
  text-transform: uppercase;
  font-size: 60px;
  font-weight: 500;
  margin: 0 0 60px;
  line-height: 100%;
`;

const SCButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SCCancelButton = styled(SCButton)`
  color: black;
`;

const LeaveGroupModal: React.FC<{ groupId: number; onRequestClose: () => void }> = ({
  groupId,
  onRequestClose,
}) => {
  const [isGroupLoaded] = fetchGroupAction.useBeckon({ groupId });
  const group = GroupDetailsStore.useState((s) => s[groupId], [groupId]);

  const isLoading = !isGroupLoaded;

  const leaveGroup = async () => {
    leaveGroupAction.run({ groupId });
    onRequestClose();
  };

  return (
    <GenericModal isLoading={isLoading} onRequestClose={onRequestClose}>
      {group != null && (
        <SCWrapper>
          <SCGroupTitle>{group.title}</SCGroupTitle>
          <SCModalHeading>Leave Group?</SCModalHeading>
          <SCButtonWrapper>
            <SCButton block onClick={leaveGroup}>
              Yes, Leave Group
            </SCButton>
            <SCCancelButton block transparent onClick={onRequestClose}>
              Not Right Now
            </SCCancelButton>
          </SCButtonWrapper>
        </SCWrapper>
      )}
    </GenericModal>
  );
};

export default LeaveGroupModal;
