import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { IGroup } from 'api/GroupsAPI';
import Link from 'components/Link';
import SCButton from 'components/styled/SCButton';
import SCShowMoreButtonWrapper from 'components/styled/SCShowMoreButtonWrapper';
import LoggedInPageWrapper from 'containers/LoggedInPageWrapper';
import GroupSearchInput, { LocalGroupSearchInput } from 'containers/SearchInput/GroupSearchInput';
import escapeRegexp from 'helpers/escapeRegexp';
import { GroupListAllStore } from 'stores/groups/GroupListStore';

import GroupCoverBar from './GroupCoverBar';

const SCCreateLink = styled(Link)`
  color: white;
  float: right;
  font-size: 16px;
  font-weight: 500;
  margin-left: 20px;
`;

const SCGroupWrapper = styled(Link)`
  display: block;
  color: white;
`;

const SCGroupTitle = styled.div`
  font-family: Gala, sans-serif;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 500;
  margin: 0 0 5px;
`;

const SCSearchWrapper = styled.div`
  flex-grow: 1;
  max-width: 610px;
  margin-right: auto;
`;

const SCSearchBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 60px;

  ${SCCreateLink} {
    float: none;
  }
`;

const Groups: React.FC = () => {
  const history = useHistory();
  const groups = GroupListAllStore.usePaginatedData();
  const [search, setSearch] = React.useState('');

  const gotoGroupPage = ({ item }: { item: IGroup }) => {
    history.push(`/dashboard/groups/details/${item.id}`);
  };

  const filteredGroups = React.useMemo(
    () =>
      groups?.items?.filter((group) => {
        const lowerCaseSearch = search.toLowerCase();
        return group.title?.toLowerCase().match(escapeRegexp(lowerCaseSearch));
      }),
    [groups, search],
  );

  const hasGroups = Boolean(groups?.items.length);

  return (
    <LoggedInPageWrapper bg='gray' title='My Groups' isLoading={groups.isLoading}>
      {hasGroups ? (
        <>
          <SCSearchBarWrapper>
            <SCSearchWrapper>
              <LocalGroupSearchInput
                noMargin
                value={search}
                onChange={setSearch}
                placeholder='Search My Groups'
              />
            </SCSearchWrapper>
            <SCCreateLink href='/dashboard/groups/create'>+ Create a Group</SCCreateLink>
          </SCSearchBarWrapper>
          {filteredGroups.map((group) => (
            <SCGroupWrapper key={group.id} href={`/dashboard/groups/details/${group.id}`}>
              <SCGroupTitle>{group.title}</SCGroupTitle>
              <GroupCoverBar avatarUrl={group.avatar} coverUrl={group.cover} />
            </SCGroupWrapper>
          ))}
          {groups.hasMorePages && (
            <SCShowMoreButtonWrapper>
              <SCButton onClick={groups.loadNextPage}>Load More</SCButton>
            </SCShowMoreButtonWrapper>
          )}
        </>
      ) : (
        <>
          <SCCreateLink href='/dashboard/groups/create'>+ Create a Group</SCCreateLink>
          <GroupSearchInput
            value={null}
            onChange={gotoGroupPage}
            placeholder='Search All Groups'
            label='You have no groups at the moment'
          />
        </>
      )}
    </LoggedInPageWrapper>
  );
};

export default Groups;
