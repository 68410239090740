import React from 'react';
import styled from 'styled-components';

import { validateEmail, validatePassword } from 'helpers/validators';

import ContentBox from './ContentBox';
import FormButton from './FormButton';
import Input from './Input';

const SCErrorWrapper = styled.div`
  color: red;
  font-size: 14px;
  text-align: center;
  margin: 40px -60px 0;
  font-weight: 500;

  @media (max-width: 1000px) {
    margin: 20px 0;
  }
`;

interface IProps {
  error?: boolean;
  isLoading: boolean;
  onForgotPassword(): void;
  onSubmit(email: string, password: string): void;
}

const LoginForm: React.FC<IProps> = ({ error = false, isLoading, onForgotPassword, onSubmit }) => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const onSubmitWrapper = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(email, password);
  };

  const emailError = validateEmail(email);
  const passwordError = validatePassword(password);

  const isDisabled = Boolean(emailError || passwordError || isLoading);

  return (
    <form onSubmit={onSubmitWrapper}>
      <ContentBox title='Welcome!'>
        <Input
          type='email'
          placeholder='Email'
          value={email}
          onChange={setEmail}
          error={emailError}
        />
        <Input
          type='password'
          placeholder='Password'
          value={password}
          onChange={setPassword}
          error={passwordError}
        />
        <FormButton type='submit' isDisabled={isDisabled}>
          Log In
        </FormButton>
        <FormButton type='cancel' onClick={() => onForgotPassword()}>
          Forgot Password
        </FormButton>
        {error && (
          <SCErrorWrapper>
            We don&apos;t recognize your email address and/or password.
            <br />
            Please try again or go to the Forgot Password page.
          </SCErrorWrapper>
        )}
      </ContentBox>
    </form>
  );
};

export default LoginForm;
