import useEffectOnce from '../../../../hooks/useEffectOnce';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Column, Direction } from 'api/CatalogAPI';
import Input from 'components/Input';
import LoggedInPageWrapper from 'containers/LoggedInPageWrapper';
import MovieTable from 'containers/pages/Dashboard/Movies/MovieTable';
import escapeRegexp from 'helpers/escapeRegexp';
import { WatchedMovieStore } from 'stores/movies/MovieStore';


const SCSearch = styled(Input)`
  @media(max-width: 1000px) {
    margin-bottom:10px;

    input {
      height: 36px;
    }
  }
`;


const Movies: React.FC = () => {
  const { column, direction } = useParams();
  const history = useHistory();
  const [search, setSearch] = React.useState('');

  const movies = WatchedMovieStore.usePaginatedData({ column, direction });
  const filteredMovies = React.useMemo(
    () =>
      movies?.items?.filter((movie) => {
        const lowerCaseSearch = search.toLowerCase();
        return movie.media.title?.toLowerCase().match(escapeRegexp(lowerCaseSearch));
      }),
    [movies, search],
  );

  useEffectOnce(() => {
    if (movies.isLoaded) WatchedMovieStore.fetchAction.clearAllCache();
  });

  const onChange = (column: Column, direction: Direction) => {
    history.push(`/dashboard/movies/watched/${column}/${direction}`);
  };

  return (
    <LoggedInPageWrapper bg='white' isLoading={movies.isLoading} title='My Movies'>
      <SCSearch type='search' value={search} onChange={setSearch} placeholder='Search My Movies' fullWidth={true} />
      {filteredMovies.length ? (
        <MovieTable
          sort={{ onChange, column, direction }}
          type='watched'
          useMyRatings
          movies={filteredMovies.map((m) => m.media)}
          hasMorePages={movies.hasMorePages}
          loadMore={movies.loadNextPage}
          isUpdating={movies.isUpdating}
        />
      ) : (
        movies.items.length > 0 
          ? <p>No movies match your search</p>
          : <p>No movies yet</p>
      )}
    </LoggedInPageWrapper>
  );
};

export default Movies;
