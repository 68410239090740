import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import GroupsAPI from 'api/GroupsAPI';
import ProfileAPI, { IUserData } from 'api/ProfileAPI';
import Input from 'components/Input';
import RadioGroup from 'components/RadioGroup';
import SCButton from 'components/styled/SCButton';
import variables from 'components/styled/variables';
import TextArea from 'components/TextArea';
import LoggedInPageWrapper from 'containers/LoggedInPageWrapper';
import GroupCoverBar from 'containers/pages/Dashboard/Groups/GroupCoverBar';
import { SCSpinner } from 'containers/pages/register/shared';
import FriendSearchInput from 'containers/SearchInput/FriendSearchInput';
import { validateEmail } from 'helpers/validators';
import { GroupListStore } from 'stores/groups/GroupListStore';

type Type = {
  id: string;
  display: string;
};

const typeOptions: Type[] = [
  {
    id: 'public',
    display: 'PUBLIC: Anyone can join this group.  Anyone can view the group information and feed.',
  },
  {
    id: 'private',
    display:
      'PRIVATE: New members must be approved by the group moderators.  Anyone can see the group information, but only members can see the polls and discussion boards.  Only moderators can invite new members.',
  },
];

const SCSubmitButton = styled(SCButton)`
  margin-top: 40px;
  min-width: 330px;
`;

const SCErrorMessage = styled.p`
  color: ${variables.colors.text.error};
`;

const CreateGroup: React.FC = () => {
  const history = useHistory();

  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [users, setUsers] = React.useState<{ value: number; display: string; item: IUserData }[]>(
    [],
  );
  const [type, setType] = React.useState<Type | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [avatar, setAvatar] = React.useState<string | null>(null);
  const [cover, setCover] = React.useState<string | null>(null);
  const [error, setError] = React.useState<string | null>(null);
  const [inviteEmails, setInviteEmails] = React.useState('');

  const onSubmit: React.FormEventHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    try {
      const groupJson = await GroupsAPI.createGroup(description, type?.id === 'public', title);
      if (!groupJson.success) throw new Error(groupJson.error.message);

      if (avatar) {
        const avatarJson = await ProfileAPI.uploadImage('avatar', avatar);
        if (!avatarJson.success) throw new Error(avatarJson.error.message);

        const setAvatarJson = await GroupsAPI.setGroupImage(
          groupJson.response.id,
          avatarJson.response.media_id,
          'avatar',
        );
        if (!setAvatarJson.success) throw new Error(setAvatarJson.error.message);
      }

      if (cover) {
        const coverJson = await ProfileAPI.uploadImage('cover', cover);
        if (!coverJson.success) throw new Error(coverJson.error.message);

        const setCoverJson = await GroupsAPI.setGroupImage(
          groupJson.response.id,
          coverJson.response.media_id,
          'cover',
        );
        if (!setCoverJson.success) throw new Error(setCoverJson.error.message);
      }

      if (inviteEmails.length > 0) {
        Promise.all(
          inviteEmails.split(',').map((rawEmail) => {
            const email = rawEmail.trim();
            return GroupsAPI.sendGroupInviteByEmail(groupJson.response.id, email);
          }),
        );
      }

      // Invite users, but we don't care about following the success here yet
      users.forEach((user) => {
        GroupsAPI.sendGroupInvite(groupJson.response.id, user.item.id);
      });

      await GroupListStore.fetchAction.run();
      history.push(`/dashboard/groups/details/${groupJson.response.id}`);
      return;
    } catch (err) {
      setError(err.message);
      console.error(err.message);
    }
    setIsLoading(false);
  };

  const isInviteFieldInvalid =
    inviteEmails.length > 0 &&
    inviteEmails.split(',').some((email) => validateEmail(email) != null);

  const isSubmitDisabled =
    title === '' || description === '' || type === null || isInviteFieldInvalid;

  return (
    <LoggedInPageWrapper bg='gray' title='Create a Group'>
      <form onSubmit={onSubmit}>
        <GroupCoverBar edit onAvatarChange={setAvatar} onCoverChange={setCover} />
        <Input
          disabled={isLoading}
          fullWidth
          onChange={setTitle}
          value={title}
          placeholder='Title'
          type='text'
        />
        <TextArea
          disabled={isLoading}
          fullWidth
          onChange={setDescription}
          value={description}
          placeholder='Description'
        />
        <FriendSearchInput
          isMulti
          cacheOptions
          disabled={isLoading}
          fullWidth
          value={users}
          onChange={setUsers}
          label='Add Friends'
          placeholder='Search'
        />
        <Input
          label='Invite Friends (to add multiple emails add a comma between each one)'
          type='text'
          placeholder='Friends Email'
          value={inviteEmails}
          onChange={(emails) => setInviteEmails(emails)}
        />
        <RadioGroup
          disabled={isLoading}
          label='Type'
          options={typeOptions}
          value={type}
          onChange={(item) => setType(item)}
        />
        <SCSubmitButton disabled={isSubmitDisabled}>
          {isLoading ? <SCSpinner /> : 'Create Group'}
        </SCSubmitButton>
        {error != null && <SCErrorMessage>Error saving group: {error}</SCErrorMessage>}
      </form>
    </LoggedInPageWrapper>
  );
};

export default CreateGroup;
