import React from 'react';

import LoggedInPageWrapper from 'containers/LoggedInPageWrapper';
import ShowTable from 'containers/pages/Dashboard/Shows/ShowTable';
import { BrowseNewShowsStore } from 'stores/shows/ShowStore';

const BrowseNewReleaseShows: React.FC = () => {
  const shows = BrowseNewShowsStore.usePaginatedData();

  return (
    <LoggedInPageWrapper
      bg='white'
      title='Browse - New Releases TV Shows'
      isLoading={shows.isLoading}>
      {shows.items.length ? (
        <ShowTable
          type='browse'
          shows={shows.items}
          hasMorePages={shows.hasMorePages}
          loadMore={shows.loadNextPage}
          isUpdating={shows.isUpdating}
        />
      ) : (
        <p>No shows yet</p>
      )}
    </LoggedInPageWrapper>
  );
};

export default BrowseNewReleaseShows;
