import styled from 'styled-components';

const SCShowForMobile = styled.div`
  display: none !important;
  @media (max-width: 1000px) {
    display: block !important;
  }
`;

export default SCShowForMobile;
