import { clearFix } from 'polished';
import styled from 'styled-components';

export default styled.div<{ includePadding?: boolean }>`
  ${clearFix()}
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  padding: ${(props) => (props.includePadding ? '60px' : 0)};
  color: ${(props) => (props.theme.dark ? 'white' : 'black')};

  @media (max-width: 1000px) {
    padding: ${(props) => (props.includePadding ? '20px' : 0)};
    max-width: none;
  }
`;
