import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AuthAPI from 'api/AuthAPI';
import ContentBox from 'components/ContentBox';
import FormButton from 'components/FormButton';
import Input from 'components/Input';
import LoginPageWrapper from 'components/LoginPageWrapper';
import { validatePassword } from 'helpers/validators';

const ForgotPasswordConfirm: React.FC = () => {
  const history = useHistory();
  const { code } = useParams();
  const [email, setEmail] = React.useState<string | null>(null);
  const [password1, setPassword1] = React.useState('');
  const [password2, setPassword2] = React.useState('');

  const passwordError = validatePassword(password1);
  const verifyError = password1 === password2 ? undefined : 'Your passwords must match';

  const isDisabled = Boolean(password1 !== password2 || passwordError);

  React.useEffect(() => {
    (async () => {
      try {
        const json = await AuthAPI.fetchUserEmail(code);
        if (!json.success) throw new Error(json.error.message);

        setEmail(json.response.email);
      } catch (err) {
        console.error(err.message);
      }
    })();
  }, [code]);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const response = await AuthAPI.resetPassword(code, password1);
    if (response.success) {
      history.push('/forgot/success');
    }
  };

  return (
    <LoginPageWrapper>
      <form onSubmit={onSubmit}>
        <ContentBox title='Reset Password'>
          <p>{email || 'Loading...'}</p>
          <Input
            type='password'
            value={password1}
            onChange={setPassword1}
            placeholder='Password'
            error={passwordError}
          />
          <Input
            type='password'
            value={password2}
            onChange={setPassword2}
            placeholder='Verify password'
            error={verifyError}
          />
          <FormButton type='submit' isDisabled={isDisabled}>
            Reset
          </FormButton>
        </ContentBox>
      </form>
    </LoginPageWrapper>
  );
};

export default ForgotPasswordConfirm;
