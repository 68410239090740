import { createAsyncAction, errorResult, Store, successResult } from 'pullstate';

import ProfileAPI, { IUserData } from 'api/ProfileAPI';

const TOKEN_KEY = 'auth/x-auth-token';

export interface IProfileUserState {
  avatar: string | null;
  cover: string | null;
  id: number;
  isMe: boolean;
  firstName: string | null;
  lastName: string | null;
  username: string;
}

interface ICurrentUserStore {
  token?: string;
  user?: IProfileUserState;
}

export const CurrentUserStore = new Store<ICurrentUserStore>({
  token: localStorage.getItem(TOKEN_KEY) ?? undefined,
});

CurrentUserStore.createReaction(
  (s) => s.token,
  (token) => {
    if (token == null) localStorage.removeItem(TOKEN_KEY);
    else localStorage.setItem(TOKEN_KEY, token);
  },
);

export const fetchCurrentUser = createAsyncAction<undefined, IUserData>(
  async () => {
    try {
      const json = await ProfileAPI.fetchCurrentUser();
      if (!json.success) throw new Error(json.error.message);

      return successResult(json.response);
    } catch (err) {
      return errorResult([], err.message);
    }
  },
  {
    postActionHook({ result }) {
      if (result.error) {
        logoutAction();
        return;
      }

      CurrentUserStore.update((s) => {
        s.user = {
          avatar: result.payload.avatar,
          lastName: result.payload.last_name,
          firstName: result.payload.first_name,
          username: result.payload.username,
          cover: result.payload.cover,
          id: result.payload.id,
          isMe: result.payload.is_me,
        };
      });
    },
  },
);

interface IUploadImageResponse {
  format: 'image',
  mediaId: number;
  url: string;
}
export const uploadImage = createAsyncAction<
  { type: 'avatar' | 'cover' | 'attachment'; image: string },
  IUploadImageResponse
>(
  async ({ type, image }) => {
    const actualType = type === 'attachment' ? 'cover' : type;
    try {
      const imageJson = await ProfileAPI.uploadImage(
        actualType,
        image.replace('data:image/jpeg;base64,', ''),
      );
      if (!imageJson.success) throw new Error(imageJson.error.message);
      if (type !== 'attachment') {
        const avatarJson = await ProfileAPI.setImage(actualType, imageJson.response.media_id);
        if (!avatarJson.success) throw new Error(avatarJson.error.message);
      }
      return successResult({
        format: 'image',
        mediaId: imageJson.response.media_id,
        url: imageJson.response.url,
      });
    } catch (err) {
      return errorResult([], err.message);
    }
  },
  {
    postActionHook({ args: { type } }) {
      if (type !== 'attachment') {
        fetchCurrentUser.run();
      }
    },
  },
);

export const setProfile = createAsyncAction<
  { firstName: string | null; lastName: string | null },
  undefined
>(
  async ({ firstName, lastName }) => {
    try {
      const json = await ProfileAPI.setProfileDetails(firstName, lastName);
      if (!json.success) throw new Error(json.error.message);

      return successResult();
    } catch (err) {
      return errorResult([], err.message);
    }
  },
  {
    postActionHook({ args: { firstName, lastName } }) {
      CurrentUserStore.update((s) => {
        if (s.user == null) return;

        s.user.firstName = firstName;
        s.user.lastName = lastName;
      });
    },
  },
);

export const logoutAction = (): void => {
  CurrentUserStore.update((s) => {
    s.user = undefined;
    s.token = undefined;
  });
};

export const useAuthToken = (): string | undefined => CurrentUserStore.useState((s) => s.token);
export const useCurrentUser = (): IProfileUserState | undefined =>
  CurrentUserStore.useState((s) => s.user);
