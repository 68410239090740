import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';

import variables from 'components/styled/variables';

const SCRadioGroupWrapper = styled.div`
  display: block;
  overflow: hidden;
  position: relative;
`;

const SCFieldLabel = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
`;

const SCItemLabel = styled.label<{ disabled: boolean }>`
  align-items: center;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  display: flex;

  & + & {
    margin-top: 20px;
  }
`;

const SCCircle = styled.div<{ size: number; checked: boolean }>`
  border: 1px solid white;
  border-radius: 50%;
  display: block;
  flex-shrink: 0;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  margin-right: 20px;
  background-color: ${(props) => props.checked && 'white !important'};
  position: relative;

  &::after {
    color: ${variables.colors.text.link};
    content: '\f00c';
    display: ${(props) => (props.checked ? 'block' : 'none')};
    font-family: ${variables.fonts.icon};
    left: 50%;
    position: absolute;
    font-weight: 900;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const SCRadioButton = styled.input.attrs({ type: 'radio' })`
  left: -100vw;
  outline: none;
  pointer-events: none;
  position: absolute;
  top: 0;

  &:focus + ${SCCircle} {
    background-color: ${rgba('white', 0.25)};
  }
`;

type Item = {
  id: string;
  display: string;
};

type Props = {
  label: React.ReactNode;
  value: Item | null;
  onChange: (value: Item) => void;
  options: Item[];
  disabled?: boolean;
};

const RadioGroup: React.FC<Props> = ({ disabled = false, label, onChange, options, value }) => {
  const name = React.useMemo(() => v4(), []);

  return (
    <SCRadioGroupWrapper>
      {label && <SCFieldLabel>{label}</SCFieldLabel>}
      {options.map((option) => (
        <SCItemLabel key={option.id} disabled={disabled}>
          <SCRadioButton
            disabled={disabled}
            id={option.id}
            name={name}
            value={option.display}
            onChange={() => !disabled && onChange(option)}
            checked={option.id === value?.id}
          />
          <SCCircle size={40} checked={option.id === value?.id} />
          {option.display}
        </SCItemLabel>
      ))}
    </SCRadioGroupWrapper>
  );
};

export default RadioGroup;
