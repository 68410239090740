import React from 'react';
import styled from 'styled-components';

import { IUserData } from 'api/ProfileAPI';
import Link from 'components/Link';
import SCAvatar from 'components/styled/SCAvatar';
import SCButton from 'components/styled/SCButton';

const SCUserRow= styled.div<{ textColor?: string }>`
  display: flex;
  margin-bottom: 20px;

  a, p {
    font-size: 20px;
    line-height: 150%;
    color: ${(props) => props.textColor || 'black'};
  }
  p {
    margin: 0;
  }

  @media(max-width: 1000px) {
    align-items: center;
    margin-bottom: 10px;

    a, p {
      font-size: 14px;
      line-height: 120%;
    }
  }
`;

const SCUserRowText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 27px;

  @media(max-width: 1000px) {
    margin-left: 7px;
  }
`;

const SCUserRowAvatar = styled(SCAvatar)`
  @media(max-width: 1000px) {
    height: 35px;
    width: 35px;
  };
`;

const SCUserRowButton = styled(SCButton)`
  margin-left: auto;

  @media(max-width: 1000px) {
    border-radius: 5px;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    font-size: 14px;
  };
`;

const UserRow: React.FC<{ user: IUserData, buttonAction: Function, buttonText: string, disabled?: boolean, textColor?: string }> = ({
  user,
  buttonAction,
  buttonText,
  disabled = false,
  textColor
}) => {
  const href = `/dashboard/user/${user.id}`;

  return (
    <SCUserRow key={user.id} textColor={textColor}>
      <Link href={href}>
        <SCUserRowAvatar variant='comment' imageUrl={user.avatar} />
      </Link>
      <SCUserRowText>
        <Link href={href}>@{user.username}</Link>
        <Link href={href}>
          <p>{user.first_name ?? 'N/A'} {user.last_name ?? 'N/A'}</p>
        </Link>
      </SCUserRowText>
      <SCUserRowButton onClick={() => buttonAction(user.id)} disabled={disabled}>
        {buttonText}
      </SCUserRowButton>
    </SCUserRow>
  );
};

export default UserRow;