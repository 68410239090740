import { Store } from 'pullstate';
import React from 'react';
import styled from 'styled-components';

import Rating from 'components/Rating';
import variables from 'components/styled/variables';
import GenericModal from 'containers/modals/GenericModal';
import {
  setEpisodeWatchedStatus,
  setShowRatingAction,
  setShowWatchedStatusAction,
  ShowStore,
} from 'stores/shows/ShowStore';

const SCWrapper = styled.div`
  padding: 60px;

  @media (max-width: 1000px) {
    padding: 60px 20px;
  }
`;

const SCWatchedStatus = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 175%;

  @media (max-width: 1000px) {
    font-size: 14px;
  }
`;

const SCShowTitle = styled.div`
  font-family: ${variables.fonts.heading};
  text-transform: uppercase;
  font-size: 60px;
  font-weight: 500;
  margin: 0 0 60px;
  line-height: 100%;

  @media (max-width: 1000px) {
    font-size: 40px;
  }
`;

const SCDetailRow = styled.div`
  line-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    border-top: 1px solid #c4c4c4;
  }

  & > :first-child {
    flex-grow: 1;
  }
`;

const SCNumberWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: 20px 0 10px 80px;
  max-width: 500px;
`;

const SCNumberButton = styled.button<{ active?: boolean }>`
  border: 0;
  background: ${(props) => (props.active ? variables.colors.text.link : 'white')};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: ${(props) => (props.active ? 'white' : variables.colors.text.link)};
  text-align: center;
  margin: 0 0 10px 10px;
  display: block;
  outline: none;
  cursor: pointer;
  padding: 0;
`;

const EditShowStore = new Store<number | null>(null);

export const displayEditShowModal = (showId: number): void => EditShowStore.update(() => showId);

const hideEditShowModal = (): void => EditShowStore.update(() => null);

const EditShowActualModal: React.FC<{ showId: number }> = ({ showId }) => {
  const [isShowLoaded] = [true];
  // TODO: this causes problems.  Figure out a better fix
  // const [isShowLoaded] = fetchShowAction.useBeckon({ showId });
  const { show, seasons } = ShowStore.useState((s) => s[showId] ?? {}, [showId]);

  const [seasonIndex, setSeasonIndex] = React.useState(0);

  const watchedStatus = show?.watched ? 'Currently Watching' : null;

  const isEpisodeWatched = (key: string) => show?.watched?.[key]?.status === 'watched';

  const allEpisodeKeys = seasons?.reduce<string[]>((episodes, season) => {
    return [...episodes, ...season.episodes.map((e) => e.key)];
  }, []);

  const getLatestWatchedEpisode = () => {
    if (allEpisodeKeys == null) return;

    let latest;
    for (const key of allEpisodeKeys) {
      if (!isEpisodeWatched(key)) break;
      latest = key;
    }
    return latest;
  };

  const toggleEpisodeWatchStatus = async (key: string) => {
    if (allEpisodeKeys == null) return;

    const latestWatched = getLatestWatchedEpisode();
    const indexOfLatest = allEpisodeKeys.indexOf(latestWatched ?? '') ?? -1;
    const indexOfKey = allEpisodeKeys.indexOf(key) ?? -1;

    if (isEpisodeWatched(key)) {
      if (indexOfKey === indexOfLatest && indexOfKey === allEpisodeKeys.length - 1) {
        await setShowWatchedStatusAction.run({ showId, status: 'watching' });
      }
      setEpisodeWatchedStatus.run({ episodeKeys: [key], showId, watched: false });
    } else {
      if (indexOfKey > indexOfLatest) {
        await setEpisodeWatchedStatus.run({
          episodeKeys: allEpisodeKeys.slice(0, indexOfKey + 1),
          showId,
          watched: true,
        });
        setShowWatchedStatusAction.run({
          showId,
          status: indexOfKey === allEpisodeKeys.length - 1 ? 'watched' : 'watching',
        });
      } else {
        setEpisodeWatchedStatus.run({ episodeKeys: [key], showId, watched: true });
      }
    }
  };

  return (
    <GenericModal isLoading={!isShowLoaded} onRequestClose={hideEditShowModal}>
      {show != null && (
        <SCWrapper>
          {watchedStatus != null && <SCWatchedStatus>{watchedStatus}</SCWatchedStatus>}
          <SCShowTitle>{show.name}</SCShowTitle>
          <SCDetailRow>
            <div>Your rating</div>
            <Rating
              value={show.rating?.value}
              onChange={(rating) => setShowRatingAction.run({ showId, rating })}
            />
          </SCDetailRow>
          <SCDetailRow>
            <div>Season</div>
            <SCNumberWrapper>
              {seasons?.map((season, index) => (
                <SCNumberButton
                  key={index}
                  onClick={() => setSeasonIndex(index)}
                  active={index === seasonIndex}>
                  {season.number}
                </SCNumberButton>
              ))}
            </SCNumberWrapper>
          </SCDetailRow>
          <SCDetailRow>
            <div>Episode</div>
            <SCNumberWrapper>
              {seasons?.[seasonIndex].episodes.map((episode) => (
                <SCNumberButton
                  key={episode.key}
                  active={isEpisodeWatched(episode.key)}
                  onClick={() => toggleEpisodeWatchStatus(episode.key)}>
                  {episode.number}
                </SCNumberButton>
              ))}
            </SCNumberWrapper>
          </SCDetailRow>
        </SCWrapper>
      )}
    </GenericModal>
  );
};

const EditShowModal: React.FC = () => {
  const showId = EditShowStore.useState();
  return showId == null ? null : <EditShowActualModal showId={showId} />;
};

export default EditShowModal;
