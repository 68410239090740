import cn from 'classnames';
import React from 'react';

import css from './ContentBox.module.scss';

interface IProps {
  children: React.ReactNode;
  title?: React.ReactNode;
}

const ContentBox: React.FC<IProps> = ({ children, title }) => {
  return (
    <div
      className={cn(css.ContentBox, {
        [css.hasTitle]: title,
      })}>
      {title && <h1 className={css.Title}>{title}</h1>}
      {children}
    </div>
  );
};

export default ContentBox;
