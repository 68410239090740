import React from 'react';
import { useParams } from 'react-router-dom';

import LoggedInPageWrapper from 'containers/LoggedInPageWrapper';
import { fetchGroupAction, GroupDetailsStore } from 'stores/groups/GroupDetailsStore';

import GroupDetailsMemberView from './GroupDetailsMemberView';
import GroupPDP from './GroupPDP';

const GroupDetails: React.FC = () => {
  const { groupId } = useParams();

  const [isGroupLoaded] = fetchGroupAction.useBeckon({ groupId });
  const group = GroupDetailsStore.useState((s) => s[groupId], [groupId]);

  const membershipStatus = group?.membership?.status;
  const isMember = membershipStatus === 'accepted';

  if (isGroupLoaded)
    return isMember ? <GroupDetailsMemberView group={group} /> : <GroupPDP group={group} />;

  return <LoggedInPageWrapper bg='gray' isLoading={!isGroupLoaded} />;
};

export default GroupDetails;
