import SCHideForMobile from '../../../../components/styled/SCHideForMobile';
import SCShowForMobile from '../../../../components/styled/SCShowForMobile';
import variables from '../../../../components/styled/variables';
import { rgba } from 'polished';
import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { IWatchListItem } from 'api/CatalogAPI';
import { IFriend } from 'api/FriendsAPI';
import Link from 'components/Link';
import Spinner from 'components/Spinner';
import SCAvatar from 'components/styled/SCAvatar';
import SCResultsTable from 'components/styled/SCResultsTable';
import { UserIsWatchingStore } from 'stores/WatchListStore';

const SCWatchItem = styled.div`
  margin: 0 0 5px;
`;

const SCMoreButton = styled.button`
  cursor: pointer;
  border: 0;
  background: transparent;
  padding: 0;
  display: inline-block;
  text-decoration: underline;
  margin: 0 0 5px;
`;

const SCMobileFriendWrapper = styled.div`
  border: 1px solid ${variables.colors.border};

  & + & {
    margin-top: 20px;
  }
`;

const SCMobileFriendTopRow = styled.div`
  display: flex;
  cursor: pointer;
`;

const SCMobileAvatarWrapper = styled.div`
  border-right: 1px solid ${variables.colors.border};
  padding: 20px;
`;

const SCMobileUserWrapper = styled.div`
  padding: 20px;

  h1 {
    font-family: ${variables.fonts.heading};
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 500;
    margin: 0 0 5px;
  }

  h2 {
    color: ${rgba('black', 0.6)};
    font-size: 14px;
    font-weight: normal;
    margin: 0;
  }
`;

const SCMobileWatchListWrapper = styled.div`
  padding: 20px;
  border-top: 1px solid ${variables.colors.border};
  color: ${variables.colors.text.placeholder};

  h1 {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 10px;
  }
`;

const MobileFriendRow: React.FC<{ friend: IFriend }> = ({ friend }) => {
  const history = useHistory();
  const [shouldShowMore, setShouldShowMore] = React.useState(false);

  const watching = UserIsWatchingStore.usePaginatedData({ userId: friend.user.id });

  const getWatchList = () => {
    return shouldShowMore ? watching.items : watching.items.slice(0, 3);
  };

  const getMediaName = (item: IWatchListItem) => {
    return item.media.model === 'movie' ? item.media.title : item.media.name;
  };

  const gotoFriend = (friend: IFriend): void => {
    history.push(`/dashboard/user/${friend.user.id}`);
  };

  return (
    <SCMobileFriendWrapper>
      <SCMobileFriendTopRow onClick={() => gotoFriend(friend)}>
        <SCMobileAvatarWrapper>
          <SCAvatar variant='comment' imageUrl={friend.user.avatar} />
        </SCMobileAvatarWrapper>
        <SCMobileUserWrapper>
          <h1>@{friend.user.username}</h1>
          <h2>
            {friend.user.first_name} {friend.user.last_name}
          </h2>
        </SCMobileUserWrapper>
      </SCMobileFriendTopRow>
      {getWatchList().length > 0 && (
        <SCMobileWatchListWrapper>
          <h1>Currently Watching</h1>
          {getWatchList().map((item, index) => (
            <SCWatchItem key={index}>{getMediaName(item)}</SCWatchItem>
          ))}
          {watching.items.length > 3 && !shouldShowMore && (
            <SCMoreButton onClick={() => setShouldShowMore(true)}>More...</SCMoreButton>
          )}
        </SCMobileWatchListWrapper>
      )}
    </SCMobileFriendWrapper>
  );
};

const FriendRow: React.FC<{ friend: IFriend }> = ({ friend }) => {
  const [shouldShowMore, setShouldShowMore] = React.useState(false);

  const watching = UserIsWatchingStore.usePaginatedData({ userId: friend.user.id });

  const getWatchList = () => {
    return shouldShowMore ? watching.items : watching.items.slice(0, 3);
  };

  const getMediaName = (item: IWatchListItem) => {
    return item.media.model === 'movie' ? item.media.title : item.media.name;
  };

  return (
    <tr>
      <td>
        <SCAvatar variant='comment' imageUrl={friend.user.avatar} />
      </td>
      <td>
        <Link href={`/dashboard/user/${friend.user.id}`}>@{friend.user.username}</Link>
      </td>
      <td>{friend.user.first_name ?? 'N/A'}</td>
      <td>{friend.user.last_name ?? 'N/A'}</td>
      <td>
        {watching.isLoading ? (
          <Spinner />
        ) : (
          <div>
            {getWatchList().map((item, index) => (
              <SCWatchItem key={index}>{getMediaName(item)}</SCWatchItem>
            ))}
            {watching.items.length > 3 && !shouldShowMore && (
              <SCMoreButton onClick={() => setShouldShowMore(true)}>More...</SCMoreButton>
            )}
          </div>
        )}
      </td>
    </tr>
  );
};

const FriendsList: React.FC<{ friends: IFriend[] }> = ({ friends }) => {
  if (friends.length === 0) return <p>No matching friends.</p>;

  return (
    <>
      <SCHideForMobile>
        <SCResultsTable cellSpacing={0} cellPadding={0}>
          <thead>
            <tr>
              <th>{/* Avatar */}</th>
              <th>Username</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Currently Watching</th>
            </tr>
          </thead>
          <tbody>
            {friends.map((friend) => (
              <FriendRow friend={friend} key={friend.id} />
            ))}
          </tbody>
        </SCResultsTable>
      </SCHideForMobile>
      <SCShowForMobile>
        {friends.map((friend) => (
          <MobileFriendRow friend={friend} key={friend.id} />
        ))}
      </SCShowForMobile>
    </>
  );
};

export default FriendsList;
