import SCShowForMobile from '../../components/styled/SCShowForMobile';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';

import Link from 'components/Link';
import MaxWidthWrapper from 'components/MaxWidthWrapper';
import SCAvatar from 'components/styled/SCAvatar';
import SCHideForMobile from 'components/styled/SCHideForMobile';
import SCNavLink from 'components/styled/SCNavLink';
import variables from 'components/styled/variables';
import Theme from 'components/Theme';
import HeaderBrowseMenu from 'containers/LoggedInHeader/HeaderBrowseMenu';
import RedLogo from 'img/RedLogo.png';
import {
  FollowRecommendationStore,
  FriendRequestStore,
  getFilteredFollowRecommendations,
  GroupInviteStore,
  GroupRequestStore,
} from 'stores/AlertsStore';
import { useCurrentUser } from 'stores/auth/CurrentUserStore';

import BetaImage from './beta.png';
import HeaderSearch from './HeaderSearch';
import NavLink from './NavLink';
import SlideOutMenu, { showSlideOutModal } from './SlideOutMenu';

const SCLoggedInHeader = styled.div`
  background-color: ${variables.colors.background.site};
`;

const SCContent = styled.div`
  align-items: center;
  display: flex;
  height: 100px;
  padding: 0 60px;

  @media (max-width: 1000px) {
    padding: 0 40px 0 20px;
  }
`;

const SCAlerts = styled.div`
  background-color: ${variables.colors.background.red};
  line-height: 30px;
  min-width: 30px;
  text-align: center;
  font-family: ${variables.fonts.heading};
  text-transform: uppercase;
  font-size: 20px;
  color: white;
  border-radius: 15px;
`;

const SCBetaImage = styled.img.attrs({ src: BetaImage })`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;

  @media (max-width: 1000px) {
    height: 80px;
  }
`;

const SCHamburger = styled.button`
  color: ${rgba('white', 0.5)};
  background: transparent;
  border: none;
  box-shadow: none;
  margin-left: 40px;
`;

const Alerts: React.FC = () => {
  const friendRequest = FriendRequestStore.usePaginatedData();
  const groupInvites = GroupInviteStore.usePaginatedData();
  const groupRequests = GroupRequestStore.usePaginatedData();
  const followRecommendations = getFilteredFollowRecommendations(
    FollowRecommendationStore.usePaginatedData().items,
  );
  const total =
    friendRequest.count + groupInvites.count + groupRequests.count + followRecommendations.length;

  React.useEffect(() => {
    const refetch = async () => {
      FriendRequestStore.fetchAction.run(undefined, { treatAsUpdate: true });
      GroupInviteStore.fetchAction.run(undefined, { treatAsUpdate: true });
      GroupRequestStore.fetchAction.run(undefined, { treatAsUpdate: true });
      FollowRecommendationStore.fetchAction.run(undefined, { treatAsUpdate: true });
      timeout = setTimeout(refetch, 30000);
    };
    let timeout = setTimeout(refetch, 30000);
    return () => clearTimeout(timeout);
  }, []);

  return <>{total > 0 ? <SCAlerts>{total}</SCAlerts> : 'Alerts'}</>;
};

const LoggedInHeader: React.FC = () => {
  const currentUser = useCurrentUser();

  const showHamburgerMenu = () => {
    showSlideOutModal();
  };

  return (
    <Theme theme='dark'>
      <SCLoggedInHeader>
        <MaxWidthWrapper theme='dark'>
          <SCContent>
            <Link href='/'>
              <img src={RedLogo} alt='BingeRoom' />
            </Link>
            <SCHideForMobile>
              <NavLink href='/dashboard/movies'>My Movies</NavLink>
            </SCHideForMobile>
            <SCHideForMobile>
              <NavLink href='/dashboard/shows'>My Shows</NavLink>
            </SCHideForMobile>
            <SCHideForMobile>
              <NavLink href='/dashboard/groups'>My Groups</NavLink>
            </SCHideForMobile>
            <SCHideForMobile>
              <HeaderBrowseMenu />
            </SCHideForMobile>
            <HeaderSearch />
            <SCHideForMobile>
              <NavLink href='/dashboard/alerts'>
                <Alerts />
              </NavLink>
            </SCHideForMobile>
            <SCHideForMobile>
              <SCNavLink href='/dashboard/profile'>
                <SCAvatar variant='header' imageUrl={currentUser?.avatar} />
              </SCNavLink>
            </SCHideForMobile>
            <SCShowForMobile>
              <SCHamburger onClick={showHamburgerMenu}>
                <i className='fas fa-bars' />
              </SCHamburger>
            </SCShowForMobile>
            <SCBetaImage />
          </SCContent>
        </MaxWidthWrapper>
      </SCLoggedInHeader>
      <SlideOutMenu />
    </Theme>
  );
};

export default LoggedInHeader;
