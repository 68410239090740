import React from 'react';

import LoggedInPageWrapper from 'containers/LoggedInPageWrapper';
import MovieTable from 'containers/pages/Dashboard/Movies/MovieTable';
import { BrowseNewMoviesStore } from 'stores/movies/MovieStore';

const BrowseNewReleaseMovies: React.FC = () => {
  const movies = BrowseNewMoviesStore.usePaginatedData();

  return (
    <LoggedInPageWrapper
      bg='white'
      isLoading={movies.isLoading}
      title='Browse - New Releases Movies'>
      {movies.items.length ? (
        <MovieTable
          type='browse'
          movies={movies.items}
          hasMorePages={movies.hasMorePages}
          loadMore={movies.loadNextPage}
          isUpdating={movies.isUpdating}
        />
      ) : (
        <p>No movies yet</p>
      )}
    </LoggedInPageWrapper>
  );
};

export default BrowseNewReleaseMovies;
