import React from 'react';
import styled from 'styled-components';

import { INetwork } from 'api/CatalogAPI';

const SCNetworkWrapper = styled.div`
  display: flex;
`;

const SCNetworkImage = styled.img`
  display: block;
  margin-left: 10px;

  & + & {
    margin-left: 40px;
  }
`;

interface IProps {
  networks: INetwork[];
}

const Networks: React.FC<IProps> = function ({ networks }) {
  if (!networks || !Array.isArray(networks)) {
    return null;
  }
  const networkLogos =
    networks.map((network) => network.logo.w92).filter((logo) => logo != null) ?? [];

  if (networkLogos.length === 0) {
    return null;
  }

  return (
    <SCNetworkWrapper>
      <div>on</div>
      {networkLogos.map((logo, index) => (
        <SCNetworkImage key={index} src={logo} />
      ))}
    </SCNetworkWrapper>
  );
};

export default Networks;
