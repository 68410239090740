import React, { MouseEvent } from 'react';
import styled from 'styled-components';

import SCButton from 'components/styled/SCButton';
import GenericModal from 'containers/modals/GenericModal';

const SCGenericPromptInner = styled.div`
  align-items: center;
  background: white;
  display: flex;
  flex-direction: column;
  width: 100%;

  &:first-of-type {
    padding: 12px 0 5px;
  }

  h3 {
    font-weight: normal;
    font-size: 20px;
    line-height: 150%;
    margin: 15px 0 11px;
  }

  p {
    font-size: 20px;
    line-height: 150%;
    opacity: 0.5;
    margin: 0 0 49px;
    text-align: center;
  }

  @media(max-width: 1000px) {
    border-radius: 10px;
    margin-bottom: 7px;
    max-width: 360px;

    h3 {
      font-size: 14px;
      margin: 8px 0 0;
    }

    p {
      font-size: 14px;
      margin: 0 0 12px;
    }
  }
`;
const SCPrimaryButtpn = styled(SCButton)`
  @media(max-width: 1000px) {
    border-radius: 5px;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
  }
`;
const SCCancelButton = styled(SCButton)`
  color: black;
  height: 35px;

  @media(max-width: 1000px) {
    font-size: 14px;
    width: 100%;
  }
`;
const closeButtonStyles = `
  top: 13px;
  right: 19px;

  @media(max-width: 1000px) {
    display: none;
  }
`;
const modalStyles = `
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 550px;
  justify-content: center;
  max-height: 550px;

  @media(max-width: 1000px) {
    align-self: flex-end;
    height: auto;
    max-height: initial;
    background: transparent;
    justify-content: flex-end;
    margin-bottom: 21px;
  }
`;

const GenericPrompt: React.FC<{
  children: React.ReactNode,
  buttonAction: (event: MouseEvent) => void,
  buttonText: string,
  isLoading?: boolean,
  onRequestClose: () => void
}> = ({
  buttonAction,
  buttonText,
  children,
  isLoading,
  onRequestClose
}) => {
  return (
    <GenericModal
      onRequestClose={onRequestClose}
      modalStyles={modalStyles}
      closeButtonStyles={closeButtonStyles}
      isLoading={isLoading}
    >
      <SCGenericPromptInner>
        {children}
        <SCPrimaryButtpn onClick={(e) => buttonAction(e)}>
          {buttonText}
        </SCPrimaryButtpn>
      </SCGenericPromptInner>
      <SCGenericPromptInner>
        <SCCancelButton onClick={onRequestClose} transparent={true}>
          Cancel
        </SCCancelButton>
      </SCGenericPromptInner>
    </GenericModal>
  );
};

export default GenericPrompt;