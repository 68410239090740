import React from 'react';

export function useIsComponentMounted(): () => boolean {
  const ref = React.useRef(true);
  React.useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  });
  return () => ref.current;
}
