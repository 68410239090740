import React from 'react';
import { ThemeProvider } from 'styled-components';

type TThemeVariant = 'light' | 'dark' | 'red';

const Theme: React.FC<{ theme?: TThemeVariant }> = ({ theme, children }) => {
  if (theme == null) return <>{children}</>;

  return (
    <ThemeProvider
      theme={{
        light: theme === 'light',
        dark: theme !== 'light',
        red: theme === 'red',
      }}>
      {children}
    </ThemeProvider>
  );
};

export default Theme;
