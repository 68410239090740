import { BaseResponse, fetchJson, Methods } from 'api/index';

export type TCreateUploadTokenFormat = 'png' | 'jpg' | 'video';
export type TCreateUploadTokenResponse = {
  url: string;
  fields: {
      acl: 'private';
      key: string;
      bucket: string;
      'X-Amz-Algorithm': string;
      'X-Amz-Credential':string;
      'X-Amz-Date': string;
      'Policy': string;
      'X-Amz-Signature': string;
  };
  id: string;
}
export type TGetUploadStatusResponse = {
  'format': TCreateUploadTokenFormat;
  'status': 'NOT_UPLOADED' | 'COMPLETE' | string;
  'type': 'upload';
  'user': number;
  'destinations': {
      'raw': string;
  },
  'id': string;
  'preview': string;
  'media_id': number;
};


const createUploadToken = async (format: TCreateUploadTokenFormat): Promise<BaseResponse<TCreateUploadTokenResponse>> => {
  const endpoint = format === 'video' ? 'media/upload/video' : 'media/upload/image';
  return fetchJson(endpoint, Methods.POST, { format });
};
const pollForUploadStatus = async (uploadId: string): Promise<BaseResponse<TGetUploadStatusResponse>> => {
  const uploadStatus = await getUploadStatus(uploadId);

  if (!uploadStatus.success) {
    throw uploadStatus.error;
  }

  if (uploadStatus.response.status !== 'COMPLETE') {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return pollForUploadStatus(uploadId);
  }

  return uploadStatus;
};
const getUploadStatus =  async (uploadId: string): Promise<BaseResponse<TGetUploadStatusResponse>> =>
  fetchJson(`media/upload/${uploadId}`, Methods.GET);

export default {
  createUploadToken,
  pollForUploadStatus
};
