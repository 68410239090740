import variables from '../../../components/styled/variables';
import styled from 'styled-components';

import SCCTAButton from './SCCTAButton';

const SCSection = styled.section`
  color: white;
  position: relative;

  &:nth-of-type(even) {
    background-color: ${variables.colors.background.red};

    ${SCCTAButton} {
      background: white;
      color: ${variables.colors.primary};
    }
  }

  h1 {
    font-family: Gala, sans-serif;
    font-size: 125px;
    font-weight: bold;
    margin: 0;
    line-height: 110%;
    text-transform: uppercase;
  }

  h2 {
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
  }
`;

export default SCSection;
