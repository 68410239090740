import React from 'react';
import styled from 'styled-components';

import Footer from 'components/Footer';
import MaxWidthWrapper from 'components/MaxWidthWrapper';
import Spinner from 'components/Spinner';
import SCPageTitle from 'components/styled/SCPageTitle';
import variables from 'components/styled/variables';
import Theme from 'components/Theme';
import LoggedInHeader from 'containers/LoggedInHeader';
import EditShowModal from 'containers/modals/EditShowModal';
import getImageUrlString from 'helpers/getImageUrlString';
import useEffectOnce from '../hooks/useEffectOnce';

const BACKGROUNDS = {
  white: 'white',
  gray: variables.colors.background.gray,
  red: variables.colors.background.red,
  none: 'transparent',
};

const SCPageWrapper = styled.div<{ backgroundImage?: string }>`
  min-height: 100vh;
  background-image: ${(props) =>
    props.backgroundImage == null ? null : getImageUrlString(props.backgroundImage)};
  background-size: cover;
  background-position: center;
`;

const SCContents = styled.div<{ background: TBackground }>`
  display: block;
  background-color: ${(props) => BACKGROUNDS[props.background]};
  min-height: calc(100vh - ${variables.headerHeight});
  color: ${(props) => (props.theme.dark ? 'white' : 'black')};
  display: flex;
  flex-direction: column;
`;

const SCPageLoader = styled(Spinner)`
  font-size: 30px;
  align-self: center;
  justify-self: center;
  text-align: center;
  opacity: 0.5;
`;

const SCMaxWidthWrapper = styled(MaxWidthWrapper)<{ noTopPadding?: boolean }>`
  position: relative;
  flex-grow: 1;
  padding-top: ${(props) => props.noTopPadding && 0};

  ${SCPageLoader} {
    margin-top: ${(props) => props.noTopPadding && '60px'};
  }
`;

type TBackground = keyof typeof BACKGROUNDS;

interface IProps {
  children?: React.ReactNode;
  bg: TBackground;
  title?: string;
  isLoading?: boolean;
  noTopPadding?: boolean;
  backgroundImage?: string;
}

const LoggedInPageWrapper: React.FC<IProps> = ({
  children,
  title,
  bg,
  noTopPadding = false,
  isLoading = false,
  backgroundImage,
}) => {
  useEffectOnce(() => {
    window.scrollTo(0, 0);
  });

  const theme = bg === 'white' ? 'light' : bg === 'red' ? 'red' : 'dark';

  return (
    <SCPageWrapper backgroundImage={backgroundImage}>
      <LoggedInHeader />
      <Theme theme={theme}>
        <SCContents background={backgroundImage == null ? bg : 'none'}>
          <SCMaxWidthWrapper noTopPadding={noTopPadding} includePadding>
            {title != null && <SCPageTitle>{title}</SCPageTitle>}
            {isLoading ? <SCPageLoader /> : children}
          </SCMaxWidthWrapper>
          <Footer includeBackground={backgroundImage != null} />
        </SCContents>
      </Theme>
      <EditShowModal />
    </SCPageWrapper>
  );
};

export default LoggedInPageWrapper;
