import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';

import Link from 'components/Link';

// Due to an issue with React Router, we have to explicitly remove any custom props before forwarding
export default styled(({ isActive, ...rest }) => <Link {...rest} />)<{ isActive?: boolean }>`
  background: transparent;
  border: 0;
  box-sizing: border-box;
  color: ${(props) => (props.isActive ? 'white' : rgba('white', 0.5))};
  cursor: pointer;
  display: inline-block;
  margin-left: 40px;
  outline: none;
  text-align: left;
`;
