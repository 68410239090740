import { rgba } from 'polished';
import styled from 'styled-components';

import variables from 'components/styled/variables';

export default styled.button<{
  cancel?: boolean;
  horizontal?: boolean;
  vertical?: boolean;
  transparent?: boolean;
  disabled?: boolean;
  block?: boolean;
  link?: boolean;
}>`
  background-color: ${({ theme, disabled, transparent, link, cancel }) => {
    if (transparent || link) return 'transparent';
    if (cancel) return rgba('black', 0.1);
    if (disabled)
      return theme.dark
        ? rgba(variables.colors.background.disabled, 0.5)
        : variables.colors.background.disabled;
    if (theme.red) return 'white';
    return variables.colors.text.link;
  }};
  border: 0;
  border-radius: 10px;
  box-sizing: border-box;
  color: ${({ cancel, disabled, transparent, link, theme }) => {
    if (transparent || link) return theme.dark ? 'white' : variables.colors.text.link;
    if (cancel) return 'black';
    if (disabled) return theme.dark ? rgba('white', 0.5) : variables.colors.text.disabled;
    if (theme.red) return variables.colors.text.link;
    return 'white';
  }};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  display: ${(props) => (props.block ? 'block' : 'inline-block')};
  font-size: 16px;
  line-height: ${({ transparent, link }) => (transparent || link ? 'inherit' : '60px')};
  height: ${({ transparent, link }) => (transparent || link ? 'fit-content' : '60px')};
  padding: 0 ${({ link }) => (link ? 0 : '60px')};
  white-space: nowrap;
  outline: none;

  & + & {
    ${(props) => !props.block && 'margin-left: 20px;'}
    ${(props) => props.block && 'margin-top: 20px;'}
  }
`;
