import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import TwelveAvatarImage from 'img/12_profile_icon.png';
import logo from 'img/logoWithBox.svg';
import NetflixLogo from 'img/netflix_logo.png';
import AmazonLogo from 'img/prime_video.png';
import ImposterAvatarImage from 'img/profile_icon.png';
import StrangerThingsBackground from 'img/stranger_things_bg.png';
import TheBoysBackground from 'img/theboys_bg.png';

import SCAvatar from './styled/SCAvatar';

const SCWrapper = styled.div<{ background: string }>`
  background-image: url('${(props) => props.background}');
  background-size: cover;
  box-sizing: border-box;
  min-height: 100vh;
  padding: 40px 100px;
  
  @media (max-width: 1000px) {
    padding: 20px;
  }
`;

const SCLogoWrapper = styled.div`
  margin: 0 0 40px;
  position: relative;
`;

const SCLogo = styled.img`
  width: 153px;
`;

const PADDING = 5;
const SCNetworkLogo = styled.div<{ logo: string }>`
  background-image: url("${(props) => props.logo}");
  position: absolute;
  top: ${3 + PADDING}px;
  left: ${64 + PADDING}px;
  width: ${86 - PADDING * 2}px;
  height: ${47 - PADDING * 2}px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const SCRecommendation = styled.div`
  display: flex;
  margin: 80px 0 0;
  color: white;
  align-items: center;
`;

const SCShowTitleWrapper = styled.div`
  margin: 0 20px;
`;

const SCShowTitle = styled.div`
  font-size: 24px;
  margin: 0;
`;

const SCRecommendedBy = styled.div`
  font-size: 16px;
  opacity: 0.5;
  margin: 0;
`;

type Show = {
  title: string;
  logo: string;
  background: string;
  username: string;
  avatar: string;
};

const SHOWS: { [key: string]: Show } = {
  theboys: {
    title: 'The Boys',
    logo: AmazonLogo,
    background: TheBoysBackground,
    username: 'imposter1',
    avatar: ImposterAvatarImage,
  },
  strangerthings: {
    title: 'Stranger Things',
    logo: NetflixLogo,
    background: StrangerThingsBackground,
    username: 'twelve',
    avatar: TwelveAvatarImage,
  },
};

interface IProps {
  children: React.ReactNode;
  showKey?: keyof typeof SHOWS;
}

const LoginPageWrapper: React.FC<IProps> = ({ children, showKey = 'strangerthings' }) => {
  const show = SHOWS[showKey];
  return (
    <SCWrapper background={show.background}>
      <Link to='/'>
        <SCLogoWrapper>
          <SCLogo src={logo} alt='BingeRoom' />
          <SCNetworkLogo logo={show.logo} />
        </SCLogoWrapper>
      </Link>
      {children}
      <SCRecommendation>
        <SCAvatar variant='comment' imageUrl={show.avatar} />
        <SCShowTitleWrapper>
          <SCShowTitle>{show.title}</SCShowTitle>
          <SCRecommendedBy>
            Recommended by <b>@{show.username}</b>
          </SCRecommendedBy>
        </SCShowTitleWrapper>
      </SCRecommendation>
    </SCWrapper>
  );
};

export default LoginPageWrapper;
