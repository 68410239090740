import GroupsAPI, { IGroup } from 'api/GroupsAPI';
import { createPaginationStore } from 'stores/index';

export const GroupListStore = createPaginationStore<undefined, IGroup>((action, page) =>
  GroupsAPI.listGroups(page),
);

export const GroupListAllStore = createPaginationStore<undefined, IGroup>((action, page) =>
  GroupsAPI.listGroups(page, 1000),
);

