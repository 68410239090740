import styled from 'styled-components';

import variables from 'components/styled/variables';
import getImageUrlString from 'helpers/getImageUrlString';

const SIZES: { [variant: string]: { size: number; fontSize: number } } = {
  comment: { size: 60, fontSize: 24 },
  header: { size: 30, fontSize: 16 },
  profile: { size: 170, fontSize: 55 },
  promptSmall: { size: 35, fontSize: 18 },
  promptBig: { size: 90, fontSize: 45 }
};

export default styled.div<{
  variant: keyof typeof SIZES;
  imageUrl?: string | null;
  icon?: string;
}>`
  background-color: #666;
  background-position: center;
  background-size: cover;
  background-image: ${(props) => getImageUrlString(props.imageUrl)};
  border-radius: 50%;
  color: white;
  position: relative;
  height: ${(props) => SIZES[props.variant].size}px;
  width: ${(props) => SIZES[props.variant].size}px;

  &::after {
    display: ${(props) => (props.imageUrl ? 'none' : 'block')};
    font-family: ${variables.fonts.icon};
    font-weight: 900;
    content: ${(props) => props.icon ? `"${props.icon}"`: "'\f007'"};
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: ${(props) => SIZES[props.variant].fontSize}px;
  }
`;
