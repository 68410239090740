export const validateEmail = (email: string): string | undefined => {
  if (email.indexOf('@') < 0 || email.length < 5 || email.indexOf('.') < 0)
    return 'Invalid email address';
};

export const validateUsername = (username: string): string | undefined => {
  if (username.length < 5) return 'Username must be at least 5 characters';
};

export const validatePassword = (password: string): string | undefined => {
  if (password.length < 8) return 'Password must be at least 8 characters';
  if (password.match(/[A-Z]/g) == null) return 'Password must contain at least one capital letter';
  if (password.match(/[a-z]/g) == null)
    return 'Password must contain at least one lowercase letter';
  if (password.match(/\d/g) == null) return 'Password must contain at least one number';
};

export const validateFirstName = (firstName: string): string | undefined => {
  if (firstName.length < 1) return 'First Name is required';
};

export const validateLastName = (lastName: string): string | undefined => {
  if (lastName.length < 1) return 'Last Name is required';
};
