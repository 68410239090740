import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { TFriendWatchItem } from 'api/FriendsAPI';
import Carousel, { CarouselItem } from 'components/Carousel';
import Link from 'components/Link';
import SCAvatar from 'components/styled/SCAvatar';
import getImageUrlString from 'helpers/getImageUrlString';
import DefaultGroupAvatar from 'img/DefaultGroupAvatar.png';

const MOBILE_SCALE = 0.65;
const MOBILE_SIZE = 375 - 40 - 40;

const SCCarousel = styled(Carousel)`
  margin: 0 -60px;
  padding: 0 60px;

  @media (max-width: 1000px) {
    margin: 0 -20px;
    padding: 0 20px;
  }
`;

const SCCarouselItem = styled(CarouselItem)`
  overflow: hidden;
  flex-shrink: 0;
`;

const SCCarouselItemInner = styled.div`
  display: flex;
  background-color: #141414;
  margin-right: 20px;
`;

const SCWrapper = styled.div`
  color: ${(props) => (props.theme.dark ? 'white' : 'black')};

  &::before,
  &::after {
    clear: both;
    content: '';
    display: table;
  }
`;

const SCGroupDetails = styled.div`
  width: 190px;
  box-sizing: border-box;
  padding: 20px;
  color: white;

  h1 {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 10px;
  }

  p {
    color: ${rgba('white', 0.5)};

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 1000px) {
    width: ${MOBILE_SIZE - 190 * MOBILE_SCALE}px;
    font-size: 12px;
    box-sizing: border-box;
    min-height: ${MOBILE_SCALE * 280}px;
    padding: 10px;

    h1 {
      font-size: 14px;
    }
  }
`;

const SCPosterLink = styled(Link)`
  display: flex;
`;

const SCPoster = styled.div<{ imageUrl?: string | null }>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${rgba('white', 0.125)};
  background-image: ${(props) => getImageUrlString(props.imageUrl ?? DefaultGroupAvatar)};
  border: 0;
  display: block;
  height: 280px;
  outline: none;
  position: relative;
  width: 190px;

  @media (max-width: 1000px) {
    width: ${190 * MOBILE_SCALE}px;
    height: ${280 * MOBILE_SCALE}px;
  }
`;

const SCAvatarWrapper = styled.div`
  margin: 0 0 20px;

  @media (max-width: 1000px) {
    margin-bottom: 10px;

    ${SCAvatar} {
      width: 30px;
      height: 30px;
    }
  }
`;

const CurrentEpisode: React.FC<{ item: TFriendWatchItem }> = ({ item }) => {
  if (item.episodes == null) return null;

  type TEpisode = { season: number; episode: number };
  const latest: TEpisode | null = item.episodes.reduce<TEpisode | null>((latest, { key }) => {
    const match = key.match(/s(\d+)e(\d+)/) ?? [];
    if (match.length < 3) return latest;

    const episode = { season: parseInt(match[1], 10), episode: parseInt(match[2], 10) };
    if (latest == null) return episode;

    if (latest.season > episode.season) return latest;
    if (latest.season === episode.season && latest.episode > episode.episode) return latest;

    return episode;
  }, null);

  if (latest == null) return null;

  return (
    <p>
      <b>Season {latest.season}</b>
      <br />
      Episode {latest.episode}
    </p>
  );
};

const WatchItem: React.FC<{ item: TFriendWatchItem }> = ({ item }) => {
  const getMediaTitle = () => (item.media.model === 'movie' ? item.media.title : item.media.name);
  const href = `/dashboard/${item.media.model === 'movie' ? 'movies' : 'shows'}/details/${item.media.id
    }`;
  return (
    <SCCarouselItem>
      <SCCarouselItemInner>
        <SCGroupDetails>
          <SCAvatarWrapper>
            <Link href={`/dashboard/user/${item.user.id}`}>
              <SCAvatar variant='comment' imageUrl={item.user.avatar} />
            </Link>
          </SCAvatarWrapper>
          <h1>@{item.user.username}</h1>
          <p>
            Currently Watching:
            <br />
            {getMediaTitle()}
          </p>
          <CurrentEpisode item={item} />
        </SCGroupDetails>
        <SCPosterLink href={href}>
          <SCPoster imageUrl={item.media.images.poster.w185} />
        </SCPosterLink>
      </SCCarouselItemInner>
    </SCCarouselItem>
  );
};

interface IProps {
  items: TFriendWatchItem[];
}

const FriendsWatchingCarousel: React.FC<IProps> = ({ items }) => {
  return (
    <SCWrapper>
      <SCCarousel>
        {items.map((item, index) => (
          <WatchItem key={index} item={item} />
        ))}
      </SCCarousel>
    </SCWrapper>
  );
};

export default FriendsWatchingCarousel;
