import { Store } from 'pullstate';
import React, { useState } from 'react';
import styled from 'styled-components';

import SCHideForMobile from 'components/styled/SCHideForMobile';
import GenericModal from 'containers/modals/GenericModal';

const SCTopBar = styled.div`
  background-color: black;
  padding: 90px 0 0;

  @media (max-width: 1000px) {
    padding: 60px 20px;
  }
`;

const SCVideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const closeButtonStyles = `
  position: absolute;
  top: 13px;
  right: 30px;
  background: transparent;
  color: white;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const WatchVideoStore = new Store<string | null>(null);

export const displayWatchVideoModal = (url: string): void => {
  console.log('trying to display video modal for', url);
  WatchVideoStore.update(() => url);
};

const hideWatchVideoModal = (): void => WatchVideoStore.update(() => null);

const WatchVideoActualModal: React.FC<{ url: string }> = ({ url }) => {
  const [isLoading, setIsLoading] = useState(true);
  const iframeProps = {
    frameBorder: '0',
    src: url,
    allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
    allowFullScreen: true
  };

  return (
    <div>
      <GenericModal closeButtonStyles={closeButtonStyles} isLoading={isLoading} onRequestClose={hideWatchVideoModal}>
        <SCHideForMobile>
          <SCTopBar />
        </SCHideForMobile>
        <SCVideoContainer>
          <iframe title="Watch Trailer" {...iframeProps}></iframe>
        </SCVideoContainer>
      </GenericModal>
      {isLoading
        ? <iframe
          height="1px"
          width="1px"
          onLoad={() => setIsLoading(false)}
          title="Loading..."
          {...iframeProps}
        >
        </iframe>
        : null
      }
    </div>
  );
};

const WatchVideoModal: React.FC = () => {
  const url = WatchVideoStore.useState();
  return url == null ? null : <WatchVideoActualModal url={url} />;
};

export default WatchVideoModal;
