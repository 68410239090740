import cn from 'classnames';
import moment from 'moment';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { IMovie, ITVShow } from 'api/CatalogAPI';
import { IUserData } from 'api/ProfileAPI';
import ReviewsAPI, { IMovieReview, ITVShowReview } from 'api/ReviewsAPI';
import Link from 'components/Link';
import Rating from 'components/Rating';
import SCHideForMobile from 'components/styled/SCHideForMobile';
import SCShowForMobile from 'components/styled/SCShowForMobile';
import variables from 'components/styled/variables';
import { displayWatchVideoModal } from 'containers/modals/WatchVideoModal';
import getImageUrlString from 'helpers/getImageUrlString';
import playIcon from 'img/play_icon.png';
import { IProfileUserState } from 'stores/auth/CurrentUserStore';

import SCAvatar from './styled/SCAvatar';

const SCReview = styled.div`
  background: white;
  border: solid 1px #ccc;
  border-radius: 10px;
  color: black;
  margin-bottom: 20px;
  padding 13px 25px 20px;

  @media (min-width: 1000px) {
    padding: 13px 20px 34px;
  }
`;

const SCProfileReview = styled.div`
  background: white;
  border: solid 1px #ccc;
  border-radius: 10px;
  color: black;
  margin-bottom: 20px;
  padding 30px 20px 15px;

  @media (min-width: 1000px) {
    padding: 10px 20px 34px;
  }
`;

const SCReviewHeader = styled.div`
  display: flex;
  margin-bottom: 14px;

  > div a {
    color: black;
  }
`;

const SCReviewHeaderInner = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 13px;

  div {
    margin: 0;
  }
  * {
    margin-bottom: 3px;
  }

  @media(min-width:1000px) {
    width: 100%;
    padding-top: 13px;
  }
`;

const SCLinkToUser = styled(Link)`
  font-weight: bold;
`;

const SCReviewEditLink = styled(Link)`
  color: ${variables.colors.primary} !important;
  text-decoration: underline;
`;

const SCGroupLink = styled(Link)`
  color: black !important;
  text-decoration: underline;
`;

const SCReviewTitle = styled.h3`
  font-size: 20px;
  line-height: 30px;
  margin: 0 0 3px 0;
`;

const SCReviewMeta = styled.div`
  margin-bottom: 35px;

  p {
    margin: 0 0 6px;
    line-height: 24px;
  }

  @media(min-width: 1000px) {
    margin-bottom: 0px;
  }
`;

const SCReviewMetaSpoliers = styled.p`
  color: ${variables.colors.primary};
  font-weight: bold;
`;

const SCReviewMetaVote = styled.div`
  align-items: center;
  display: flex;
  margin: 15px 0 0 0;

  svg {
    cursor: pointer;
  }
  svg.active {
    cursor: initial;
  }
  span {
    margin: 0 10px;
    user-select: none;
  }

  @media(min-width: 1000px) {
    display: flex;
    flex-direction: column;
    margin: 0 30px 0 0;

    span {
      margin: 12px 0;
    }
  }
`;

const SCReviewBody = styled.div`
  line-height: 24px;

  @media(min-width: 1000px) {
    padding-right: 72px;
  }
`;

const SCAttachment = styled.div`
  display: inline-block;
  position: relative;
  margin-top: 25px;

  img, video {
    max-width: 100%;
    max-height: 300px;
  }
`;
const SCVideoOverlay = styled.div`
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  img {
    max-height: 80px;
    max-width: 80px;
  }
`;
const SCFlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const SCProfileDesktopLeft = styled.div`
  margin-bottom: 36px;
  p {
    margin 0 0 4px 0;
  }
`;

const SCProfileDesktopRight = styled.div`
  > * {
    text-align: right;
    display: block;
    margin-bottom: 4px;
    line-height: 24px;
  }
`;

const SCDesktopHeaderWrapper = styled.div`
display: flex;
flex-direction: column;
width: 100%;
`;

const SCDesktopHeaderInner = styled.div`
display: flex;
width: 100%;
margin-bottom: 10px;
`;

const SCProfileDesktopWrapper = styled.div`
  display:flex;
  width: 100%;
  flex-direction: row;
`;

const SCProfileDesktopPosterWrapper = styled.div`
  display:flex;
  flex-direction: column;
  margin-right: 29px;
  text-align: center;
  max-width: 190px;
  width: 190px;
  
  a:nth-child(2) {
    margin-top: 13px;
    font-size: 16px;
    color: black;
    text-decoration: none;  
  }
`;

const SCProfileDesktopMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SCProfileReviewHeaderMobile = styled.p`
  margin: 0;
`;

export const SCPoster = styled.button<{ width: number, height: number, multiplier: number, imageUrl?: string }>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${(props) => getImageUrlString(props.imageUrl)};
  border: 0;
  cursor: pointer;
  display: block;
  height: ${(props) => props.height * props.multiplier}px;
  outline: none;
  position: relative;
  width: ${(props) => props.width * props.multiplier}px;

  &::after {
    display: ${(props) => props.imageUrl != null && 'none'};
    color: ${rgba('white', 0.5)};
    content: '\f03d';
    font-family: ${variables.fonts.icon};
    font-size: 30px;
    font-weight: 900;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const getMediaTitle = (show?: ITVShow, movie?: IMovie): string => {
  return show?.name || movie?.title || '';
};

const UpArrow: React.FC<{ active: boolean, onClick: React.MouseEventHandler }> = ({ active = false, onClick }) => {
  return (
    <svg className={cn({ active })} onClick={onClick} width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 0L27.9904 15.75H2.00962L15 0Z" fill={active ? variables.colors.background.disabled : 'black'} />
      <path d="M21.25 26V15H15H8.75V26H21.25Z" fill={active ? variables.colors.background.disabled : 'black'} />
    </svg>
  );
};

const DownArrow: React.FC<{ active: boolean, onClick: React.MouseEventHandler }> = ({ active = false, onClick }) => {
  return (
    <svg className={cn({ active })} onClick={onClick} width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 26L2.00962 10.25L27.9904 10.25L15 26Z" fill={active ? variables.colors.background.disabled : 'black'} />
      <path d="M8.75 0L8.75 11L15 11L21.25 11L21.25 0L8.75 0Z" fill={active ? variables.colors.background.disabled : 'black'} />
    </svg>
  );
};

const ReviewAvatar: React.FC<{ user: IUserData | IProfileUserState }> = ({ user }) => {
  return (
    <Link href={`/dashboard/user/${user.id}`}>
      <SCAvatar variant='comment' imageUrl={user?.avatar}></SCAvatar>
    </Link>
  );
};

const ReviewDate: React.FC<{ review: IReview, onlyDate?: boolean, onlyTime?: boolean }> = ({ review, onlyDate, onlyTime }) => {
  if (onlyDate) {
    return (
      <span>{moment(review.created_at).format('MM/DD/YYYY')}</span>
    );
  }
  if (onlyTime) {
    return (
      <span>{moment(review.created_at).format('hh:mm A')}</span>
    );
  }
  return (
    <span>{moment(review.created_at).format('MM/DD/YYYY hh:mm A')}</span>
  );
};

const ReviewGroup: React.FC<{ review: IReview }> = ({ review }) => {
  const group = review?.group;
  return (
    group && (
      <p>
        Group: <SCGroupLink href={`/dashboard/groups/details/${group.id}`}>{group.title}</SCGroupLink>
      </p>
    )
  );
};

const ReviewVote: React.FC<{ vote: number, voted: boolean, review: IReview, setVote: (arg0: number) => void, setVoted: (arg0: boolean) => void }> = ({ vote, voted, review, setVote, setVoted }) => {
  const { my_vote } = review;
  const upvoted = my_vote?.action === 'up';
  const downvoted = my_vote?.action === 'down';

  const toggleUpvote = () => {
    if (voted) {
      // Product doesnt want people to be able to modify votes.
      return;
    }
    if (upvoted) {
      ReviewsAPI.removeVoteOnReview(review.id);
    } else {
      ReviewsAPI.voteOnReview(review.id, 'up');
    }
    setVote(vote + 1);
    setVoted(true);
  };
  const toggleDownvote = () => {
    if (voted || vote === 0) {
      // Product doesnt want people to be able to modify votes, or vote < 0.
      return;
    }
    if (downvoted) {
      ReviewsAPI.removeVoteOnReview(review.id);
    } else {
      ReviewsAPI.voteOnReview(review.id, 'down');
    }
    setVote(vote - 1);
    setVoted(true);
  };
  return (
    <SCReviewMetaVote>
      <UpArrow onClick={() => toggleUpvote()} active={voted} />
      <span>{vote}</span>
      <DownArrow onClick={() => toggleDownvote()} active={voted || vote === 0} />
    </SCReviewMetaVote>
  );
};

const ReviewEditLink: React.FC<IReviewProps> = ({ review, show, movie }) => {
  const { is_me, season } = review;
  const isMovie = !!movie;
  const mediaId = isMovie ? movie?.id : show?.id;
  const editUrl = isMovie
    ? `/dashboard/review/movie/${mediaId}`
    : `/dashboard/review/tv/${mediaId}/${season}`;

  return (
    is_me ? <SCReviewEditLink href={editUrl}>Edit Review</SCReviewEditLink> : null
  );
};

const ReviewHasSpoilers: React.FC<{ review: IReview }> = ({ review }) => {
  const { has_spoilers } = review;

  return (
    has_spoilers ? <SCReviewMetaSpoliers>Warning: Spoilers</SCReviewMetaSpoliers> : null
  );
};

const ReviewTitle: React.FC<{ review: IReview }> = ({ review }) => {
  const { title } = review;

  return (
    <SCReviewTitle>{title}</SCReviewTitle>
  );
};

const ReviewRating: React.FC<{ review: IReview }> = ({ review }) => {
  const { media_rating } = review;

  return (
    media_rating?.value ? <div><Rating value={media_rating?.value}></Rating></div> : null
  );
};

const ReviewMediaLink: React.FC<{ show?: ITVShow, movie?: IMovie, children: React.ReactNode }> = ({ show, movie, children }) => {
  if (show) {
    return (
      <Link href={`/dashboard/shows/details/${show.id}`}>{children}</Link>
    );
  }
  if (movie) {
    return (
      <Link href={`/dashboard/movies/details/${movie.id}`}>{children}</Link>
    );
  }

  return null;
};

const ReviewAttachment: React.FC<{ review: IReview }> = ({ review }) => {
  const { media } = review;

  return media ? (
    <SCAttachment>
      {
        media.format === 'image'
          ? <img alt='' src={media.path} />
          : <>
            <SCVideoOverlay onClick={() => displayWatchVideoModal(media.path)}>
              <img src={playIcon} alt='Play Icon' />
            </SCVideoOverlay>
            <video src={media?.path} onClick={() => displayWatchVideoModal(media.path)} />
          </>}{' '}
    </SCAttachment>
  ) : null;
};

const ReviewMediaImage: React.FC<{ show?: ITVShow, movie?: IMovie, isMobile?: boolean }> = ({ show, movie, isMobile }) => {
  if (show) {
    return (
      show.images.poster.w342 ? <SCPoster width={190} height={280} imageUrl={show.images.poster.w342} multiplier={isMobile ? .27 : 1} /> : null
    );
  }
  if (movie) {
    return (
      movie.images.poster.w342 ? <SCPoster width={190} height={280} imageUrl={movie.images.poster.w342} multiplier={isMobile ? .27 : 1} /> : null
    );
  }

  return null;
};

const ReviewHeader: React.FC<IReviewProps> = ({ review, user, show, movie }) => {
  const {
    my_vote,
    rating,
  } = review;

  const [vote, setVote] = React.useState(rating);
  const [voted, setVoted] = React.useState(!!my_vote?.action);

  return (
    <>
      <SCShowForMobile>
        <SCReviewHeader>
          <ReviewAvatar user={user} />
          <SCReviewHeaderInner>
            <SCLinkToUser href={`/dashboard/user/${user.id}`}>@{user.username}</SCLinkToUser>
            <ReviewRating review={review} />
            <ReviewDate review={review} />
            <ReviewEditLink review={review} show={show} movie={movie} user={user} />
          </SCReviewHeaderInner>
        </SCReviewHeader>
        <SCReviewMeta>
          <ReviewTitle review={review} />
          <ReviewGroup review={review} />
          <ReviewHasSpoilers review={review} />
          <ReviewVote vote={vote} voted={voted} review={review} setVote={setVote} setVoted={setVoted} />
        </SCReviewMeta>
      </SCShowForMobile>
      <SCHideForMobile>
        <SCReviewHeader>
          <ReviewVote vote={vote} voted={voted} review={review} setVote={setVote} setVoted={setVoted} />
          <SCDesktopHeaderWrapper>
            <SCDesktopHeaderInner>
              <ReviewAvatar user={user} />
              <SCReviewHeaderInner>
                <SCFlexRow>
                  <SCLinkToUser href={`/dashboard/user/${user.id}`}>@{user.username}</SCLinkToUser>
                  <ReviewDate review={review} />
                </SCFlexRow>
                <SCFlexRow>
                  <ReviewRating review={review} />
                  <ReviewEditLink review={review} show={show} movie={movie} user={user} />
                </SCFlexRow>
              </SCReviewHeaderInner>
            </SCDesktopHeaderInner>
            <SCReviewMeta>
              <ReviewTitle review={review} />
              <ReviewGroup review={review} />
              <ReviewHasSpoilers review={review} />
            </SCReviewMeta>
          </SCDesktopHeaderWrapper>

        </SCReviewHeader>
      </SCHideForMobile>
    </>
  );
};

interface IReview extends IMovieReview, ITVShowReview { }
interface IReviewProps {
  review: IReview;
  show?: ITVShow;
  movie?: IMovie;
  user: IUserData | IProfileUserState;
}
const Review: React.FC<IReviewProps> = ({ review, user, show, movie }) => {
  const { body } = review;
  return (
    <SCReview>
      <ReviewHeader review={review} user={user} show={show} movie={movie} />
      <SCReviewBody>{body}</SCReviewBody>
      <ReviewAttachment review={review} />
    </SCReview >
  );
};

const ProfileReview: React.FC<IReviewProps> = ({ review, user, show, movie }) => {
  const { body } = review;
  const mediaTitle = getMediaTitle(show, movie);

  return (
    <SCProfileReview>
      <SCShowForMobile>
        <SCReviewHeader>
          <ReviewMediaImage show={show} movie={movie} isMobile={true} />
          <SCReviewHeaderInner>
            <SCProfileReviewHeaderMobile>{mediaTitle}</SCProfileReviewHeaderMobile>
            <ReviewRating review={review} />
            <ReviewDate review={review} onlyTime={true} />
            <ReviewDate review={review} onlyDate={true} />
            <ReviewEditLink review={review} show={show} movie={movie} user={user} />
          </SCReviewHeaderInner>
        </SCReviewHeader>
        <SCReviewMeta>
          <ReviewTitle review={review} />
          <ReviewGroup review={review} />
          <ReviewHasSpoilers review={review} />
        </SCReviewMeta>
        <SCReviewBody>{body}</SCReviewBody>
        <ReviewAttachment review={review} />
      </SCShowForMobile>
      <SCHideForMobile>
        <SCProfileDesktopWrapper>
          <SCProfileDesktopPosterWrapper>
            <ReviewMediaLink show={show} movie={movie}>
              <ReviewMediaImage show={show} movie={movie} />
            </ReviewMediaLink>
            <ReviewMediaLink show={show} movie={movie}>{getMediaTitle(show, movie)}</ReviewMediaLink>
          </SCProfileDesktopPosterWrapper>
          <SCProfileDesktopMainWrapper>
            <SCFlexRow>
              <SCProfileDesktopLeft>
                <ReviewRating review={review} />
                <ReviewTitle review={review} />
                <ReviewGroup review={review} />
                <ReviewHasSpoilers review={review} />
              </SCProfileDesktopLeft>
              <SCProfileDesktopRight>
                <ReviewDate review={review} onlyDate={true} />
                <ReviewDate review={review} onlyTime={true} />
                <ReviewEditLink review={review} show={show} movie={movie} user={user} />
              </SCProfileDesktopRight>
            </SCFlexRow>
            <div>
              <SCReviewBody>{body}</SCReviewBody>
              <ReviewAttachment review={review} />
            </div>
          </SCProfileDesktopMainWrapper>
        </SCProfileDesktopWrapper>
      </SCHideForMobile >
    </SCProfileReview >
  );
};


export default Review;
export { ProfileReview };
