import { useIsComponentMounted } from 'hooks/useIsComponentMounted';
import {
  createAsyncAction,
  errorResult,
  IOCreateAsyncActionOutput,
  Store,
  successResult,
} from 'pullstate';
import React from 'react';

import { BaseResponse, IPage } from 'api';

export const createGenericAsyncAction = <TActionType, TResponseType>(
  request: (action: TActionType) => Promise<BaseResponse<TResponseType>>,
  postAction?: (action: TActionType, response: TResponseType) => void,
  shortCircuit?: (action: TActionType) => Error | void,
): IOCreateAsyncActionOutput<TActionType, TResponseType> => {
  return createAsyncAction<TActionType, TResponseType>(
    async (action) => {
      try {
        const json = await request(action);
        if (!json.success) throw new Error(json.error.message);

        return successResult(json.response);
      } catch (err) {
        return errorResult([], err.message);
      }
    },
    {
      postActionHook(response) {
        if (response.result.error) return;

        postAction?.(response.args, response.result.payload);
      },
      shortCircuitHook(response) {
        const error = shortCircuit?.(response.args);
        if (error == null) return false;
        return errorResult([], error.message);
      },
    },
  );
};

interface IPaginatedStore<TActionType, TResultType> {
  store: Store<IPage<TResultType>>;
  fetchAction: IOCreateAsyncActionOutput<TActionType, IPage<TResultType>>;
  fetchNextAction: IOCreateAsyncActionOutput<TActionType, IPage<TResultType>>;
  refresh: () => void;
  useItems: () => TResultType[];
  useHasMorePages: () => boolean;
  usePaginatedData: () => {
    isLoaded: boolean;
    isLoading: boolean;
    isUpdating: boolean;
    hasMorePages: boolean;
    items: TResultType[];
    count: number;
    refresh: () => void;
    loadNextPage: () => void;
  };
}

export const createPaginationStore = <TActionType, TResponseType>(
  request: (action: TActionType, page: number) => Promise<BaseResponse<IPage<TResponseType>>>,
  postAction?: (action: TActionType, response: IPage<TResponseType>) => void,
  shortCircuit?: (action: TActionType) => Error | void,
): IPaginatedStore<TActionType, TResponseType> => {
  const store = new Store<IPage<TResponseType>>({
    items: [],
    pagination: { page: 0, total: 0, size: 0 },
    count: 0,
  });

  const fetchAction = createGenericAsyncAction<TActionType, IPage<TResponseType>>(
    (action) => request(action, 1),
    (action, response) => {
      store.update(() => response);
      postAction?.(action, response);
    },
    shortCircuit,
  );

  const fetchNextAction = createGenericAsyncAction<TActionType, IPage<TResponseType>>(
    (action) => {
      const page = store.getRawState().pagination?.page ?? 0;
      return request(action, page + 1);
    },
    (action, response) => {
      store.update((s) => {
        s.pagination.page = response.pagination.page;
        s.items.push(...(response.items as typeof s['items']));
      });
      postAction?.(action, response);
    },
    shortCircuit,
  );

  const useItems = () => store.useState((s) => s.items ?? []);
  const useCount = () => store.useState((s) => s.count ?? 0);

  const useHasMorePages = () =>
    store.useState((s) => {
      const { pagination } = s;
      if (pagination == null) return false;

      const { page, total } = pagination;
      return page < total;
    });

  const refresh = () => fetchAction.run(undefined, { treatAsUpdate: true });

  const usePaginatedData = () => {
    const isComponentMounted = useIsComponentMounted();
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [isLoaded] = fetchAction.useBeckon();
    const items = useItems();
    const count = useCount();
    const loadNextPage = async () => {
      setIsUpdating(true);
      await fetchNextAction.run(undefined, { treatAsUpdate: true });
      isComponentMounted() && setIsUpdating(false);
    };
    const hasMorePages = useHasMorePages();
    return {
      isLoaded,
      isLoading: !isLoaded,
      isUpdating,
      hasMorePages,
      items,
      count,
      refresh,
      loadNextPage,
    };
  };

  return {
    store,
    fetchAction,
    fetchNextAction,
    refresh,
    useItems,
    useHasMorePages,
    usePaginatedData,
  };
};

interface IPaginatedLookupStore<TActionType, TResultType> {
  store: Store<{ [term: string]: IPage<TResultType> }>;
  fetchAction: IOCreateAsyncActionOutput<TActionType, IPage<TResultType>>;
  fetchNextAction: IOCreateAsyncActionOutput<TActionType, IPage<TResultType>>;
  useItems: (action: TActionType) => TResultType[];
  useHasMorePages: (action: TActionType) => boolean;
  usePaginatedData: (
    action: TActionType,
  ) => {
    isLoaded: boolean;
    isLoading: boolean;
    isUpdating: boolean;
    hasMorePages: boolean;
    items: TResultType[];
    refresh: () => void;
    loadNextPage: () => void;
  };
}

export const createPaginatedLookupStore = <TActionType, TResponseType>(
  request: (action: TActionType, page: number) => Promise<BaseResponse<IPage<TResponseType>>>,
  getActionTerm: (action: TActionType) => string,
  postAction?: (action: TActionType, response: IPage<TResponseType>) => void,
  shortCircuit?: (action: TActionType) => Error | void,
): IPaginatedLookupStore<TActionType, TResponseType> => {
  const store = new Store<{ [term: string]: IPage<TResponseType> }>({});

  const fetchAction = createGenericAsyncAction<TActionType, IPage<TResponseType>>(
    (action) => request(action, 1),
    (action, response) => {
      store.update((s) => {
        s[getActionTerm(action)] = response as typeof s[string];
      });
      postAction?.(action, response);
    },
    shortCircuit,
  );

  const fetchNextAction = createGenericAsyncAction<TActionType, IPage<TResponseType>>(
    (action) => {
      const key = getActionTerm(action);
      if (typeof key !== 'string') throw new Error('');

      const page = store.getRawState()[getActionTerm(action)]?.pagination?.page ?? 0;

      return request(action, page + 1);
    },
    (action, response) => {
      store.update((s) => {
        const match = s[getActionTerm(action)];
        if (!match) return;
        match.pagination.page = response.pagination.page;
        match.items.push(...(response.items as typeof s[string]['items']));
      });
      postAction?.(action, response);
    },
    (action) => {
      if (store.getRawState()[getActionTerm(action)] == null)
        return new Error('Cannot fetch more results when no results are present');
      return shortCircuit?.(action);
    },
  );

  const useItems = (action: TActionType) =>
    store.useState((s) => s[getActionTerm(action)]?.items ?? [], [getActionTerm(action)]);

  const useCount = (action: TActionType) =>
    store.useState((s) => s[getActionTerm(action)]?.count ?? 0, [getActionTerm(action)]);

  const useHasMorePages = (action: TActionType) =>
    store.useState(
      (s) =>
        s[getActionTerm(action)] == null
          ? false
          : s[getActionTerm(action)].pagination.page < s[getActionTerm(action)].pagination.total,
      [getActionTerm(action)],
    );

  const usePaginatedData = (action: TActionType) => {
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [isLoaded] = fetchAction.useBeckon(action);
    const items = useItems(action);
    const count = useCount(action);
    const refresh = () => fetchAction.run(action, { treatAsUpdate: true });
    const loadNextPage = async () => {
      setIsUpdating(true);
      await fetchNextAction.run(action, { treatAsUpdate: true });
      setIsUpdating(false);
    };
    const hasMorePages = useHasMorePages(action);

    return {
      isLoaded,
      isLoading: !isLoaded,
      isUpdating,
      hasMorePages,
      items,
      count,
      refresh,
      loadNextPage,
    };
  };

  return {
    store,
    fetchAction,
    fetchNextAction,
    useItems,
    useHasMorePages,
    usePaginatedData,
  };
};
