import React from 'react';

import { validateEmail } from 'helpers/validators';

import ContentBox from './ContentBox';
import FormButton from './FormButton';
import Input from './Input';

interface IProps {
  error?: string;
  isLoading: boolean;
  onCancel(): void;
  onSubmit(email: string): void;
}

const ForgotPasswordRequestForm: React.FC<IProps> = ({ isLoading, onCancel, onSubmit }) => {
  const [email, setEmail] = React.useState('');

  const onSubmitWrapper = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(email);
  };

  const emailError = validateEmail(email);

  const isDisabled = Boolean(emailError || isLoading);

  return (
    <form onSubmit={onSubmitWrapper}>
      <ContentBox title='Forgot Password'>
        <p>
          Please enter your email address.
          <br />
          You will receive a link to create a new password via email.
        </p>
        <Input
          type='email'
          placeholder='Email'
          value={email}
          onChange={setEmail}
          error={emailError}
        />
        <FormButton type='submit' isDisabled={isDisabled}>
          Reset Password
        </FormButton>
        <FormButton type='cancel' onClick={onCancel}>
          Back
        </FormButton>
      </ContentBox>
    </form>
  );
};

export default ForgotPasswordRequestForm;
