import moment from 'moment';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';

import Link from 'components/Link';
import MaxWidthWrapper from 'components/MaxWidthWrapper';
import Theme from 'components/Theme';

const SCFooter = styled.div<{ includeBackground: boolean }>`
  background-color: ${(props) => (props.includeBackground ? '#252525' : 'transparent')};
  color: ${(props) => rgba(props.theme.dark ? 'white' : 'black', 0.4)};
`;

const SCLink = styled(Link)`
  color: ${(props) => (props.theme.dark ? 'white' : 'black')};
  font-weight: 500;

  & + & {
    margin-left: 35px;

    @media (max-width: 1000px) {
      margin-left: 0;
    }
  }
`;

const SCContent = styled.div<{ noBorder: boolean }>`
  border-top: 1px solid
    ${(props) =>
    props.noBorder ? 'transparent' : rgba(props.theme.dark ? 'white' : 'black', 0.15)};
  margin: 0 60px;
  display: flex;
  align-items: center;
  padding: 40px 0;
  justify-content: space-between;

  @media (max-width: 1000px) {
    justify-content: space-around;
    font-size: 12px;
    flex-direction: column;
    height: 100px;
    text-align: justify;
    width: calc(100% - 40px);
    padding: 0;
    margin: 0 20px;
  }
`;

const SCLeft = styled.div`
  white-space: nowrap;
`;

const SCRight = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1000px) {
    justify-content: space-around;
    width: 100%;
  }
`;

interface IProps {
  includeBackground?: boolean;
}

const Footer: React.FC<IProps> = ({ includeBackground = false }) => {
  const year = moment().format('YYYY');
  return (
    <Theme theme={includeBackground ? 'dark' : undefined}>
      <SCFooter includeBackground={includeBackground}>
        <MaxWidthWrapper>
          <SCContent noBorder={includeBackground}>
            <SCLeft>Copyright &copy; {year}, BingeRoom, LLC, All Rights Reserved</SCLeft>
            <SCRight>
              <SCLink
                href='https://blog.bingeroom.com'
                rel='noopener noreferrer'
                noClick
                onClick={() => window.location.assign('https://blog.bingeroom.com')}>
                Blog
              </SCLink>
              <SCLink href='/faqs'>FAQs</SCLink>
              <SCLink href='/privacy'>Privacy Policy</SCLink>
              <SCLink href='/terms'>Terms</SCLink>
              <SCLink href='/contact'>Contact Us</SCLink>
            </SCRight>
          </SCContent>
        </MaxWidthWrapper>
      </SCFooter>
    </Theme>
  );
};

export default Footer;
