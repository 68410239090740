import cn from 'classnames';
import React from 'react';

import css from 'containers/pages/Dashboard/Groups/GroupCoverBar.module.scss';
import getImageUrlString from 'helpers/getImageUrlString';
import DefaultGroupAvatar from 'img/DefaultGroupAvatar.png';
import DefaultGroupCover from 'img/DefaultGroupCover.png';

const UpdateBackgroundButton: React.FC<{
  onChange: (url: string) => void;
}> = ({ onChange }) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const onClick = () => inputRef.current?.click();

  const onChangeWrapper: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target.files?.[0];
    if (file == null) return;

    const reader = new FileReader();
    reader.onload = () => {
      const data = btoa(reader.result as string);
      onChange('data:image/jpeg;base64,' + data);
    };
    reader.readAsBinaryString(file);
  };

  return (
    <>
      <button type='button' className={css.UpdateBackgroundButton} onClick={onClick}>
        <i className={cn('fas fa-camera', css.Icon)} />
        <span className={css.ButtonText}>Update Background Photo</span>
      </button>
      <input type='file' style={{ display: 'none' }} ref={inputRef} onChange={onChangeWrapper} />
    </>
  );
};

const Avatar: React.FC<{
  avatar: string | null;
  edit: boolean;
  onChange: (url: string) => void;
}> = ({ avatar, edit, onChange }) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const onClick = () => inputRef.current?.click();

  const onChangeWrapper: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();

    const file = e.target.files?.[0];
    if (file == null) return;

    const reader = new FileReader();
    reader.onload = () => {
      const data = btoa(reader.result as string);
      onChange('data:image/jpeg;base64,' + data);
    };
    reader.readAsBinaryString(file);
  };

  return (
    <button
      className={css.Avatar}
      type='button'
      onClick={onClick}
      style={{ backgroundImage: getImageUrlString(avatar) }}>
      {edit && (
        <>
          <div className={css.Overlay}>
            <i className={cn('fas fa-camera', css.CameraIcon)} />
            <span className={css.Text}>Update Thumbnail</span>
          </div>
          <span className={cn('fas fa-camera', css.OuterCameraIcon)} />
          <input
            type='file'
            style={{ display: 'none' }}
            ref={inputRef}
            onChange={onChangeWrapper}
          />
        </>
      )}
    </button>
  );
};

interface IProps {
  avatarUrl?: string | null;
  coverUrl?: string | null;
  edit?: boolean;
  onAvatarChange?: (avatar: string | null) => void;
  onCoverChange?: (avatar: string | null) => void;
}

const GroupCoverBar: React.FC<IProps> = ({
  edit = false,
  coverUrl,
  avatarUrl,
  onAvatarChange,
  onCoverChange,
}) => {
  const [cover, setCover] = React.useState<string | null>(coverUrl ?? null);
  const [avatar, setAvatar] = React.useState<string | null>(avatarUrl ?? null);

  const handleAvatarChange = (avatar: string) => {
    setAvatar(avatar);
    onAvatarChange?.(avatar);
  };

  const handleCoverChange = (cover: string) => {
    setCover(cover);
    onCoverChange?.(cover);
  };

  const showAvatar = onAvatarChange != null;

  return (
    <div
      className={cn(css.CoverWrapper, {
        [css.isEditable]: edit,
      })}
      style={{ backgroundImage: getImageUrlString(cover ?? DefaultGroupCover) }}>
      {showAvatar && (
        <Avatar avatar={avatar ?? DefaultGroupAvatar} onChange={handleAvatarChange} edit={edit} />
      )}
      {edit && <UpdateBackgroundButton onChange={handleCoverChange} />}
    </div>
  );
};

export default GroupCoverBar;
