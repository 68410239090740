import { createAsyncAction, errorResult, successResult } from 'pullstate';
import React from 'react';

import AuthAPI from 'api/AuthAPI';
import LoginPageWrapper from 'components/LoginPageWrapper';
import RegisterForm from 'components/RegisterForm';
import { history } from 'containers/AppRouter';
import { CurrentUserStore } from 'stores/auth/CurrentUserStore';

const register = createAsyncAction<
  { email: string; username: string; password: string; firstName: string; lastName: string },
  { token: string }
>(
  async ({ email, username, password, firstName, lastName }) => {
    try {
      const json = await AuthAPI.register(email, username, password, firstName, lastName);
      if (!json.success) throw new Error(json.error.message);

      return successResult(json.response);
    } catch (err) {
      return errorResult([], err.message);
    }
  },
  {
    postActionHook({ result }) {
      if (result.error) return;
      CurrentUserStore.update((s) => {
        s.token = result.payload.token;
      });
      history.push('/register/1');
    },
  },
);

const Register: React.FC = () => {
  const [error, setError] = React.useState('');

  const [isLoading, setIsLoading] = React.useState(false);

  const onRegister = async (
    email: string,
    username: string,
    password: string,
    firstName: string,
    lastName: string,
  ) => {
    setIsLoading(true);
    setError('');
    const results = await register.run({ email, username, password, firstName, lastName });
    if (results.error) setError(results.message);
    setIsLoading(false);
  };

  return (
    <LoginPageWrapper showKey='theboys'>
      <RegisterForm onSubmit={onRegister} error={error} isLoading={isLoading} />
    </LoginPageWrapper>
  );
};

export default Register;
