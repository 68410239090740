import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Link from 'components/Link';
import LoggedInPageWrapper from 'containers/LoggedInPageWrapper';
import ShowTable from 'containers/pages/Dashboard/Shows/ShowTable';
import { BrowseAllShowsStore } from 'stores/shows/ShowStore';

const SCAlphabet = styled.div`
  padding: 0 0 40px;
  display: flex;
  margin-left: -10px;
  width: 100%;
  overflow: auto;
`;

const SCLink = styled(Link)``;

const SCLetter = styled.div<{ selected: boolean }>`
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
  pointer-events: ${(props) => (props.selected ? 'none' : undefined)};
  text-transform: uppercase;

  ${SCLink} {
    padding: 10px;
  }
`;

const Alphabet: React.FC<{ letter: string }> = ({ letter }) => {
  const letters = 'abcdefghijklmnopqrstuvwxyz'.split('');
  return (
    <SCAlphabet>
      {letters.map((l) => (
        <SCLetter selected={l === letter} key={l}>
          <SCLink href={`/dashboard/browse/tv/all/${l}`}>{l}</SCLink>
        </SCLetter>
      ))}
    </SCAlphabet>
  );
};

const BrowseAllShows: React.FC = () => {
  const { letter } = useParams();
  const shows = BrowseAllShowsStore.usePaginatedData({ letter });

  return (
    <LoggedInPageWrapper bg='white' title='Browse - All TV Shows' isLoading={shows.isLoading}>
      {shows.items.length ? (
        <>
          <Alphabet letter={letter} />
          <ShowTable
            type='browse'
            shows={shows.items}
            hasMorePages={shows.hasMorePages}
            loadMore={shows.loadNextPage}
            isUpdating={shows.isUpdating}
          />
        </>
      ) : (
        <p>No shows yet</p>
      )}
    </LoggedInPageWrapper>
  );
};

export default BrowseAllShows;
